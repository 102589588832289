import { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid, Typography, Card, Divider, Box, useMediaQuery, Button, TextField,
    FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material'
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import Typewriter from 'typewriter-effect';
import parse from 'html-react-parser'
import moment from 'moment';
import { companyUser_createTaskByAI } from 'context';
import {
    internshipAreaList, internshipApplicationTypeList, internshipCoffeeList, InternshipGoalForCompanyList, internshipFollowUpUserTypeList,
    internshipPreferredCollegeYearList, internshipPreferredGPAList, internshipSalaryTypeList, internshipTimePeriodList, getNumberOfInternshipTimePeriod,
    InternshipFollowUpUserType, InternshipTimePeriod, getMessageOfValidationError, internshipLanguageList, getNameOfInternshipTimePeriod
} from "utils";
import AILoading from 'assets/animations/ai_loading.json';
import Lottie from 'lottie-react';
import { enqueueSnackbar } from 'notistack';

const info = [
    "Faz 1 başlamadı."
]


const tipTitle = `How IF-AI Works for Your Business`;
const tipDescription = `<p><b>Effortless Talent Analysis: </b><br/>
Leverage IF-AI's automated talent analysis for a comprehensive understanding of your workforce. <br/>
Identify high-potential employees and areas for skill development effortlessly. <br/><br/>

<b>Accelerated Recruitment:</b> <br/>
Shorten the recruitment cycle with IF-AI's quick and precise candidate assessments. <br/>
Ensure that you connect with candidates who align with your company's vision and values. <br/><br/>

<b>Data-Backed HR Strategy:</b><br/>
Access detailed analytics for evidence-based HR strategy formulation. <br/>
Make decisions that drive productivity and contribute to your company's overall success. <br/><br/>

<b>Transform Your HR Landscape with IF-AI:</b> <br/>
IF-AI is not just an AI tool; it's a game-changer in how you approach talent assessment. Experience enhanced precision, speed, and accuracy in your HR processes with IF-AI. <br/><br/>
<b>Ready to Optimize Your HR Operations?</b> <br/><br/>
`

const IFAI = () => {

    const dispatch = useDispatch();
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const { self_companyUserData: companyUser } = useSelector((state) => state.self_companyUser);
    const { accessToken } = useSelector((state) => state.user);
    const [startTyping, setStartTyping] = useState(false);
    const [startTyping2, setStartTyping2] = useState(false);
    const [startTyping3, setStartTyping3] = useState(false);
    const [startTyping4, setStartTyping4] = useState(false);
    const [startTyping5, setStartTyping5] = useState(false);
    const [startTyping6, setStartTyping6] = useState(false);
    const [firstTypingDone, setFirstTypingDone] = useState(false);
    const [secondTypingDone, setSecondTypingDone] = useState(false);
    const [thirdTypingDone, setThirdTypingDone] = useState(false);
    const [fourthTypingDone, setFourthTypingDone] = useState(false);
    const [fifthTypingDone, setFifthTypingDone] = useState(false);
    const [responseEnd, setResponseEnd] = useState(false);
    const [internshipArea, setInternshipArea] = useState("");
    const [internshipGoal, setInternshipGoal] = useState("");
    const [taskLength, setTaskLength] = useState("");
    const [taskType, setTaskType] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [responseAI, setResponseAI] = useState(null);
    const [renderedAreas, setRenderedAreas] = useState([]);
    const [renderedGoals, setRenderedGoals] = useState([]);
    const [openWaitlistDialog, setOpenWaitlistDialog] = useState(false);
    const [loadingAI, setLoadingAI] = useState(false);
    const [regenerate, setRegenerate] = useState(0);

    const numberOfEmployees = companyUser?.company?.companyInformation?.numberOfEmployee?.name === "Up to 10" ? 5 :
        companyUser?.company?.companyInformation?.numberOfEmployee?.name === "10-50" ? 30 :
            companyUser?.company?.companyInformation?.numberOfEmployee?.name === "51-100" ? 75 :
                companyUser?.company?.companyInformation?.numberOfEmployee?.name === "101-250" ? 175 :
                    companyUser?.company?.companyInformation?.numberOfEmployee?.name === "251-999" ? 500 :
                        companyUser?.company?.companyInformation?.numberOfEmployee?.name === "1000+" ? 1000 : 1;
    const industry = companyUser?.company?.companyInformation?.industry?.name || "";
    const foundedIn = moment(companyUser?.company?.companyInformation?.foundedIn).format("YYYY") || "";
    const city = companyUser?.company?.companyInformation?.city?.name || "";
    const country = companyUser?.company?.companyInformation?.country?.name || "";


    const handleTry = () => {

        setLoadingAI(true)

        setTimeout(() => {
            setLoadingAI(false)
            setStartTyping(true)
        }, 3000);

    }

    const FirstTypewriter = useCallback(() => {

        return (
            <Typewriter
                options={{
                    cursor: ""
                }}
                onInit={(typewriter) => {
                    typewriter.changeDelay(25)
                        .typeString('You can now try Task Generation Tool from IF-AI! <br/><br/>')
                        .typeString('Please select the internship area first..')
                        .callFunction(() => {
                            setFirstTypingDone(true)
                            typewriter.stop()
                        })
                        .start()

                }
                }
            />
        );
    }, [startTyping, regenerate])

    const SecondTypewriter = useCallback(() => {
        return (
            <Typewriter
                options={{
                    cursor: ""
                }}
                onInit={(typewriter) => {
                    typewriter.changeDelay(25)
                        .typeString('Please select the internship goal..')
                        .callFunction(() => {
                            setSecondTypingDone(true)
                            typewriter.stop()
                        })
                        .start()

                }
                }
            />
        );
    }, [startTyping2])

    const ThirdTypewriter = useCallback(() => {
        return (
            <Typewriter
                options={{
                    cursor: ""
                }}
                onInit={(typewriter) => {
                    typewriter.changeDelay(25)
                        .typeString('Please select the length of the task..')
                        .callFunction(() => {
                            setThirdTypingDone(true)
                            typewriter.stop()
                        })
                        .start()

                }
                }
            />
        );
    }, [startTyping3])

    const FourthTypewriter = useCallback(() => {
        return (
            <Typewriter
                options={{
                    cursor: ""
                }}
                onInit={(typewriter) => {
                    typewriter.changeDelay(25)
                        .typeString('Please select the type of the task..')
                        .callFunction(() => {
                            setFourthTypingDone(true)
                            typewriter.stop()
                        })
                        .start()

                }
                }
            />
        );
    }, [startTyping4])

    const FifthTypewriter = useCallback(() => {
        return (
            <Typewriter
                options={{
                    cursor: ""
                }}
                onInit={(typewriter) => {
                    typewriter.typeString('Ek açıklamanız varsa yazınız.')
                        .callFunction(() => {
                            setFifthTypingDone(true)
                            typewriter.stop()
                        })
                        .start()

                }
                }
            />
        );
    }, [startTyping5])

    const LoadingTypewritter = useCallback(() => {
        return (
            <Typewriter
                options={{
                    cursor: "."
                }}
                onInit={(typewriter) => {
                    typewriter.typeString(regenerate>0 ? 'Regenerating...' : 'The task is generating..')
                        .callFunction(() => {
                            setFifthTypingDone(true)
                            typewriter.stop()
                        })
                        .start()

                }
                }
            />
        );
    }, [loading])

    const SixthTypewriter = useCallback(() => {
        return (
            <Typewriter
                options={{
                    cursor: "|"
                }}
                onInit={(typewriter) => {
                    typewriter.changeDelay(25)
                        .typeString('Title: <br/>')
                        .typeString(responseAI?.title)
                        .typeString('<br/><br/>')
                        .typeString('Overview: <br/>')
                        .typeString(responseAI?.overview)
                        .typeString('<br/><br/>')
                        .typeString('Deliverables: <br/>')
                        .typeString(responseAI?.deliverables?.map((item, index) => {
                            return `${index + 1} ) ${item?.name} : ${item?.content} <br>`
                        }).join(''))
                        .typeString('<br/>')
                        .typeString('Objectives: <br/><br/>')
                        .typeString(responseAI?.objectives?.map((item, index) => {
                            return item?.duration + "<br/>" + item?.content.replace(/\n/g, "<br/>") + "<br>"
                        }).join(''))
                        .typeString('<br/>')
                        .callFunction(() => {
                            setResponseEnd(true)
                            typewriter.stop()
                        })
                        .start()
                }
                }
            />
        );
    }, [startTyping6, responseAI])

    const handleGenerateTaskAI = () => {
        setLoading(true)
        companyUser_createTaskByAI({
            accessToken: accessToken,
            taskType: taskType,
            taskLength: Number(taskLength),
            numberOfEmployee: numberOfEmployees,
            internshipGoal: internshipGoal,
            industry: industry,
            internshipArea: internshipArea,
            foundedIn: foundedIn,
            detailedDescription: description,
            country: country,
            city: city
        }).then((res) => {
            setResponseAI(res)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }


    useEffect(() => {
        const timeoutIds = [];
        firstTypingDone && internshipAreaList?.forEach((option, index) => {
            const timeoutId = setTimeout(() => {
                setRenderedAreas((prevItems) => [...prevItems, option]);
            }, index * 100);
            timeoutIds.push(timeoutId);
        });

        return () => {
            // Temizlik için timeoutları temizle
            timeoutIds.forEach(clearTimeout);
        };
    }, [firstTypingDone && internshipAreaList]);

    useEffect(() => {
        const timeoutIds = [];
        secondTypingDone && InternshipGoalForCompanyList?.forEach((option, index) => {
            const timeoutId = setTimeout(() => {
                setRenderedGoals((prevItems) => [...prevItems, option]);
            }, index * 100);
            timeoutIds.push(timeoutId);
        });

        return () => {
            // Temizlik için timeoutları temizle
            timeoutIds.forEach(clearTimeout);
        };
    }, [secondTypingDone && InternshipGoalForCompanyList]);

    const [scale, setScale] = useState(1);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setScale((prevScale) => (prevScale === 1 ? 1.2 : 1));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return <DashboardLayout info={info}>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} >
                <AnimatedTitle title="IF-AI" />
            </Grid>
            <Grid item xs={12} lg={8} xl={9} >
                {!startTyping && !loadingAI && <Typography variant="h7" fontWeight={500} component="div" sx={{ color: 'text.secondary' }}>
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter.changeDelay(25)
                                .typeString(`<strong>Welcome to IF-AI: Revolutionizing HR Evaluations for Companies</strong> <br/><br/>`)
                                .changeDelay(25)
                                .typeString(`<strong>🚀 Unlock Efficiency, Elevate Talent Assessment!</strong> <br/><br/>`)
                                .typeString(`Companies, meet your new ally in HR optimization—IF-AI! Redefining the way you evaluate and understand your talent, IF-AI provides advanced assistance in streamlining and accelerating your HR processes. <br/><br/>`)
                                .changeDelay(10)
                                .typeString(`<strong>Why Choose IF-AI for Your HR Needs?</strong> <br/><br/>`)
                                .typeString(`<strong>1.⁠ ⁠Intelligent Talent Assessment:</strong>  <br/>`)
                                .typeString(`Experience a paradigm shift in talent evaluation. IF-AI employs cutting-edge technology to analyze and assess the skills, potential, and compatibility of your workforce seamlessly. <br/><br/>`)
                                .typeString(`<strong>2.⁠ ⁠Streamlined Recruitment Process:</strong>  <br/>`)
                                .typeString(`Optimize your recruitment journey with IF-AI's efficient evaluations. Identify the right candidates faster, saving valuable time and resources in the hiring process. <br/><br/>`)
                                .typeString(`<strong>3.⁠ ⁠Data-Driven Decision Making:</strong>  <br/>`)
                                .typeString(`Harness the power of data for informed decision-making. IF-AI provides comprehensive insights into candidate profiles, empowering you to make strategic HR decisions backed by real-time analytics. <br/><br/>`)
                                .typeString(`<strong>4.⁠ ⁠Customized Evaluation Modules:</strong>  <br/>`)
                                .typeString(`Tailor evaluations to meet your company's specific needs. IF-AI offers customizable modules, ensuring that assessments align with your industry, values, and unique requirements. <br/><br/>`)
                                .typeString(``)
                                .typeString(``)
                                .callFunction(() => {
                                    console.log('String typed out!');
                                })
                                // .deleteAll()
                                .callFunction(() => {
                                    console.log('All strings were deleted');
                                })
                                .start();
                        }}
                    />
                </Typography>}
                {loadingAI && <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Lottie
                        animationData={AILoading}
                        style={{
                            width: '200px', height: '200px',
                            // animasyon ile büyüyüp küçülsün 
                            transition: 'transform 1s ease-in-out',
                            transform: `scale(${scale})`,

                        }}
                        autoplay={true}
                        loop={true}
                    />
                </Box>
                }
                {startTyping && !loadingAI && <Box sx={{
                    height: '100%', width: '100%', backgroundColor: '#222',
                    borderRadius: 2, p: 5, borderWidth: 4, borderColor: '#FF9737', borderStyle: 'solid',
                }}>
                    {!responseAI && <Typography variant="h6" fontWeight={800} component="div" sx={{
                        color: '#fff',
                        '::before': {
                            borderColor: 'white !important',
                        },
                        '::after': {
                            borderColor: 'white !important',
                        },

                    }}>
                        <FirstTypewriter />
                    </Typography>}
                    {firstTypingDone && !responseAI &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                            {renderedAreas.map((option, index) => (
                                <Button
                                    key={index}
                                    variant="outlined"
                                    size="large"
                                    onClick={() => {
                                        setInternshipArea(option.name);
                                        setStartTyping2(true);
                                    }}
                                    sx={{
                                        color: 'white',
                                        fontSize: 'small',
                                        borderRadius: 4,
                                        borderColor: internshipArea === option.name ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                        borderWidth: internshipArea === option.name ? 3 : 1,
                                        height: '40px',
                                        mt: 2,
                                        mr: 1,
                                    }}
                                >
                                    {option.name}
                                </Button>
                            ))}
                        </Box>

                    }
                    {startTyping2 && !responseAI && <>
                        <Typography variant="h6" fontWeight={800} component="div" sx={{ mt: 5, color: '#fff' }}>
                            <SecondTypewriter />
                        </Typography>
                        {secondTypingDone &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                                {renderedGoals?.map((option, index) => (
                                    <Button
                                        key={index}
                                        variant="outlined"
                                        size="large"
                                        onClick={() => {
                                            setInternshipGoal(option.name);
                                            setStartTyping3(true);
                                        }}
                                        sx={{
                                            color: 'white',
                                            borderRadius: 4,
                                            fontSize: 'small',
                                            borderColor: internshipGoal === option.name ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                            borderWidth: internshipGoal === option.name ? 3 : 1,
                                            height: '40px',
                                            mt: 2,
                                            mr: 1,
                                        }}
                                    >
                                        {option.name}
                                    </Button>
                                ))}
                            </Box>

                        }
                    </>}
                    {startTyping3 && !responseAI && <>
                        <Typography variant="h6" fontWeight={800} component="div" sx={{ mt: 5, color: '#fff' }}>
                            <ThirdTypewriter />
                        </Typography>
                        {thirdTypingDone && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskLength(1)
                                    setStartTyping4(true)
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskLength === 1 ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskLength === 1 ? 3 : 1,
                                    width: '90px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >1 day</Button>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskLength(2)
                                    setStartTyping4(true)
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskLength === 2 ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskLength === 2 ? 3 : 1,
                                    width: '90px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >2 days</Button>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskLength(3)
                                    setStartTyping4(true)
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskLength === 3 ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskLength === 3 ? 3 : 1,
                                    width: '90px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >3 days</Button>
                        </Box>
                        }
                    </>}
                    {startTyping4 && !responseAI && <>
                        <Typography variant="h6" fontWeight={800} component="div" sx={{ mt: 5, color: '#fff' }}>
                            <FourthTypewriter />
                        </Typography>
                        {fourthTypingDone && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskType('Application')
                                    handleGenerateTaskAI()
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskType === 'Application' ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskType === 'Application' ? 3 : 1,
                                    width: '100px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >Application</Button>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskType('Design')
                                    handleGenerateTaskAI()
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskType === 'Design' ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskType === 'Design' ? 3 : 1,
                                    width: '100px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >Design</Button>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskType('Examination')
                                    handleGenerateTaskAI()
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskType === 'Examination' ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskType === 'Examination' ? 3 : 1,
                                    width: '100px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >Examination</Button>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskType('Evaluation')
                                    handleGenerateTaskAI()
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskType === 'Evaluation' ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskType === 'Evaluation' ? 3 : 1,
                                    width: '100px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >Evaluation</Button>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskType('Refactoring')
                                    handleGenerateTaskAI()
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskType === 'Refactoring' ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskType === 'Refactoring' ? 3 : 1,
                                    width: '100px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >Refactoring</Button>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskType('Reporting')
                                    handleGenerateTaskAI()
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskType === 'Reporting' ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskType === 'Reporting' ? 3 : 1,
                                    width: '100px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >Reporting</Button>
                            <Button variant="outlined" size='large'
                                onClick={() => {
                                    setTaskType('Research')
                                    handleGenerateTaskAI()
                                }
                                }
                                sx={{
                                    color: 'white',
                                    borderRadius: 4,
                                    fontSize: 'small',
                                    borderColor: taskType === 'Research' ? 'white' : 'rgba(228, 219, 233, 0.25)',
                                    borderWidth: taskType === 'Research' ? 3 : 1,
                                    width: '100px',
                                    height: '40px',
                                    mt: 2,
                                    mr: 1
                                }}
                            >Research</Button>
                        </Box>}
                    </>}
                    {/* {startTyping5 && !responseAI && <>
                        <Typography variant="h6" fontWeight={800} component="div" sx={{ mt: 5, color: '#fff' }}>
                            <FifthTypewriter />
                        </Typography>
                        {fifthTypingDone && <TextField fullWidth variant="standard"
                            InputProps={{
                                sx: {
                                    mt: 2,
                                    mb: 2,
                                    color: 'white',
                                    '::before': {
                                        borderBottomColor: 'white !important',
                                    },
                                    '::after': {
                                        borderBottomColor: 'white !important',
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: { color: 'white', borderColor: 'white' },
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    console.log('do validate');
                                    console.log(e.target.value)
                                    setDescription(e.target.value)
                                    handleGenerateTaskAI()
                                }
                            }}
                            inputRef={inputRef5}
                        />}
                    </>} */}
                    {loading && <>
                        <Typography variant="h6" fontWeight={800} component="div" sx={{ mt: 5, color: '#fff' }}>
                            <LoadingTypewritter />
                        </Typography>
                    </>}
                    {responseAI && !loading && <>
                        <Typography variant="h7" fontWeight={800} component="div" sx={{ mt: 5, color: '#fff' }}>
                            <SixthTypewriter />
                        </Typography>
                        {responseEnd && <>
                            <Button variant="contained" size='large'
                                onClick={() => {
                                    enqueueSnackbar('Copied to clipboard', { variant: 'success' })
                                }
                                }
                                sx={{
                                    color: 'black',
                                    width: '150px',
                                    backgroundColor: 'white',
                                    mr: 2
                                }}
                            >Copy</Button>

                            <Button variant="contained" size='large' color='secondary'
                                onClick={() => {
                                    setRegenerate(regenerate + 1)
                                    handleGenerateTaskAI()
                                    setResponseEnd(false)

                                }}
                                sx={{

                                    width: '150px',
                                }
                                }
                            >ReGenerate</Button>
                        </>}
                    </>}

                </Box>
                }



            </Grid>




            {upLg && <Grid item xs={12} lg={4} xl={3}>
                <Card sx={{
                    padding: 3, backgroundColor: "#F8F8F8", border: '1px solid #E0E0E0',
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                }}>
                    <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} display={"flex"} flexDirection={"column"} >
                        <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >{parse(tipTitle)}</Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body1" fontSize={"small"} fontWeight={"regular"}>
                            {parse(tipDescription)}
                        </Typography>
                        <Button variant="contained" fullWidth color='secondary' size='large'
                            onClick={() => { setOpenWaitlistDialog(true) }}
                            sx={{
                                borderRadius: 1.5,
                                animation: 'shadowPulse 2s infinite',
                                '@keyframes shadowPulse': {
                                    '0%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1)'
                                    },
                                    '50%': {
                                        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.25)',
                                        transform: 'scale(1.02)'
                                    },
                                    '100%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1)'
                                    },
                                },
                                '&:hover': {
                                    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)',
                                    transform: 'translateY(-2px)',
                                },
                                '&:active': {
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    transform: 'translateY(2px)',
                                },
                            }}
                        >Join the Waitlist</Button>
                        <Button variant="contained" fullWidth color='primary' size='large'
                            onClick={handleTry}
                            sx={{
                                borderRadius: 1.5,
                                mt: 2,
                                animation: 'shadowPulse 2s infinite',
                                '@keyframes shadowPulse': {
                                    '0%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1)'
                                    },
                                    '50%': {
                                        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.25)',
                                        transform: 'scale(1.02)'
                                    },
                                    '100%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1)'
                                    },
                                },
                                '&:hover': {
                                    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)',
                                    transform: 'translateY(-2px)',
                                },
                                '&:active': {
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    transform: 'translateY(2px)',
                                },
                            }}
                        >Try</Button>
                    </Box>

                </Card>
            </Grid>}
        </Grid>

        <Dialog open={openWaitlistDialog} onClose={() => { setOpenWaitlistDialog(false) }}>
            <DialogTitle>
                IF-AI Waitlist
            </DialogTitle>
            <DialogContent>
                You have been added to the waitlist successfully.
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => { setOpenWaitlistDialog(false) }}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </DashboardLayout >
}

export default IFAI


