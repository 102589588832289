export const demoDocumentsData = [
    {
        id: '1',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: false
    },
    {
        id: '2',
        title: 'Q1 Company Financial Report PDF',
        color: "#2F64A7",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: true
    },
    {
        id: '3',
        title: 'Q1 Company Financial Report PDF',
        color: "#248E9D",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: false
    },
    {
        id: '4',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: false
    },
    {
        id: '5',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: true
    },
    {
        id: '6',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: false
    },
    {
        id: '7',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: false
    },
    {
        id: '8',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: true
    },
    {
        id: '9',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: false
    },
    {
        id: '10',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: true
    },
    {
        id: '11',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: false
    },
    {
        id: '12',
        title: 'Q1 Company Financial Report PDF',
        color: "#FF9737",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra facilisi senectus pulvinar dui.",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=3",
        user: 'Kevin Ryson',
        archive: true
    },
]