import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_completeAssignedTask = async ({ accessToken, assignTaskId, rate }) => {

    const data = JSON.stringify({
        query: `mutation CompanyAdmin_completeAssignedTask($filter: CompleteAssignedTaskByAssignerFilterInput!, $input: CompleteAssignedTaskByAssignerInput!) {
  companyAdmin_completeAssignedTask(filter: $filter, input: $input) {
    _id
  }
}`,
        variables: {
            "filter": {
              "id": assignTaskId
            },
            "input": {
              "evaluations": [
                {
                  "questionId": "1",
                  "rate": rate
                }
              ]
            }
          }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.companyAdmin_completeAssignedTask
    } catch (err) {
        return err
    }
}
