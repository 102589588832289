import { motion } from "framer-motion"

const sliderDuration = 1

export const SliderForAuth = ({ children }) => {
    return <motion.div
        initial={{ translateX: '-100%' }}
        animate={{ translateX: '0%' }}
        transition={{ duration: sliderDuration }}
    >
        {children}
    </motion.div>
}

