export const InternshipTimePeriod = {
  fourWeeks: {
    value: "fourWeeks",
    name: "4 Weeks",
    months: 1,
    numberValue: 4,
  },
  eightWeeks: {
    value: "eightWeeks",
    name: "8 Weeks",
    months: 2,
    numberValue: 8,
  },
  twelveWeeks: {
    value: "twelveWeeks",
    name: "12 Weeks",
    months: 3,
    numberValue: 12,
  },
  sixteenWeeks: {
    value: "sixteenWeeks",
    name: "16 Weeks",
    months: 4,
    numberValue: 16,
  },
  twentyWeeks: {
    value: "twentyWeeks",
    name: "20 Weeks",
    months: 5,
    numberValue: 20,
  },
  twentyFourWeeks: {
    value: "twentyFourWeeks",
    name: "24 Weeks",
    months: 6,
    numberValue: 24,
  },
};

//console.log("regex: ", "fourWeeks".replace(/([A-Z])/g, " $1"));

export const internshipTimePeriodList = Object.values(InternshipTimePeriod);

export const internshipTimePeriodValueList = Object.values(
  InternshipTimePeriod
).map((period) => period.value);

export const internshipTimePeriodNameList = Object.values(
  InternshipTimePeriod
).map((period) => period.name);

export function getValueOfInternshipTimePeriod(name) {
  for (const key of Object.keys(InternshipTimePeriod)) {
    if (InternshipTimePeriod[key].name === name) {
      return InternshipTimePeriod[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipTimePeriod(value) {
  for (const key of Object.keys(InternshipTimePeriod)) {
    if (InternshipTimePeriod[key].value === value) {
      return InternshipTimePeriod[key].name;
    }
  }
  return null;
}

export function getNumberOfInternshipTimePeriod(value) {
  for (const key of Object.keys(InternshipTimePeriod)) {
    if (InternshipTimePeriod[key].value === value) {
      return InternshipTimePeriod[key].numberValue;
    }
  }
  return null;
}

export function getInternshipExpireDate(startDate, timePeriod) {
  let months = InternshipTimePeriod[timePeriod];
  const date = new Date(startDate);
  date.setMonth(date.getMonth() + months);

  return date;
}

export function isInternshipExpired(startDate, timePeriod) {
  const expireDate = getInternshipExpireDate(startDate, timePeriod);

  return new Date() > expireDate;
}
