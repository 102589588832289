
import { Card, Grid } from '@mui/material';


export const SettingsContainer = ({ children, pb, mb, ml }) => {
    return <Grid item xs={12} ml={ml ?? {xs:0, sm:5}} mb={mb ?? 5}><Card sx={{
        pb: pb ?? 5, borderRadius: "20px", backdropFilter: "blur(20px)",
        backgroundColor: '#FFFFFF33', boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)"
    }}>
        {children}
    </Card>
    </Grid>
}
