
export const intern_premium_title = `<b>Tips</b>`

export const intern_premiumTips = `
<p style="text-align: justify;">
Are you ready to take your internship experience to the next level? Look no further than InternIF Premium - your gateway to exclusive opportunities and dedicated support.
<br/>
<br/>
<b>Tailored Internship Placements:</b>
<br/> 
Say goodbye to endless job searches.
<br/>
<br/>
<b>Global Network of Opportunities:</b>
<br/>
 Whether you're dreaming of a tech internship in Silicon Valley or a marketing placement in Paris, we've got you covered.
<br/>
<br/>
<b>Expert Guidance and Support:</b>
<br/>
 From day one, you'll have a dedicated support team by your side. Benefit from personalized orientation programs, ongoing mentoring, and round-the-clock assistance to ensure a smooth and successful internship experience.
<br/>
<br/>
<b>Certification and Recognition:</b>
<br/>
 Earn a prestigious certificate upon completion of your internship, validating your skills and enhancing your resume.
<br/>
<br/>
<b>Exclusive Premium Features:</b>
<br/>
 Choose from a range of premium packages tailored to your needs.<br/>
<br/>
</p>
`

export const intern_premium_title_tr = `<b>İpuçları</b>`
export const intern_premiumTips_tr = `
<p style="text-align: justify;">
Staj deneyiminizi bir üst seviyeye taşımaya hazır mısınız? InternIF Premium - özel fırsatlar ve özel desteklerinizin kapısı-'na göz atın.
<br/>
<br/>
<b>Kişiselleştirilmiş Staj Yerleştirmeleri:</b>
<br/>
Sonsuz iş aramalarına veda edin.
<br/>
<br/>
<b>Fırsatlar Ağı:</b>
<br/>
Silikon Vadisi'nde bir teknoloji stajı veya Paris'te bir pazarlama yerleşimi hayal ediyor olun, sizin için düşündük.
<br/>
<br/>
<b>Uzman Rehberlik ve Destek:</b>
<br/>
İlk günden itibaren yanınızda olacak özel bir destek ekibiniz olacak. Kişiselleştirilmiş oryantasyon programlarından, sürekli mentorluğa ve sorunsuz ve başarılı bir staj deneyimi yaşamanızı sağlamak için günün her saati destek alacaksınız.
<br/>
<br/>
<b>Sertifikasyon ve Tanınma:</b>
<br/>
Stajınızı tamamladığınızda prestijli bir sertifika alacak ve becerilerinizi doğrulayacak ve özgeçmişinizi güçlendirecek.
<br/>
<br/>
<b>Özel Premium Özellikler:</b>
<br/>
İhtiyaçlarınıza uygun bir dizi premium paket arasından seçim yapın.
<br/>
</p>
`



