export const InternshipArea = {
  BusinessAndManagement: {
    value: "BusinessAndManagement",
    name: "Business and Management",
  },
  Consulting: {
    value: "Consulting",
    name: "Consulting",
  },
  EducationAndTeaching: {
    value: "EducationAndTeaching",
    name: "Education and Teaching",
  },
  EngineeringAndArchitecture: {
    value: "EngineeringAndArchitecture",
    name: "Engineering and Architecture",
  },
  EnvironmentalAndSustainability: {
    value: "EnvironmentalAndSustainability",
    name: "Environmental and Sustainability",
  },
  FashionAndDesign: {
    value: "FashionAndDesign",
    name: "Fashion and Design",
  },
  FinanceAndAccounting: {
    value: "FinanceAndAccounting",
    name: "Finance and Accounting",
  },
  GovernmentAndPublicPolicy: {
    value: "GovernmentAndPublicPolicy",
    name: "Government and Public Policy",
  },
  GraphicDesignAndMultimedia: {
    value: "GraphicDesignAndMultimedia",
    name: "Graphic Design and Multimedia",
  },
  HealthcareAndMedical: {
    value: "HealthcareAndMedical",
    name: "Healthcare and Medical",
  },
  HumanResources: {
    value: "HumanResources",
    name: "Human Resources",
  },
  InternshipArea: {
    value: "InternshipArea",
    name: "Internship Area",
  },
  JournalismAndMedia: {
    value: "JournalismAndMedia",
    name: "Journalism and Media",
  },
  Legal: {
    value: "Legal",
    name: "Legal",
  },
  MarketingAndAdvertising: {
    value: "MarketingAndAdvertising",
    name: "Marketing and Advertising",
  },
  NonprofitAndSocialServices: {
    value: "NonprofitAndSocialServices",
    name: "Nonprofit and Social Services",
  },
  ResearchAndDevelopment: {
    value: "ResearchAndDevelopment",
    name: "Research and Development",
  },
  Sales: {
    value: "Sales",
    name: "Sales",
  },
  TechnologyAndSoftwareDevelopment: {
    value: "TechnologyAndSoftwareDevelopment",
    name: "Technology and Software Development",
  },
};


export const internshipAreaList = Object.values(InternshipArea);

export const internshipAreaValueList = Object.values(InternshipArea).map(
  (period) => period.value
);

export const internshipAreaNameList = Object.values(InternshipArea).map(
  (period) => period.name
);

export function getValueOfInternshipArea(name) {
  for (const key of Object.keys(InternshipArea)) {
    if (InternshipArea[key].name === name) {
      return InternshipArea[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipArea(value) {
  for (const key of Object.keys(InternshipArea)) {
    if (InternshipArea[key].value === value) {
      return InternshipArea[key].name;
    }
  }
  return null;
}