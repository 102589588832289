export const InternshipPreferredCollegeYear = {
  None: {
    value: "None",
    name: "None",
  },
  SecondOrHigher: {
    value: "SecondOrHigher",
    name: "2 +",
  },
  ThirdOrHigher: {
    value: "ThirdOrHigher",
    name: "3 +",
  },
  Forth: {
    value: "Forth",
    name: "4",
  },
};

export const internshipPreferredCollegeYearList = Object.values(
  InternshipPreferredCollegeYear
);

export const internshipPreferredCollegeYearValueList = Object.values(
  InternshipPreferredCollegeYear
).map((period) => period.value);

export const internshipPreferredCollegeYearNameList = Object.values(
  InternshipPreferredCollegeYear
).map((period) => period.name);

export function getValueOfInternshipPreferredCollegeYear(name) {
  for (const key of Object.keys(InternshipPreferredCollegeYear)) {
    if (InternshipPreferredCollegeYear[key].name === name) {
      return InternshipPreferredCollegeYear[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipPreferredCollegeYear(value) {
  for (const key of Object.keys(InternshipPreferredCollegeYear)) {
    if (InternshipPreferredCollegeYear[key].value === value) {
      return InternshipPreferredCollegeYear[key].name;
    }
  }
  return null;
}
