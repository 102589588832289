import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, Checkbox, Typography, Button } from '@mui/material';
import AuthLayout from 'layouts/AuthLayout'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack'
import { BasicInput, BlackButton } from 'components'
import { AuthHead} from "page-sections/auth";
import { useNavigate } from 'react-router';
import { requestResetPasswordFunc } from 'context';
import {FileCopy} from "@mui/icons-material";



const ValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email address'),
})


const Signin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { handleSubmit, handleChange, handleBlur, errors, values, touched } = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            dispatch(requestResetPasswordFunc({ email: values.email })).then(res => {
                if (res?.payload?.isSuccess) {
                    enqueueSnackbar('The email has been sent to reset password. Please check your mailbox!', { variant: 'success' })
                    process.env.REACT_APP_TEST === "true" && enqueueSnackbar(<div>
                        {"Registration link: "}
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => {
                                const textToCopy = `http://app.internif.com/reset-password?token=${res?.payload.data.token}`
                                const textArea = document.createElement('textarea');
                                textArea.value = textToCopy;
                                document.body.appendChild(textArea);
                                textArea.select();
                                document.execCommand('copy');
                                document.body.removeChild(textArea);
                            }}
                        >
                            Copy Link
                            <FileCopy />
                        </Button>
                    </div>, { variant: 'warning', autoHideDuration: 6000 });
                    navigate('/check-your-email-forgot/?email=' + values.email)
                } else {
                    enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
                }
            })
        }
    })

    return <AuthLayout>
        <Grid item xs={12} justifyContent="center" alignItems="center" flexDirection={"column"}>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Reset Password" description="We’ll send a link to reset your password. The link expires in 15 minutes." />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" marginTop="30px">

            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" width="100%">
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Box flexDirection="column" alignItems="center" gap="10px" width="100%">
                        <BasicInput
                            name="email"
                            type="email"
                            label="Email"
                            placeholder="Enter your email address"
                            value={values?.email}
                            error={touched.email && errors.email}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%">
                            <BlackButton
                                name="Reset Password"
                                isActive={values?.email && !errors?.email}
                                handleSubmit={handleSubmit}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"15px"} mb={"30px"}>
                            <Typography variant="h7" color="primary.300">
                                Do you have an account? <a href="/signin" style={{ textDecoration: 'none' }}><Typography variant="h7" color="info.main" ml={"5px"}>
                                    Sign In
                                </Typography>
                                </a>
                            </Typography>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Grid>
    </AuthLayout >
}

export default Signin