import React from 'react'
import { Box } from '@mui/material'

import {
    CompletedTasksIcon,
    InternshipProgressIcon,
    WorkedHoursIcon,
} from 'assets'
import { TaskWidget } from 'components'


// export const UpperWidget = ({ color, icon, text, bottomText, isDemo }) => {

//   return (
//     <Box
//       sx={{
//         backgroundColor: color,
//         border: "1px solid #E5EAED",
//         borderRadius: "8px",

//         minWidth: "180px",

//         display: "flex",
//         flexDirection: "column",
//         gap: '6px',

//         p: { xs: '20px' },
//         width: "100%",
//       }}
//     >

//       <Box
//         sx={{
//           width: '32px',
//           height: '32px',
//         }}
//       >
//         <img src={icon} alt="" height="100%" />
//       </Box>

//       <Typography
//         sx={{
//           fontFamily: 'Causten-Bold',
//           fontSize: { xs: '24px', sm: '40px', lg: '40px', xl: '40px' },
//           lineHeight: '32px',
//           color: '#fff',

//           mt: { xs: '36px' },
//         }}
//       >
//         {text}
//       </Typography>

//       <Typography
//         sx={{

//           fontFamily: 'Causten-Regular',
//           fontSize: { xs: '16px', sm: '16px', lg: '20px', xl: '20px' },
//           lineHeight: '24px',
//           color: '#fff',
//           whiteSpace: 'nowrap',
//         }}
//       >
//         {bottomText}
//       </Typography>

//     </Box>

//   )
// }



export const DocumentsWidgetSection = ({ isDemo }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row', lg: 'row', xl: 'row' },
                justifyContent: { md: 'space-between' },

                gap: '16px',
                // backgroundColor: 'red',
                overflow: 'auto',
                pb: { xs: '15px' },

                '&::-webkit-scrollbar': {
                    width: '3px',
                    height: '5px',
                },
                '&::-webkit-scrollbar-track': {
                    // boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    // webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    // outline: '1px solid slategrey'
                }
            }}
        >

            <TaskWidget
                color='#248E9D'
                icon={CompletedTasksIcon}
                text={`${isDemo ? 12 : 0}`}
                bottomText="Completed Tasks"
            />

            <TaskWidget
                color='#3D7CF8'
                icon={WorkedHoursIcon}
                text={`${isDemo ? 24 : 0}`}
                bottomText="Ongoing Tasks"
            />

            <TaskWidget
                color='#FF9737'
                icon={InternshipProgressIcon}
                text={`${isDemo ? 14 : 0}`}
                bottomText="Total Team Members"
            />

        </Box>
    )
}