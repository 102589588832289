import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import { SettingsContainer } from 'components';
import { ResumeBackgroundImage } from './_ResumeBackgroundImage';
import { ResumeProfileImage } from './_ResumeProfileImage';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const ResumeHeader = ({ user }) => {

    const upXl = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const navigate = useNavigate();

    return <SettingsContainer pb={2} mb={3}>

        <ResumeBackgroundImage image={user?.personalInformation?.backgroundImageUrl} />

        <ResumeProfileImage image={user?.personalInformation?.profileImageUrl} />

        <Box pl={3} pt={3} pr={3}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h4">
                    {user.personalInformation.name} {user.personalInformation.surname}
                </Typography>
                {!upXl && <Button variant='outlined' size='medium' onClick={() => navigate("/my-account")}>
                    Edit Account</Button>}
            </Box>
            <Box mt={2} display={"flex"} flexDirection={{ xs: 'column', sm: 'row' }} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h7" color={"grey.600"}>
                    {user.personalInformation.city?.name} / {user.personalInformation.country?.name}
                </Typography>
                <Typography variant="h7" color={"grey.600"}>
                    {user.contact.email}
                </Typography>
                {user.personalInformation?.phoneNumber && <Typography variant="h7" color={"grey.600"}>
                    {user.personalInformation.phoneNumber}
                </Typography>}
                <Typography variant="h7" color={"grey.600"}>
                    Last Updated at {moment(user.updatedAt).format("DD MMM")}
                </Typography>
            </Box>
        </Box>
    </SettingsContainer>

}
