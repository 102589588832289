import React from 'react'
import { Avatar, AvatarGroup, Box, Typography, Grid } from '@mui/material'

import {
  CompletedTasksIcon,
  // InternshipProgressIcon,
  WorkedHoursIcon,
} from 'assets'
import { TaskWidget } from 'components'


const Widget3 = ({ interns }) => {

  return (
    <AvatarGroup
      total={interns?.length}
      max={4}
      sx={{
        alignItems: 'center',
        justifyContent: 'start',
        position: 'relative',
        top: '-30px',

        '& > div:first-of-type': {
          zIndex: 10,
          right: `${5 * 2}px !important`,
          width: '32px',
          height: '32px',
        }
      }}
    >
      {
        interns?.map((item, index) => {

          return (
            <Avatar
              key={index}
              alt="Remy Sharp"
              src={item?.personalInformation?.profileImageUrl}
              sx={{
                zIndex: index,
                right: `${5 * index}px !important`,
                width: '30px',
                height: '30px',
              }}

            />
          )
        })
      }
    </AvatarGroup>
  )
}



const tasks = []
const selectedApplication = null
export const TeamWidgetSection = () => {

  // const { tasks } = useSelector(state => state.tasks)
  // const { selectedApplication } = useSelector(state => state.applications)
  // console.log(selectedApplication?.internship?.interns)

  const completedTasksLength = tasks?.filter(task => task.status !== 'inprogress')?.length ?? 0
  const ongoingTasksLength = tasks?.filter(task => task.status === 'inprogress')?.length ?? 0

  return <Grid container spacing={3} mb={3} >


    <TaskWidget
      color='#FF9737'
      icon={'GroupOutlined'}
      text={`${selectedApplication?.internship?.interns?.length ?? 0}`}
      bottomText="Team Members"
    >
      <Widget3 interns={selectedApplication?.internship?.interns ?? [1, 2, 3]} />
    </TaskWidget>

    <TaskWidget
      color='#248E9D'
      icon={CompletedTasksIcon}
      text={`${completedTasksLength}`}
      bottomText="Active Internships"
    />

    <TaskWidget
      color='#3D7CF8'
      icon={WorkedHoursIcon}
      text={`${ongoingTasksLength}`}
      bottomText="Intern"
    />


  </Grid>
}