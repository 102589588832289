import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { useRouter } from 'minimal/routes/hook';
import { useResponsive } from 'minimal/hooks/use-responsive';

// import { useRouter } from 'src/routes/hook';

// import { useResponsive } from 'src/hooks/use-responsive';


// ----------------------------------------------------------------------

export const ChatNavItem = ({ selected, collapse, conversation, onCloseMobile }) => {


  const mdUp = useResponsive('up', 'md');
  const router = useRouter();

  const handleClickConversation = useCallback(async () => {
    try {
      if (!mdUp) {
        onCloseMobile();
      }
      router.push(`/mesajlar?activityId=${conversation?.activity?.activityId?._id}&&receiverId=${conversation?.receiver?._id}`);
    } catch (error) {
      console.error(error);
    }
  }, [conversation, mdUp, onCloseMobile, router]);

  const renderGroup = (
    <Badge
      variant={true ? 'online' : 'invisible'}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <AvatarGroup variant="compact" sx={{ width: 48, height: 48 }}>
        {[1, 2].slice(0, 2).map((participant) => (
          <Avatar key={participant} alt={participant?.name} src={participant?.avatarUrl} />
        ))}
      </AvatarGroup>
    </Badge>
  );

  const renderSingle = (
    <Badge key={conversation?.lastMessage?._id} variant={conversation?.status} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Avatar alt={conversation?.receiver?.displayName} src={conversation?.receiver?.displayImageUrl?.startsWith("http") ? conversation?.receiver?.displayImageUrl : ""} sx={{ width: 48, height: 48 }} />
    </Badge>
  );

  return (
    <ListItemButton
      disableGutters
      onClick={handleClickConversation}
      sx={{
        py: 1.5,
        px: 2.5,
        ...(selected && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <Badge
        color="error"
        overlap="circular"
        badgeContent={collapse ? conversation.unreadCount : 0}
      >
        {false ? renderGroup : renderSingle}
      </Badge>

      {!collapse && (
        <>
          <ListItemText
            sx={{ ml: 2 }}
            primary={conversation?.receiver?.displayName}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
              textTransform: "capitalize"
            }}
            secondary={conversation?.lastMessage?.content}
            secondaryTypographyProps={{
              noWrap: true,
              component: 'span',
              variant: conversation.unreadCount ? 'subtitle2' : 'body2',
              color: conversation.unreadCount ? 'text.primary' : 'text.secondary',
            }}
          />

          <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
            <Typography
              noWrap
              variant="body2"
              component="span"
              sx={{
                mb: 1.5,
                fontSize: 12,
                color: 'text.disabled',
              }}
            >
              {/* {formatDistanceToNowStrict(new Date(conversation?.lastActivity), {
                addSuffix: false,
              })} */}
              {
                new Intl.DateTimeFormat("tr", {}).format(new Date(conversation?.lastMessage?.createdAt))
              }
            </Typography>

            {/* {!!conversation.lastMessage?.receiverHasReceived && (
              <Box sx={{ width: 8, height: 8, bgcolor: 'info.main', borderRadius: '50%' }} />
            )} */}
          </Stack>
        </>
      )}
    </ListItemButton>
  );
}

ChatNavItem.propTypes = {
  collapse: PropTypes.bool,
  conversation: PropTypes.object,
  onCloseMobile: PropTypes.func,
  selected: PropTypes.bool,
};
