import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";

const initialState = {
    self_ordersData: null,
    self_ordersCount: null,
    self_ordersLoading: false,
    self_ordersError: null,
};

export const self_ordersFunc = createAsyncThunk("self_orders/self_ordersFunc", async (values) => {

    const data1 = JSON.stringify({
        query: `query Self_Orders {
            self_orders {
              count
              data {
                _id
                payment {
                  paymentStatus
                }
                price {
                  currency
                  netTotal
                }
                createdAt
              }
            }
          }`
    });

    const config = initialConfig(process.env.REACT_APP_BASE_URL + "/payment/graphql", data1, values?.accessToken);

    const response = axios(config)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => {
            console.log("err:", err);
            return err;
        });

    const data = await response;
    return data;
}
);

const self_orders = createSlice({
    name: "self_orders",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(self_ordersFunc.pending, (state) => {
            state.self_ordersLoading = true;
        });
        builder.addCase(self_ordersFunc.fulfilled, (state, action) => {
            state.self_ordersLoading = false;
            state.self_ordersData = action.payload?.self_orders?.data;
            state.self_ordersCount = action.payload?.self_orders?.count;
            state.self_ordersError = action?.payload?.message;
        });
        builder.addCase(self_ordersFunc.rejected, (state, action) => {
            state.self_ordersLoading = false;
            state.self_ordersError = action.error.message;
        });
    },
});

export default self_orders.reducer;
