import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_addCommentToAssignTask = async ({ accessToken, taskId, content }) => {

    const data = JSON.stringify({
        query: `mutation Intern_addCommentToAssignTask($filter: AddCommentToAssignedTaskFilterInput!, $input: AddCommentToAssignedTaskInput!) {
            intern_addCommentToAssignTask(filter: $filter, input: $input) {
                _id
            }
            }`,
        variables: {
            "filter": {
                "id": taskId
            },
            "input": {
                "type": "comment",
                "content": content,
                "parentModel": {
                    "_id": taskId
                },
                "tags": []
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.intern_addCommentToAssignTask
    } catch (err) {
        return err
    }
}
