import { useState, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box, Typography, IconButton, TextField, Grid, Card, Slide, Dialog, DialogTitle, DialogContent,
    DialogActions, Button, FormGroup, FormControlLabel, Checkbox,
} from '@mui/material';
import { SettingsContainer, Dropdown } from 'components';
import ExperienceIcon from 'assets/icons/onboarding/upper-text-experiences-icon.png';
import AddIcon from '@mui/icons-material/Add';
import { DeleteOutline, Update } from '@mui/icons-material';
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useFormik } from "formik";
import {t} from 'i18next'
import {
    intern_removeExperienceFunc,
    self_internFunc, anonymous_citiesFunc, intern_addExperienceFunc, intern_updateExperienceFunc
} from 'context';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ResumeExperiences = ({ user }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector((state) => state.user);
    const { user_parametersData: parameters,
        anonymous_countriesData: countries, anonymous_citiesData: cities
    } = useSelector((state) => state.parameters);
    const [checkStillAttending, setCheckStillAttending] = useState(false)
    const [editableExperience, setEditableExperience] = useState(null)
    const [openExperience, setOpenExperience] = useState(false)
    const [position, setPosition] = useState("")
    const [selectedExperiences, setSelectedExperiences] = useState([])



    const handleClick = () => {
        setOpenExperience(true)
        setFieldValue("companyName", "")
        setFieldValue("position", "")
        setFieldValue("positionName", "")
        setFieldValue("startDate", null)
        setFieldValue("endDate", null)
        setFieldValue("country", "")
        setFieldValue("city", "")
        setFieldValue("positionDetail", "")
        setCheckStillAttending(false);
        setEditableExperience(null)
        setPosition(null)
    };


    const userInitialValues = {
        companyName: "",
        position: "",
        positionName: "",
        country: "",
        city: "",
        startDate: null,
        endDate: null,
        positionDetail: "",
        birthDate: user?.personalInformation?.birthDate ? new Date(user?.personalInformation?.birthDate) : null,
    }

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        onSubmit: null
    })


    const handleAddExperience = () => {
        intern_addExperienceFunc({
            values: values,
            position: position,
            accessToken: accessToken
        }).then(res => {
            if (res?._id) {
                setOpenExperience(false)
                enqueueSnackbar("The experience has been added!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })
    }

    const handleUpdateExperience = () => {

        intern_updateExperienceFunc({
            values: values,
            id: editableExperience.id,
            accessToken: accessToken
        }).then(res => {
            if (res?._id) {
                setOpenExperience(false)
                enqueueSnackbar("The experience has been updated!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })
    }

    const handleRemoveExperience = () => {

        intern_removeExperienceFunc({
            id: editableExperience.id,
            accessToken: accessToken
        }).then(res => {
            if (res?.success === true) {
                setOpenExperience(false)
                enqueueSnackbar("The experience has been removed!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })

    }



    useEffect(() => {
        setSelectedExperiences(user?.cv?.experiences?.map?.(item => {
            return {
                id: item?._id,
                companyName: item?.companyName,
                position: {
                    "_id": item?.position?._id,
                    "name": item?.position?.name
                },
                country: {
                    "_id": item?.country?._id,
                    "name": item?.country?.name
                },
                city: {
                    "_id": item?.city?._id,
                    "name": item?.city?.name
                },
                startDate: item?.startDate,
                endDate: item?.endDate,
                positionDetail: item?.positionDetail
            }
        }))
    }, [user?.cv?.experiences])

    useEffect(() => {
        if (values.country) {
            accessToken && dispatch(anonymous_citiesFunc({ accessToken, countryId: values?.country }));
        }
    }, [accessToken, dispatch, values?.country]);

    return <>
        <SettingsContainer pb={2} mb={3}>

            <Box pl={3} pt={3} pr={3}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h5">
                       {t('Experiences')}
                    </Typography>
                    <IconButton
                        disabled={selectedExperiences?.length >= 6}
                        sx={{ ml: 1 }}
                        aria-label="edit"
                        onClick={handleClick}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
                <>
                    {(selectedExperiences?.length === 0 || !selectedExperiences) && <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" color={"grey.600"} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center', pb:4 }}>   {t("No experience added yet.")} </Typography>
                        </Grid>
                    </Grid>}
                    <Grid container sx={{ width: '100%', mt: "10px",gap: { xs: 1, sm: 0 } }} spacing={{ xs: 0, sm: 2 }} >
                        {selectedExperiences?.length > 0 &&
                            selectedExperiences?.map((item, index) => (
                                <Grid item xs={12} md={4}>
                                    <Card sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2,
                                        '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)", cursor: "pointer" }
                                    }} onClick={() => {
                                        setFieldValue("companyName", item?.companyName)
                                        setFieldValue("position", item?.position?._id)
                                        setFieldValue("positionName", item?.position?.name)
                                        setFieldValue("country", item?.country?._id)
                                        setFieldValue("city", item?.city?._id)
                                        setFieldValue("startDate", item?.startDate)
                                        setFieldValue("endDate", item?.endDate ?? null)
                                        setFieldValue("positionDetail", item?.positionDetail)
                                        setCheckStillAttending(!item?.endDate)
                                        setEditableExperience({ index: index, id: item?.id })
                                        setOpenExperience(true)
                                        setPosition(item?.position)
                                    }}>
                                        <img src={ExperienceIcon} alt="experience" style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>   {item?.position?.name} </Typography>
                                        <Typography variant="h7" sx={{ fontWeight: 'bold', textAlign: 'center' }}> {item?.companyName}</Typography>
                                        <Typography variant="h8" sx={{ textAlign: 'center' }}> {item?.positionDetail}</Typography>
                                        <Typography variant="caption" sx={{ textAlign: 'center' }}>
                                            {format(new Date(item?.startDate), 'MMM yyyy')} /
                                            {item?.endDate ? format(new Date(item?.endDate), ' MMM yyyy') : ' Still working here'}
                                        </Typography>
                                        <Typography variant="caption" sx={{ textAlign: 'center' }}>
                                            {item?.city?.name} / {item?.country?.name}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))
                        }

                    </Grid>
                </>
            </Box>

        </SettingsContainer>


        <Dialog
            open={openExperience}
            keepMounted
            onClose={() => setOpenExperience(false)}
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={Transition}
        >
            <DialogTitle>{editableExperience ? 'Update Experience' : 'Add Experience'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ width: '100%', mt: "10px", justifyContent: 'flex-end' }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="companyName"
                            value={values.companyName}
                            onChange={(e => {
                                const enteredValue = e.target.value;
                                const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                setFieldValue("companyName", capitalizedValue)
                            })}
                            onBlur={handleBlur}
                            error={touched.companyName && Boolean(errors.companyName)}
                            helperText={touched.companyName && errors.companyName}
                            label="Company Name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            value={position}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setPosition({
                                        name: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setPosition({
                                        name: newValue.inputValue,
                                    });
                                } else {
                                    setPosition(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.name);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        name: `Add "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={parameters?.positions?.data}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            sx={{ width: '100%' }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Position" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Dropdown
                            setFieldValue={setFieldValue}
                            errors={errors}
                            data={countries}
                            label={"Country"}
                            labelId={"country-label"}
                            value={values.country}
                            id={"country"}
                            type={"withFlag"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Dropdown
                            setFieldValue={setFieldValue}
                            disabled={!values.country}
                            errors={errors}
                            data={cities}
                            label={"City"}
                            labelId={"city-label"}
                            value={values.city}
                            id={"city"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="positionDetail"
                            value={values.positionDetail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.positionDetail && Boolean(errors.positionDetail)}
                            helperText={touched.positionDetail && errors.positionDetail}
                            label="Position Detail"
                            multiline
                            fullWidth
                            rows={4}

                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDPicker
                            sx={{ width: '100%' }}
                            label="Start Date"
                            value={values.startDate ? new Date(values.startDate) : null}
                            openTo="month"
                            onChange={(value) => setFieldValue("startDate", value)}
                            name="startDate"
                            views={['month', 'year']}
                            maxDate={new Date()}
                        />
                        <MDPicker
                            disabled={checkStillAttending || !values.startDate}
                            sx={{ width: '100%', mt: 1.7 }}
                            label={!checkStillAttending ? "End Date" : "Still working here"}
                            value={values.endDate ? new Date(values.endDate) : null}
                            openTo="month"
                            onChange={(value) => setFieldValue("endDate", value)}
                            name="endDate"
                            views={['month', 'year']}
                            maxDate={new Date()}
                            minDate={new Date(values.startDate)}
                        />
                        <FormGroup sx={{ mt: 1 }}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={checkStillAttending}
                                    onChange={(e) => {
                                        setCheckStillAttending(e.target.checked)
                                        setFieldValue("endDate", null)
                                    }}
                                />} label="Still working here" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>

                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button color="primary" variant="outlined" onClick={() => setOpenExperience(false)}>Cancel</Button>
                {editableExperience && <Button color="error" variant="outlined" onClick={handleRemoveExperience}>
                    <DeleteOutline sx={{ mr: 1 }} fontSize="small" />
                    Remove Experience</Button>}
                <Button
                    disabled={!(values.companyName && position && values.country && values.city && values.startDate && (values.endDate || checkStillAttending))}
                    color="primary" variant="contained" onClick={editableExperience ? handleUpdateExperience : handleAddExperience}><Update sx={{ mr: 1 }} fontSize="small" /> {editableExperience ? 'Update Experience' : 'Add Experience'}</Button>
            </DialogActions>
        </Dialog>




    </>
}
