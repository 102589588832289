import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../../../initialConfig";

const initialState = {
  user_internshipPriceData: null,
  user_internshipPriceLoading: false,
  user_internshipPriceError: null,
};

export const user_internshipPriceFunc = createAsyncThunk("user_internshipPrice/user_internshipPriceFunc", async (values) => {

  console.log(values)

  const body = JSON.stringify({
    query: `query Query($input: GetInternshipPriceInput) {
      user_internshipPrice(input: $input) {
        cost {
          optionalStandardCredit
          mandatoryStandardCredit
          mandatoryFreeCredit
          baseCost
          internCost
          managementCost
          coffeeCost
        }
        price
        discount
        netPrice
      }
    }`,
    variables: {
      "input": {
        "timePeriod": values?.timePeriod,
        "quota": values?.quota,
        "followUpUserType": values?.followUpUserType,
        "creationType": values?.creationType,
        "coffee": values?.coffee,
      }
    }
  });

  const config = initialConfig(
    process.env.REACT_APP_BASE_URL + "/internship/graphql",
    body,
    values?.accessToken
  );

  const response = axios(config)
    .then((res) => {
      const data = res.data.data;
      return data;
    })
    .catch((err) => {
      console.log("err: ", err);
      return err;
    });

  const data = await response;
  return data;
}
);

const user_internshipPrice = createSlice({
  name: "user_internshipPrice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(user_internshipPriceFunc.pending, (state) => {
      state.user_internshipPriceLoading = true;
    });
    builder.addCase(user_internshipPriceFunc.fulfilled, (state, action) => {
      state.user_internshipPriceLoading = false;
      state.user_internshipPriceData = action.payload?.user_internshipPrice;
      state.user_internshipPriceError = action.payload.message;
    });
    builder.addCase(user_internshipPriceFunc.rejected, (state, action) => {
      state.user_internshipPriceLoading = false;
      state.user_internshipPriceError = action.error.message;
    });
  },
});

export default user_internshipPrice.reducer;


