import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_removeExperienceFunc = async ({ id, accessToken }) => {

  const data = JSON.stringify({
    query: `
        mutation Intern_removeExperience($experienceId: ID!) {
            intern_removeExperience(experienceId: $experienceId) {
              success
            }
          }
        `,
    variables: {
      "experienceId": id
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }

    return res?.data?.data?.intern_removeExperience;

  } catch (err) {
    return err
  }
}