

export const fillRateCalculator = (self_internData) => {


    const personalInformation = self_internData?.personalInformation?.description;
    const education = self_internData?.cv?.education;
    const experiences = self_internData?.cv?.experiences;
    const foreignLanguages = self_internData?.cv?.foreignLanguages;
    const skills = self_internData?.cv?.skills;
    const certificates = self_internData?.cv?.certificates;



    let rate = 0;
    if (personalInformation) rate += 20;
    if (education?.highSchooleducations || education?.universityEducations) rate += 20;
    if (experiences?.length > 0) rate += 15;
    if (foreignLanguages?.length > 0) rate += 15;
    if (skills?.length > 0) rate += 15;
    if (certificates?.length > 0) rate += 15;

    return rate;

}
