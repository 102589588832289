import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import AnonymousRouter from './routes/AnonymousRouter';
import { setUserRole, setUserId, setAccessToken, setEmail, setCompanyId } from 'context/common/user';
import { useEffect } from 'react';
import { CompanyDashboardRouter, CompanyOnboardingRouter } from 'routes/CompanyRouter';
import { InternDashboardRouter, InternOnboardingRouter } from 'routes/InternRouter';

import {
    self_companyUserFunc, self_internFunc, user_parametersFunc, user_parameters_educationsFunc,
    anonymous_countriesFunc,
    self_userFunc, user_userNotificationCountFunc
} from 'context';

import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { HighSchoolDashboardRouter, HighSchoolOnboardingRouter } from 'routes';


const Router = () => {

    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { userRole, accessToken } = useSelector(state => state.user);
    const { onboardedCompany } = useSelector(state => state.self_companyUser);
    const { onboardedIntern } = useSelector(state => state.self_intern);
    const { self_companyUserData } = useSelector(state => state.self_companyUser);
    const { defaultLanguageData } = useSelector(state => state.self_user);

    useEffect(() => {
        const lang = defaultLanguageData || 'en';
        i18n.changeLanguage(lang);
    }, [i18n, defaultLanguageData])


    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");
        const email = localStorage.getItem("email");
        const userRole = localStorage.getItem("userRole");

        dispatch(setAccessToken(accessToken));
        dispatch(setUserId(userId));
        dispatch(setEmail(email));
        dispatch(setUserRole(userRole));

    }, [dispatch]);

    useEffect(() => {
        accessToken && userRole === "companyAdmin" && dispatch(self_companyUserFunc({ accessToken }))
        accessToken && userRole === "intern" && dispatch(self_internFunc({ accessToken }))
        accessToken && userRole === "highSchoolIntern" && dispatch(self_internFunc({ accessToken, highSchool: true }))
        accessToken && dispatch(user_parametersFunc({ accessToken }))
        accessToken && dispatch(user_parameters_educationsFunc({ accessToken }))
        accessToken && dispatch(anonymous_countriesFunc({ accessToken }))
        accessToken && dispatch(self_userFunc({ accessToken }))
        accessToken && dispatch(user_userNotificationCountFunc({ accessToken: accessToken }))
    }, [accessToken, dispatch, userRole])

    // useEffect(() => {
    //     fetch("http://api.internif.com/user/healthcheck").then(res => {
    //         console.log(res, "res")
    //     }).catch(err => {
    //         console.log(err, "err")
    //     } )
    // }, [])


    const renderUserTypeRouter = () => {
        switch (userRole) {
            case 'intern':
                switch (onboardedIntern) {
                    case "completed":
                        return InternDashboardRouter();
                    case ("personalInformation" || "education" || "foreignLanguages" || "experiences" || "skills" || "certificates" || "proficiencyTest"):
                        return InternOnboardingRouter();
                    default:
                        return null;
                }
            case 'highSchoolIntern':
                switch (onboardedIntern) {
                    case "completed":
                        return HighSchoolDashboardRouter();
                    case ("personalInformation" || "education" || "foreignLanguages" || "experiences" || "skills" || "certificates" || "proficiencyTest"):
                        return HighSchoolOnboardingRouter();
                    default:
                        return null;
                }
            case 'companyAdmin':
                switch (onboardedCompany) {
                    case "completed":
                        return CompanyDashboardRouter();
                    case ("personalInformation" || "companyInformation"):
                        return CompanyOnboardingRouter();
                    default:
                        return null;
                }
            default:
                return AnonymousRouter();
        }
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/linkedin" element={<LinkedInCallback />} />
                {renderUserTypeRouter()}
            </Routes>
        </BrowserRouter>
    )

}

export default Router;