import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";


const initialState = {
  companyAdmin_companyUsersData: null,
  companyAdmin_companyUsersLoading: false,
  companyAdmin_companyUsersError: null,
};

export const companyAdmin_companyUsersFunc = createAsyncThunk("companyAdmin_companyUsers/companyAdmin_companyUsersFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `query CompanyAdmin_companyUsers {
        companyAdmin_companyUsers {
          count
          data {
            _id
            personalInformation {
              name
              surname
              profileImageUrl
              country {
                name
              }
              city {
                name
              }
            }
            contact {
              email
            }
            role
          }
        }
      }`

  });


  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const companyAdmin_companyUsers = createSlice({
  name: "companyAdmin_companyUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyAdmin_companyUsersFunc.pending, (state) => {
      state.companyAdmin_companyUsersLoading = true;
    });
    builder.addCase(companyAdmin_companyUsersFunc.fulfilled, (state, action) => {
      state.companyAdmin_companyUsersLoading = false;
      state.companyAdmin_companyUsersData = action.payload?.companyAdmin_companyUsers?.data;
      state.companyAdmin_companyUsersError = action.payload.message;
    });
    builder.addCase(companyAdmin_companyUsersFunc.rejected, (state, action) => {
      state.companyAdmin_companyUsersLoading = false;
      state.companyAdmin_companyUsersError = action.error.message;
    });
  }
});


export default companyAdmin_companyUsers.reducer;