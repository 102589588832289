
export const company_teamTips_title = `<b>Navigating Your Internship Applications on InternIF</b>`

export const company_teamTips = `
<p>
Welcome to the hub of talent exploration! At InternIF, we understand that managing internship applications is a crucial aspect of your hiring journey. Here's how we simplify the process for you:
    <br/>
    <br/>
<b>Real-Time Application Tracking:</b>
    <br/>
Effortlessly track the status of each application in real time. Our intuitive dashboard updates you on every stage, from the moment an intern applies to the final decision.
    <br/>
    <br/>
<b>Benefits of Using InternIF's Application Dashboard: </b>
    <br/>
    <br/>
Efficiency: Streamlined processes for efficient application management.
    <br/>
Clarity: Real-time updates for clear decision-making.
    <br/>
Future Planning: Identify potential candidates for upcoming opportunities.
    <br/>
Communication: Foster transparent communication with interns throughout the process.
    <br/>
    <br/>
    <b>Get Started with Seamless Internship Application Management! </b>
    <br/>
    <br/>
InternIF's application tracking is designed with your convenience in mind. Make informed decisions, discover top talent, and build lasting connections with interns from around the world.
    <br/>
    <br/>
Simplify Your Hiring Journey with InternIF!
</p>
`