import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router'
import { LogoOnly, VerifyEmailIcon } from 'assets'

export const AuthHead = ({ title, description }) => {

    const path = useLocation().pathname.split('/')[1];

    return <Box display="flex" flexDirection="column" alignItems="center" gap="10px">

        <Box sx={{ width: '64px', height: '64px' }}>
            {path === 'create-new-password' ? <img src={VerifyEmailIcon} alt='verify-email-icon' />
                : path === 'forgot-password' ? <img src={VerifyEmailIcon} alt='verify-email-icon' /> : <LogoOnly />}

        </Box>
        <Typography variant="h1" textAlign={'center'}>
            {title}
        </Typography>
        <Typography variant='h6' fontWeight={'bold'} color="primary.light" textAlign="center">
            {description}
        </Typography>
    </Box>
}
