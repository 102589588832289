import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../../initialConfig";

const initialState = {
  user_parametersData: null,
  user_parametersLoading: false,
  user_parametersError: null,

  user_parameters_industriesData: null,
  user_parameters_industriesLoading: false,
  user_parameters_industriesError: null,

  user_parameters_positionsData: null,
  user_parameters_positionsLoading: false,
  user_parameters_positionsError: null,

  user_parameters_educationsData: null,
  user_parameters_educationsLoading: false,
  user_parameters_educationsError: null,

  user_parameters_highSchoolDepartmentsData: null,
  user_parameters_highSchoolDepartmentsLoading: false,
  user_parameters_highSchoolDepartmentsError: null,

  anonymous_countriesData: null,
  anonymous_countriesLoading: false,
  anonymous_countriesError: null,

  anonymous_citiesData: null,
  anonymous_citiesLoading: false,
  anonymous_citiesError: null,
};

export const user_parametersFunc = createAsyncThunk(
  "user/user_parametersFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `
        query User_parameters($filter: FilterParameterInput, $pagination: Pagination) {
            user_parameters(filter: $filter, pagination: $pagination) {
             

              highSchoolDepartments {
                count
                data {
                  _id
                  name
                }
              }


              languages {
                count
                data {
                  _id
                  name
                  metadata {
                    code
                    nativeName
                  }
                }
              }


              languageLevels {
                count
                data {
                  _id
                  name
                }
              }


              positions {
                count
                data {
                  _id
                  name
                }
              }


              industries {
                count
                data {
                  _id
                  name
                }
              }


              companyDepartments {
                count
                data {
                  _id
                  name
                }
              }


              numberOfEmployees {
                count
                data {
                  _id
                  name
                }
              }


              skills {
                count
                data {
                  _id
                  name
                }
              }


              universities {
                count
                data {
                  _id
                  name
                }
              }


              universityDepartments {
                count
                data {
                  _id
                  name
                }
              }


            }
          }
          `,
      variables: {
        filter: {
          name: null,
        },
        pagination: {
          page: null,
          pageSize: null,
          sort: null,
        },
      },
    });

    const config = initialConfig(
      process.env.REACT_APP_BASE_URL + "/user/graphql",
      data1,
      values?.accessToken
    );

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        return err;
      });

    const data = await response;
    return data;
  }
);

export const user_parameters_industriesFunc = createAsyncThunk(
  "user/user_parameters_industriesFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `
        query User_parameters($filter: FilterParameterInput, $pagination: Pagination) {
            user_parameters(filter: $filter, pagination: $pagination) {
            
              industries {
                count
                data {
                  _id
                  name
                }
              }

              numberOfEmployees {
                count
                data {
                  _id
                  name
                }
              }
           
            }
          }
          `,
      variables: {
        filter: {
          name: null,
        },
        pagination: {
          page: null,
          pageSize: null,
          sort: null,
        },
      },
    });

    const config = initialConfig(
      process.env.REACT_APP_BASE_URL + "/user/graphql",
      data1,
      values?.accessToken
    );

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        return err;
      });

    const data = await response;
    return data;
  }
);

export const user_parameters_educationsFunc = createAsyncThunk(
  "user/user_parameters_educationsFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `
        query User_parameters($filter: FilterParameterInput, $pagination: Pagination) {
            user_parameters(filter: $filter, pagination: $pagination) {
            
            
              
              universities {
                count
                data {
                  _id
                  name
                }
              }


              universityDepartments {
                count
                data {
                  _id
                  name
                }
              }

              
              highSchoolDepartments {
                count
                data {
                  _id
                  name
                }
              }

              
              
           
            }
          }
          `,
      variables: {
        filter: {
          name: null,
        },
        pagination: {
          page: null,
          pageSize: null,
          sort: null,
        },
      },
    });

    const config = initialConfig(
      process.env.REACT_APP_BASE_URL + "/user/graphql",
      data1,
      values?.accessToken
    );

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        return err;
      });

    const data = await response;
    return data;
  }
);

export const user_parameters_positionsFunc = createAsyncThunk(
  "user/user_parameters_positionsFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `
        query User_parameters($filter: FilterParameterInput, $pagination: Pagination) {
            user_parameters(filter: $filter, pagination: $pagination) {
            
            
              
              positions {
                count
                data {
                  _id
                  name
                }
              }
              
              
           
            }
          }
          `,
      variables: {
        filter: {
          name: null,
        },
        pagination: {
          page: null,
          pageSize: null,
          sort: null,
        },
      },
    });

    const config = initialConfig(
      process.env.REACT_APP_BASE_URL + "/user/graphql",
      data1,
      values?.accessToken
    );

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        return err;
      });

    const data = await response;
    return data;
  }
);

export const anonymous_countriesFunc = createAsyncThunk(
  "user/anonymous_countriesFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `
            query Anonymous_countries($filter: FilterParameterInput, $pagination: Pagination) {
                anonymous_countries(filter: $filter, pagination: $pagination) {
                count
                data {
                    _id
                    code
                    flagUrl
                    name
                    codeISO3
                }
                }
            }
          `,
      variables: {
        filter: {
          name: null,
        },
        pagination: {
          page: null,
          pageSize: null,
          sort: null,
        },
      },
    });

    const config = initialConfig(
      process.env.REACT_APP_BASE_URL + "/user/graphql",
      data1,
      values?.accessToken
    );

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        return err;
      });

    const data = await response;
    return data;
  }
);

export const anonymous_citiesFunc = createAsyncThunk(
  "user/anonymous_citiesFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `
        query Anonymous_cities($countryId: ID!, $filter: FilterParameterInput, $pagination: Pagination) {
            anonymous_cities(countryId: $countryId, filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                code
                name
              }
            }
          }
          `,
      variables: {
        countryId: values?.countryId,
        filter: {
          name: null,
        },
        pagination: {
          page: null,
          pageSize: null,
          sort: null,
        },
      },
    });

    const config = initialConfig(
      process.env.REACT_APP_BASE_URL + "/user/graphql",
      data1,
      values?.accessToken
    );

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        return err;
      });

    const data = await response;
    return data;
  }
);

const parameters = createSlice({
  name: "parameters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ############# parameters  ############# #############
    builder.addCase(user_parametersFunc.pending, (state) => {
      state.user_parametersLoading = true;
    });
    builder.addCase(user_parametersFunc.fulfilled, (state, action) => {
      state.user_parametersLoading = false;
      state.user_parametersData = action.payload?.user_parameters;
      state.user_parametersError = action.payload.message;
    });
    builder.addCase(user_parametersFunc.rejected, (state, action) => {
      state.user_parametersLoading = false;
      state.user_parametersError = action.error.message;
    });

    // ############# company parameters  ############# #############
    builder.addCase(user_parameters_industriesFunc.pending, (state) => {
      state.user_parameters_industriesLoading = true;
    });
    builder.addCase(
      user_parameters_industriesFunc.fulfilled,
      (state, action) => {
        state.user_parameters_industriesLoading = false;
        state.user_parameters_industriesData = action.payload?.user_parameters;
        state.user_parameters_industriesError = action.payload.message;
      }
    );
    builder.addCase(
      user_parameters_industriesFunc.rejected,
      (state, action) => {
        state.user_parameters_industriesLoading = false;
        state.user_parameters_industriesError = action.error.message;
      }
    );

    // ############# experience parameters  ############# #############
    builder.addCase(user_parameters_positionsFunc.pending, (state) => {
      state.user_parameters_positionsLoading = true;
    });
    builder.addCase(
      user_parameters_positionsFunc.fulfilled,
      (state, action) => {
        state.user_parameters_positionsLoading = false;
        state.user_parameters_positionsData = action.payload?.user_parameters;
        state.user_parameters_positionsError = action.payload.message;
      }
    );
    builder.addCase(user_parameters_positionsFunc.rejected, (state, action) => {
      state.user_parameters_positionsLoading = false;
      state.user_parameters_positionsError = action.error.message;
    });

    // ############# education parameters  ############# #############
    builder.addCase(user_parameters_educationsFunc.pending, (state) => {
      state.user_parameters_educationsLoading = true;
    });
    builder.addCase(
      user_parameters_educationsFunc.fulfilled,
      (state, action) => {
        state.user_parameters_educationsLoading = false;
        state.user_parameters_educationsData = action.payload?.user_parameters;
        state.user_parameters_educationsError = action.payload.message;
      }
    );
    builder.addCase(
      user_parameters_educationsFunc.rejected,
      (state, action) => {
        state.user_parameters_educationsLoading = false;
        state.user_parameters_educationsError = action.error.message;
      }
    );





    // ############# countries  ############# #############
    builder.addCase(anonymous_countriesFunc.pending, (state) => {
      state.anonymous_countriesLoading = true;
    });
    builder.addCase(anonymous_countriesFunc.fulfilled, (state, action) => {
      state.anonymous_countriesLoading = false;
      state.anonymous_countriesData = action.payload?.anonymous_countries?.data;
      state.anonymous_countriesError = action.payload.message;
    });
    builder.addCase(anonymous_countriesFunc.rejected, (state, action) => {
      state.anonymous_countriesLoading = false;
      state.anonymous_countriesError = action.error.message;
    });

    // ############# cities  ############# #############
    builder.addCase(anonymous_citiesFunc.pending, (state) => {
      state.anonymous_citiesLoading = true;
    });
    builder.addCase(anonymous_citiesFunc.fulfilled, (state, action) => {
      state.anonymous_citiesLoading = false;
      state.anonymous_citiesData = action.payload?.anonymous_cities?.data;
      state.anonymous_citiesError = action.payload.message;
    });
    builder.addCase(anonymous_citiesFunc.rejected, (state, action) => {
      state.anonymous_citiesLoading = false;
      state.anonymous_citiesError = action.error.message;
    });
  },
});

export default parameters.reducer;
