import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_addCertificateFunc = async ({ values, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_addCertificate($certificateInput: CertificateInput!) {
            intern_addCertificate(certificateInput: $certificateInput) {
              _id
            }
          }
        `,
        variables: {
            "certificateInput": {
                "expirationDate": values?.expirationDate,
                "issueDate": values?.issueDate,
                "name": values?.name,
                "organization": values?.organization
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }

        return res?.data?.data?.intern_addCertificate;

    } catch (err) {
        return err
    }
}
