
import axios from "axios";
import initialConfig from "context/initialConfig";

export const user_readAllUserNotificationsFunc = async (values) => {

    const data = JSON.stringify({
        query: `mutation User_readAllUserNotifications {
            user_readAllUserNotifications {
              success
            }
          }`,
    })


    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/notification/graphql', data, values?.accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data
    } catch (err) {
        return err
    }

}