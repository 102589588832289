
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, Tab, Tabs, Pagination, Typography, Badge } from "@mui/material";
import { companyUser_applicationsFunc, companyAdmin_viewApplication, user_internsByIdsForApplicationFunc } from "context";
import { ApplicationCardForCompany } from "components";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ApplicationsSubSection = ({ applications = [], setSelectedApplicant, applicants, setIsViewed, setSelectedApplication }) => {

  const { accessToken } = useSelector((state) => state.user);

  const handleViewApplication = async (application) => {

    if (application?.status !== "applied") return null;

    try {
      const response = await companyAdmin_viewApplication({ applicationId: application?._id, accessToken })
      if (response?.success) {
        setIsViewed(true)
      }
    } catch (error) {
      return null
    }
  }

  return (
    <Grid
      container
      sx={{
        width: "100%",
      }}
    >
      {applicants?.map((applicant, index) => (
        <Grid item xs={12} sx={{ width: '100%' }}>
          <ApplicationCardForCompany
            item={applicant}
            applicationDetails={applications?.filter((item) => item?.intern?._id === applicant?._id)[0]}
            loading={false}
            onOpenDetail={() => {
              setSelectedApplicant(applicant)
              setSelectedApplication(applications?.filter((item) => item?.intern?._id === applicant?._id)[0])
            }}
            onViewApplication={() => handleViewApplication(applications?.filter((item) => item?.intern?._id === applicant?._id)[0])}
          />
        </Grid>
      ))}
    </Grid>
  );
  //return <InternshipCard />;
};

export const ApplicationsSection = ({ applicationStatuses, pageSize, setSelectedApplicant, setSelectedApplication }) => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const { accessToken } = useSelector((state) => state.user);
  const [isViewed, setIsViewed] = useState(false);

  const { companyUser_applicationsData: applicationData } = useSelector(state => state.companyUser_applications);
  const { user_internsByIdsForApplicationData: applicants } = useSelector(state => state.user_internsByIdsForApplication);

  const pageCount = applicationData?.count
    ? Math.ceil(applicationData.count / pageSize)
    : 1;


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePageChange = (event, newValue) => {
    setPage(newValue);
  };

  useEffect(() => {
    accessToken && dispatch(companyUser_applicationsFunc({
      page: page, pageSize: pageSize, accessToken, internshipId: id,
      statuses: value === 0 ? null : value === 1 ? ["accepted", "viewed", "evaluating", "suitable"] : value === 2 ? ["accepted", "approved"] : value === 3 ? ["rejected", "disapproved"] : null
    })).then(res => {
      const applications = res.payload?.companyUser_applications?.data;
      const ids = applications.map((item) => item.intern?._id);
      dispatch(user_internsByIdsForApplicationFunc({ userIds: ids, accessToken }));
    })

    setIsViewed(false)

  }, [dispatch, accessToken, value, page, pageSize, id, isViewed]);

  return <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ borderBottom: 1, borderColor: "divider", width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="basic tabs example"
        style={{ marginBottom: "-7px" }}
        TabIndicatorProps={{
          style: {
            bottom: 7,
            backgroundColor: "#FF9737",
            height: "4px",
            borderRadius: "10px",
          },
        }}
      >
        {applicationStatuses.map((status) => (
          <Tab label={<Badge color="secondary" variant={status === "Pending" ? "dot" : "standard"}>
            {status}
          </Badge>} {...a11yProps(0)} sx={{ px: 2 }} />
        ))}
      </Tabs>
    </Box>
    {applicationStatuses.map((_, index) => (
      <CustomTabPanel value={value} index={index} >
        <ApplicationsSubSection
          applicants={applicants}
          applications={applicationData?.data}
          setSelectedApplicant={setSelectedApplicant}
          setSelectedApplication={setSelectedApplication}
          setIsViewed={setIsViewed}
        />
      </CustomTabPanel>
    ))}
    <Pagination count={pageCount} page={page} onChange={handlePageChange} />
  </Box>

};


