import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";

const initialState = {
  companyUser_internshipData: null,
  companyUser_internshipLoading: false,
  companyUser_internshipError: null,
};

export const companyUser_internshipFunc = createAsyncThunk(
  "user/companyUser_internshipFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `query CompanyUser_internship($internshipId: ID!) {
        companyUser_internship(internshipId: $internshipId) {
          _id
          positionName
          timePeriodInWeeks
          internshipArea
          internshipGoal
          quota
          language
          startDate
          dueDate
          preferredLanguages
          description
          preferredGPA
          preferredCollegeYear
          preferredSkills
          applicationType
          salaryType
          salary
          coffee
          interns {
            _id
            role
            displayName
            displayImageUrl
          }
          followUpUserType
          numberOfAcceptedInterns
          numberOfAppliedInterns
          descriptionSummary
          status
          createdAt
          updatedAt
        }
      }
      `,
      variables: {
        internshipId: values.internshipId,
      },
    });

    const config = initialConfig(
      process.env.REACT_APP_BASE_URL + "/internship/graphql",
      data1,
      values?.accessToken
    );

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        console.error("err:", err);
        return err;
      });

    const data = await response;
    return data;
  }
);

const companyUser_internship = createSlice({
  name: "companyUser_internship",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyUser_internshipFunc.pending, (state) => {
      state.companyUser_internshipLoading = true;
    });
    builder.addCase(companyUser_internshipFunc.fulfilled, (state, action) => {
      state.companyUser_internshipLoading = false;
      state.companyUser_internshipData = action.payload?.companyUser_internship;
      state.companyUser_internshipError = action?.payload?.message;
    });
    builder.addCase(companyUser_internshipFunc.rejected, (state, action) => {
      state.companyUser_internshipLoading = false;
      state.companyUser_internshipError = action.error.message;
    });
  },
});

export default companyUser_internship.reducer;
