import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";

const initialState = {
  companyUser_internshipsData: null,
  companyUser_internshipsLoading: false,
  companyUser_internshipsError: null,
  companyUser_internshipsCount: null,
};

export const companyUser_internshipsFunc = createAsyncThunk("companyUser_internships/companyUser_internshipsFunc", async (values) => {


  const data1 = JSON.stringify({
    query: `
      query CompanyUser_internships($filter: FilterInternshipInputForCompany, $pagination: PaginationWithInternshipSort) {
        companyUser_internships: companyUser_internships(filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            applicationType
            status
            positionName
            type
            quota
            startDate
            timePeriodInWeeks
            numberOfAppliedInterns
            numberOfAcceptedInterns
            language
            descriptionSummary
            description
            createdAt
            company {
              _id
              country {
                name
              }
              city {
                name
              }
              displayImageUrl
              displayName
              industry {
                name
              }
              numberOfEmployee {
                name
              }
            }
          }
        }
        allInternships: companyUser_internships {
          count
        }
      }
      `,
    variables: {
      pagination: {
        page: values?.variables?.pagination?.page ?? null,
        pageSize: values?.variables?.pagination?.pageSize ?? null,
        sort: values?.variables?.pagination?.sort ?? null,
      },
      filter: {
        positionName: values?.variables?.filter?.positionName ?? null,
        statuses: values?.variables?.filter?.statuses ?? null,
        timePeriodInWeeks:
          values?.variables?.filter?.timePeriodInWeeks ?? null,
        type: values?.variables?.filter?.type ?? null,
      },
    },
  });

  const config = initialConfig(
    process.env.REACT_APP_BASE_URL + "/internship/graphql",
    data1,
    values?.accessToken
  );

  const response = axios(config)
    .then((res) => {
      const data = res.data?.data;
      return data;
    })
    .catch((err) => {
      console.log("err:", err);
      return err;
    });

  const data = await response;
  return data;
}
);

const companyUser_internships = createSlice({
  name: "companyUser_internships",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyUser_internshipsFunc.pending, (state) => {
      state.companyUser_internshipsLoading = true;
    });
    builder.addCase(companyUser_internshipsFunc.fulfilled, (state, action) => {
      state.companyUser_internshipsLoading = false;
      state.companyUser_internshipsData = action.payload?.companyUser_internships;
      state.companyUser_internshipsCount = action.payload?.allInternships?.count;
      state.companyUser_internshipsError = action?.payload?.message;
    });
    builder.addCase(companyUser_internshipsFunc.rejected, (state, action) => {
      state.companyUser_internshipsLoading = false;
      state.companyUser_internshipsError = action.error.message;
    });
  },
});

export default companyUser_internships.reducer;
