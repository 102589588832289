import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    confirmationEmailData: null,
    confirmationEmailLoading: false,
    confirmationEmailError: null,
};


export const confirmationEmailFunc = createAsyncThunk("confirmationEmail/confirmationEmailFunc", async (values) => {
    let data1 = JSON.stringify({
        "token": values.token,
        "code": values.code
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/confirmRegistration', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const confirmationEmail = createSlice({
    name: "confirmationEmail",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // confirmationEmail
        builder.addCase(confirmationEmailFunc.pending, (state) => {
            state.confirmationEmailLoading = true;
        }
        );
        builder.addCase(confirmationEmailFunc.fulfilled, (state, action) => {
            state.confirmationEmailLoading = false;
            state.confirmationEmailData = action.payload;
            state.confirmationEmailError = action.payload.message;
        }
        );
        builder.addCase(confirmationEmailFunc.rejected, (state, action) => {
            state.confirmationEmailLoading = false;
            state.confirmationEmailError = action.error.message;
        }
        );
    }
});


export default confirmationEmail.reducer;