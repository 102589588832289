import React from 'react'
import { Box, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { RecentActivityCard, NoItemsPlaceHolder } from 'components'


export const RecentActivitiesSection = ({ recentActivities, isSideInfo = false }) => {

    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                border: '1px solid #E5EAED',
                borderRadius: '8px',
                p: '16px',
                height: '100%',
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: { xs: '24px' },
                }}
            >

                <Typography
                    sx={{
                        fontFamily: 'Causten-SemiBold',
                        fontSize: '20px',
                        lineHeight: '32px',
                        color: '#000',
                    }}
                >
                    Recent Activities
                </Typography>

                <Typography
                    to="/notifications"
                    component={RouterLink}
                    sx={{
                        fontFamily: 'Causten-SemiBold',
                        fontSize: '12px',
                        lineHeight: '14.4px',
                        color: '#A1ABC5',
                    }}
                >
                    See All
                </Typography>

            </Box>

            <Box>


                {
                    recentActivities?.length > 0 ? (
                        recentActivities.map((item, index) => {

                            return (
                                <RecentActivityCard
                                    isSideInfo={isSideInfo}
                                    index={index}
                                    key={item?._id ?? index}
                                    item={item}
                                    len={recentActivities?.length}
                                />
                            )
                        })
                    ) : (
                        <NoItemsPlaceHolder text="There is no activities!" />
                    )
                }

            </Box>

        </Box>
    )
}