import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    TextField,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Divider,
} from "@mui/material";
import OnlineWorld from 'assets/images/dashboard/Online world-amico.png';
import Iconify from 'minimal/components/iconify/iconify';
import { useEffect, useState } from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { PageOpening } from 'components';
import { useSelector } from 'react-redux';
import { anonymous_createRemoteTransformForm } from 'context';

const info = [
    "Faz 1 başlamadı."
]

const RemoteTransform = () => {

    const [remoteForm, setRemoteForm] = useState(false);
    const { self_companyUserData: company } = useSelector(state => state.self_companyUser);
    const { accessToken } = useSelector(state => state.user);



    const initialValues = {
        companyName: company?.company?.companyInformation?.companyName ?? "",
        industry: company?.company?.companyInformation?.industry?.name ?? "",
        currentTeamSize: "",
        primaryRemoteWorkChallenges: "",
        additionalComments: "",
        name: company?.personalInformation?.name ?? "",
        position: "",
        email: company?.contact?.email ?? "",
        phone: company?.personalInformation?.phoneNumber ?? ""
    };

    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required("Company Name is required").min(2, "Name must be at least 2 characters"),
        industry: Yup.string().required("Industry is required"),
        currentTeamSize: Yup.string().required("Current Team Size is required"),
        primaryRemoteWorkChallenges: Yup.string(),
        additionalComments: Yup.string(),
        name: Yup.string().required("Name is required"),
        position: Yup.string(),
        email: Yup.string().email("Invalid email address").required("Email is required"),
        phone: Yup.number("Phone number must be a number").required("Phone number is required"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            // Handle form submission here
            console.log(values);
            anonymous_createRemoteTransformForm({values, accessToken})
        },
    });

    return <DashboardLayout info={info}>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%' }}>
            <Grid item xs={12} >
                <AnimatedTitle title="Remote Transform" />
            </Grid>
            {!remoteForm && <Grid item xs={7} pr={10} ><PageOpening>
                <Typography variant="h5">
                    Welcome to the Future of Remote Work Transformation!
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    At InternIF, we understand the dynamics of the modern workplace. As companies worldwide embrace remote work, we're here to guide your transformation journey. Leverage our expertise and resources to propel your organization into the era of flexible and efficient remote operations.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2 }}>
                    Why Choose InternIF for Remote Transformation?
                </Typography>

                <Typography variant="body2" sx={{ mt: 2 }}>
                    • Tailored Solutions: Our experts craft personalized strategies based on your company's unique needs and goals. From optimizing workflows to enhancing collaboration, we've got you covered.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    • Global Insights: Benefit from our global perspective. We bring insights from successful remote companies worldwide, helping you implement best practices for a thriving remote workplace.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    • Training and Development: Equip your teams with the skills needed for remote success. Our training programs cover remote collaboration tools, effective communication, and productivity enhancement.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    • Technology Integration: Seamlessly integrate cutting-edge technologies to streamline remote operations. From project management to virtual team collaboration, we recommend and implement the right tools for your business.
                </Typography>
                <Typography variant="h5" sx={{ mt: 2 }}>
                    Get Started on Your Remote Transformation Journey!
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Ready to embrace the future of work? Complete the form below to request assistance with your remote transformation. Our experts will connect with you to understand your requirements and design a customized plan for success.
                </Typography>
            </PageOpening></Grid>}
            {remoteForm && <Grid item xs={7}>
                <PageOpening>
                    <Button
                        style={{ marginTop: '-20px', marginLeft: '-10px', marginBottom: '20px' }}
                        onClick={() => {
                            setRemoteForm(false)
                        }}
                    >
                        <ChevronLeft /> Back
                    </Button>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            {/* Company Information */}
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="companyName"
                                    name="companyName"
                                    label="Company Name"
                                    value={formik.values.companyName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                    helperText={formik.touched.companyName && formik.errors.companyName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="industry"
                                    name="industry"
                                    label="Industry"
                                    value={formik.values.industry}
                                    onChange={formik.handleChange}
                                    error={formik.touched.industry && Boolean(formik.errors.industry)}
                                    helperText={formik.touched.industry && formik.errors.industry}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="currentTeamSize"
                                    name="currentTeamSize"
                                    label="Current Team Size"
                                    value={formik.values.currentTeamSize}
                                    onChange={formik.handleChange}
                                    error={formik.touched.currentTeamSize && Boolean(formik.errors.currentTeamSize)}
                                    helperText={formik.touched.currentTeamSize && formik.errors.currentTeamSize}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="primaryRemoteWorkChallenges"
                                    name="primaryRemoteWorkChallenges"
                                    label="Primary Remote Work Challenges"
                                    value={formik.values.primaryRemoteWorkChallenges}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="additionalComments"
                                    name="additionalComments"
                                    label="Additional Comments/Requests"
                                    multiline
                                    rows={4}
                                    value={formik.values.additionalComments}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            {/* Contact Information */}
                            <Grid item xs={12} >
                                <Divider textAlign='left' sx={{
                                    mt: 2,
                                    ':before': {
                                        width: '0%',
                                    },
                                }}  ><Typography variant="h7" color={'GrayText'}>Contact Information</Typography></Divider>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="position"
                                    name="position"
                                    label="Position"
                                    value={formik.values.position}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label="Phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </Grid>

                        </Grid>
                        <Button variant="contained"
                            color={remoteForm !== true ? "primary" : "secondary"}
                            sx={{
                                mt: 2,
                                borderRadius: 1.5,
                                animation: 'shadowPulse 2s infinite', // 2 saniyede bir tekrarlayan animasyon
                                '@keyframes shadowPulse': {
                                    '0%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1)'
                                    },
                                    '50%': {
                                        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.25)',
                                        transform: 'scale(1.02)'
                                    },
                                    '100%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1)'
                                    },
                                },
                                '&:hover': {
                                    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)', // Hover durumunda gölgeyi güncelle
                                    transform: 'translateY(-2px)', // Hover durumunda hafif yukarı zıplama efekti
                                },
                                '&:active': {
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Basıldığında gölgeyi güncelle
                                    transform: 'translateY(2px)', // Basıldığında hafif aşağı zıplama efekti
                                },
                            }}
                            size='large'
                            onClick={() => {
                                remoteForm ? formik.handleSubmit() 
                                : setRemoteForm(true)
                            }
                            }
                        >
                            <Iconify icon="icon-park-outline:transform" color="#fff" height="100%" mr={1} />
                            Transform My Company
                        </Button>
                    </form>
                </PageOpening>
            </Grid>}
            <Grid item xs={5} width={'80%'} display={"flex"} flexDirection={"column"} alignItems={"center"} >
                <img src={OnlineWorld} alt="Remote Team" style={{ width: '80%' }} />
                {remoteForm !== true && <Button variant="contained"
                    color={remoteForm !== true ? "primary" : "secondary"}
                    sx={{
                        mt: 2,
                        borderRadius: 1.5,
                        animation: 'shadowPulse 2s infinite', // 2 saniyede bir tekrarlayan animasyon
                        '@keyframes shadowPulse': {
                            '0%': {
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                transform: 'scale(1)'
                            },
                            '50%': {
                                boxShadow: '0 8px 12px rgba(0, 0, 0, 0.25)',
                                transform: 'scale(1.02)'
                            },
                            '100%': {
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                transform: 'scale(1)'
                            },
                        },
                        '&:hover': {
                            boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)', // Hover durumunda gölgeyi güncelle
                            transform: 'translateY(-2px)', // Hover durumunda hafif yukarı zıplama efekti
                        },
                        '&:active': {
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Basıldığında gölgeyi güncelle
                            transform: 'translateY(2px)', // Basıldığında hafif aşağı zıplama efekti
                        },
                    }}
                    size='large'
                    onClick={() => {
                        setRemoteForm(true)
                    }
                    }
                >
                    <Iconify icon="icon-park-outline:transform" color="#fff" height="100%" mr={1} />
                    Transform My Company
                </Button>}
            </Grid>


        </Grid>
    </DashboardLayout >
}

export default RemoteTransform