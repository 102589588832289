import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";


const initialState = {
  intern_applicationsData: null,
  intern_applicationsLoading: false,
  intern_applicationsError: null,
};

export const intern_applicationsFunc = createAsyncThunk("intern_applications/intern_applicationsFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `
    query Intern_applications($filter: FilterApplicationInput, $pagination: Pagination) {
      intern_applications(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          createdAt
          status
          updatedAt
          internship {
            _id
            type
            company {
              _id
              city {
                _id
                name
              }
              country {
                _id
                code
                flagUrl
                name
              }
              displayImageUrl
              displayName
              industry {
                name
                _id
              }
              numberOfEmployee {
                name
                _id
              }
              rating {
                average
                createdAt
                numberOfReviews
                updatedAt
              }
            }
            createdAt
            description
            descriptionSummary
            positionName
            startDate
            status
            timePeriodInWeeks
            updatedAt
            quota
          }
        }
      }
    }
    `,
    variables: {
      "pagination": {
        "page": values?.variables?.pagination?.page ?? null,
        "pageSize": values?.variables?.pagination?.pageSize ?? null,
        "sort": values?.variables?.pagination?.sort ?? null
      },
      "filter": {
        "internshipGoal": values?.variables?.filter?.internshipGoal ?? null,
        "statuses": values?.status ?? null,
      }
    }

  });

  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const intern_applications = createSlice({
  name: "intern_applications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(intern_applicationsFunc.pending, (state) => {
      state.intern_applicationsLoading = true;
    });
    builder.addCase(intern_applicationsFunc.fulfilled, (state, action) => {
      state.intern_applicationsLoading = false;
      state.intern_applicationsData = action.payload?.intern_applications;
      state.intern_applicationsError = action?.payload?.message;
    });
    builder.addCase(intern_applicationsFunc.rejected, (state, action) => {
      state.intern_applicationsLoading = false;
      state.intern_applicationsError = action.error.message;
    });
  }
});


export default intern_applications.reducer;