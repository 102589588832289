import axios from "axios";
import { imageCompress } from "./_imageCompress";
import { organizationLogoPresignedUrls, profileImagePresignedUrls, backgroundImagePresignedUrls, blogPostPresignedUrls } from "context";

export const upload = async ({ urls, image, imageType }) => {

    const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: urls?.presignedUploadUrl,
        headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': imageType === 'pngImage' ? 'image/png' : 'image/jpeg'
        },
        data: image
    };


    try {
        await axios(config)
        return urls?.url;
    } catch (error) {
        return error
    }
}


export const imageUpload = async ({ accessToken, presignedType, image, imageType }) => {
    let url = null

    switch (presignedType) {
        case 'profileImage':
            url = await profileImagePresignedUrls({ accessToken: accessToken, contentType: imageType }).then(res =>
                upload({ urls: res, image: image, imageType: imageType }))
            break;
        case 'organizationLogo':
            url = await organizationLogoPresignedUrls({ accessToken: accessToken, contentType: imageType }).then(res =>
                upload({ urls: res, image: image, imageType: imageType }))
            break;
        case 'backgroundImage':
            url = await backgroundImagePresignedUrls({ accessToken: accessToken, contentType: imageType }).then(res =>
                upload({ urls: res, image: image, imageType: imageType }))
            break;
        default:
            url = null
    }

    return url




    // imageCompress({ file: image, setCompressedImage: setCompressedImage, ratio: ratio ?? 0.5, maxWidth: maxWidth ?? 1000 })



}
