import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";

const initialState = {
    user_productCodeData: null,
    user_productCodeLoading: false,
    user_productCodeError: null,
};

export const user_productCodeFunc = createAsyncThunk("user_productCode/user_productCodeFunc", async (values) => {

    const data1 = JSON.stringify({
        query: `query User_productCode($productCode: String!) {
                user_productCode(productCode: $productCode) {
                  discountRate
                  name
                }
              }`,
        variables: {
            "productCode": values?.productCode,
        }
    });

    const config = initialConfig(process.env.REACT_APP_BASE_URL + "/payment/graphql", data1, values?.accessToken);

    const response = axios(config)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => {
            console.log("err:", err);
            return err;
        });

    const data = await response;
    return data;
}
);

const user_productCode = createSlice({
    name: "user_productCode",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(user_productCodeFunc.pending, (state) => {
            state.user_productCodeLoading = true;
        });
        builder.addCase(user_productCodeFunc.fulfilled, (state, action) => {
            state.user_productCodeLoading = false;
            state.user_productCodeData = action.payload?.user_productCode?.discountRate;
            state.user_productCodeError = action?.payload?.message;
        });
        builder.addCase(user_productCodeFunc.rejected, (state, action) => {
            state.user_productCodeLoading = false;
            state.user_productCodeError = action.error.message;
        });
    },
});

export default user_productCode.reducer;
