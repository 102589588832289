import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_updateCompanyFunc = async ({ values, logoImageUrl, accessToken, backgroundImageUrl }) => {

    const data = JSON.stringify({
        query: `
        mutation CompanyAdmin_updateCompany($companyId: ID!, $updateCompanyInput: UpdateCompanyInput!) {
            companyAdmin_updateCompany(companyId: $companyId, updateCompanyInput: $updateCompanyInput) {
              _id
            }
          }
        `,
        variables: {
            "companyId": values?.companyId || "",
            "updateCompanyInput": {
                "companyInformation": {
                    "backgroundImageUrl": backgroundImageUrl || "",
                    "logoImageUrl": logoImageUrl || "",
                    "city": {
                        "_id": values?.city || "",
                    },
                    "companyName": values?.companyName,
                    "companyWebsite": values?.companyWebsite,
                    "contactInfo": values?.contactInfo,
                    "country": {
                        "_id": values?.country || "",
                    },
                    "description": values?.description || "",
                    "detailedDescription": values?.detailedDescription || "",
                    "foundedIn": new Date(values?.foundedIn.toString()) ?? "",
                    "industry": {
                        "_id": values?.industry || "",
                    },
                    "numberOfEmployee": {
                        "_id": values?.numberOfEmployee || "",
                    },
                    "socialMediaInfo": {
                        "twitter": values?.twitter || "",
                        "linkedIn": values?.linkedin || "",
                        "instagram": values?.instagram || "",
                        "facebook": values?.facebook || "",
                    },
                }
            }
        }
    })


    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + "/user/graphql", data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data?.data?.companyAdmin_updateCompany;
    } catch (err) {
        return err;
    }
}