import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";

const initialState = {
    intern_packagesData: null,
    intern_packagesCount: 0,
    intern_packagesLoading: false,
    intern_packagesError: null,
};

export const intern_packagesFunc = createAsyncThunk("intern_packages/intern_packagesFunc", async (values) => {

    const data1 = JSON.stringify({
        query: `query Intern_packages($filter: FilterInternshipPackagesForInternInput) {
            intern_packages(filter: $filter) {
              count
              data {
                _id
                status
                createdAt
                internshipInfo {
                  date
                }
                placedInternship {
                  _id
                }
                packageType
      revisionRequestDetail
      revisionSubmitDetail
                statusChangeLogs {
                    reason
                    prevStatus
                    assignedStatus
                  }
              }
            }
          }`,
        variables: {
            "filter": {
                "packageType": values?.packageType ?? null,
                "status": values?.status ?? null,
            }
        }
    });

    const config = initialConfig(process.env.REACT_APP_BASE_URL + "/internship/graphql", data1, values?.accessToken);

    const response = axios(config)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => {
            console.log("err:", err);
            return err;
        });

    const data = await response;
    return data;
}
);

const intern_packages = createSlice({
    name: "intern_packages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(intern_packagesFunc.pending, (state) => {
            state.intern_packagesLoading = true;
        });
        builder.addCase(intern_packagesFunc.fulfilled, (state, action) => {
            state.intern_packagesLoading = false;
            state.intern_packagesData = action.payload?.intern_packages?.data;
            state.intern_packagesCount = action.payload?.intern_packages?.count;
            state.intern_packagesError = action?.payload?.message;
        });
        builder.addCase(intern_packagesFunc.rejected, (state, action) => {
            state.intern_packagesLoading = false;
            state.intern_packagesError = action.error.message;
        });
    },
});

export default intern_packages.reducer;
