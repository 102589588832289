import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_createApplication = async ({ coverLetter, internshipId, internshipGoal, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_createApplication($applicationInput: ApplicationInput!) {
            intern_createApplication(applicationInput: $applicationInput) {
              _id
            }
          }
        `,
        variables: {
            "applicationInput": {
                "coverLetter": {
                    "name": coverLetter?.name,
                    "content": coverLetter?.content,
                  },
                "internship": {
                    "_id": internshipId
                },
                "internshipGoal": internshipGoal
            }

        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data, accessToken));
        return res?.data?.data?.intern_createApplication || res?.data?.errors
    } catch (err) {
        return err
    }
}
