import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useSelector } from 'react-redux';
// hooks
import { useResponsive } from 'minimal/hooks/use-responsive';

// components
import Iconify from 'minimal/components/iconify';
import Scrollbar from 'minimal/components/scrollbar';
//
import { useCollapseNav } from '../hooks';
import { ChatNavAccount, ChatNavItem } from '../components';
import { ChatNavItemSkeleton } from '../chat-skeleton';
// import ChatNavSearchResults from '../chat-nav-search-results';

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;

const conversations = null,
  selectedConversation = null

export const ChatNav = ({ user }) => {

  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  // const { moderatorConversations: conversations, selectedConversation } = useSelector(state => state?.message)


  const {
    collapseDesktop,
    onCloseDesktop,
    onCollapseDesktop,
    //
    openMobile,
    onOpenMobile,
    onCloseMobile,
  } = useCollapseNav();

  // const [searchContacts, setSearchContacts] = useState({
  //   query: '',
  //   results: [],
  // });

  useEffect(() => {
    if (!mdUp) {
      onCloseDesktop();
    }
  }, [onCloseDesktop, mdUp]);

  const handleToggleNav = useCallback(() => {
    if (mdUp) {
      onCollapseDesktop();
    } else {
      onCloseMobile();
    }
  }, [mdUp, onCloseMobile, onCollapseDesktop]);




  // const handleClickAwaySearch = useCallback(() => {
  //   setSearchContacts({
  //     query: '',
  //     results: [],
  //   });
  // }, []);

  // const handleClickResult = useCallback(
  //   (result) => {
  //     handleClickAwaySearch();

  //     router.push(`${paths.dashboard.chat}?id=${result.id}`);
  //   },
  //   [handleClickAwaySearch, router]
  // );

  const renderToggleBtn = (
    <IconButton
      onClick={onOpenMobile}
      sx={{
        left: 0,
        top: 84,
        zIndex: 9,
        width: 32,
        height: 32,
        position: 'absolute',
        borderRadius: `0 12px 12px 0`,
        bgcolor: theme.palette.primary.main,
        boxShadow: theme.customShadows.primary,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          bgcolor: theme.palette.primary.darker,
        },
      }}
    >
      <Iconify width={16} icon="solar:users-group-rounded-bold" />
    </IconButton>
  );

  const renderSkeleton = (
    <>
      {[...Array(12)].map((_, index) => (
        <ChatNavItemSkeleton key={index} />
      ))}
    </>
  );

  const renderList = (
    <>
      {conversations?.data?.map((item, index) => (
        <ChatNavItem
          key={index}
          collapse={collapseDesktop}
          conversation={item}
          selected={
            (item?.activity?.activityId?._id === selectedConversation?.activity?.activityId?._id)
            &&
            (item?.receiver?._id === selectedConversation?.receiver?._id)
          }
          onCloseMobile={onCloseMobile}
        />
      ))}
    </>
  );

  // console.log({ selectedConversation })

  // const renderListResults = (
  //   <ChatNavSearchResults
  //     query={searchContacts.query}
  //     results={searchContacts.results}
  //     onClickResult={handleClickResult}
  //   />
  // );


  const renderContent = (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 2.5, pb: 0 }}>
        {!collapseDesktop && (
          <>
            <ChatNavAccount user={user} />
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}

        <IconButton onClick={handleToggleNav}>
          <Iconify
            icon={collapseDesktop ? 'eva:arrow-ios-forward-fill' : 'eva:arrow-ios-back-fill'}
          />
        </IconButton>
      </Stack>

      <Scrollbar sx={{ pb: 1, pt: 1 }}>
        {false ? renderSkeleton : renderList}
      </Scrollbar>
    </>
  );

  return (
    <>
      {!mdUp && renderToggleBtn}

      {mdUp ? (
        <Stack
          sx={{
            height: 1,
            flexShrink: 0,
            width: NAV_WIDTH,
            borderRight: `solid 1px ${theme.palette.divider}`,
            transition: theme.transitions.create(['width'], {
              duration: theme.transitions.duration.shorter,
            }),
            ...(collapseDesktop && {
              width: NAV_COLLAPSE_WIDTH,
            }),
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openMobile}
          onClose={onCloseMobile}
          slotProps={{
            backdrop: { invisible: true },
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}

ChatNav.propTypes = {
  // contacts: PropTypes.array,
  // conversations: PropTypes.object,
  // loading: PropTypes.bool,
  // selectedConversationId: PropTypes.string,
};

export default memo(ChatNav)
