
export const company_internshipTips_title = `<b>Unlock Global Talent with InternIF</b>`

export const company_internshipTips = `
<p>
    🌐 <b>Global Reach:</b>
    <br />
    Connect with a diverse pool of talented interns from around the world. Leverage unique perspectives and skills to enhance your projects.
    <br />
    <br />
    🚀 <b>Effortless Management:</b>
    <br />
    Streamline your internship programs with our intuitive dashboard. Effortlessly track and manage internships in real-time, from anywhere in the world.
    <br />
    <br />
    🌍 <b>Cross-Cultural Collaboration:</b>
    <br />
    Foster a dynamic work environment by engaging with interns from different cultures. Boost creativity and innovation through cross-cultural collaboration.
    <br />
    <br />
    🔍 <b>Tailored Intern Selection:</b>
    <br />
    Our platform ensures a tailored selection process, matching you with interns that best fit your company culture and project requirements.
    <br />
    <br />
    🌟 <b>Seamless Transitions:</b>
    <br />
    Identify potential long-term employees during the internship phase. Seamlessly transition outstanding interns to your workforce through our EmployIF Phase.
    <br />
    <br />
    📈 <b>Performance Analytics:</b>
    <br />
    Access insightful analytics to track internship performance. Make data-driven decisions and optimize your internship programs for maximum impact.
    <br />
    <br />
    🛠️ <b>Advanced Task Management:</b>
    <br />
    Utilize our Task Management System to streamline projects and enhance collaboration. Save time and ensure tasks are completed efficiently.
    <br />
    <br />
    💬 <b>In-App Communication:</b>
    <br />
    Facilitate effective communication through our In-App Chat and Video Meeting features. Stay connected with your interns effortlessly.
    <br />
    <br />
    📜 <b>Certification and Recognition:</b>
    <br />
    Acknowledge and reward interns with certifications at the end of successful internships. Boost your company's reputation as an employer of choice.
    <br />
    <br />
    🔄 <b>Continuous Improvement:</b>
    <br />
    Receive feedback from interns and enhance your internship programs. Continuously improve your processes for a more enriching experience.
    <br />
</p>`