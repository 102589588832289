import { motion } from 'framer-motion';


export const AnimatedMappingCard = ({ children, index }) => {
    return <motion.div
        style={{ width: "100%" }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1, duration: 0.5, ease: "easeOut" }}
    >
        {children}
    </motion.div>
}