
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { hideScroll } from 'minimal/theme/css';
import { useMockedUser } from 'minimal/hooks/use-mocked-user';
import InternIFLogoOnlyIF from 'assets/logos/InternIFLogoOnlyIF.png';
import { NavSectionMini } from 'minimal/components/nav-section';
import { useNavData } from './ConfigNavigations';
import NavToggleButton from './NavToggleButton';



export default function NavMini() {
  const { user } = useMockedUser();

  const navData = useNavData();

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: 120 },
      }}
    >
      <NavToggleButton
        sx={{
          top: 38,
          left: 108,
        }}
      />

      <Stack
        sx={{
          pt: 4,
          pb: 2, 
          height: 1,
          position: 'fixed',
          alignItems: 'center',
          width: 120,
          backgroundColor: '#101426',
          borderRight: () => ` 0px #dedede`,
          ...hideScroll.x,
        }}
      >
        <img style={{ width: 40, marginBottom: 60, }} src={InternIFLogoOnlyIF} alt="logo" />

        <NavSectionMini
          data={navData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
      </Stack>
    </Box>
  );
}
