import React from 'react'
import { motion } from "framer-motion"


export const CardContainer = ({ children }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}

            whileHover={{
                scale: 1.01,
                boxShadow: "10px 10px 20px 1px rgba(122, 122, 122, 0.2)",
            }}
            whileTap={{ scale: 1.005 }}
            // drag="y"
            // dragConstraints={{ top: -10, bottom: 10 }}

            animate={{
                backgroundColor: "rgba(51, 51, 51, 0)",
                borderRadius: '8px',
                // cursor: "pointer",
            }}
        >
            {children}
        </motion.div>
    )
}
