import React, { useState } from 'react'
import { Box, IconButton, Rating, Typography } from '@mui/material'
import StraightIcon from '@mui/icons-material/Straight';
import { SliderForAuth } from 'components';

const sliderExample = [
    {
        "id": "1",
        "comment": "My boss and co-workers were great about giving me contacts and referring me to open positions in the industry.",
        "name": "Seth Julie",
        "stars": 5,
        "school": "Internationa University of Sarajevo",
        "grade": "Law 2nd Grade",
    },
    {
        "id": "2",
        "comment": "I thoroughly enjoyed my internship this summer and now have very valuable experience under my belt. I know this will...",
        "name": "Jessalyn Nance",
        "stars": 5,
        "school": "International Balkan University",
        "grade": "Law 2nd Grade",
    },
    {
        "id": "3",
        "comment": "I was dreading it in the beginning, but now I am so happy it was required. As much as the curriculum changes, I hope that..",
        "name": "Lydie Chucky",
        "stars": 5,
        "school": "Goce Delchev University",
        "grade": "Law 2nd Grade",
    },
]



export const AuthSlider = ({ style }) => {

    return (
        <Box sx={style} >
            <Box
                sx={{
                    position: 'absolute',
                    bottom: { md: '70px', lg: '100px' },
                    left: '12.5%',
                    width: '75%',
                    overflow: 'hidden',
                }}
            >
                <Slider />
            </Box>
        </Box>
    )
}

const Slider = () => {

    const [sliderNumber, setSliderNumber] = useState(0)

    const handleClickNext = () => {
        setSliderNumber((state) => {
            if (state >= sliderExample.length - 1) {
                return 0;
            }
            return state + 1;
        });
    };

    const handleClickPrev = () => {
        setSliderNumber((state) => {
            if (state === 0) {
                return sliderExample?.length - 1;
            }
            return state - 1;
        });
    };

    return (
        <>
            {
                sliderExample?.map((item, index) => {

                    if (sliderNumber !== index) return null;

                    return (
                        <Box
                            key={index}
                            sx={{

                                backgroundColor: "rgba(255, 255, 255, 0.6)",
                                background: 'linear-gradient(135.85deg, rgba(255, 255, 255, 0.64) 17.26%, rgba(255, 255, 255, 0.1) 105.55%)',
                                backdropFilter: "blur(30px)",

                                color: '#fff',
                                borderRadius: '16px',
                                p: { md: '24px', lg: '28px', xl: '32px' },

                                overflow: 'hidden',
                            }}
                        >

                            <SliderItem
                                key={item.id}
                                number={index}
                                comment={item.comment}
                                name={item.name}
                                stars={item.stars}
                                school={item.school}
                                grade={item.grade}

                                sliderNumber={sliderNumber}
                                handleClickNext={handleClickNext}
                                handleClickPrev={handleClickPrev}
                            />

                        </Box>
                    )
                })
            }
        </>
    )
}

const SliderItem = ({ comment, name, stars, school, number, handleClickNext, handleClickPrev }) => {

    return (
        <Box sx={{ overflow: 'hidden', }} >

            <SliderForAuth>
                <Typography
                    sx={{
                        fontFamily: 'Causten-Medium',
                        fontSize: { md: '16px', lg: '18px', xl: '20px' },
                        lineHeight: { md: '21px', lg: '32px' },
                        color: '#fff',
                    }}
                >
                    “{comment}”
                </Typography>


                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: { md: '16px', lg: '20px', xl: '25px' },
                        mb: { md: '10px', lg: '13px', xl: '15px' },
                        overflow: 'hidden',
                    }}
                >

                    <Typography
                        sx={{
                            fontFamily: 'Causten-Bold',
                            fontSize: { md: '16px', lg: '18px', xl: '20px' },
                            lineHeight: { md: '20px', lg: '33.6px' },
                            color: '#fff',
                        }}
                    >
                        {name}
                    </Typography>


                    <Rating name="read-only" value={stars} readOnly />
                </Box>

            </SliderForAuth>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <SliderForAuth>
                    <Typography
                        sx={{
                            fontFamily: 'Causten-Regular',
                            fontSize: { md: '16px', lg: '18px', xl: '20px' },
                            lineHeight: { md: '20px', lg: '24px', xl: '28px' },
                            color: '#fff'
                        }}
                    >
                        {school} <br />
                        {/* {grade} */}
                    </Typography>
                </SliderForAuth>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        mr: { md: 0, lg: 0 },
                        color: '#fff',
                    }}
                >
                    <IconButton
                        onClick={handleClickPrev}
                        sx={{
                            width: { md: '50px', lg: '55px', xl: "60px" },
                            height: { md: '50px', lg: '55px', xl: "60px" },
                            transform: 'rotate(-90deg)',
                            color: '#fff',
                        }}
                    >
                        <StraightIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleClickNext}
                        sx={{
                            width: { md: '50px', lg: '55px', xl: "60px" },
                            height: { md: '50px', lg: '55px', xl: "60px" },
                            transform: 'rotate(90deg)',
                            color: '#fff',
                        }}
                    >
                        <StraightIcon />
                    </IconButton>
                </Box>

            </Box>

        </Box >
    )
}

export default AuthSlider