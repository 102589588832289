import axios from "axios";
import initialConfig from "context/initialConfig";


export const self_updateUserBillingInfo = async ({ values, accessToken, ip }) => {

  const data = JSON.stringify({
    query: `mutation Self_updateUserInvoiceInfo($input: InvoiceInfoInput!) {
      self_updateUserInvoiceInfo(input: $input) {
        success
      }
    }`,
    variables: {
      "input": {
        "personal": {
          "address": values?.address,
          "city": values?.city2,
          "country": values?.country2,
          "email": values?.email,
          "phoneNumber": values?.phone,
          "id": values?.identityNumber.toString(),
          "name": values?.name,
          "zip": values?.postcode,
          "surname": values?.surname
        },
        "type": "personal"
      }
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    if (res.errors?.length > 0) {
      return {
        errorMessage: res?.errors[0].message,
      };
    }
    return res?.data?.data?.self_updateUserInvoiceInfo
  } catch (err) {
    return err
  }
}