import axios from "axios";
import initialConfig from "context/initialConfig";


export const intern_addUniversityEducationFunc = async ({ selectedUniversity, accessToken }) => {

  console.log(selectedUniversity, 'selectedUniversity')

  const data = JSON.stringify({
    query: `
        mutation Intern_addUniversityEducation($universityEducationInput: UniversityEducationInput!) {
            intern_addUniversityEducation(universityEducationInput: $universityEducationInput) {
              _id
            }
          }
        `,
    variables: selectedUniversity?.university?._id ? {
      "universityEducationInput": {
        "university": {
          "_id": selectedUniversity?.university?._id ?? null,
          "name": selectedUniversity?.university?.name ?? null
        },
        "startDate": selectedUniversity?.startDate ?? null,
        "finishDate": selectedUniversity?.finishDate ?? null,
        "department": {
          "_id": selectedUniversity?.department?._id ?? null,
          "name": selectedUniversity?.department?.name ?? null
        }
      }
    } : {
      "universityEducationInput": {
        "university": {
          "name": selectedUniversity?.university?.name ?? null
        },
        "startDate": selectedUniversity?.startDate ?? null,
        "finishDate": selectedUniversity?.finishDate ?? null,
        "department": {
          "_id": selectedUniversity?.department?._id ?? null,
          "name": selectedUniversity?.department?.name ?? null
        }
      }
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    // console.log(res?.data)
    return res?.data?.data?.intern_addUniversityEducation;

  } catch (err) {
    return err
  }
}
