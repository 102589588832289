import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {t} from 'i18next';


export const Dropdown = ({ setFieldValue, setValue, errors, data, labelId, label, id, value, disabled, type, style }) => {

    return <FormControl fullWidth error={errors?._id} disabled={disabled} style={style ?? null}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
            labelId={labelId}
            id={id}
            value={value}
            label={label}
            onChange={(event) => { setValue ? setValue(event.target.value) : setFieldValue(id, event.target.value) }}
            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
            {data?.map((option, index) => (
                <MenuItem key={index} value={type !== 'withoutId' ? option?._id : option?.value} sx={{ display: "flex", alignItems: "center", }}>
                    {type === "withFlag" && <img
                        loading="lazy"
                        style={{ width: "20px", height: "20px", borderRadius: '15px', objectFit: 'cover', marginRight: "10px" }}
                        src={`https://flagcdn.com/${option?.code.toLowerCase()}.svg`}
                        alt={option?.code.toLowerCase()}
                    />}
                    {type === "withFlagForLanguage" && <img
                        loading="lazy"
                        style={{ width: "20px", height: "20px", borderRadius: '15px', objectFit: 'cover', marginRight: "10px" }}
                        src={`https://flagcdn.com/${option?.metadata?.code.toLowerCase()}.svg`}
                        alt={option?.metadata?.code.toLowerCase()}
                    />}
                    {t(option?.name)}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}
