
import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_updateHighSchoolEducationFunc = async ({ values, id, accessToken }) => {

    const data = JSON.stringify({
        query: `
            mutation Intern_updateHighSchoolEducation($highSchoolEducationId: ID!, $updateHighSchoolEducationInput: UpdateHighSchoolEducationInput!) {
                intern_updateHighSchoolEducation(highSchoolEducationId: $highSchoolEducationId, updateHighSchoolEducationInput: $updateHighSchoolEducationInput) {
                    _id
                }
            }
        `,
        variables: {
            "highSchoolEducationId": id,
            "updateHighSchoolEducationInput": {
                "name": values?.name,
                "department": {
                    "_id": values?.department?._id,
                },
                "finishDate": values.finishDate,
                "startDate": values.startDate,
            }
        }

    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }

        return res?.data?.data?.intern_updateHighSchoolEducation;

    } catch (err) {
        return err
    }
}
