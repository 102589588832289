export const genderOptions = [
  {
    value: "male",
    name: "Male"
  },
  {
    value: "female",
    name: "Female",
  },
  {
    value: "other",
    name: "Other",
  }
]