// At least 4 weeks later
//  Always start on Monday

export const UsePackageTime = {
  AtLeast4WeeksLater: {
    value: "AtLeast4WeeksLater",
    name: "At least 4 weeks later",
  },
  AlwaysStartOnMonday: {
    value: "AlwaysStartOnMonday",
    name: "Always start on Monday",
  },
};


export const usePackageTimeList = Object.values(UsePackageTime);

export const usePackageTimeValueList = Object.values(UsePackageTime).map(
  (period) => period.value
);

export const usePackageTimeNameList = Object.values(UsePackageTime).map(
  (period) => period.name
);

export function getValueOfusePackageTime(name) {
  for (const key of Object.keys(UsePackageTime)) {
    if (UsePackageTime[key].name === name) {
      return UsePackageTime[key].value;
    }
  }
  return null;
}

export function getNameOfusePackageTime(value) {
  for (const key of Object.keys(UsePackageTime)) {
    if (UsePackageTime[key].value === value) {
      return UsePackageTime[key].name;
    }
  }
  return null;
}