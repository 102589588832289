
import { Box, Card, Grid, Avatar, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { AccountCircleOutlined } from '@mui/icons-material'
import { getNameOfApplicationStatusForCompany } from 'utils'
import Label from 'minimal/components/label';
import parse from 'html-react-parser';


export const ApplicationCardForCompany = ({ item, applicationDetails, onOpenDetail, onViewApplication }) => {



    return <Card
        sx={{
            p: 2, borderRadius: 2, backgroundColor: 'rgba(255,255,255, 0.5)', backdropFilter: 'blur(6px)', cursor: 'pointer',
            '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)" }
        }}
        onClick={() => {
            ;
            onOpenDetail();
            onViewApplication(item);
        }}
    >

        <Grid container spacing={2} alignItems='center' justifyContent={'center'}>
            <Grid item xs={12} justifyContent={'center'} >
                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={1} >
                    <Card sx={{ width: "60px", height: "60px", p: 0, display: "flex", justifyContent: 'center', alignItems: 'center', borderRadius: 2, boxShadow: '0px 0px 10px rgba(30, 30, 30, 0.2)' }}>
                        <Avatar
                            alt={item.personalInformation?.name + ' ' + item.personalInformation?.surname}
                            src={item.personalInformation?.profileImageUrl}
                            sx={{ width: 50, height: 50 }}
                        />
                    </Card>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"} ml={1}>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' }, gap: 1 }}>
                            <Typography variant='h5' color={'#444'} mr={1} >  {item.personalInformation?.name + ' ' + item.personalInformation?.surname} </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ flexDirection: { xs: 'column', sm: 'row' } }} >
                            <Typography variant='h7' color={'#666'}>{item.personalInformation?.country?.name} </Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >,</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >{item.personalInformation?.city?.name}</Typography>
                        </Box>
                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>{' '}</Typography>
                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>Applied {moment(applicationDetails?.createdAt).fromNow()} </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item right={10} top={0} position={'absolute'}>
                <Label color={
                    applicationDetails?.status === "applied" ? 'info' :
                        applicationDetails?.status === "viewed" ? 'secondary' :
                            applicationDetails?.status === "rejected" ? 'error' :
                                applicationDetails?.status === "accepted" ? 'success' : 'primary'
                }
                    sx={{ borderRadius: 200, minWidth: 90 }}
                > {getNameOfApplicationStatusForCompany(applicationDetails?.status)}</Label>
            </Grid>

        </Grid>



    </Card >
}
