import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";


const initialState = {
  user_internsByIdsForApplicationData: null,
  user_internsByIdsForApplicationLoading: false,
  user_internsByIdsForApplicationError: null,
};

export const user_internsByIdsForApplicationFunc = createAsyncThunk("user_internsByIdsForApplication/user_internsByIdsForApplicationFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `query User_internsByIdsForApplication($userIds: [String]!) {
        user_internsByIdsForApplication(userIds: $userIds) {
          _id
          personalInformation {
            name
            surname
            profileImageUrl
            country {
              name
            }
            city {
              name
            }
          }
        }
      }`,
    variables: {
        "userIds": values.userIds
      }

  });


  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const user_internsByIdsForApplication = createSlice({
  name: "user_internsByIdsForApplication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(user_internsByIdsForApplicationFunc.pending, (state) => {
      state.user_internsByIdsForApplicationLoading = true;
    });
    builder.addCase(user_internsByIdsForApplicationFunc.fulfilled, (state, action) => {
      state.user_internsByIdsForApplicationLoading = false;
      state.user_internsByIdsForApplicationData = action.payload?.user_internsByIdsForApplication;
      state.user_internsByIdsForApplicationError = action.payload.message;
    });
    builder.addCase(user_internsByIdsForApplicationFunc.rejected, (state, action) => {
      state.user_internsByIdsForApplicationLoading = false;
      state.user_internsByIdsForApplicationError = action.error.message;
    });
  }
});


export default user_internsByIdsForApplication.reducer;