import axios from "axios";
import { imageCompress } from "./_imageCompress";
import { organizationLogoPresignedUrls, profileImagePresignedUrls, backgroundImagePresignedUrls, blogPostPresignedUrls, taskCommentAttachmentPresignedUrls } from "context";

export const upload = async ({ urls, file, fileType }) => {

    const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: urls?.presignedUploadUrl,
        headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': fileType
        },
        data: file
    };


    try {
        await axios(config)
        console.log(urls)
        return urls;
    } catch (error) {
        return error
    }
}


export const fileUpload = async ({ accessToken, presignedType, file, fileType, fileName }) => {
    let url = null

    switch (presignedType) {
        case 'commentFile':
            url = await taskCommentAttachmentPresignedUrls({ accessToken: accessToken, contentType: fileType, fileName: fileName }).then(res =>
                upload({ urls: res, file: file, fileType: fileType }))
            break;
        default:
            url = null
    }

    return url



}
