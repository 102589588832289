import { useEffect, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, useMediaQuery, Button, Stack } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import { Circle, CircleNotifications, DraftsOutlined, LockOpen, Search } from '@mui/icons-material'
import Label from 'minimal/components/label'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { user_userNotificationsFunc, user_readUserNotificationFunc, user_readAllUserNotificationsFunc } from 'context'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'

const info = [
    "Faz 1 başlamadı."
]

const filters = ["All", "Today", "Yesterday", "Last Week", "Last Month"]

const Notifications = () => {

    const { accessToken } = useSelector(state => state.user)
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const [activeFilter, setActiveFilter] = useState(0)
    const [selectedNotification, setSelectedNotification] = useState(null)
    const dispatch = useDispatch()
    const { user_userNotificationsData } = useSelector(state => state.user_userNotifications)

    useEffect(() => {
        dispatch(user_userNotificationsFunc({ accessToken: accessToken }))
    }, [accessToken, dispatch])

    const handleNotifitationClick = (notification) => {
        console.log(notification)
        setSelectedNotification(notification)
        if (!notification.isRead) {
            user_readUserNotificationFunc({ accessToken: accessToken, uid: notification.uid }).then(() => {
                dispatch(user_userNotificationsFunc({ accessToken: accessToken }))
            })
        }
    }


    const handleMarkAllAsRead = () => {
        user_readAllUserNotificationsFunc({ accessToken: accessToken }).then(() => {
            dispatch(user_userNotificationsFunc({ accessToken: accessToken }))
            enqueueSnackbar(t('All notifications marked as read.'), { variant: 'success' })
        })
    }

    const handleFilter = (filter, index) => {
        console.log(filter)
        setActiveFilter(index)
        //TODO Filter ayarlarını backendden sonra yap
        dispatch(user_userNotificationsFunc({ accessToken: accessToken }))
    }


    return <DashboardLayout info={info}>
        <Grid container sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} position={"relative"}>
                <AnimatedTitle title={`${t("Notifications")}`} />
                {upLg && <TextField placeholder={t("Search")} sx={{ width: '40%' }} />}
                {upLg && <Search sx={{ position: 'absolute', right: 15, top: 30 }} color='alternative2' />}
            </Grid>

            <Grid item xs={12} mt={2}>
                {/* <Card sx={{pb:15, width:'100%'}}> */}
                <Box sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    alignItems: 'center', width: '100%'
                }}>
                    <Box sx={{ display: 'flex' }}>
                        {filters.map((filter, index) => <Label
                            key={index}
                            onClick={() => handleFilter(filter, index)}
                            color={activeFilter === index ? "primary" : "alternative1"}
                            sx={{ cursor: 'pointer', width: '100px', py: 2, mr: 2, borderRadius: 10, fontSize: 14, fontWeight: 'bold' }}>
                            {t(filter)}
                        </Label>)}
                    </Box>
                    <Box sx={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', alignItems: 'center' }} onClick={handleMarkAllAsRead}>
                        <DraftsOutlined sx={{ mr: 1 }} fontSize='small' color='alternative1' />
                        <Typography fontSize="small" sx={{ color: '#8d8d8d' }}>
                            {t('Mark All as Read')}
                        </Typography>
                    </Box>
                </Box>
                {user_userNotificationsData?.length > 0 ? user_userNotificationsData?.map((notification, index) => <Box key={index}
                    onClick={() => handleNotifitationClick(notification)}
                    sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', p: 2,
                        border: '1px solid #f0f0f0', mt: 3, borderRadius: 1, cursor: 'pointer',
                        borderColor: notification?.isRead ? '#f0f0f0' : '#FFD5AF',
                        backgroundColor: notification?.isRead ? '#fff' : '#FFFAF5',
                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {!notification?.isRead && <Circle sx={{ color: 'secondary.main', mr: 2, width: 10 }} />}
                        <Box sx={{
                            width: 45, height: 45, borderRadius: 25,
                            backgroundColor: notification?.isRead ? 'primary.lighter' : '#FFD5AF'
                        }}>
                            {notification?.notificationType === "authentication" ? <LockOpen sx={{
                                color: notification?.isRead ? 'primary.main' : 'secondary.main',
                                fontSize: 30, mt: 1, ml: 1
                            }} /> : null}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', ml: 2 }}>
                            <Typography variant='h7' color={'#444'}>{notification?.notificationPreview?.subject}</Typography>
                            <Typography variant='body2' color={'#666'}>{moment(notification?.createdAt).fromNow()}</Typography>
                        </Box>
                    </Box>
                </Box>) :
                    <Typography variant="h6" textAlign={"left"} mt={3} color="text.secondary">
                        {t('There is no notifications.')}
                    </Typography>
                }
            </Grid>
        </Grid>

        <Dialog open={selectedNotification} onClose={() => setSelectedNotification(null)} fullWidth maxWidth="sm">
            <DialogTitle sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 2 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ width: 45, height: 45, borderRadius: 25, backgroundColor: 'primary.lighter' }}>
                        {selectedNotification?.notificationType === "authentication" ? <LockOpen sx={{ color: 'primary.main', fontSize: 30, mt: 1, ml: 1 }} /> : null}
                    </Box>{selectedNotification?.notificationPreview?.subject}
                </Stack>
                <Typography fontSize={"medium"} fontWeight={"bold"} color={"primary.light"}>
                    {moment(selectedNotification?.createdAt).fromNow()}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {selectedNotification?.notificationPreview?.content}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined"
                    onClick={() => setSelectedNotification(null)}
                    color="inherit">
                    {t('Close')}
                </Button>
            </DialogActions>

        </Dialog>
    </DashboardLayout >
}

export default Notifications