import { useState, useEffect } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BasicInput, BasicInputPassword } from 'components';

const ValidationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address!')
        .required('Email is required!'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters!')
        .required('Password is required!'),
})

export const AuthSigninForm = ({ handleSignin }) => {


    const { handleSubmit, handleChange, handleBlur, errors, values, touched, setFieldValue } = useFormik({
        initialValues: {
            email: localStorage.getItem('email'),
            password: '',
            remember: localStorage.getItem('remember') === 'true' ? true : false
        },
        validationSchema: ValidationSchema,
        onSubmit: handleSignin
    })

    const navigate = useNavigate();
    const { signinLoading } = useSelector(state => state.signin);



    return <Box display="flex" flexDirection="column" alignItems="center" gap="10px" width="100%">
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Box flexDirection="column" alignItems="center" gap="10px" width="100%">
                <BasicInput
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Enter your email address"
                    value={values?.email}
                    error={touched.email && errors.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                />
                <BasicInputPassword
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Enter your password"
                    value={values?.password}
                    error={touched.password && errors.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                />
                <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%">
                    <FormControlLabel
                        control={<Checkbox
                            color="primary"
                            checked={values?.remember}
                            onChange={(e) => setFieldValue('remember', e.target.checked)}
                            name="remember"
                        />}
                        label={<Typography variant="h7" color="primary.main">
                            Remember me
                        </Typography>}
                    />
                    <Typography variant="h7" color="info.main" onClick={() => navigate('/forgot-password')} style={{ cursor: 'pointer' }}>
                        Forgot Password?
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center" alignItems={'center'} width="100%">
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={(signinLoading || !values?.email || !values?.password || errors?.email || errors?.password) ? true : false}
                        onClick={handleSubmit}
                        type=""
                        sx={{
                            width: '100%',
                            borderRadius: '12px',
                            textTransform: 'none',
                            marginTop: '10px',
                            gap: 2,
                            height: '48px',
                        }}
                    >
                        <Typography variant='h6' >
                            {signinLoading ? <Box mt={1}><CircularProgress size={20} pt={10} /></Box> : 'Sign In'}
                        </Typography>
                    </Button>
                </Box>
                <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"15px"} mb={"30px"}>
                    <Typography variant="h7" color="primary.300">
                        Don't have an account? <Typography onClick={() => navigate('/signup')} variant="h7" color="info.main" ml={"5px"}
                            style={{ cursor: 'pointer' }}>
                            Sign Up
                        </Typography>

                    </Typography>
                </Box>
            </Box>
        </form>
    </Box>
}
