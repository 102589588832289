import axios from "axios";
import Compressor from "compressorjs"



export const dmsConfig = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/dms/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data
});

export const dmsQueries = {

    profileImagePresignedUrls: async ({ contentType, accessToken }) => {

        const data = JSON.stringify({
            query: `
                query UserPresignedUrls($contentType: ImageContentType!) {
                    userPresignedUrls(contentType: $contentType) {
                        profileImage {
                            presignedThumbnailUpload
                            presignedUploadUrl
                            url
                        }
                    }
                }
              `,
            variables: {
                "contentType": contentType ?? null,
            }
        })


        try {
            const res = await axios(dmsConfig({ data, accessToken }))
            return res?.data?.data?.userPresignedUrls.profileImage
        } catch (err) {
            return err
        }

    },

    backgroundImagePresignedUrls: async ({ contentType, accessToken }) => {

        const data = JSON.stringify({
            query: `
                query UserPresignedUrls($contentType: ImageContentType!) {
                    userPresignedUrls(contentType: $contentType) {
                        backgroundImage {
                            presignedThumbnailUpload
                            presignedUploadUrl
                            url
                        }
                    }
                }
              `,
            variables: {
                "contentType": contentType ?? null,
            }
        })


        try {
            const res = await axios(dmsConfig({ data, accessToken }))
            return res?.data?.data?.userPresignedUrls.backgroundImage
        } catch (err) {
            return err
        }

    },

    blogPostPresignedUrls: async ({ contentType, accessToken }) => {

        const data = JSON.stringify({
            query: `
                query BlogPostPresignedUrls($contentType: ImageContentType!) {
                    blogPostPresignedUrls(contentType: $contentType) {
                        contentImage {
                            presignedThumbnailUpload
                            presignedUploadUrl
                            url
                        }
                    }
                }
            `,
            variables: {
                "contentType": contentType ?? null,
            }
        })


        try {
            const res = await axios(dmsConfig({ data, accessToken }))
            return res?.data?.data?.blogPostPresignedUrls.contentImage
        } catch (err) {
            return err
        }

    },

}


export const findImageContentType = (file) => {

    if (file?.type === "image/png") return "pngImage"
    return "jpegImage"

}

export const compressImage = (file, quality) => {

    return new Promise((resolve, reject) => {

        new Compressor(
            file, {
            quality: quality ?? 0.6,
            success(result) {
                resolve(result)
            },
            error(e) {
                reject(e)
            },
        })

    })

}

export const handleSaveImage = async ({ getPresignUrl, file, comppressRate, accessToken }) => {

    console.log({ getPresignUrl, file, comppressRate, accessToken })

    const contentType = findImageContentType(file)
    const url = await getPresignUrl({ contentType, accessToken })
    const comppressedImage = await compressImage(file, comppressRate)

    console.log({ contentType, url, comppressedImage })


    const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: url?.presignedUploadUrl,
        headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': 'image/png'
        },
        data: comppressedImage
    };

    try {
        await axios(config)
        return url?.url
    } catch (error) {
        return error
    }


}