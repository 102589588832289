import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_addHighSchoolEducationFunc = async ({ values, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_addHighSchoolEducation($highSchoolEducationInput: HighSchoolEducationInput!) {
            intern_addHighSchoolEducation(highSchoolEducationInput: $highSchoolEducationInput) {
              _id
            }
          }
        `,
        variables: {
            "highSchoolEducationInput": {
                "name": values?.name,
                "department": {
                    "_id": values?.department?._id,
                },
                "finishDate": values.finishDate,
                "startDate": values.startDate,
            }
        }

    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }

        return res?.data?.data?.intern_addHighSchoolEducation;

    } catch (err) {
        return err
    }
}
