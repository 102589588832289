import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, IconButton, Grid, useMediaQuery, Typography, Card, Tooltip } from '@mui/material'
import { intern_internshipFunc, intern_saveInternship, intern_setInternshipNull, intern_unsaveInternship } from 'context'
import { DashboardLayout } from 'layouts'
import moment from 'moment'
import { ApplyToInternship } from 'page-sections'
import {
    CalendarMonth, FactoryOutlined, PeopleOutline, SummarizeOutlined,
    SchoolOutlined, SettingsSuggestOutlined, LibraryAddCheckOutlined, PaymentsOutlined, CoffeeOutlined,
    LanguageOutlined, Favorite, FavoriteBorder, ChevronLeft, Explore, People, BusinessCenterOutlined, Info, InfoOutlined
} from '@mui/icons-material'
import {
    getNameOfInternshipTimePeriod, getNameOfInternshipArea, getNameOfInternshipPreferredGPA,
    getNameOfInternshipPreferredCollegeYear, getNameOfInternshipCoffee, getNameOfInternshipLanguage
} from 'utils'
import { user_companyFunc } from 'context'
import { AnimatedTitle, ShareButtonsMenuContainer } from 'components'
import DefaultBackground from 'assets/images/default-background.png';
import userImage from 'assets/images/common/user.png';
import parse from 'html-react-parser';
import { t } from 'i18next'
const info = [
    "Faz 2 bitti",
]


const InternshipDetail = () => {

    const upMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const upSm = useMediaQuery(theme => theme.breakpoints.up('sm'));

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { accessToken } = useSelector(state => state.user);
    const { intern_internshipData: internship } = useSelector(state => state.intern_internship);
    const { user_companyData: company } = useSelector(state => state.user_company);

    const handleLikeAndDislike = async () => {
        if (internship?.isSaved) {
            await intern_unsaveInternship({ internshipId: internship?._id, accessToken })
        } else {
            await intern_saveInternship({ internshipId: internship?._id, accessToken })
        }
        dispatch(intern_internshipFunc({ internshipId: internship?._id, accessToken }))
    }


    useEffect(() => {
        dispatch(intern_internshipFunc({ internshipId: id, accessToken }))
        dispatch(user_companyFunc({ accessToken, companyId: internship?.company?._id }))
    }, [accessToken, dispatch, id, internship?.company?._id])



    return (
        <DashboardLayout info={info}>
            <Button
                sx={{ position: 'absolute', top: {xs:50, lg:0}, left: {xs:0, md: 20} }}
                onClick={() => {
                    dispatch(intern_setInternshipNull())
                    navigate("/internship")
                }}
            >
                <ChevronLeft /> {t("Back")}
            </Button>

            <Grid container sx={{ m: { xs: 0, md: 4 } }}>

                <Grid item xs={12} lg={8} xl={9} pr={{ xs: 0, sm: 10 }} >

                    <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ mb: 2, mt: 2 }}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>

                            <Grid container >
                                <Grid item xs={12} md={9} display={"flex"} flexDirection={"column"} mt={{ xs: 5 }} >
                                    <AnimatedTitle title={internship?.positionName} />
                                    {
                                       (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography onClick={() => navigate(`/company/${internship?.company?._id}`)}
                                                    style={{ cursor: 'pointer' }}
                                                    variant="h7">{internship?.company?.displayName}</Typography>
                                                <Typography variant="h5" color={"GrayText"} >•</Typography>
                                                <Typography variant="h7" color={"GrayText"} >Posted {moment(internship?.createdAt).fromNow()}</Typography>
                                                <Typography variant="h5" color={"GrayText"} >•</Typography>
                                                <Typography variant="h7" color={"GrayText"} >{getNameOfInternshipTimePeriod(internship?.timePeriodInWeeks)}</Typography>
                                            </Box>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', gap: 2 }}>

                                    <ShareButtonsMenuContainer shareUrl={`https://internif.com/internship/${internship?._id}`} title={t("Share")} />
                                    <IconButton sx={{ ml: 3, flexDirection: 'column', width: 70, height: 70 }} color='primary' size='large' onClick={handleLikeAndDislike}>
                                        {internship?.isSaved ? <Favorite color='secondary' /> : <FavoriteBorder color='primary' />}
                                        <Typography variant="h7">{internship?.isSaved ? t("Saved") : t("Save")}</Typography>
                                    </IconButton>
                                </Grid>
                            </Grid>




                        </Grid>

                        {/* {
                            !upSm && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pl: 0 }}>
                                    <Typography onClick={() => navigate(`/company/${internship?.company?._id}`)}
                                        style={{ cursor: 'pointer' }}
                                        variant="h7">{internship?.company?.displayName}</Typography>
                                    <Typography variant="h5" color={"GrayText"} >•</Typography>
                                    <Typography variant="h7" color={"GrayText"} >Posted {moment(internship?.createdAt).fromNow()}</Typography>
                                    <Typography variant="h5" color={"GrayText"} >•</Typography>
                                    <Typography variant="h7" color={"GrayText"} >{getNameOfInternshipTimePeriod(internship?.timePeriodInWeeks)}</Typography>
                                </Box>
                            )
                        } */}
                    </Grid>

                    <Grid container sx={{ mb: 2, mt: 5, pr: 4, gap: { xs: 1, md: 0 } }}>
                        <Grid xs={12} md={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'flex-start', md: 'flex-start' }, gap: 1 }}>
                            <img src={require('assets/icons/dashboard/internship-details-country.png')} alt="internship-details-country" />
                            <Typography variant="h8"> {internship?.company?.country?.name}</Typography>
                        </Grid>

                        <Grid xs={12} md={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'flex-start', md: 'center' }, gap: 1 }}>
                            <img src={require('assets/icons/dashboard/internship-details-employee.png')} alt="internship-details-employee" />
                            <Typography variant="h8">{internship?.company?.numberOfEmployee?.name} Employees</Typography>
                        </Grid>

                        <Grid xs={12} md={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, justifyContent: { xs: 'flex-start', md: 'flex-end' } }}>
                            <img src={require('assets/icons/dashboard/internship-details-healt.png')} alt="internship-details-area" />
                            <Typography variant="h8">{getNameOfInternshipArea(internship?.internshipArea)}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ my: 2 }}>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                            <BusinessCenterOutlined color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Internship Type")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.type === "indefinite" ? t("Indefinite") : t("Scheduled")}</Typography>
                        </Grid>
                        {internship?.type !== "indefinite" && <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                            <CalendarMonth color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Start Date")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{moment(internship?.startDate).format("DD MMMM YYYY")}</Typography>
                        </Grid>}
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                            <LibraryAddCheckOutlined color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Application Type")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.applicationType.charAt(0).toUpperCase() + internship?.applicationType.slice(1)}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                            <PaymentsOutlined color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Salary")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.salaryType === "Paid" ? internship?.salary :
                                internship?.salaryType === "Volunteer" ? "Volunteer" : null}</Typography>
                            <Tooltip title={t("Salary")} placement="top" sx={{ mt: -0.5, cursor: 'pointer' }}>
                                <InfoOutlined color='primary' fontSize='inherit' />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                            <PeopleOutline color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Quota")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.quota}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                            <CoffeeOutlined color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Coffee")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipCoffee(internship?.coffee)}</Typography>
                            <Tooltip title={t("Coffee")} placement="top" sx={{ mt: -0.5, cursor: 'pointer' }}>
                                <InfoOutlined color='primary' fontSize='inherit' />
                            </Tooltip>
                        </Grid>

                    </Grid>

                    <Grid container sx={{ mb: 2 }} flexDirection={"column"} overflow={"hidden"} >
                        <Typography variant="h7" mb={2}>{t("Description")}</Typography>
                        <Typography variant="body2">{parse(internship?.description ?? '')}</Typography>
                    </Grid>

                    <Grid container sx={{ my: 2 }}>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                            <Typography variant="h7" mb={2}>{t("Preferences")}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                            <LanguageOutlined color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Languages")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.preferredLanguages?.map((language, index) => <span key={language}>{index !== 0 && ','}  {getNameOfInternshipLanguage(language)} </span>)}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                            <SummarizeOutlined color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("GPA")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipPreferredGPA(internship?.preferredGPA)}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                            <SchoolOutlined color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("College Year")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipPreferredCollegeYear(internship?.preferredCollegeYear)}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                            <SettingsSuggestOutlined color='alternative1' fontSize='small' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Skills")} :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.preferredSkills?.map((skill, index) => <span key={skill}>{index !== 0 && ','}  {skill}</span>)}</Typography>
                        </Grid>
                    </Grid>

                    <Box sx={{ width: 500, mt: 5 }}>
                        <ApplyToInternship internship={internship} />
                    </Box>

                </Grid>

                {upMd && (
                    <Grid item xs={12} lg={4} xl={3}>
                        <Card width={'100%'} sx={{ borderRadius: 3, height: '100%' }}>
                            <img src={company?.companyInformation?.backgroundImageUrl ?? DefaultBackground} alt="company-background" style={{ width: '100%', height: 120, objectFit: 'cover', borderTopLeftRadius: 23, borderTopRightRadius: 23 }} />
                            <img src={company?.companyInformation?.logoImageUrl ?? userImage} alt="company-logo" style={{ width: 100, height: 100, objectFit: 'cover', position: 'absolute', top: 50, left: '5%', borderRadius: 50, border: '5px solid #ffff' }} />

                            <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 5, ml: 3 }}>{company?.companyInformation?.companyName}</Typography>
                            <Typography variant="h7" sx={{ ml: 3 }}>{company?.companyInformation?.city?.name}, {company?.companyInformation?.country?.name}</Typography>

                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 3, mt: 1 }}>
                                <FactoryOutlined color='alternative1' fontSize='small' />
                                <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.company?.industry?.name}</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 3, mt: 1 }}>
                                <Explore color='alternative1' fontSize='small' />

                                <Typography variant="h8" sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                                    onClick={() => window.open(company?.companyInformation?.companyWebsite?.includes('http') ?
                                        company?.companyInformation?.companyWebsite :
                                        `https://${company?.companyInformation?.companyWebsite}`, "_blank")}>
                                    {company?.companyInformation?.companyWebsite}</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 3, mt: 1 }}>
                                <People color='alternative1' fontSize='small' />
                                <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.company?.numberOfEmployee?.name} Employees</Typography>
                            </Grid>

                            <Typography variant="h6" sx={{ mt: 2, ml: 3 }}>{t("About Company")} </Typography>
                            <Typography variant="body2" sx={{ mt: 1, ml: 3, mr: 3 }}>{company?.companyInformation?.description}</Typography>

                            <Box
                                sx={{
                                    display: 'flex', position: 'absolute', bottom: 0, boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                                    flexDirection: 'column', alignItems: 'center', py: 2, width: '100%', cursor: 'pointer'
                                }}
                                onClick={() => navigate(`/company/${internship?.company?._id}`)}
                            >
                                <Typography variant="h7" color={"#555"} textAlign={'center'} >{t("View More")}</Typography>
                            </Box>
                        </Card>
                    </Grid>
                )
                }


                <Card>
                    <Box
                        sx={{
                            display: 'flex', position: 'absolute', bottom: 0, boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                            flexDirection: 'column', alignItems: 'center', py: 2, width: '100%', cursor: 'pointer'
                        }}
                        onClick={() => navigate(`/company/${internship?.company?._id}`)}
                    >
                        <Typography variant="h7" color={"#555"} textAlign={'center'} >View More</Typography>
                    </Box>
                </Card>

            </Grid >

        </DashboardLayout >
    )
}


export default InternshipDetail