import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    confirmRegistrationByUserData: null,
    confirmRegistrationByUserLoading: false,
    confirmRegistrationByUserError: null,
};


export const confirmRegistrationByUserFunc = createAsyncThunk("confirmRegistrationByUser/confirmRegistrationByUserFunc", async (values) => {
    let data1 = JSON.stringify({
        "token": values.token,
        "code": values.code,
        "password": values.password,
        "resetPasswordToken": values.resetPasswordToken
    });


    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/confirmRegistrationByUser', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const confirmRegistrationByUser = createSlice({
    name: "confirmRegistrationByUser",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // confirmRegistrationByUser
        builder.addCase(confirmRegistrationByUserFunc.pending, (state) => {
            state.confirmRegistrationByUserLoading = true;
        }
        );
        builder.addCase(confirmRegistrationByUserFunc.fulfilled, (state, action) => {
            state.confirmRegistrationByUserLoading = false;
            state.confirmRegistrationByUserData = action.payload;
            state.confirmRegistrationByUserError = action.payload.message;
        }
        );
        builder.addCase(confirmRegistrationByUserFunc.rejected, (state, action) => {
            state.confirmRegistrationByUserLoading = false;
            state.confirmRegistrationByUserError = action.error.message;
        }
        );
    }
});


export default confirmRegistrationByUser.reducer;