import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_updateExperienceFunc = async ({ values, id, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_updateExperience($experienceId: ID!, $updateExperienceInput: UpdateExperienceInput!) {
            intern_updateExperience(experienceId: $experienceId, updateExperienceInput: $updateExperienceInput) {
              _id
            }
          }
        `,
        variables: {
            "experienceId": id,
            "updateExperienceInput": {
                "companyName": values?.companyName,
                "positionDetail": values?.positionDetail,

                "city": {
                    "_id": values?.city
                },
                "country": {
                    "_id": values?.country
                },
                "position": {
                    "_id": values?.position
                },
                "endDate": values?.endDate ?? null,
                "startDate": values?.startDate
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }

        return res?.data?.data?.intern_updateExperience;

    } catch (err) {
        return err
    }
}

