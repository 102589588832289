import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useResponsive } from 'minimal/hooks/use-responsive';
import { bgBlur } from 'minimal/theme/css';
import Iconify from 'minimal/components/iconify';
import { useSettingsContext } from 'minimal/components/settings';
import { setNavigationMini } from 'context/common/theme';



export default function NavToggleButton({ sx, ...other }) {

  const dispatch = useDispatch();
  const { isNavigationMini } = useSelector((state) => state.theme);
  const theme = useTheme();
  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');


  if (!lgUp) {
    return null;
  }

  return (
    <IconButton
      size="small"
      onClick={() =>
        dispatch(setNavigationMini(!isNavigationMini))
      }
      sx={{
        p: 0.35,
        top: 38,
        position: 'fixed',
        left: 264,
        zIndex: theme.zIndex.appBar + 1,
        border: `dashed 1px ${theme.palette.divider}`,
        ...bgBlur({ opacity: 0.58, color: theme.palette.background.default }),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={22}
        icon={
          isNavigationMini === false
            ? 'icon-park:left'
            : 'icon-park:right'
        }
      />
    </IconButton >
  );
}

NavToggleButton.propTypes = {
  sx: PropTypes.object,
};
