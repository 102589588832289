import React, { useEffect, useState } from 'react'
import { DashboardLayout } from 'layouts'
import { useDispatch, useSelector } from 'react-redux'
import { user_parametersFunc, self_internFunc, intern_updateIntern_aboutFunc } from 'context'
import { Grid, Box, Button, Typography, Dialog, Divider, useMediaQuery, DialogContent } from '@mui/material'
import {
    ResumeHeader, ResumeAbout, ResumeExperiences, ResumeEducations, ResumeLanguages,
    ResumeSkills, ResumeCertificates
} from 'page-sections'
import { AnimatedTitle } from 'components'
import { useSnackbar } from 'notistack'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import userImage from 'assets/images/common/user.png';
import { EditOutlined, Print, PrintOutlined } from '@mui/icons-material'
import { fillRateCalculator } from 'utils'
import moment from 'moment'
import { t } from 'i18next'

const info = [
    "Faz 2 bitti.",
]

const ResumePDF = ({ user }) => {

    const upXl = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const [openPreviewCV, setOpenPreviewCV] = useState(false);

    const downloadPDF = () => {
        const input = document.getElementById('dashboard-layout');
        const pdfWidth = 210;
        const pdfHeight = 297;


        html2canvas(input, { useCORS: true, windowWidth: 2480, windowHeight: 3508 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait',

                });
                pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.save("download.pdf");
            });
    }


    const Documentz = () => {

        return <div id="dashboard-layout"
            style={{
                backgroundImage: `url(${require('assets/images/background.png')})`,
                fontFamily: "Georgia",
                display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                width: '794px', height: '1123px',
                backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right'
            }}>

            <div>
                <div style={{
                    marginTop: 40, marginBottom: 40, display: 'flex', alignItems: 'center', gap: "20px",
                }}>
                    <img src={user.personalInformation?.profileImageUrl ?? userImage} alt="user" style={{ width: '100px', marginLeft: 40, height: '100px', borderRadius: '50%' }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography fontFamily="Georgia" variant='h3' mb={1} > {user.personalInformation?.name} {user.personalInformation?.surname}</Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                            <Typography fontFamily="Georgia" variant='h7' > {user.personalInformation?.city?.name}, {user.personalInformation?.country?.name}</Typography>
                            <Typography fontFamily="Georgia" variant='h7' > {user.contact?.phoneNumber}</Typography>
                            <Typography fontFamily="Georgia" variant='h7' > {user.contact?.email}</Typography>
                        </div>
                    </div>
                </div>
                {user?.personalInformation?.description && <div style={{ marginTop: "15px", paddingLeft: 40, paddingRight: 40 }} >
                    <Typography fontFamily="Georgia" fontSize={'medium'} fontWeight={'bold'} >PERSONAL PROFILE</Typography>
                    <Divider />
                    <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} >{user.personalInformation?.description}</Typography>
                </div>}
                {(user?.cv?.education?.highSchoolEducations?.length > 0 || user?.cv?.education?.universityEducations?.length > 0) &&
                    <div style={{ marginTop: "25px", paddingLeft: 40, paddingRight: 40 }} >
                        <Typography fontFamily="Georgia" fontSize={'medium'} fontWeight={'bold'} >EDUCATIONS</Typography>
                        <Divider />
                        {user?.cv?.education?.highSchoolEducations?.map((item, index) => {
                            return <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} width={"80px"} >{moment(item.startDate).format('YYYY')} - {moment(item.finishDate).format('YYYY')}</Typography>
                                <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} fontWeight={'bold'} mt={1} mr={"3px"} >{item.name}, </Typography>
                                <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} > {item.department?.name}</Typography>
                            </div>
                        })}
                        {user?.cv?.education?.universityEducations?.map((item, index) => {
                            return <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} width={"80px"}>{moment(item.startDate).format('YYYY')} - {moment(item.finishDate).format('YYYY')}</Typography>
                                <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} fontWeight={'bold'} mt={1} mr={"3px"}>{item.university?.name}, </Typography>
                                <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} > {item.department?.name}</Typography>
                            </div>
                        })}</div>
                }
                {user?.cv?.foreignLanguages?.length > 0 && <div style={{ marginTop: "25px", paddingLeft: 40, paddingRight: 40 }} >
                    <Typography fontFamily="Georgia" fontSize={'medium'} fontWeight={'bold'}  >LANGUAGES</Typography>
                    <Divider />
                    {user?.cv?.foreignLanguages?.map((item, index) => {
                        return <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} mr={1}> ○</Typography>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} fontWeight={'bold'} mt={1} mr={'3px'} >{item.language?.name}, </Typography>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} > {item.level?.name}</Typography>
                        </div>
                    })}
                </div>
                }
                {user?.cv?.experiences?.length > 0 && <div style={{ marginTop: "25px", paddingLeft: 40, paddingRight: 40 }} >
                    <Typography fontFamily="Georgia" fontSize={'medium'} fontWeight={'bold'} >EXPERIENCES</Typography>
                    <Divider />
                    {user?.cv?.experiences?.map((item, index) => {
                        return <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} width={"80px"} >{moment(item.startDate).format('YYYY')} - {moment(item.endDate).format('YYYY')}</Typography>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} fontWeight={'bold'} mt={1} mr={"3px"} >{item.companyName}, </Typography>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} fontWeight={'bold'} mt={1} mr={"5px"}> {item.position?.name}</Typography>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} > {item.positionDetail}</Typography>
                        </div>
                    })}
                </div>
                }
                {user?.cv?.skills?.length > 0 && <div style={{ marginTop: "25px", paddingLeft: 40, paddingRight: 40 }} >
                    <Typography fontFamily="Georgia" fontSize={'medium'} fontWeight={'bold'} >SKILLS</Typography>
                    <Divider />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {user?.cv?.skills?.map((item, index) => {
                            return <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} mr={'3px'} >{item.name}{user?.cv?.skills?.length > 1 && ', '} </Typography>
                            </div>
                        })}
                    </div>
                </div>}
                {user?.cv?.certificates?.length > 0 && <div style={{ marginTop: "25px", paddingLeft: 40, paddingRight: 40 }} >
                    <Typography fontFamily="Georgia" fontSize={'medium'} fontWeight={'bold'} >CERTIFICATES</Typography>
                    <Divider />
                    {user?.cv?.certificates?.map((item, index) => {
                        return <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} width={"80px"} >{moment(item.issueDate).format('YYYY')} - {moment(item.expirationDate).format('YYYY')}</Typography>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} fontWeight={'bold'} mt={1} mr={"3px"} >{item.name}, </Typography>
                            <Typography fontFamily="Georgia" variant='body2' fontSize={'small'} mt={1} > {item.organization}</Typography>
                        </div>
                    })}
                </div>
                }
            </div>
            <div style={{ marginTop: "30px", marginBottom: "30px", paddingLeft: 40, paddingRight: 40 }} >
                <img src={require('assets/logos/InternIFLogo.png')} alt="signature" style={{ height: "40px" }} />
            </div>
        </div >

    }


    return <>
        {upXl && <Box flexDirection={"row"} display={"flex"} alignItems={"center"} px={3} py={2}
            onClick={() => setOpenPreviewCV(true)}
            justifyContent={"space-between"}
            sx={{ cursor: 'pointer' }}
        >
            <Typography sx={{ fontWeight: 600, color: 'gray' }}>{t('Print CV')} </Typography>
            <PrintOutlined sx={{ color: 'gray' }} fontSize={"small"} />
        </Box>}
        {
            !upXl && <Button variant="outlined" color="primary" sx={{ position: 'absolute', bottom: 20, right: 5, px: 4 }} onClick={() => setOpenPreviewCV(true)}>
                {t('Print CV')}
            </Button>
        }


        <Dialog open={openPreviewCV} onClose={() => { setOpenPreviewCV(false) }} sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    overflowX: "hidden",
                    maxWidth: "830px",  // Set your width here
                },
            },
        }} >
            <Button variant="contained" color="primary" sx={{ m: 1, width: { xs: '30%', sm: '15%' }, position: 'absolute', right: 10 }} onClick={() => downloadPDF()}>
                Download PDF
            </Button>
            <DialogContent sx={{ width: '100%', overflowX: { xs: 'auto', md: 'hidden' } }}>
                <Documentz />
            </DialogContent>
        </Dialog>
    </>

}

const MyResume = () => {

    const dispatch = useDispatch()
    const upXl = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const { enqueueSnackbar } = useSnackbar()
    const { accessToken, userRole } = useSelector(state => state.user);
    const { self_internData: user } = useSelector(state => state.self_intern);
    const [personalProfile, setPersonalProfile] = useState(user?.personalInformation?.description);
    const [openAbout, setOpenAbout] = useState(false);

    const handleSavePersonalProfile = () => {
        intern_updateIntern_aboutFunc({ accessToken, description: personalProfile }).then((res) => {
            if (res?._id) {
                dispatch(self_internFunc({ accessToken }))
                setOpenAbout(false)
                enqueueSnackbar('The personal information has been updated!', { variant: 'success' })
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
            }
        })
    };

    useEffect(() => {
        dispatch(user_parametersFunc({ accessToken }))
    }, [dispatch, accessToken])



    return <DashboardLayout info={info}>
        <Grid container sx={{ width: '100%' }}>
            <Box ml={{ xs: 0, sm: 5 }} mt={{ xs: 0, sm: 5 }} sx={{ width: '100%', position: 'relative' }}>
                <AnimatedTitle title={t("My Resume")} />
                {!upXl && <ResumePDF user={user} />}
            </Box>
            <Grid xs={12} xl={9}>
                <ResumeHeader user={user} />
                <ResumeAbout
                    open={openAbout}
                    setOpen={setOpenAbout}
                    user={user}
                    handleSave={handleSavePersonalProfile}
                    setPersonalProfile={setPersonalProfile}
                    personalProfile={personalProfile}
                />

                <ResumeEducations
                    user={user}
                    userRole={userRole}
                />


                <ResumeLanguages
                    user={user}
                />

                <ResumeExperiences
                    user={user}
                />

                <ResumeSkills
                    user={user}
                />

                <ResumeCertificates
                    user={user}
                />
            </Grid>
            {upXl && <Grid item xs={12} xl={3} >
                <Box flexDirection={"row"} display={"flex"} alignItems={"center"} px={3} py={2} justifyContent={"space-between"}>
                    <Typography sx={{ fontWeight: 600, color: 'gray' }}>{t('Resume fill rate')} : </Typography>
                    <Box flexDirection={"row"} display={"flex"} alignItems={"center"} gap={1}>
                        <Typography sx={{ fontWeight: 600, color: 'gray' }}>{fillRateCalculator(user)}%</Typography>
                        <Box sx={{ height: '30px', width: '5px', backgroundColor: 'lightgray', borderRadius: '5px', display: 'flex', flexDirection: 'column-reverse' }}>
                            <Box sx={{ height: `${fillRateCalculator(user)}%`, width: '100%', backgroundColor: 'orange', borderRadius: '5px' }} />
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ mx: 3 }} />
                <ResumePDF user={user} />
                <Divider sx={{ mx: 3 }} />
                <Box flexDirection={"row"} display={"flex"} alignItems={"center"} px={3} py={2} justifyContent={"space-between"}
                    sx={{ cursor: 'pointer' }}
                >
                    <Typography sx={{ fontWeight: 600, color: 'gray' }}>{t('Edit Account')}</Typography>
                    <EditOutlined sx={{ color: 'gray' }} fontSize={"small"} />
                </Box>
            </Grid>
            }

        </Grid>

    </DashboardLayout >
}

export default MyResume