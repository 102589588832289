import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    googleLoginData: null,
    googleLoginLoading: false,
    googleLoginError: null,
};


export const googleLoginFunc = createAsyncThunk("googleLogin/googleLoginFunc", async (values) => {

    let data1 = JSON.stringify({
        "token": values.token,
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/oauth/google/login',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data1
    };

    const response = axios.request(config)
        .then((res) => {

            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const googleLogin = createSlice({
    name: "googleLogin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(googleLoginFunc.pending, (state) => {
            state.googleLoginLoading = true;
        });
        builder.addCase(googleLoginFunc.fulfilled, (state, action) => {
            state.googleLoginLoading = false;
            state.googleLoginData = action.payload;
            console.log(action.payload, "action.payload login")
            state.googleLoginError = action.payload.message;
        });
        builder.addCase(googleLoginFunc.rejected, (state, action) => {
            state.googleLoginLoading = false;
            state.googleLoginError = action.error.message;
        });
    }
});


export default googleLogin.reducer;