import { Route } from 'react-router-dom'

import Signin from 'pages/auth/Signin';
import Signup from 'pages/auth/Signup';
import CheckYourMail from 'pages/auth/CheckYourMail';
import Confirmation from 'pages/auth/Confirmation';
import ForgotPassword from 'pages/auth/ForgotPassword';
import CheckYourMailForgot from 'pages/auth/CheckYourMailForgot';
import ResetPassword from 'pages/auth/ResetPassword';
import TwoFactorSignin from 'pages/auth/TwoFactorSignin';
import CreateNewPassword from 'pages/auth/CreateNewPassword';

export const InternRouter = () => {
    return <>
        <Route path="/" element={<Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/check-your-email" element={<CheckYourMail />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/check-your-email-forgot" element={<CheckYourMailForgot />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/2fa-signin" element={<TwoFactorSignin />} />
        <Route path="*" element={<Signin />} />
    </>
}

export default InternRouter;