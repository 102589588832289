import React, { useEffect, useMemo, useState } from 'react'
import { MoreHorizRounded } from '@mui/icons-material';
import * as Yup from 'yup'
import {
    Box, Grid, IconButton, Typography, TextField, Dialog, DialogTitle,
    DialogContent, DialogActions, Slide, Button, Card
} from '@mui/material'

import { ExampleProfileImage } from 'assets';
import { Dropdown, NoItemsPlaceHolder } from 'components';
import { useFormik } from 'formik';




export const TeamMemberListSection = ({ memberList, addTeamMemberDrawer, setAddTeamMemberDrawer, handleAddTeamMember }) => {

    return <Box>

        <Grid container spacing={1}>
            {
                memberList?.length > 0 ?
                    (
                        memberList.map((item) => {

                            return (
                                <Grid item xs={12} sm={4} lg={3} key={item.id}>
                                    <TeamListItem item={item} />
                                </Grid>
                            )
                        })
                    ) : (
                        <NoItemsPlaceHolder
                            text={`There is no ${"tabValue" === 0 ? "" : ""} team members!`}
                        />
                    )
            }
        </Grid>

        <TeamMemberDialog
            open={addTeamMemberDrawer}
            onClose={() => setAddTeamMemberDrawer(false)}
            onSubmit={handleAddTeamMember}
        />

    </Box>
}


const TeamListItem = ({ item }) => {

    return (
        <Card
            sx={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #E5EAED",
                p: { xs: '16px', sm: '16px' },

                display: "flex",
                flexDirection: { sm: "column" },
                alignItems: { sm: "center" },
                gap: { xs: '10px', sm: '16px' },
                cursor: "pointer",

                position: "relative",
                transitionDuration: '0.5s',

                '&:hover': {
                    border: '1px solid #fff',
                    boxShadow: '2px 2px 20px 0px #ddd'
                }
            }}
        >


            <IconButton
                sx={{
                    position: "absolute",
                    top: { xs: "0px", sm: "10px" },
                    right: { xs: "0px", sm: "10px" },
                }}
            >
                <MoreHorizRounded />
            </IconButton>

            <Box
                sx={{
                    width: { xs: '30px', sm: '72px' },
                    height: { xs: '30px', sm: '72px' },
                    border: '1px solid #fff',
                    borderRadius: '50%',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    position: 'relative',
                    bottom: '-2px',
                    overflow: 'hidden',
                    flex: { xs: '0 0 30px', sm: '0 0 72px' },
                    background: `url(${item?.personalInformation?.profileImageUrl ?? ExampleProfileImage})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            />


            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { xs: '2px', sm: '5px' },
                    alignItems: { sm: 'center' },
                }}
            >

                <Typography
                    sx={{

                        fontFamily: 'Causten-SemiBold',
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#0E1436',
                        textTransform: 'capitalize',
                    }}
                >
                    {item?.personalInformation?.name + ' ' + item?.personalInformation?.surname}
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Causten-Regular',
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: '#7D8898',
                    }}
                >
                    {item?.personalInformation?.country?.name}
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Causten-Regular',
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: '#A1ABC5',
                    }}
                >
                    {item?.personalInformation?.city?.name}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { sm: 'column' },
                        alignItems: { sm: 'center' },
                        justifyContent: 'space-between',
                        gap: { xs: '2px', sm: '5px' },
                        flexWrap: 'wrap',
                    }}
                >

                    <Typography
                        sx={{

                            fontFamily: 'Causten-Regular',
                            fontSize: '12px',
                            lineHeight: '14px',
                            color: '#A1ABC5',
                        }}
                    >
                        {item?.contact?.email}
                    </Typography>

                    <IconButton
                        sx={{
                            width: '112px',
                            height: '24px',
                            borderRadius: '48px',
                            backgroundColor: 'rgba(59, 162, 235, 0.2)',
                            color: '#3BA1EB',
                            '&:focus': {
                                outline: 'none',
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Causten-Medium',
                                fontSize: '12px',
                                lineHeight: '16px',
                            }}
                        >
                            {item?.role === "companyManager" ? "Manager" : "Specialist"}
                        </Typography>
                    </IconButton>

                </Box>
            </Box>

        </Card>
    )
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TeamMemberDialog = ({ open, onClose, onSubmit, selectedMember }) => {

    const userValidationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required").min(2, "Name must be at least 2 characters"),
        surname: Yup.string().required("Surname is required").min(2, "Surname must be at least 2 characters"),
        email: Yup.string().required("Email is required"),
        role: Yup.string().required("Role is required"),
    });

    const userInitialValues = useMemo(() => ({
        name: selectedMember?.name ?? "",
        surname: selectedMember?.surname ?? "",
        email: selectedMember?.email ?? "",
        role: selectedMember?.role ?? "",
    }), [selectedMember]);


    const { values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        validationSchema: userValidationSchema,
        onSubmit: (values) => {
            onSubmit({ values })
        },
    });


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"

        >
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    Add new team member.
                </DialogTitle>

                <DialogContent>

                    <Grid container spacing={3} sx={{ pt: 3 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="name"
                                label="Name"
                                value={values?.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                // sx={{ mt: "24px" }}
                                name="surname"
                                label="Surname"
                                value={values?.surname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.surname && errors.surname)}
                                helperText={touched.surname && errors.surname}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                // sx={{ mt: "24px" }}
                                name="email"
                                label="Email"
                                value={values?.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Dropdown
                                setFieldValue={setFieldValue}
                                errors={errors}
                                data={[
                                    {
                                        value: "companyManager",
                                        name: "Company Manager",
                                    },
                                    {
                                        value: "companySpecialist",
                                        name: "Company Specialist",
                                    },
                                ]}
                                label="Role"
                                labelId="role-label"
                                value={values.role}
                                id="role"
                                type="withoutId"
                            />
                        </Grid>
                    </Grid>


                </DialogContent>

                <DialogActions>
                    <Button
                        variant='outlined'
                        onClick={onClose}
                    >
                        Close
                    </Button>

                    <Button
                        variant='contained'
                        // onClick={onAccept}
                        type='submit'
                    >
                        Add Member
                    </Button>
                </DialogActions>
            </form>

        </Dialog>
    )
}
