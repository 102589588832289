import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    requestResetPasswordData: null,
    requestResetPasswordLoading: false,
    requestResetPasswordError: null,
};


export const requestResetPasswordFunc = createAsyncThunk("requestResetPassword/requestResetPasswordFunc", async (values) => {
    let data1 = JSON.stringify({
        "email": values.email
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/requestresetpassword', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});



const requestResetPassword = createSlice({
    name: "requestResetPassword",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(requestResetPasswordFunc.pending, (state) => {
            state.requestResetPasswordLoading = true;
        });
        builder.addCase(requestResetPasswordFunc.fulfilled, (state, action) => {
            state.requestResetPasswordLoading = false;
            state.requestResetPasswordData = action.payload;
            state.requestResetPasswordError = action.payload.message;
        });
        builder.addCase(requestResetPasswordFunc.rejected, (state, action) => {
            state.requestResetPasswordLoading = false;
            state.requestResetPasswordError = action.error.message;
        });
    }
});


export default requestResetPassword.reducer;