import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_updateCertificateFunc =async ({ values, id, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_updateCertificate($certificateId: ID!, $updateCertificateInput: UpdateCertificateInput!) {
            intern_updateCertificate(certificateId: $certificateId, updateCertificateInput: $updateCertificateInput) {
              _id
            }
          }
        `,
        variables: {
            "certificateId": id,
            "updateCertificateInput": {
                "name": values?.name,
                "organization": values?.organization,
                "issueDate": values?.issueDate,
                "expirationDate": values?.expirationDate,
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }

        return res?.data?.data?.intern_updateCertificate;

    } catch (err) {
        return err
    }
}