import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";

const initialState = {
    hIntern_productsData: null,
    hIntern_productsLoading: false,
    hIntern_productsError: null,
};

export const hIntern_productsFunc = createAsyncThunk(
    "hIntern_products/hIntern_productsFunc",
    async (values) => {
        const data1 = JSON.stringify({
            query: `query HIntern_products($filter: FilterProductInput) {
                hIntern_products(filter: $filter) {
                  count
                  data {
                    _id
                    amount
                    name
                    unitPrice
                    listUnitPrice
                    price
                  }
                }
              }
              `,
            variables: {
                "filter": {
                    "type": values?.type ?? null,
                    "name": values?.name ?? null,
                }
            }
        });

        const config = initialConfig(process.env.REACT_APP_BASE_URL + "/payment/graphql", data1, values?.accessToken);

        const response = axios(config)
            .then((res) => {
                const data = res.data.data;
                return data;
            })
            .catch((err) => {
                console.log("err:", err);
                return err;
            });

        const data = await response;
        return data;
    }
);

const hIntern_products = createSlice({
    name: "hIntern_products",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(hIntern_productsFunc.pending, (state) => {
            state.hIntern_productsLoading = true;
        });
        builder.addCase(hIntern_productsFunc.fulfilled, (state, action) => {
            state.hIntern_productsLoading = false;
            state.hIntern_productsData = action.payload?.hIntern_products?.data;
            state.hIntern_productsError = action?.payload?.message;
        });
        builder.addCase(hIntern_productsFunc.rejected, (state, action) => {
            state.hIntern_productsLoading = false;
            state.hIntern_productsError = action.error.message;
        });
    },
});

export default hIntern_products.reducer;
