import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";


const initialState = {
    intern_savedInternshipsData: null,
    intern_savedInternshipsLoading: false,
    intern_savedInternshipsError: null,
};

export const intern_savedInternshipsFunc = createAsyncThunk("intern_savedInternships/intern_savedInternshipsFunc", async (values) => {

    const data1 = JSON.stringify({
        query: `
    query intern_savedInternships($pagination: PaginationWithInternshipSort, $filter: FilterInternshipInputForIntern) {
      intern_savedInternships(pagination: $pagination, filter: $filter) {
        count
        data {
          _id
          applicationType
          coffee
          company {
            _id
            city {
              name
              _id
            }
            country {
              name
              flagUrl
              code
              _id
            }
            displayImageUrl
            displayName
            industry {
              name
              _id
            }
            numberOfEmployee {
              name
              _id
            }
            # rating {
            #   updatedAt
            #   numberOfReviews
            #   createdAt
            #   average
            # }
          }
          createdAt
          internshipArea
          isSaved
          numberOfAcceptedInterns
          numberOfAppliedInterns
          positionName
          preferredLanguages
          quota
          salary
          salaryType
          startDate
          status
          timePeriodInWeeks
          updatedAt
        }
      }
    }
    `,
        variables: {
            "pagination": {
                "page": values?.variables?.pagination?.page ?? null,
                "pageSize": values?.variables?.pagination?.pageSize ?? null,
                "sort": values?.variables?.pagination?.sort ?? null
            },
            "filter": {
                "positionName": values?.variables?.filter?.positionName ?? null,

                "internshipArea": values?.variables?.filter?.internshipArea?.value ?? null,

                "applicationType": values?.variables?.filter?.applicationType ?? null,
                "timePeriodInWeeks": values?.variables?.filter?.timePeriodInWeeks ?? null,
                "coffee": values?.variables?.filter?.coffee ?? null,
                "preferredCollegeYear": values?.variables?.filter?.preferredCollegeYear ?? null,
                "preferredGPA": values?.variables?.filter?.preferredGPA ?? null,
                "salaryType": values?.variables?.filter?.salaryType ?? null,

                "countryId": values?.variables?.filter?.country?._id ?? null,
                "cityId": values?.variables?.filter?.city?._id ?? null,
                "industryId": values?.variables?.filter?.industry?._id ?? null,
                "language": values?.variables?.filter?.language?.name?.toLowerCase() ?? null,

                "startDate": values?.variables?.filter?.startDate ?? null,

                "status": values?.variables?.filter?.status ?? null,
                "companyName": values?.variables?.filter?.companyName ?? null,
                "companyId": values?.variables?.filter?.company?._id ?? null,
                "type": values?.variables?.filter?.type ?? null
            }
        }

    });

    const config = initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data1, values?.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const intern_savedInternships = createSlice({
    name: "intern_savedInternships",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(intern_savedInternshipsFunc.pending, (state) => {
            state.intern_savedInternshipsLoading = true;
        });
        builder.addCase(intern_savedInternshipsFunc.fulfilled, (state, action) => {
            // console.log(action.payload)
            state.intern_savedInternshipsLoading = false;
            state.intern_savedInternshipsData = action.payload?.intern_savedInternships;
            state.onboardedCompany = action.payload?.intern_savedInternships?.data;
            state.intern_savedInternshipsError = action?.payload?.message;
        });
        builder.addCase(intern_savedInternshipsFunc.rejected, (state, action) => {
            state.intern_savedInternshipsLoading = false;
            state.intern_savedInternshipsError = action.error.message;
        });
    }
});


export default intern_savedInternships.reducer;