
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box } from "@mui/material";
import { DashboardLayout } from "layouts";
import { anonymous_citiesFunc, anonymous_countriesFunc, self_internFunc, self_updateUserFunc } from "context";
import { imageCompress, imageUpload } from "utils/helpers";
import { BackgroundImageAccount, ProfileImage, CardHeaderAccount, MyAccountForm } from "page-sections";
import { SettingsContainer, AnimatedTitle } from "components";
import { t } from "i18next";

const info = [
    "Faz 2 bitti."
]

const userValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").min(2, "Name must be at least 2 characters"),
    surname: Yup.string().required("Surname is required").min(2, "Surname must be at least 2 characters"),
    gender: Yup.string().required("Gender is required"),
    address: Yup.string(),
    birthDate: Yup.date().required("BirthDate is required"),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required"),
    nationality: Yup.string().required("Nationality is required"),
    phone: Yup.string().nullable().min(6, 'Phone Number must be at least 6 characters').max(20, 'Phone Number must be at most 20 characters'),
    profileImageUrl: Yup.string()
});

const MyAccount = () => {

    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.user);
    const { self_internData: user } = useSelector(state => state.self_intern);
    const { anonymous_countriesData, anonymous_citiesData } = useSelector((state) => state.parameters);
    const [compressedBackground, setCompressedBackground] = useState(null);
    const [compressedProfileImage, setCompressedProfileImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const userInitialValues = useMemo(() => ({
        name: user?.personalInformation?.name ?? "",
        surname: user?.personalInformation?.surname ?? "",
        gender: user?.personalInformation?.gender ?? "",
        phoneNumber: user?.personalInformation?.phoneNumber ?? "",
        birthDate: user?.personalInformation?.birthDate ? new Date(user.personalInformation?.birthDate) : new Date(),
        nationality: user?.personalInformation?.nationality?._id ?? "",
        country: user?.personalInformation?.country?._id ?? "",
        city: user?.personalInformation?.city?._id ?? '',
        profileImageUrl: user?.personalInformation?.profileImageUrl ?? "",
        backgroundImageUrl: user?.personalInformation?.backgroundImageUrl ?? "",
    }), [user]);
 
    const handleBackgroundChange = (e) => {
        const file = e.target.files[0];
        imageCompress({ file, enqueueSnackbar, setCompressedImage: setCompressedBackground, ratio: 0.4, maxWidth: 1000 });
    };

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        imageCompress({ file, enqueueSnackbar, setCompressedImage: setCompressedProfileImage, ratio: 0.4, maxWidth: 400 });
    }

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        validationSchema: userValidationSchema,
        onSubmit: (values) => {
            handleUpdateUserInfo({ values });
        },
    });

    const handleUpdateUserInfo = async ({ values }) => {
        try {
            setLoading(true);
            const resBackgroundImage = compressedBackground && await imageUpload({
                presignedType: 'backgroundImage',
                accessToken: accessToken,
                image: compressedBackground?.image,
                imageType: compressedBackground?.type
            });
            const resProfileImage = compressedProfileImage && await imageUpload({
                presignedType: 'profileImage',
                accessToken: accessToken,
                image: compressedProfileImage?.image,
                imageType: compressedProfileImage?.type
            });
            const updateInternUserRes = await self_updateUserFunc({
                values: values, profileImage: resProfileImage ?? values?.profileImageUrl, backgroundImage: resBackgroundImage ?? values?.backgroundImageUrl,
                accessToken: accessToken
            });
            if (!(updateInternUserRes._id)) {
                throw new Error(updateInternUserRes ?? "User could not updated!");
            } else if (updateInternUserRes._id) {
                enqueueSnackbar("The personal account has been updated!", { variant: "success" });
                dispatch(self_internFunc({ accessToken }));
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        accessToken && dispatch(self_internFunc({ accessToken }));
        accessToken && dispatch(anonymous_countriesFunc({ accessToken }));
    }, [accessToken, dispatch]);

    useEffect(() => {
        if (values.country) {
            accessToken && dispatch(anonymous_citiesFunc({ accessToken, countryId: values?.country }));
        }
    }, [accessToken, dispatch, values?.country]);

    return <DashboardLayout info={info}>
        <Grid container sx={{ width: '100%', }}>
            <Box ml={{ xs: 0, sm: 5 }} mt={{ xs: 0, sm: 5 }}>
                <AnimatedTitle title={t("My Account")} />
            </Box>
            <SettingsContainer>
                <BackgroundImageAccount
                    values={values}
                    handleBackgroundChange={handleBackgroundChange}
                    compressedBackground={compressedBackground}
                />
                <ProfileImage
                    values={values}
                    handleProfileImageChange={handleProfileImageChange}
                    compressedProfileImage={compressedProfileImage}
                />
                <CardHeaderAccount />
                <MyAccountForm
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    loading={loading}
                    anonymous_countriesData={anonymous_countriesData}
                    anonymous_citiesData={anonymous_citiesData}
                />
            </SettingsContainer>
        </Grid>
    </DashboardLayout>
}

export default MyAccount