import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_removeUniversityEducationFunc = async({ id, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_removeUniversityEducation($universityEducationId: ID!) {
            intern_removeUniversityEducation(universityEducationId: $universityEducationId) {
              success
            }
          }
        `,
        variables: {
            "universityEducationId": id
        }
    })

    try {
      const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
      if (res.data?.errors?.length > 0) {
          return {
              errorMessage: res.data?.errors[0].message,
          };
      }
      // console.log(res?.data)
      return res?.data?.data?.intern_removeUniversityEducation;

  } catch (err) {
      return err
  }
}