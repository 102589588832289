import moment from 'moment';
import Calendar from 'react-calendar';
import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';

import { NoItemsPlaceHolder, RecentActivityCard } from 'components';

import 'react-calendar/dist/Calendar.css';
import './react-calendar.scss'



export const SideCalendarInfoSection = ({ tasks, recentActivitiesData, filterToDate = () => null }) => {


    const [value, onChange] = useState(new Date());
    useEffect(() => {
        filterToDate(value)
    }, [filterToDate, value]);


    return (
        <Box
            sx={{
                p: 1,
                gap: 1,
                height: "100%",
                overflow: "auto",
                borderRadius: "8px",
                alignItems: 'center',
                display: { xs: 'flex', md: 'flex', xl: 'flex' },
                flexDirection: { xs: 'column', sm: 'row', xl: 'column' },
                backgroundColor: { xs: "#FFFFFF", md: 'transparent', xl: "#FFFFFF" },
                boxShadow: {
                    xs: "0px 3px 16px rgba(22, 53, 67, 0.06)",
                    md: 'none',
                    xl: "0px 3px 16px rgba(22, 53, 67, 0.06)"
                },
            }}
        >

            {/* ## ######################  calendar ##################### */}
            <Box
                sx={{
                    backgroundColor: { xs: "transparent", md: '#FFFFFF', lg: "transparent" },
                    boxShadow: {
                        md: "0px 3px 16px rgba(22, 53, 67, 0.06)",
                        xl: 'none',
                    },
                    borderRadius: "8px",
                    width: { xs: '260px', md: "300px", xl: "100%", },
                    height: '100%',
                    overflow: "hidden",
                    float: { md: 'left', lg: 'none' },
                }}
            >

                <Box
                    sx={{
                        mx: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: 'red',
                        px: { md: '10px', lg: '0px' },
                        width: '100%',
                    }}
                >
                    <Calendar
                        onChange={onChange}
                        value={value}
                        navigationLabel={({ date, label, locale, view }) => {
                            return (
                                ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Agu", "Sep", "Oct", "Nov", "Dec"][date.getMonth()]
                                + " " +
                                date.getFullYear()
                            )
                        }}
                        formatMonth={(locale, date) => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Agu", "Sep", "Oct", "Nov", "Dec"][date.getMonth()]}
                        formatShortWeekday={(locale, date) => ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fri', 'Sa'][date.getDay()]}

                        tileClassName={({ date, view }) => {

                            if (tasks?.find(task => moment(task?.dueDate).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY"))) {
                                // console.log(tasks?.find(task => moment(task?.dueDate).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY"))?.priority)
                                const dateList = tasks?.filter(task => moment(task?.dueDate).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY"))

                                if (dateList?.length === 1) {
                                    return dateList[0]?.priority?.toLowerCase()
                                } else if (dateList?.length > 1) {
                                    return "multipleTaskInADate"
                                }
                            }
                        }}
                    />
                </Box>

            </Box>

            {/* ## ######################  recent activities ##################### */}
            <Box
                sx={{
                    backgroundColor: { xs: "transparent", md: '#FFFFFF', xl: "transparent" },
                    boxShadow: {
                        md: "0px 3px 16px rgba(22, 53, 67, 0.06)",
                        xl: 'none',
                    },
                    borderRadius: "8px",
                    height: { xl: "100%", },
                    p: "12px",
                    flex: 1,
                }}
            >

                <Typography
                    sx={{
                        fontFamily: 'Causten-Regular',
                        fontSize: '20px',
                        lineHeight: '32px',
                        // color: '#000',
                        mb: '10px',
                    }}
                >
                    Recent Activities
                </Typography>

                {
                    recentActivitiesData?.length > 0 ? (
                        recentActivitiesData.map((item, index) => {

                            return (
                                <RecentActivityCard
                                    isSideInfo
                                    index={index}
                                    key={item?._id ?? index}
                                    item={item}
                                    len={recentActivitiesData?.length}
                                />
                            )
                        })
                    ) : (
                        <NoItemsPlaceHolder
                            text="There is no recent activities!"
                        />
                    )
                }

            </Box>

        </Box>
    )
}





