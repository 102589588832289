export const InternshipFollowUpUserType = {
  internIFUser: {
    value: "internIFUser",
    name: "InternIF User",
  },
  companyUser: {
    value: "companyUser",
    name: "Company User",
  },
};

export const internshipFollowUpUserTypeList = Object.values(
  InternshipFollowUpUserType
);

export const internshipFollowUpUserTypeValueList = Object.values(
  InternshipFollowUpUserType
).map((period) => period.value);

export const internshipFollowUpUserTypeNameList = Object.values(
  InternshipFollowUpUserType
).map((period) => period.name);

export function getValueOfInternshipFollowUpUserType(name) {
  for (const key of Object.keys(InternshipFollowUpUserType)) {
    if (InternshipFollowUpUserType[key].name === name) {
      return InternshipFollowUpUserType[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipFollowUpUserType(value) {
  for (const key of Object.keys(InternshipFollowUpUserType)) {
    if (InternshipFollowUpUserType[key].value === value) {
      return InternshipFollowUpUserType[key].name;
    }
  }
  return null;
}
