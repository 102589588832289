import axios from "axios";
import initialConfig from "context/initialConfig";

export const user_createOrderForOrganizationWithStripeCheckoutFormPayment = async ({ values, productId, accessToken }) => {

    const data = JSON.stringify({
        query: `mutation User_createOrderForOrganizationWithStripeCheckoutFormPayment($orderInput: CreateOrderWithCheckoutFormInput!) {
            user_createOrderForOrganizationWithStripeCheckoutFormPayment(orderInput: $orderInput) {
              order {
                _id
              }
              paymentResult {
                id
                amount_subtotal
                amount_total
                client_reference_id
                currency
                mode
                payment_status
                status
                cancel_url
                success_url
                url
                created
                expires_at
                
              }
            }
          }`,
        variables: {
            "orderInput": {
              "product": {
                "_id": productId
              }
            }
          }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        if (res.errors?.length > 0) {
            return {
                errorMessage: res?.errors[0].message,
            };
        }
        return res?.data?.data?.user_createOrderForOrganizationWithStripeCheckoutFormPayment
    } catch (err) {
        return err
    }
}