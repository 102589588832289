import React from 'react'
import { Box, Typography } from '@mui/material'
import { NoItemsPlaceHolder } from 'components'
import { Link as RouterLink } from 'react-router-dom'


export const LastMessagesSection = ({ lastMessages }) => {

    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                border: '1px solid #E5EAED',
                p: '16px',
                borderRadius: '8px',
                height: '100%',
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: { xs: '24px' },
                }}
            >

                <Typography
                    sx={{
                        fontFamily: 'Causten-Medium',
                        fontSize: '20px',
                        lineHeight: '32px',
                        color: '#000',
                    }}
                >
                    Last Messages
                </Typography>

                <Typography
                    to="/messages"
                    component={RouterLink}
                    sx={{
                        fontFamily: 'Causten-SemiBold',
                        fontSize: '12px',
                        lineHeight: '14.4px',
                        color: '#A1ABC5',
                    }}
                >
                    See All
                </Typography>

            </Box>

            <Box>

                {
                    lastMessages?.length > 0 ? (
                        lastMessages.map((item) => {

                            return (
                                <MessageItems
                                    key={item.id}
                                    item={item}
                                    len={lastMessages?.length}
                                />
                            )
                        })
                    ) : (
                        <NoItemsPlaceHolder text="There is no messages!" />
                    )
                }

            </Box>

        </Box>
    )
}


const MessageItems = ({ item, len }) => {

    const splitName = item.name.split(" ")

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',

                py: { xs: '12px' },
                borderBottom: len !== Number.parseInt(item.id) ? '1px solid rgba(125, 136, 152, 0.3)' : 'none'
            }}
        >

            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    overflow: 'hidden',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: item.color
                }}
            >
                {/* <img src={item.image} alt="" width="" height="100%" /> */}
                <Typography
                    sx={{
                        fontFamily: 'Causten-SemiBold',
                        fontSize: '16px',
                        lineHeight: '19.2px',
                        color: '#fff',
                    }}
                >
                    {splitName[0][0] + splitName[1][0]}
                </Typography>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: { xs: '5px' }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Causten-SemiBold',
                            fontSize: '14px',
                            lineHeight: '16px',
                            color: '#000',
                        }}
                    >
                        {item.name}
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Causten-Regular',
                            fontSize: '14px',
                            lineHeight: '16px',
                            color: '#A1ABC5',
                        }}
                    >
                        {item.time}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{

                            fontFamily: 'Causten-Medium',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#A1ABC5',
                            display: 'inline-block',
                        }}
                    >
                        {item.mesage.slice(0, 36) + "..."}
                    </Typography>

                    {
                        item.count > 0 && (
                            <Box
                                sx={{
                                    backgroundColor: '#FF9737',
                                    width: '16px',
                                    height: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '4px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Causten-Medium',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: '#fff',
                                    }}
                                >
                                    {item.count}
                                </Typography>
                            </Box>
                        )
                    }



                </Box>



            </Box>

        </Box>
    )
}


