export const InternshipApplicationType = {
  global: {
    value: "global",
    name: "Global",
  },
  local: {
    value: "local",
    name: "Local",
  },
};

export const internshipApplicationTypeList = Object.values(
  InternshipApplicationType
);

export const internshipApplicationTypeValueList = Object.values(
  InternshipApplicationType
).map((period) => period.value);

export const internshipApplicationTypeNameList = Object.values(
  InternshipApplicationType
).map((period) => period.name);

export function getValueOfInternshipApplicationType(name) {
  for (const key of Object.keys(InternshipApplicationType)) {
    if (InternshipApplicationType[key].name === name) {
      return InternshipApplicationType[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipApplicationType(value) {
  for (const key of Object.keys(InternshipApplicationType)) {
    if (InternshipApplicationType[key].value === value) {
      return InternshipApplicationType[key].name;
    }
  }
  return null;
}
