import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";


const initialState = {
  anonymous_policiesData: null,
  anonymous_policiesLoading: false,
  anonymous_policiesError: null,
};

export const anonymous_policiesFunc = createAsyncThunk("anonymous_policies/anonymous_policiesFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `query Anonymous_policies($filter: FilterPolicyInput) {
      anonymous_policies(filter: $filter) {
        data {
          _id
          name
          description
          domain
          content {
            tr
            en
          }
          target
          required
          targetRoles
          type
          version
          versionCount
          updatedAt
          createdAt
        }
      }
    }`,
    variables: {
      "filter": {
        "domain": values?.domain,
        "type": values?.type,
        "target": values?.target,
      }
    }

  });


  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const anonymous_policies = createSlice({
  name: "anonymous_policies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(anonymous_policiesFunc.pending, (state) => {
      state.anonymous_policiesLoading = true;
    });
    builder.addCase(anonymous_policiesFunc.fulfilled, (state, action) => {
      state.anonymous_policiesLoading = false;
      state.anonymous_policiesData = action.payload?.anonymous_policies;
      state.anonymous_policiesError = action.payload.message;
    });
    builder.addCase(anonymous_policiesFunc.rejected, (state, action) => {
      state.anonymous_policiesLoading = false;
      state.anonymous_policiesError = action.error.message;
    });
  }
});


export default anonymous_policies.reducer;