import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Stack, Button, useMediaQuery, Card, Box, Typography, Divider } from '@mui/material'
import { AnimatedTitle } from 'components'
import { DashboardLayout } from 'layouts'
import { TeamMemberListSection, TeamWidgetSection } from 'page-sections'
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { AddRounded } from "@mui/icons-material";
import { companyAdmin_companyUsersFunc } from 'context'
import parse from 'html-react-parser';
import { company_teamTips, company_teamTips_title } from "utils";


const info = [
    "Faz 2 bitti",
    "Team member üyelerini silme veya editleme business kararı",
    "Üst kısımda hangi istatistikler olacak business kararı"
]

const tipTitle = company_teamTips_title;
const tipDescription = company_teamTips;


const Team = () => {

    const dispatch = useDispatch()
    const [addTeamMemberDrawer, setAddTeamMemberDrawer] = useState(false)
    const { accessToken } = useSelector(state => state.user);
    const { companyAdmin_companyUsersData: memberList } = useSelector(state => state.companyAdmin_companyUsers);
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const handleAddTeamMember = async ({ values }) => {
        try {

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: process.env.REACT_APP_BASE_URL + '/auth/auth/companyAdmin_register',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${accessToken}`
                },
                data: JSON.stringify(values)
            };

            const response = await axios.request(config)


            if (response?.data?.isSuccess) {
                enqueueSnackbar("New team member has been added to the company!", { variant: "success" })
                setAddTeamMemberDrawer(false)
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" })
                setAddTeamMemberDrawer(false)
            }


        } catch (error) {
            console.log({ error })
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" })
        }
    }

    useEffect(() => {
        accessToken && dispatch(companyAdmin_companyUsersFunc({ accessToken }))
    }, [accessToken, dispatch])


    return (
        <DashboardLayout info={info}>
            <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
                <Grid item xs={12} >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <AnimatedTitle title="Team" />
                        <Button
                            variant='contained'
                            size={"large"}
                            onClick={() => setAddTeamMemberDrawer(true)}
                        >
                            <AddRounded sx={{ mr: 1 }} />
                            Add Member
                        </Button>
                    </Stack>

                </Grid>


                <Grid item xs={12} lg={7.6} xl={8.7} >
                    <TeamWidgetSection />

                    <TeamMemberListSection
                        memberList={memberList || []}
                        addTeamMemberDrawer={addTeamMemberDrawer}
                        setAddTeamMemberDrawer={setAddTeamMemberDrawer}
                        handleAddTeamMember={handleAddTeamMember}
                    />
                </Grid>

                {upLg && <Grid item xs={12} lg={4.4} xl={3.3}>
                    <Card sx={{
                        padding: 3, backgroundColor: "#F8F8F8", height: '100%', border: '1px solid #E0E0E0',
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                    }}>
                        <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                            <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >{parse(tipTitle)}</Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant="body1" fontSize={"small"} fontWeight={"regular"}>
                                {parse(tipDescription)}
                            </Typography>
                        </Box>

                    </Card>
                </Grid>}



            </Grid>
        </DashboardLayout>
    )
}

export default Team