import React from 'react'
import { Box, Typography } from '@mui/material'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

import { CardContainer, NoItemsPlaceHolder } from 'components';


export const DocumentsForMyInternshipSection = ({ documentsList }) => {
    return (
        <Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: { xs: '16px' }
                }}
            >
                <Typography
                    sx={{

                        fontFamily: 'Causten-Regular',
                        fontSize: '20px',
                        lineHeight: '32px',
                        color: '#0E1436',
                    }}
                >
                    Documents
                </Typography>
                <Typography
                    sx={{

                        fontFamily: 'Causten-Regular',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: 'rgba(14, 20, 54, 0.5)',
                    }}
                >
                    {`(${documentsList?.length ?? 0})`}
                </Typography>
            </Box>


            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: '10px' },
                    overflow: 'auto',
                    pb: { xs: '10px' },

                    '&::-webkit-scrollbar': {
                        height: '5px',
                    }
                }}
            >
                {
                    documentsList?.length > 0 ? (
                        documentsList.map((item) => {
                            return (<DocumentItem item={item} key={item.id} />)

                        })
                    ) : (
                        <NoItemsPlaceHolder text="There is no documents!" />
                    )
                }
            </Box>

        </Box>
    )
}


const DocumentItem = ({ item }) => {

    return (
        <CardContainer>
            <Box
                sx={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E5EAED",
                    borderRadius: "8px",
                    p: '16px',
                    minWidth: '188px',

                    // display: "flex",
                    // gap: '16px',

                    // overflow: "auto",

                    '&::-webkit-scrollbar': {
                        width: '3px',
                        height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                        // boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        // webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.1)',
                        // outline: '1px solid slategrey'
                    }

                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: { xs: '10px' },
                    }}
                >

                    <AssignmentOutlinedIcon
                        sx={{
                            backgroundColor: item.color,
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: '2px',
                            borderRadius: '5px',

                            width: '24px',
                            height: '24px',
                        }}
                    />

                    <ArrowRightAltOutlinedIcon
                        sx={{
                            transform: 'rotate(-45deg)',
                            color: '#7D8898',
                        }}
                    />
                </Box>

                <Typography
                    sx={{
                        // whiteSpace: 'nowrap',
                        fontFamily: 'Causten-Medium',
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#222B45',

                        mt: { xs: '15px' },
                        mb: { xs: '8px' },
                    }}
                >
                    {item.title}
                </Typography>

                <Typography
                    sx={{
                        // whiteSpace: 'nowrap',
                        fontFamily: 'Causten-Regular',
                        fontSize: '10px',
                        lineHeight: '16px',
                        color: '#8F9BB3',

                        mb: { xs: '45px' },
                    }}
                >
                    {item.description}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        // backgroundColor: 'red',
                    }}
                >
                    <Box
                        sx={{
                            width: '16px',
                            height: '16px',
                            borderRadius: '50%',
                            overflow: 'hidden',

                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src="https://jenmulligandesign.com/wp-content/uploads/2017/04/unsplash-free-stock-photos-download.jpg"
                            alt=""
                            width="100%" height="100%"
                        />
                    </Box>

                    <Typography
                        sx={{
                            // whiteSpace: 'nowrap',
                            fontFamily: 'Causten-Medium',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#70798D',
                        }}
                    >
                        {item.user}
                    </Typography>
                </Box>


            </Box>
        </CardContainer>
    )
}