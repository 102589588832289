import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Box, Button, Drawer, Grid, Divider, Typography, Stack, TextField, CircularProgress, SwipeableDrawer } from '@mui/material'
import { AttachFile, AttachmentOutlined, CalendarMonth, CancelOutlined, CheckCircle, CheckOutlined, Checklist, Download, Flag, InfoOutlined, LowPriority, Send } from '@mui/icons-material';
import Label from 'minimal/components/label';
import parse from 'html-react-parser';
import { preSignedUrlToDownloadPrivateFile } from 'context';

export const TaskDetailDrawer = ({ handleCloseDetail, task, internship, handleAddComment, handleSendAttachment, loadingUpload, setSelectedItemForEvaluate }) => {

    const { accessToken } = useSelector(state => state.user)
    const { self_internData: intern } = useSelector(state => state.self_intern)
    const [commentContent, setCommentContent] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);

    const handleComment = () => {
        handleAddComment(commentContent, setCommentContent)
    }

    const handleAttachment = () => {
        handleSendAttachment(selectedFile)
    }

    console.log(selectedFile, 'sssss')

    const handleFileChange = (e) => {
        // console.log(e.target.files)
        console.log('zzzz')
        const file = e.target.files[0];
        setSelectedFile(file)
        console.log(file)
    }

    const handleDownloadAttachment = (fileName) => {
        console.log(fileName)
        preSignedUrlToDownloadPrivateFile({ accessToken, fileName }).then(res => {
            window.open(res, '_blank')
        })
    }

    return <SwipeableDrawer
        anchor="right"
        open={!!task}
        onOpen={() => null}
        onClose={() => handleCloseDetail()}
    >
        <Box
            sx={{
                minWidth: { xs: '100%', md: '600px' },
                maxWidth: { xs: '100%', md: '600px' },
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                p: 3,
                pb: 10,
            }}
        >
            <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                        <Stack direction={"row"} justifyContent={"flex-start"} gap={2}>
                            <Label
                                color={task?.status === 'accepted' ? 'info' : task?.status === 'pending' ? 'info' : task?.status === 'rejected' ? 'error' : 'primary'}
                                sx={{ borderRadius: 200, minWidth: 90 }}
                            >
                                {(task?.status === 'accepted' ? 'In Progress' :
                                    (task?.status === 'pending' && task?.task?.taskStatus === "toDo") ? 'Waiting for Company Start' :
                                        (task?.status === 'pending' && task?.task?.taskStatus === "inProgress") ? 'Waiting for Your Action' :
                                            task?.status === 'waitingForReview' ? 'Waiting for Company Review' : task?.status)}
                            </Label>
                            <Label variant="filled" sx={{
                                borderRadius: 200, minWidth: 90,
                                backgroundColor: task?.task?.priority === 'low' ? '#50EF8B' : task?.task?.priority === 'normal' ? 'grey' : 'pink',
                                color: task?.task?.priority === 'low' ? 'darkgreen' : task?.task?.priority === 'normal' ? 'white' : 'darkred'
                            }}>
                                <Flag sx={{ mr: 1 }} fontSize='small' />
                                {task?.task?.priority}
                            </Label>
                        </Stack>
                        {task?.status === "accepted" && <Button variant='contained' color='secondary' size='medium'
                            onClick={() => setSelectedItemForEvaluate(task)}
                        >
                            <CheckCircle sx={{ mr: 1 }} />
                            Complete Task
                        </Button>}
                    </Stack>
                </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ mb: 3, mt: 3 }}>
                <Grid xs={12} item sx={{ display: 'flex', flexDirection: 'column', }}>
                    <Typography variant="h4">{task?.task?.name}</Typography>
                    <Typography fontSize={"small"} color={"#888"} mt={1}>Created At: {moment(task?.task?.createdAt).format('DD.MM.YYYY')}</Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ mb: 2, mt: 2 }} flexDirection={"column"}>
                <Grid xs={12} item sx={{ display: 'flex', flexDirection: 'column', }}>
                    <Typography variant="body2">{parse(task?.task?.description ?? '')}</Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ mb: 2, mt: 2 }} flexDirection={"column"} gap={2} alignItems={"flex-start"}>
                <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }}>
                    <Typography width={80} variant="body2" color={"#888"}>Due Date:</Typography>
                    <Stack direction="row" alignItems={'center'} gap={0.5} sx={{ py: 0.5, px: 2, border: '1px solid #dedede', borderRadius: 20 }}>
                        <CalendarMonth sx={{ color: '#888', mr: 1 }} />
                        <Typography variant="body2" color={"#888"} >{moment(task?.task?.dueDate).format('DD.MM.YYYY')}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }}>
                    <Typography width={80} variant="body2" color={"#888"}>Task Type:</Typography>
                    <Stack direction="row" alignItems={'center'} gap={0.5} sx={{ py: 0.5, px: 2, border: '1px solid #dedede', borderRadius: 20 }}>
                        <Checklist sx={{ color: '#888', mr: 1 }} />
                        <Typography variant="body2" color={"#888"} >{task?.task?.taskType?.slice(0, 1).toUpperCase() + task?.task?.taskType?.slice(1)}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }}>
                    <Typography width={80} variant="body2" color={"#888"}>Difficulty:</Typography>
                    <Stack direction="row" alignItems={'center'} gap={0.5} sx={{ py: 0.5, px: 2, border: '1px solid #dedede', borderRadius: 20 }}>
                        <LowPriority sx={{ color: '#888', mr: 1 }} />
                        <Typography variant="body2" color={"#888"} >{task?.task?.difficulty?.slice(0, 1).toUpperCase() + task?.task?.difficulty?.slice(1)}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }}>
                    <Typography width={80} variant="body2" color={"#888"}>Assignee:</Typography>
                    <Stack direction="row" alignItems={'center'} gap={0.5} sx={{ py: 0.5, px: 2, border: '1px solid #dedede', borderRadius: 20 }}>
                        <img src={intern?.personalInformation.profileImageUrl} alt="profile" style={{ width: 25, height: 25, borderRadius: 30, objectFit: 'cover', marginRight: 2 }} />
                        <Typography variant="body2" color={"#888"} >{intern.personalInformation.name} {intern.personalInformation.surname}</Typography>
                    </Stack>
                </Stack>
                {task?.task?.attachments?.length > 0 && <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%', flexWrap: 'wrap' }}>
                    <Typography width={80} variant="body2" color={"#888"}>Task Attachments:</Typography>
                    {task?.task?.attachments?.length > 0 &&
                        task?.task?.attachments?.map((attachment, index) => <Stack direction="row" alignItems={'center'} sx={{ py: 0.5, px: 2, cursor: 'pointer', border: '1px solid #dedede', borderRadius: 20 }} key={index}
                            onClick={() => handleDownloadAttachment(attachment.fileName)}>
                            <AttachFile sx={{ color: '#888', mr: 1 }} />
                            <Typography variant="body2" color={"#888"} >{attachment.name} </Typography>
                            <Download sx={{ color: '#888', ml: 1 }} />
                        </Stack>)}
                </Stack>}
                {task?.attachments?.length > 0 && <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%', flexWrap: 'wrap' }}>
                    <Typography width={80} variant="body2" color={"#888"}>Comment Attachments:</Typography>
                    {task?.attachments?.length > 0 &&
                        task?.attachments?.map((attachment, index) => <Stack direction="row" alignItems={'center'} sx={{ py: 0.5, px: 2, cursor: 'pointer', border: '1px solid #dedede', borderRadius: 20 }} key={index}
                            onClick={() => handleDownloadAttachment(attachment.fileName)}>
                            <AttachFile sx={{ color: '#888', mr: 1 }} />
                            <Typography variant="body2" color={"#888"} >{attachment.name} </Typography>
                            <Download sx={{ color: '#888', ml: 1 }} />
                        </Stack>)}
                </Stack>}
            </Grid>
            <Divider />
            <Grid container sx={{ mb: 2, mt: 2 }} flexDirection={"column"} gap={2} alignItems={"flex-start"}>
                <Typography variant="body2">Comments</Typography>
                {task?.comments?.map((comment, index) => {
                    return (
                        comment?.createdBy?._id === intern?._id ? <Stack direction="column" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }} key={index}>
                            <Stack direction="row" alignItems={'flex-start'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }}>
                                <img src={intern?.personalInformation.profileImageUrl} alt="profile" style={{ width: 25, height: 25, borderRadius: 30, objectFit: 'cover', marginRight: 2 }} />
                                <Box border={"1px solid #ddd"} borderRadius={1} p={1} sx={{ width: '100%' }}>
                                    <Stack direction={"column"} alignItems={'flex-start'} gap={1} sx={{ width: '100%' }}>
                                        <Stack direction={"row"} alignItems={'center'} gap={1} justifyContent={"space-between"} sx={{ width: '100%' }}>
                                            <Typography variant="body2" fontWeight={"bold"} >{intern.personalInformation.name} {intern.personalInformation.surname}</Typography>
                                            <Typography variant="body2" color={"#aaa"} >{moment(comment.createdAt).fromNow()}</Typography>
                                        </Stack>
                                        <Typography variant="body2" color={"#888"} >{comment.content}</Typography>
                                        {comment.attachments?.length > 0 && <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }}>
                                            <Typography variant="body2" color={"#888"}>Attachments:</Typography>
                                            {comment.attachments?.length > 0 &&
                                                comment.attachments?.map((attachment, index) => <Stack direction="row" alignItems={'center'} sx={{ py: 0.5, px: 2, cursor: 'pointer', border: '1px solid #dedede', borderRadius: 20 }} key={index}
                                                    onClick={() => window.open(attachment.url, '_blank')}>
                                                    <AttachFile sx={{ color: '#888', mr: 1 }} />
                                                    <Typography variant="body2" color={"#888"} >{attachment.name} </Typography>
                                                    <Download sx={{ color: '#888', ml: 1 }} />
                                                </Stack>)}
                                        </Stack>}
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack> :
                            <Stack direction="column" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }} key={index}>
                                <Stack direction="row" alignItems={'flex-start'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }}>

                                    <Box border={"1px solid #ddd"} borderRadius={1} p={1} sx={{ width: '100%' }}>
                                        <Stack direction={"column"} alignItems={'flex-start'} gap={1} sx={{ width: '100%' }}>
                                            <Stack direction={"row"} alignItems={'center'} gap={1} justifyContent={"space-between"} sx={{ width: '100%' }}>
                                                <Typography variant="body2" color={"#aaa"} >{moment(comment.createdAt).fromNow()}</Typography>
                                                <Typography variant="body2" fontWeight={"bold"} >
                                                    {internship?.company?.displayName}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="body2" color={"#888"} >{comment.content}</Typography>
                                            {comment.attachments?.length > 0 && <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"flex-start"} sx={{ width: '100%' }}>
                                                <Typography variant="body2" color={"#888"}>Attachments:</Typography>
                                                {comment.attachments?.length > 0 &&
                                                    comment.attachments?.map((attachment, index) => <Stack direction="row" alignItems={'center'} sx={{ py: 0.5, px: 2, cursor: 'pointer', border: '1px solid #dedede', borderRadius: 20 }} key={index}
                                                        onClick={() => window.open(attachment.url, '_blank')}>
                                                        <AttachFile sx={{ color: '#888', mr: 1 }} />
                                                        <Typography variant="body2" color={"#888"} >{attachment.name} </Typography>
                                                        <Download sx={{ color: '#888', ml: 1 }} />
                                                    </Stack>)}
                                            </Stack>}
                                        </Stack>
                                    </Box>
                                    <img src={internship?.company?.displayImageUrl} alt="profile" style={{ width: 25, height: 25, borderRadius: 30, objectFit: 'cover', marginRight: 2 }} />

                                </Stack>
                            </Stack>

                    )
                })}
            </Grid>

        </Box>

        <Box
            sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: '#efefef',
                zIndex: 10,
                width: '100%',
            }}
        >
            <Box sx={{
                py: '24px',
                px: { xs: '15px', sm: '15px', },
                display: 'flex',
                justifyContent: 'flex-end',
            }}>

                <Stack sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 1 }}>
                    <Box bgcolor={'#fff'} borderRadius={1}
                        onClick={() => document.getElementById('file-input').click()}
                        p={1} width={53} height={53} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ cursor: 'pointer' }}>
                        <AttachmentOutlined sx={{ color: '#888' }} />
                    </Box>
                    <input
                        id="file-input"
                        type="file"
                        accept="application/pdf"
                        style={{ display: 'none' }}
                        key={selectedFile ? selectedFile.name : ''}  // Bu key'i ekleyerek yeniden render edilmesini sağlıyoruz
                        onChange={e => handleFileChange(e)}
                    />
                    {!selectedFile && <TextField
                        placeholder="Write a comment"
                        variant="outlined"
                        value={commentContent}
                        onChange={(e) => setCommentContent(e.target.value)}
                        sx={{ width: '100%', bgcolor: 'white', borderRadius: 1 }} />}
                    {selectedFile && <Typography
                        variant="body2"
                        sx={{ width: '100%', bgcolor: 'white', borderRadius: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 1 }}
                    >
                        {selectedFile.name}
                        <CancelOutlined onClick={() => setSelectedFile(null)} sx={{ cursor: 'pointer' }} />
                    </Typography>}

                    <Button variant="contained" color="primary" sx={{ px: 0.5 }}
                        disabled={loadingUpload}
                        onClick={() => {
                            !selectedFile && handleComment()
                            selectedFile && handleAttachment()
                        }}>
                        {loadingUpload ? <CircularProgress size={"1rem"} />
                            : <Send fontSize='small' />}
                    </Button>
                </Stack>
            </Box>
        </Box>
    </SwipeableDrawer >
}
