import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material'
import { useMediaQuery, Typography } from '@mui/material';
import { BackToHome, PageOpening, ExittoOnboarding } from 'components';
import { BackgroundImage, CreateNewPassword } from 'assets'
import { useLocation } from 'react-router-dom';
import { InformationDrawer } from "components";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const OnboardingLayout = ({ children, info, activeStep }) => {

  const path = useLocation().pathname.split('/')[1];
  const { t } = useTranslation();

  const [pageHeight, setPageHeight] = useState(window.innerHeight);
  const { userRole } = useSelector((state) => state.user);

  const downXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const updatePageHeight = () => {
    setPageHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePageHeight);

    return () => {
      window.removeEventListener('resize', updatePageHeight);
    };
  }, []);

  return <PageOpening>
    {process.env.REACT_APP_TEST === "true" && info && <InformationDrawer info={info} />}
    <Grid container sx={{ height: pageHeight }}>
      {!downXs &&
        <Grid item xs={12} sm={5} md={4}
          style={{
            backgroundImage: `url(${CreateNewPassword})`, backgroundPositionY: 0, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'right',
          }}
          sx={{
            alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', position: 'relative',
            minHeight: '1110px',
          }}
        >
          <Box sx={{
            padding: 4,
            width: '80%',
            backgroundColor: 'rgba(50, 50, 50, 0.5)',
            backdropFilter: 'blur(6px)',
            borderRadius: "16px",
          }}>
            {(userRole === "intern" || userRole === "highSchoolIntern") ? (
              activeStep === 0 ? <><Typography variant="h4" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                {t("Personal Information")}
              </Typography>
                <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {t("The first part of your CV, positioned at the top of the page, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.")}
                  <br /><br />
                  {t("When it comes to your contact details, your email address and phone number(s) are essential. We use your registered email on the CV. Once upon a time, it was customary to include your full address on your CV. Today, you simply need to list your town and county.")}
                </Typography></> :
                activeStep === 1 ? <><Typography variant="h4" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {t("Personal Details")}
                </Typography>
                  <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {t("When it comes to your contact details, yo, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.")}
                    <br /><br />
                    {t("When it comes toand phone registere was customary to include your full address on your CV. Today, you simply need to list your town and county.")}
                  </Typography></> :
                  activeStep === 2 ? <><Typography variant="h4" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {t("Educational Information")} </Typography>
                    <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                      {t("The first part of your CV, positioned at the top of the page, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.")}
                      <br /><br />
                      {t("When it comes to your contact details, your email address and phone number(s) are essential. We use your registered email on the CV. Once upon a time, it was customary to include your full address on your CV. Today, you simply need to list your town and county.")}
                    </Typography></> :
                    activeStep === 3 ? <><Typography variant="h4" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                      {t("Foreign Languages")} </Typography>
                      <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {t("When it comes to your contact details, yo, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.")}
                        <br /><br />
                        {t("When it comes toand phone registere was customary to include your full address on your CV. Today, you simply need to list your town and county.")}
                      </Typography></> :
                      activeStep === 4 ? <><Typography variant="h4" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {t("Skills")}  </Typography>
                        <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                          {t("The first part of your CV, positioned at the top of the page, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.")}
                          <br /><br />
                          {t("When it comes to your contact details, your email address and phone number(s) are essential. We use your registered email on the CV. Once upon a time, it was customary to include your full address on your CV. Today, you simply need to list your town and county.")}
                        </Typography></>
                        : null)
              :
              userRole === "companyAdmin" ? (
                activeStep === 0 ? <><Typography variant="h4" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {t("Efficient Talent Matchmaking")}
                </Typography>
                  <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {t("InternIF's AI-driven recommendation system streamlines the talent matchmaking process for companies.")}
                    <br /><br />
                    {t("By understanding the unique needs and preferences of each company, InternIF ensures that the intern selection process is not only efficient but also results in high-quality matches.")}
                  </Typography></> :
                  activeStep === 1 ? <><Typography variant="h4" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {t("Diverse and Inclusive Hiring")}
                  </Typography>
                    <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                      {t("Companies partnering with InternIF gain access to a diverse pool of global talent. This inclusivity promotes diverse perspectives within the workplace, fostering innovation and creativity.")}
                      <br /><br />
                      {t("InternIF actively supports companies in building teams that reflect a variety of backgrounds and experiences.")}
                    </Typography></> :
                    activeStep === 2 ? <><Typography variant="h4" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                      {t("Remote Work Adaptability")} </Typography>
                      <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {t("Recognizing the evolving landscape of work, InternIF embraces and facilitates remote internships.")}
                        <br /><br />
                        {t("Companies benefit from the flexibility of engaging with interns regardless of geographical constraints, tapping into a broader talent pool and adapting to the changing dynamics of the professional world.")}
                      </Typography></> : null)
                : null

            }

          </Box>
        </Grid>}
      <Grid item xs={12} sm={7} md={8} display="flex" >
        <BackToHome />
        <ExittoOnboarding />
        <Grid py={5} container justifyContent="center" alignItems="flex-start">
          {children}
        </Grid>
      </Grid>

    </Grid>
  </PageOpening>
}

export default OnboardingLayout