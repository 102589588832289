import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_createInternship = async ({
  values,
  accessToken,
  preferredLanguages,
  preferredSkills,
}) => {
  const data = JSON.stringify({
    query: `mutation CompanyAdmin_createInternship($internshipInput: InternshipInput!) {
      companyAdmin_createInternship(internshipInput: $internshipInput) {
        _id
      }
    }`,
    variables: {
      internshipInput: {
        applicationType: values?.applicationType,
        coffee: values?.coffee,
        description: values?.description,
        followUpUserType: values?.followUpUserType,
        internshipArea: values?.internshipArea,
        descriptionSummary: values?.descriptionSummary,
        internshipGoal: values?.internshipGoal,
        positionName: values?.positionName,
        preferredCollegeYear: values?.preferredCollegeYear ?? null,
        preferredGPA: values?.preferredGPA ?? null,
        preferredLanguages: preferredLanguages ?? null,
        language: values?.internshipLanguage,
        preferredSkills: preferredSkills ?? null,
        quota: Number(values?.quota),
        salary: values?.salaryType === "Volunteer" ? 0 : Number(values?.salary),
        salaryType: values?.salaryType,
        startDate: values?.startDate,
        timePeriodInWeeks: values?.timePeriodInWeeks,
      },
    },
  });

  try {
    const res = await axios(
      initialConfig(
        process.env.REACT_APP_BASE_URL + "/internship/graphql",
        data,
        accessToken
      )
    );
    if (res.data?.errors && res.data?.errors.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    return res?.data?.data?.companyAdmin_createInternship;
  } catch (err) {
    return err;
  }
};
