import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";


const initialState = {
  companyUser_menuInternshipsData: null,
  companyUser_menuInternshipsLoading: false,
  companyUser_menuInternshipsError: null,
};

export const companyUser_menuInternshipsFunc = createAsyncThunk("companyUser_menuInternships/companyUser_menuInternshipsFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `query CompanyUser_menuInternships {
      companyUser_menuInternships {
        _id
        positionName
      }
    }`
  });

  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const companyUser_menuInternships = createSlice({
  name: "companyUser_menuInternships",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyUser_menuInternshipsFunc.pending, (state) => {
      state.companyUser_menuInternshipsLoading = true;
    });
    builder.addCase(companyUser_menuInternshipsFunc.fulfilled, (state, action) => {
      state.companyUser_menuInternshipsLoading = false;
      state.companyUser_menuInternshipsData = action.payload?.companyUser_menuInternships;
      state.companyUser_menuInternshipsError = action?.payload?.message;
    });
    builder.addCase(companyUser_menuInternshipsFunc.rejected, (state, action) => {
      state.companyUser_menuInternshipsLoading = false;
      state.companyUser_menuInternshipsError = action.error.message;
    });
  }
});


export default companyUser_menuInternships.reducer;