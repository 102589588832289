import { useSelector } from 'react-redux';
import { Link, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { removeUser } from 'context/common/user';
import { setOnboardedIntern } from 'context';
import { useNavigate } from 'react-router-dom';


export const ExittoOnboarding = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleLogout = () => {
        dispatch(removeUser());
        dispatch(setOnboardedIntern(null));
        navigate('/');

    }

    return (
        <Link
            onClick={handleLogout}
            sx={{
                right: '20px',
                position: 'absolute',
                top: '20px',
                gap: '5px',
                display: { xs: 'none', sm: 'flex' },
                textDecoration: 'none',
                cursor: 'pointer',
            }}
        >
            {/* employif te  */}
            <Typography
                sx={{
                    color: '#96A1BA',
                    fontFamily: 'Causten-Medium',
                    fontSize: '14px',
                    lineHeight: '24px',
                }}
            >
                Sign out
            </Typography>
            <LogoutIcon sx={{ color: '#96A1BA' }} fontSize="small" />
        </Link>
    )
}