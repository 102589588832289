import { useState, useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, Grid, Card, Typography, TextField, Dialog, DialogTitle, Slide, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { DashboardLayout } from 'layouts';
import { AnimatedTitle, RichTip, SettingsContainer } from 'components';
import CoverLetterIcon from "assets/icons/onboarding/upper-text-skills-icon.png";
import { useFormik } from 'formik'
import { DeleteOutline, Update, Add } from '@mui/icons-material';
import debounce from 'lodash/debounce';
import { intern_addCoverLetterFunc, intern_updateCoverLetterFunc, self_internFunc, intern_removeCoverLetterFunc } from 'context';
import { useSnackbar } from 'notistack';
import parse from 'html-react-parser';
import {t} from 'i18next'

const info = [
    "Faz 2 bitti.",
]

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CoverLetters = () => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [openCoverLetter, setOpenCoverLetter] = useState(false);
    const [editableCoverLetter, setEditableCoverLetter] = useState(null);
    const { accessToken } = useSelector((state) => state.user);
    const { self_internData: user } = useSelector(state => state.self_intern);
    const [selectedCoverLetters, setSelectedCoverLetters] = useState([]);

    const [editorKey, setEditorKey] = useState(0);

    const userInitialValues = {
        name: "",
        content: ""
    }


    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        onSubmit: null
    })

    const debouncedSetValue = debounce((e) => {
        setFieldValue('content', e);
    }, 300);

    const handleAddCoverLetter = () => {
        intern_addCoverLetterFunc({ accessToken, values }).then((res) => {
            if (res?._id) {
                dispatch(self_internFunc({ accessToken }))
                setOpenCoverLetter(false)
                enqueueSnackbar('The cover letter has been added!', { variant: 'success' })
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
            }
        })
    }


    const handleUpdateCoverLetter = () => {
        intern_updateCoverLetterFunc({ accessToken, values, id: editableCoverLetter?.id }).then((res) => {
            if (res?._id) {
                dispatch(self_internFunc({ accessToken }))
                setOpenCoverLetter(false)
                enqueueSnackbar('The cover letter has been updated!', { variant: 'success' })
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
            }
        })
    }

    const handleRemoveCoverLetter = () => {
        intern_removeCoverLetterFunc({ accessToken, id: editableCoverLetter?.id }).then((res) => {
            if (res?.success) {
                dispatch(self_internFunc({ accessToken }))
                setOpenCoverLetter(false)
                enqueueSnackbar('The cover letter has been removed!', { variant: 'success' })
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
            }
        })
    } 


    useEffect(() => {
        user?.cv?.coverLetters && setSelectedCoverLetters(user?.cv?.coverLetters)
    }, [user])

    return <DashboardLayout info={info}>
        <Grid container sx={{ p:{xs:0, sm:5}, width: '100%', display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <Box >
                <AnimatedTitle title={t("My Cover Letters")} />
            </Box>
        </Grid>
        <SettingsContainer pb={2} mb={3}>
            {(selectedCoverLetters?.length === 0 || !selectedCoverLetters) && <Grid container spacing={{xs:0, sm:2}} sx={{ width: '100%', justifyContent: 'center', mt: 2, mb: 2 }}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" color={"grey.600"} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>{t('No cover letter added yet.')}</Typography>
                </Grid>
            </Grid>}
            <Grid container sx={{ width: '100%', mt: "10px", pl: 2, pr: 0 }} spacing={2}>
                {selectedCoverLetters?.length > 0 &&
                    selectedCoverLetters?.map((item, index) => (
                        <Grid item xs={12} md={4}>
                            <Card sx={{
                                width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2,
                                '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)", cursor: "pointer" }
                            }} onClick={() => {
                                setOpenCoverLetter(true)
                                setFieldValue("name", item?.name);
                                setEditorKey((prevKey) => prevKey + 1);
                                setFieldValue("content", item?.content);
                                setEditableCoverLetter({ id: item?._id })
                            }}>
                                <img src={CoverLetterIcon} alt="certificate" style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>   {item?.name} </Typography>
                                <Box width={'100%'} bgcolor={'grey.100'} sx={{ borderRadius: 1, pl: 1, pr: 1, height: 100, overflow: 'hidden' }}>
                                    <Typography variant="body2">{parse(item?.content ?? '')}</Typography>
                                    {/* <Typography variant="body2">{new Parser().parse(item?.content)}</Typography> */}
                                </Box>
                            </Card>
                        </Grid>
                    ))
                }
                <Grid item xs={12} md={4}>
                    {selectedCoverLetters?.length <= 5 && <Card
                        onClick={() => {
                            setEditableCoverLetter(null);
                            setFieldValue('name', '')
                            setFieldValue('content', '');
                            setEditorKey((prevKey) => prevKey + 1);
                            setOpenCoverLetter(true);
                        }}
                        sx={{
                            display: "flex", flexDirection: "column", height: '100%', justifyContent: "center", alignItems: "center", p: 2,
                            '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.2)", cursor: "pointer" }
                        }} >
                        <Add sx={{ mr: 1 }} fontSize="large" />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>   {t('Add Cover Letter')}</Typography>
                    </Card>
                    }
                </Grid>

            </Grid>
        </SettingsContainer>

        <Dialog
            open={openCoverLetter}
            keepMounted
            onClose={() => setOpenCoverLetter(false)}
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={Transition}
        >
            <DialogTitle>{editableCoverLetter ? t('Update Cover Letter') : t('Add Cover Letter')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ width: '100%', mt: "10px", justifyContent: 'flex-end' }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t("Cover Letter Name")}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="name"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <Box style={{ position: 'absolute', left: "25px", top: "8px", backgroundColor: 'white', zIndex: 100, paddingLeft: "5px", paddingRight: "5px" }}>
                            <Typography style={{
                                lineHeight: 1.5, fontSize: "12px", fontFamily: "Causten-Medium,sans-serif",
                                fontWeight: "600", color: "#637381",
                            }}      > {t('Content')} </Typography>
                        </Box>
                        <RichTip
                            label="Cover Letter"
                            value={values.content}
                            setValue={debouncedSetValue}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            key={editorKey}
                            name="content"
                            error={touched.content && Boolean(errors.content)}
                            helperText={touched.content && errors.content}
                            type="coverletter"
                            handleFocus={() => null}
                        />



                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button color="primary" variant="outlined" onClick={() => setOpenCoverLetter(false)}>{t('Cancel')}</Button>
                {editableCoverLetter && <Button color="error" variant="outlined" onClick={handleRemoveCoverLetter}>
                    <DeleteOutline sx={{ mr: 1 }} fontSize="small" />
                    {t('Remove')}</Button>}
                <Button
                    disabled={!(values.name && values.content)}
                    color="primary" variant="contained" onClick={editableCoverLetter ? handleUpdateCoverLetter : handleAddCoverLetter}><Update sx={{ mr: 1 }} fontSize="small" /> {editableCoverLetter ? t('Update Cover Letter') : t('Add Cover Letter')}</Button>
            </DialogActions>
        </Dialog>

    </DashboardLayout>
}


export default CoverLetters