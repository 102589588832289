import { Grid, Typography } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { ChatView } from 'page-sections';
import { useSelector } from 'react-redux'
import { AnimatedTitle } from 'components'


const info = [
    "Faz 0 bitti.",
    "Faz 1 başlamadı."
]

const Messages = () => {


    const { self_companyUserData } = useSelector((state) => state.self_companyUser);

    const user = {
        fullName: `${self_companyUserData?.personalInformation?.name} ${self_companyUserData?.personalInformation?.surname}`?.slice(0, 15),
        email: self_companyUserData?.contact?.email,
        profileImageUrl: self_companyUserData?.personalInformation?.profileImageUrl
    }

    return <DashboardLayout info={info}>
        <Grid container sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <AnimatedTitle title="Messages" />
            </Grid>


            <Grid item xs={12} >
                <ChatView user={user} />
            </Grid>
        </Grid>

    </DashboardLayout >
}


export default Messages