import React from 'react'
import { Grid, Typography, Card } from '@mui/material'
import { GroupOutlined } from '@mui/icons-material'

export const TaskWidget = ({ color, icon, text, bottomText, children }) => {

    return <Grid item xs={12} sm={4}>
        <Card
            sx={{
                backgroundColor: color,
                padding: 2, alignItems: 'center', height: '100%'
            }}
        >
            {icon === 'GroupOutlined' ? <GroupOutlined sx={{ color: 'white', fontSize: '35px', }}  /> : <img src={icon} alt="" height="30px" sx={{ mb: 2 }} />}
            <Typography sx={{ fontSize: "25pt", fontWeight: 'bold' }} color={"white"}>{text}</Typography>
            <Typography variant="h5" color={"white"}>{bottomText}</Typography>

        </Card>
    </Grid>
}
