import Compressor from 'compressorjs';


export const imageCompress = ({ file, enqueueSnackbar, setCompressedImage, ratio, maxWidth }) => {

    if (file?.size > 5 * 1024 * 1024) {
        enqueueSnackbar("Image size must be less than 5 MB.", { variant: "error" });
        return;
    }

    new Compressor(file, {
        quality: ratio ?? 0.7,
        maxWidth: maxWidth ?? 1000,
        maxSize: 2,
        success(result) {
            const formData = new FormData();
            formData.append('file', result, result.name);
            setCompressedImage({
                image: result,
                url: URL.createObjectURL(result),
                type: result.type === 'image/png' ? 'pngImage' : 'jpegImage'
            })
        },
        error(err) {
            console.log(err.message);
        },
    });
}
