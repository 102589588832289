import React from 'react'
import { Box, Typography } from '@mui/material'

export const MyInternshipWidget = ({ icon, title, desc, text1, text2, bottomText }) => {

    return (
        <Box
            sx={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #E5EAED",
                borderRadius: "8px",
                display: "flex",
                gap: '6px',
                p: { xs: '10px' },
                width: "100%",
            }}
        >

            <Box
                sx={{
                    width: '32px',
                    height: '32px',
                }}
            >
                <img src={icon} alt="" height="100%" />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >

                <Typography
                    sx={{

                        fontFamily: 'Causten-Medium',
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#222B45',
                    }}
                >
                    {title} &nbsp;
                </Typography>

                <Typography
                    sx={{

                        fontFamily: 'Causten-Regular',
                        fontSize: '10px',
                        lineHeight: '16px',
                        color: '#8F9BB3',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {desc} &nbsp;
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        my: '16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Causten-Bold',
                            fontSize: '24px',
                            lineHeight: '32px',
                            color: '#0E1436',
                        }}
                    >
                        {text1}
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Causten-Regular',
                            fontSize: '24px',
                            lineHeight: '32px',
                            color: '#7D8898',
                        }}
                    >
                        {text2}
                    </Typography>
                </Box>

                <Typography
                    sx={{

                        fontFamily: 'Causten-Bold',
                        fontSize: '10px',
                        lineHeight: '16px',
                        color: '#8F9BB3',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {bottomText}
                </Typography>

            </Box>

        </Box>
    )
}