import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_acceptAssignedTask = async ({ accessToken, taskId }) => {

    const data = JSON.stringify({
        query: `mutation Intern_acceptAssignedTask($filter: AcceptAssignedTaskFilterInput!) {
            intern_acceptAssignedTask(filter: $filter) {
                _id
            }
            }`,
        variables: {
            "filter": {
                "id": taskId
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.intern_acceptAssignedTask
    } catch (err) {
        return err
    }
}
