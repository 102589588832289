import { Grid, TextField, CircularProgress, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Dropdown } from 'components';
import { RichTip } from 'components';
import debounce from 'lodash/debounce';

export const MyCompanyForm = ({ values, handleChange, handleBlur, handleSubmit, setFieldValue,
    touched, errors, loading, countryOptions, cityOptions, industryOptions, employeeOptions }) => {

    const debouncedSetValue = debounce((e) => {
        setFieldValue('detailedDescription', e);
    }, 300);

    let descriptonCharacterCount = values.detailedDescription?.replace(/<[^>]*>/g, '').replace(/\s/g, '')?.length ?? 0;


    return <Grid container spacing={3} sx={{ width: '100%', pl: 3 }}>
        <Grid item xs={12} md={6}>
            <TextField
                name="companyName"
                label="Company Name"
                value={values?.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.companyName && errors.companyName)}
                helperText={touched.companyName && errors.companyName}
                fullWidth
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                name="contactInfo"
                label="Contact Info"
                value={values?.contactInfo}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.contactInfo && errors.contactInfo)}
                helperText={touched.contactInfo && errors.contactInfo}
                fullWidth
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Dropdown
                setFieldValue={setFieldValue}
                errors={errors}
                data={industryOptions}
                label={"Industry"}
                labelId={"industry-label"}
                value={values.industry}
                id={"industry"}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Dropdown
                setFieldValue={setFieldValue}
                errors={errors}
                data={employeeOptions}
                label={"Number of Employee"}
                labelId={"employee-label"}
                value={values.numberOfEmployee}
                id={"numberOfEmployee"}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Dropdown
                setFieldValue={setFieldValue}
                errors={errors}
                data={countryOptions}
                label={"Country"}
                labelId={"country-label"}
                value={values.country}
                id={"country"}
                type={"withFlag"}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Dropdown
                setFieldValue={setFieldValue}
                disabled={!values.country}
                errors={errors}
                data={cityOptions}
                label={"City"}
                labelId={"city-label"}
                value={values.city}
                id={"city"}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                name="companyWebsite"
                label="Company Website"
                value={values?.companyWebsite}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.companyWebsite && errors.companyWebsite)}
                helperText={touched.companyWebsite && errors.companyWebsite}
                fullWidth
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                name="foundedIn"
                label="Year Founded"
                value={values?.foundedIn}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.foundedIn && errors.foundedIn)}
                helperText={touched.foundedIn && errors.foundedIn}
                fullWidth
                type='number'
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <TextField
                name="twitter"
                label="Twitter"
                value={values?.twitter}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.twitter && errors.twitter)}
                helperText={touched.twitter && errors.twitter}
                fullWidth
                type='url'
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <TextField
                name="linkedin"
                label="LinkedIn"
                value={values?.linkedin}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.linkedin && errors.linkedin)}
                helperText={touched.linkedin && errors.linkedin}
                fullWidth
                type='url'
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <TextField
                name="instagram"
                label="Instagram"
                value={values?.instagram}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.instagram && errors.instagram)}
                helperText={touched.instagram && errors.instagram}
                fullWidth
                type='url'
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <TextField
                name="facebook"
                label="Facebook"
                value={values?.facebook}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.facebook && errors.facebook)}
                helperText={touched.facebook && errors.facebook}
                fullWidth
                type='url'
            />
        </Grid>


        <Grid item xs={12} md={12}>
            <TextField
                name="description"
                label="Company Summary"
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                fullWidth
                multiline
                rows={2}
            />
        </Grid>
        <Grid item xs={12} md={12} style={{ position: 'relative' }}>
            <Box style={{ position: 'absolute', left: "35px", top: "17px", backgroundColor: 'white', zIndex: 100, paddingLeft: "5px", paddingRight: "5px" }}>
                <Typography style={{
                    lineHeight: 1.5, fontSize: "12px", fontFamily: "Causten-Medium,sans-serif",
                    fontWeight: "600", color: "#637381",
                }}
                > Company Description </Typography>
            </Box>
            <Typography variant="body2" sx={{ color: "#637381", fontSize: "12px", position: 'absolute', bottom: 3, right: 25 }}>
                {descriptonCharacterCount} - {descriptonCharacterCount < 100 ? ' (min 100)' : '(max 500)'}
            </Typography>
            <RichTip
                value={values.detailedDescription}
                setValue={debouncedSetValue}
                handleFocus={() => null}
            />
        </Grid>
        <Grid item xs={12}>
            <LoadingButton variant="contained" onClick={handleSubmit} loading={loading} loadingIndicator={<CircularProgress size={"1rem"} />} type="submit" size="large">
                Save Changes
            </LoadingButton>
        </Grid>
    </Grid >

}

