import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Card, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react';
import PaymentFailed from "assets/animations/payment_failed.json";
import { self_ordersFunc } from 'context'
import { AnimatedTitle } from 'components';
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Label from 'minimal/components/label';





const PaymentFailIntern = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.user);
    const { self_ordersData, self_orderscount } = useSelector((state) => state.self_orders);

    useEffect(() => {
        dispatch(self_ordersFunc({ accessToken }))
    }, [])

    return <DashboardLayout>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', gap: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                <AnimatedTitle title={`${t("My Orders")}`} />
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"} >
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{t("Order Date")}</TableCell>
                                <TableCell>{t("Total Price")}</TableCell>
                                <TableCell>{t("Currency")}</TableCell>
                                <TableCell>{t("Status")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {self_ordersData?.length > 0 ? self_ordersData?.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{moment(row.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                    <TableCell>{row.price?.netTotal?.toFixed(2)}</TableCell>
                                    <TableCell>{row.price?.currency}</TableCell>
                                    <TableCell><Label
                                        color={row.payment?.paymentStatus === "success" ? "success" : "error"}
                                    >{row.payment?.paymentStatus === "success" ? t("Success") : t("Fail")}</Label></TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell colSpan={5} align="center">{t("No data found")}</TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>
        </Grid>
    </DashboardLayout>
}

export default PaymentFailIntern