
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Grid, Box, Typography, Link, Divider, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button } from '@mui/material';
import AuthLayout from 'layouts/AuthLayout';
import { enqueueSnackbar } from 'notistack'
import { FileCopy } from '@mui/icons-material';
import { AuthHead } from 'page-sections/auth'
import { useLocation, useNavigate } from 'react-router-dom';
import { requestResetPasswordFunc } from 'context';





const CheckYourMailForgot = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSendPopup, setIsSendPopup] = useState(false)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const email = urlSearchParams.get("email");

    console.log(new URLSearchParams(window.location.search), 'route')

    const handleResendCode = () => {
        dispatch(requestResetPasswordFunc({ email: email })).then((res) => {
            console.log(res)
            if (res?.payload?.isSuccess) {
                enqueueSnackbar('-> The email has been sent to reset password. Please check your mailbox!', { variant: 'success' })
                process.env.REACT_APP_TEST === "true" && enqueueSnackbar(<div>
                    {"Registration link: "}
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                            const textToCopy = `http://app.internif.com/reset-password?token=${res?.payload.data.token}`
                            const textArea = document.createElement('textarea');
                            textArea.value = textToCopy;
                            document.body.appendChild(textArea);
                            textArea.select();
                            document.execCommand('copy');
                            document.body.removeChild(textArea);
                        }}
                    >
                        Copy Link
                        <FileCopy />
                    </Button>
                </div>, { variant: 'warning', autoHideDuration: 6000 });
            }
            if (res.payload?.response?.status === 400) {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'warning' })
            }
        }).catch((err) => {
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
        }
        )
    }






    return <AuthLayout>
        <Grid item xs={12} justifyContent="center" alignItems="center" flexDirection={"column"}>


            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Check Your Email" description={`We have sent a link to your email address so you can reset your password.`} />
            </Box>

            <Dialog open={isSendPopup} onClose={() => setIsSendPopup(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Resend Code</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to resend code?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setIsSendPopup(false)} color="primary">
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={() => {
                        setIsSendPopup(false)
                        handleResendCode()
                    }} color="primary">
                        Resend
                    </Button>
                </DialogActions>
            </Dialog>


            <Divider sx={{ width: "100%", marginTop: "30px" }} />
            {/* Didn't receive the code? Request a new one. Already have an account? Log in */}
            <Box justifyContent="center" alignItems={'center'} width="100%" mt={"35px"} mb={"30px"}>
                <Box display={"flex"} justifyContent="center" alignItems={'center'} width="100%">
                    <Typography variant="h7" color="textSecondary" sx={{ textAlign: "center" }}>
                        Didn’t get the code? <Link style={{ textDecoration: 'none' }} component="span" onClick={() => setIsSendPopup(true)} color="info.main" variant="h7" sx={{ cursor: "pointer" }}>
                            You can request a new one.
                        </Link>
                    </Typography>
                </Box>
                <Box mt={2} display={"flex"} justifyContent="center" alignItems={'center'} width="100%">
                    <Typography variant="h7" color="textSecondary" sx={{ textAlign: "center" }}>
                        <Link style={{ textDecoration: 'none' }} component="span" onClick={() => navigate("/signin")} color="info.main" variant="h7" sx={{ cursor: "pointer" }}>
                            Sign in
                        </Link>
                    </Typography>
                </Box>
            </Box>

        </Grid >


    </AuthLayout >
}



export default CheckYourMailForgot