import { Box, Typography } from "@mui/material";
import CompanyAccountIcon from 'assets/icons/dashboard/my-company.png'




export const CardHeaderCompany = () => {
    return <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} pl={3} mt={2} mb={5}>
        <img src={CompanyAccountIcon} alt="my account icon" style={{ width: "55px", height: "55px", objectFit: "contain" }} />
        <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"} ml={2}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Company Information
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "400" }}>
            You can enter information about your company.
            </Typography>
        </Box>
    </Box>
}

