export const ApplicationStatusForCompany = {
  applied: {
    name: "Applied",
    value: "applied",
  },
  viewed: {
    name: "Viewed",
    value: "viewed",
  },
  evaluating: {
    name: "Saved",
    value: "evaluating",
  },
  suitable: {
    name: "Suitable",
    value: "suitable",
  },
  unsuitable: {
    name: "Unsuitable",
    value: "unsuitable",
  },
  rejected: {
    name: "Rejected",
    value: "rejected",
  },
  accepted: {
    name: "Accepted",
    value: "accepted",
  },
  approved: {
    name: "Approved",
    value: "approved",
  },
  disapproved: {
    name: "Disapproved",
    value: "disapproved",
  },
};

export const applicationStatusForCompanyList = Object.values(ApplicationStatusForCompany);

export const applicationStatusForCompanyValueList = Object.values(ApplicationStatusForCompany).map(
  (status) => status.value
);

export const applicationStatusForCompanyNameList = Object.values(ApplicationStatusForCompany).map(
  (status) => status.name
);

export function getValueOfApplicationStatusForCompany(name) {
  for (const key of Object.keys(ApplicationStatusForCompany)) {
    if (ApplicationStatusForCompany[key].name === name) {
      return ApplicationStatusForCompany[key].value;
    }
  }
  return null;
}

export function getNameOfApplicationStatusForCompany(value) {
  for (const key of Object.keys(ApplicationStatusForCompany)) {
    if (ApplicationStatusForCompany[key].value === value) {
      return ApplicationStatusForCompany[key].name;
    }
  }
  return null;
}
