
export const intern_applicationTips_title = `<b>Your Internship Journey Unveiled at InternIF</b>`

export const intern_applicationTips = `
<p>
Embark on a seamless journey through your internship applications with InternIF's intuitive dashboard. Here's a guide to understanding the stages and maximizing your opportunities:
    <br/>
    <br/>
<b>Navigating Your Applications: </b>
    <br/>
Welcome to your application hub! Each stage is meticulously designed to keep you informed and empowered throughout the process.
    <br/>
    <br/>
    <b>Unlock the Power of Your Internship Dashboard:   </b>
    <br/>
Transparency: Real-time updates for transparent and informed decision-making.
    <br/>
Empowerment: Take charge of your internship journey, making decisions that resonate with your goals.
    <br/>
Future Planning: Identify potential opportunities for upcoming internships.
    <br/>
    <br/>
    <b>Why Choose InternIF for Your Internship Journey? </b>
    <br/>
User-Centric Design: A dashboard tailored to enhance your experience.
    <br/>
Global Opportunities: Explore internships from around the world.
    <br/>
Communication Channels: Foster clear communication with companies throughout the application process.
    <br/>
    <br/>
    <b>Seize Opportunities with InternIF!   </b>
    <br/>
Unlock the door to a world of possibilities. Navigate your applications seamlessly and make the most of your internship journey with InternIF.
</p>`

export const intern_applicationTips_title_tr = `<b>Staj Yolculuğunuz InternIF'te Açığa Çıkıyor</b>`

export const intern_applicationTips_tr = `
<p>
Staj başvurularınızı InternIF'in sezgisel paneli ile sorunsuz bir şekilde başlatın. İşte aşamaları anlamanıza ve fırsatlarınızı en üst düzeye çıkarmanıza yardımcı olacak bir rehber:
    <br/>
    <br/>
<b>Başvurularınızı Gezin: </b>
    <br/>
Başvuru merkezinize hoş geldiniz! Her aşama, sizi bilgilendirmek ve süreç boyunca güçlü hissetmenizi sağlamak için özenle tasarlanmıştır.
    <br/>
    <br/>
    <b>Staj Panelinizin Gücünü Açığa Çıkarın:   </b>
    <br/>
Şeffaflık: Şeffaf ve bilgiye dayalı kararlar almak için gerçek zamanlı güncellemeler.
    <br/>
Güçlendirme: Staj yolculuğunuzda söz sahibi olun ve hedeflerinizle uyumlu kararlar alın.
    <br/>
Gelecek Planlama: Gelecek stajlar için potansiyel fırsatları belirleyin.
    <br/>
    <br/>
    <b>Staj Yolculuğunuz İçin Neden InternIF'i Seçmelisiniz? </b>
    <br/>
Kullanıcı Odaklı Tasarım: Deneyiminizi artırmak için özel olarak tasarlanmış bir panel.
    <br/>
Küresel Fırsatlar: Dünyanın dört bir yanından stajları keşfedin.
    <br/>
İletişim Kanalları: Başvuru süreci boyunca şirketlerle net iletişim kurun.
    <br/>
    <br/>
    <b>InternIF ile Fırsatları Kucaklayın!   </b>
    <br/>
Birçok olasılığın kapısını açın. Başvurularınızı sorunsuz bir şekilde gezinin ve InternIF ile staj yolculuğunuzun tadını çıkarın.
</p>`