import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { DashboardLayout } from 'layouts'
import { ChatView } from 'page-sections'
import { AnimatedTitle } from 'components'
import { t } from 'i18next'

const info = [
    "Faz 0 bitti",
    "Faz 1 başlamadı."
]

const Messages = () => {

    const { self_internData } = useSelector((state) => state.self_intern);

    const user = {
        fullName: `${self_internData?.personalInformation?.name} ${self_internData?.personalInformation?.surname}`?.slice(0, 15),
        email: self_internData?.contact?.email,
        profileImageUrl: self_internData?.personalInformation?.profileImageUrl
    }

    return <DashboardLayout info={info}>
        <Grid container sx={{ p: {xs:0, sm:5}, width: '100%', }}>
            <Grid item xs={12} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <AnimatedTitle title={t("Messages" )}/>
            </Grid>



            <Grid item xs={12} >
                <ChatView user={user} />
            </Grid>
        </Grid>
    </DashboardLayout>
}

export default Messages