import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    googleRegisterData: null,
    googleRegisterLoading: false,
    googleRegisterError: null,
};


export const googleRegisterFunc = createAsyncThunk("googleRegister/googleRegisterFunc", async (values) => {

    console.log(values, "values")
    let data1 = JSON.stringify({
        "token": values.token,
        "role": values.role
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/oauth/google/register',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data1
    };

    const response = axios.request(config)
        .then((res) => {

            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const googleRegister = createSlice({
    name: "googleRegister",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(googleRegisterFunc.pending, (state) => {
            state.googleRegisterLoading = true;
        });
        builder.addCase(googleRegisterFunc.fulfilled, (state, action) => {
            state.googleRegisterLoading = false;
            state.googleRegisterData = action.payload;
            console.log(action.payload, "action.payload")
            state.googleRegisterError = action.payload.message;
        });
        builder.addCase(googleRegisterFunc.rejected, (state, action) => {
            state.googleRegisterLoading = false;
            state.googleRegisterError = action.error.message;
        });
    }
});


export default googleRegister.reducer;