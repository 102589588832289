import { LocalizationProvider, MobileDatePicker as MDatePicker } from "@mui/x-date-pickers"
import { CalendarIcon } from "@mui/x-date-pickers"



export const MobileDatePicker = ({ value, setFieldValue, name, label, width, disabled, disabledText, setCheckStillAttending }) => {
    return <LocalizationProvider >
        <MDatePicker
            disabled={disabled}
            sx={{ width: width ?? '100%' }}
            name={name}
            label={disabled ? disabledText : label}
            format='dd MMMM yyyy'
            value={value}
            onChange={(newValue) => setFieldValue(name, newValue)}
            className="customInput"
            onClose={() => setCheckStillAttending ? setCheckStillAttending(false) : null}
        />
        <CalendarIcon sx={{ position: 'absolute', marginLeft: -5, marginTop: 1.5, color: '#bbb' }} />
    </LocalizationProvider>
}