// OneCoffeePerInternship 
// OneCoffeePerWeek 
// OneCoffeePerDay

export const InternshipCoffee = {
  No: {
    value: "No",
    name: "No",
  },
  JustFirstCoffee: {
    value: "OneCoffeePerInternship",
    name: "1 Coffee Per Internship",
  },
  OneCoffeePerWeek: {
    value: "OneCoffeePerWeek",
    name: "1 Coffee Per Week",
  },
  twentyCoffees: {
    value: "OneCoffeePerDay",
    name: "1 Coffee Per Day",
  },
};

export const internshipCoffeeList = Object.values(InternshipCoffee);

export const internshipCoffeeValueList = Object.values(InternshipCoffee).map(
  (period) => period.value
);

export const internshipCoffeeNameList = Object.values(InternshipCoffee).map(
  (period) => period.name
);

export function getValueOfInternshipCoffee(name) {
  for (const key of Object.keys(InternshipCoffee)) {
    if (InternshipCoffee[key].name === name) {
      return InternshipCoffee[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipCoffee(value) {
  for (const key of Object.keys(InternshipCoffee)) {
    if (InternshipCoffee[key].value === value) {
      return InternshipCoffee[key].name;
    }
  }
  return null;
}
