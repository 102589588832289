
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, Checkbox, Typography, FormControlLabel, Link, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import AuthLayout from 'layouts/AuthLayout';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack'
import { BasicInput, BasicInputPassword, BlackButton, GoogleButton, LinkedinButton, WhiteButton, TermsDialog, PrivacyDialog } from 'components'
import { AuthHead, AuthSeperator } from 'page-sections/auth';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { signupFunc, anonymous_policiesFunc } from 'context';
import regexPattern from 'minimal/utils/regexPattern';
import axios from "axios";


const ValidationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address!')
        .required('Email is required!'),
    password: Yup.string()
        .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
        .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
        .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
        .matches(regexPattern.atLeastOnepecialCharacters, "You must use one of these characters: !@#$%^&()+,.\\/:;'-_")
        .min(8, "Password must be at least 8 characters!")
        .required('Password is required!'),
    accept: Yup.bool()
        .oneOf([true], 'Accept Terms & Conditions is required'),
})

export const delay = ms => new Promise((res) => setTimeout(res, ms));


const Signup = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { signupLoading } = useSelector(state => state.signup);
    const { anonymous_policiesData: policies, anonymous_policiesLoading: policiesLoading } = useSelector(state => state.anonymous_policies);
    const [userRole, setUserRole] = useState('intern')
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [loading, setLoading] = useState(false);

    const descriptionElementRef = useRef(null);


    useEffect(() => {
        if (openDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDialog]);

    useEffect(() => {
        if (openDialog2) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDialog2]);


    const handleClickOpen = (type) => () => {

        dispatch(anonymous_policiesFunc({ domain: "app_internif", type: type }))

        if (type) {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
        setOpenDialog2(false);
    };


    const { handleSubmit, handleChange, handleBlur, errors, values, touched } = useFormik({
        initialValues: {
            email: '', password: '', accept: false, referralCode: ''
        },
        validationSchema: ValidationSchema,
        onSubmit: () => dispatch(signupFunc({ email: values.email, password: values.password, userRole: userRole })).then((res) => {
            console.log(res.payload)
            if (res.payload?.isSuccess) {
                enqueueSnackbar('The confirmation email has been sent. Please check your mailbox!', { variant: 'success' })
                process.env.REACT_APP_TEST === "true" && enqueueSnackbar(<div>
                    {"Registration link: "}
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                            const textToCopy = `http://app.internif.com/confirmation?token=${res?.payload.data.token}&code=${res?.payload.data.code}`
                            const textArea = document.createElement('textarea');
                            textArea.value = textToCopy;
                            document.body.appendChild(textArea);
                            textArea.select();
                            document.execCommand('copy');
                            document.body.removeChild(textArea);
                        }}
                    >
                        Copy Link
                        <FileCopy />
                    </Button>
                </div>, { variant: 'warning', autoHideDuration: 6000 });
                setTimeout(() => {
                    navigate('/check-your-email/?email=' + values.email)
                }, 500)

            } else if (res.payload?.response?.data?.errors?.[0]?.msg === "Email already in use") {

                enqueueSnackbar('You are already registered. Please login.', { variant: 'warning' })
                setTimeout(() => {
                    navigate('/signin')
                }, 1500)

            } else if (res.payload?.response?.data?.errors?.[0]?.msg === "Password must contain at least a number, an uppercase letter, a lowercase letter, a special char") {

                enqueueSnackbar('Password must contain at least a number, an uppercase letter, a lowercase letter, a special char', { variant: 'warning' })

            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' })
            }
        }).catch((err) => {
            console.log(err, 'err');
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
        })
    })


    const handleLinkedinRegister = (token) => {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_BASE_URL + '/auth/oauth/linkedin/register',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ token, role: userRole })
        };

        setLoading(true)
        axios.request(config)
            .then(async (response) => {
                const data = response.data;
                console.log(JSON.stringify(response.data), "response.data");
                await delay(5000);

                if (data.payload.isSuccess) {

                    enqueueSnackbar('Registration Successful. You can log in now.', { variant: 'success' });

                    setLoading(false)
                    navigate('/signin')
                } else if (data.payload?.response?.data?.message === "AlreadyRegistered") {
                    enqueueSnackbar('You are already registered. Please login.', { variant: 'warning' });
                    setLoading(false)
                } else {
                    enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
                    setLoading(false)
                }

            })
            .catch((error) => {
                if (error?.response?.data?.message === "AlreadyRegistered") {
                    enqueueSnackbar('You are already registered. Please login.', { variant: 'warning' });
                    setLoading(false)
                } else {
                    console.log(error);
                    enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
                    setLoading(false)
                }
            });
    }


    return <AuthLayout>
        <Grid item xs={12} justifyContent="center" alignItems="center" flexDirection={"column"}>


            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Sign Up Now" description={`Let’s sign up as an ${userRole === "intern" ? "Intern" : "Company"}!`} />
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" gap="10px" width="100%">
                {userRole === "intern" ?
                    <>
                        <BlackButton name="Intern" handleSubmit={() => setUserRole('intern')} />
                        <WhiteButton name="Company" handleSubmit={() => setUserRole('companyAdmin')} />
                    </>
                    :
                    <>
                        <WhiteButton name="Intern" handleSubmit={() => setUserRole('intern')} />
                        <BlackButton name="Company" handleSubmit={() => setUserRole('companyAdmin')} />
                    </>
                }
            </Box>


            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" marginTop="30px">
                <Box flexDirection={"column"} display="flex" gap="10px" width="100%">
                    <LinkedinButton onClick={handleLinkedinRegister} loading={loading} />
                    {/* <GoogleButton type={"signup"} role={userRole} /> */}
                </Box>
                <AuthSeperator type="sign up" />

            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" width="100%">
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>

                    <Box flexDirection="column" alignItems="center" gap="10px" width="100%">

                        <BasicInput
                            name="email"
                            type="email"
                            label="Email*"
                            placeholder={userRole === "intern" ? "Enter your email address as intern" : "Enter your email address as company"}
                            value={values?.email}
                            error={touched.email && errors.email}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <BasicInputPassword
                            name="password"
                            type="password"
                            label="Password*"
                            placeholder="Enter a strong password"
                            value={values?.password}
                            error={touched.password && errors.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        {/* <BasicInput
                            name="referralCode"
                            type="text"
                            label="Referral Code"
                            placeholder="Enter your referral code (optional)"
                            value={values?.referralCode}
                            error={touched.referralCode && errors.referralCode}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        /> */}


                        <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%" mb={1} mt={errors?.password ? 3 : 0  }>
                            <FormControlLabel
                                control={<Checkbox
                                    color="primary"
                                    checked={values?.accept}
                                    onChange={handleChange}
                                    name="accept"
                                />}
                                label={<Typography variant="h8" color="primary.main">
                                    I’ve read and accept the <Link component="span" onClick={handleClickOpen("privacy_policy")} color="info.main" variant="h8">
                                        Privacy Policy</Link>, <Link component="span" onClick={handleClickOpen("terms_and_conditions")} color="info.main" variant="h8">
                                        Terms and Conditions
                                    </Link> and  <Link component="span" onClick={handleClickOpen("cookies_policy")} color="info.main" variant="h8">
                                        Cookies Policy
                                    </Link> of InternIF.
                                </Typography>}
                            />
                        </Box>

                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%">
                            <BlackButton
                                name="Sign Up"
                                isActive={values?.email && values?.password && !errors?.email && !errors?.password && values?.accept}
                                handleSubmit={handleSubmit}
                                loading={signupLoading}
                            />
                        </Box>


                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"15px"} mb={"30px"}>

                            Already have an account? <a href="/" style={{ textDecoration: 'none' }}> <Typography variant="h7" color="info.main" ml={"5px"}>
                                Sign In
                            </Typography>
                            </a>
                        </Box>



                    </Box>

                </form>
            </Box>

        </Grid >

        <Dialog
            open={openDialog}
            onClose={handleClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogContent dividers={true} sx={{ minWidth: 'md', width: '500px', mt: 4 }}>
                {policiesLoading
                    ? <CircularProgress />
                    : parse(policies?.data?.[0]?.content?.en ?? '')}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    </AuthLayout >
}

export default Signup