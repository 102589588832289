import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    FormControl, Grid, InputLabel, MenuItem, Select, TextField,
    Typography, Card, Box,
    Button, Stack,
    CircularProgress
} from "@mui/material";
import { DashboardLayout } from "layouts";
import { AnimatedTitle } from "components";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AttachmentOutlined, CancelOutlined } from "@mui/icons-material";
import { fileUpload } from 'utils/helpers/_fileUpload'
import { companyUser_menuInternshipsFunc, companyAdmin_createTask, companyAdmin_internsJoinedToInternshipFunc } from "context";

const CreateTask = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.user);
    const [selectedFile, setSelectedFile] = useState(null);
    const { companyUser_menuInternshipsData } = useSelector((state) => state.companyUser_menuInternships);
    const [loading, setLoading] = useState(false);
    const { companyAdmin_internsJoinedToInternshipData: interns } = useSelector((state) => state.companyAdmin_internsJoinedToInternship);

    const taskInitialValues = useMemo(() => ({
        name: null,
        description: null,
        allowableFileFormats: ["pdf"],
        startDate: null,
        dueDate: null,
        reminder: null,
        taskType: null,
        priority: null,
        difficulty: null,
        duration: null,
        internId: null,
        internshipId: null,
    }), []);

    const taskValidationSchema = Yup.object().shape({
        // allowableFileFormats: Yup.string().required('Required'),
        // attachments: Yup.array().of(Yup.object().shape({
        //   caption: Yup.string().required('Required'),
        //   contentType: Yup.string().required('Required'),
        //   fileName: Yup.string().required('Required'),
        //   isPrivate: Yup.boolean().required('Required'),
        //   name: Yup.string().required('Required'),
        //   thumbnailUrl: Yup.string().required('Required'),
        //   url: Yup.string().required('Required'),
        // })),
        // description: Yup.string().required('Required'),
        // difficulty: Yup.string().required('Required'),
        // dueDate: Yup.date().required('Required'),
        // duration: Yup.number().required('Required'),
        // interns: Yup.array().of(Yup.object().shape({
        //   _id: Yup.string().required('Required')
        // })),
        // internship: Yup.object().shape({
        //   _id: Yup.string().required('Required')
        // }),
        // name: Yup.string().required('Required'),
        // priority: Yup.string().required('Required'),
        // reminder: Yup.string().required('Required'),
        // startDate: Yup.date().required('Required'),
        // taskType: Yup.string().required('Required'),

    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
    } = useFormik({
        enableReinitialize: true,
        initialValues: taskInitialValues,
        validationSchema: taskValidationSchema,
        onSubmit: (values) => {
            handleCreateTask({ values });
        }
    });

    const handleCreateTask = async ({ values }) => {

        setLoading(true)
        console.log(values)
        try {

            const urls = await fileUpload({ accessToken, presignedType: "commentFile", file: selectedFile, fileType: "pdfFile", fileName: selectedFile?.name })

            if (urls) {
                const createTask = await companyAdmin_createTask({ accessToken, values, attachment: urls.attachment })
                console.log(createTask)
                if (createTask?._id) {
                    enqueueSnackbar(t('Task created successfully'), { variant: 'success' });
                    navigate('/task')
                    setLoading(false)
                }
                else {
                    enqueueSnackbar(t('Task creation failed'), { variant: 'error' });
                    setLoading(false)
                }
            }

        } catch (err) {
            enqueueSnackbar(t('Task creation failed'), { variant: 'error' });
            setLoading(false)
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file)
    }

    useEffect(() => {
        accessToken && dispatch(companyUser_menuInternshipsFunc({ accessToken }))
    }, [dispatch, accessToken])

    useEffect(() => {
        values?.internshipId && accessToken && dispatch(companyAdmin_internsJoinedToInternshipFunc({ accessToken, internshipId: values?.internshipId }))
    }, [values?.internshipId])

    return (
        <DashboardLayout>
            <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
                <Grid item xs={12} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <AnimatedTitle title="Create Task" />
                </Grid>

                <Grid item xs={12} lg={8} xl={9} mt={2} >
                    <Card sx={{ p: 2, borderRadius: 2, backgroundColor: 'rgba(255,255,255, 0.5)', backdropFilter: 'blur(6px)', }}>
                        <Grid container spacing={2} mb={5} >
                            <Grid item xs={12} >
                                <Stack direction={"column"} gap={2}>
                                    <FormControl fullWidth >
                                        <InputLabel id="internshipId">Internship</InputLabel>
                                        <Select
                                            name="internshipId"
                                            labelId="internshipId"
                                            label="Internship"
                                            value={values.internshipId}
                                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                            onChange={handleChange}>
                                            {companyUser_menuInternshipsData?.map((internship) => <MenuItem value={internship._id} key={internship._id}>
                                                {internship.positionName}
                                            </MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth >
                                        <InputLabel id="internshipId">Assignee</InputLabel>
                                        <Select
                                            name="internId"
                                            labelId="internId"
                                            label="Assignee"
                                            value={values.internId}
                                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                            onChange={handleChange}>
                                            {interns?.map((intern) => <MenuItem value={intern._id} key={intern._id}>
                                                {intern.displayName}
                                            </MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        name="name"
                                        label="Task Name"
                                        value={values?.name}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                    <TextField
                                        name="description"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        value={values?.description}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                    <MDPicker
                                        sx={{ width: '100%', borderRadius: "10px" }}
                                        label="Start Date"
                                        value={values.startDate ? new Date(values.startDate) : null}
                                        onChange={(newValue) => setFieldValue("startDate", newValue)}
                                        name="startDate"
                                        format="dd MMM yyyy"
                                        minDate={new Date()}
                                    />
                                    <MDPicker
                                        sx={{ width: '100%', borderRadius: "10px" }}
                                        label="Due Date"
                                        value={values.dueDate ? new Date(values.dueDate) : null}
                                        onChange={(newValue) => setFieldValue("dueDate", newValue)}
                                        name="dueDate"
                                        format="dd MMM yyyy"
                                        minDate={values.startDate}
                                    />
                                    <TextField
                                        name="reminder"
                                        label="Reminder"
                                        type="number"
                                        value={values?.reminder}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                    <FormControl fullWidth >
                                        <InputLabel id="taskType">Task Type</InputLabel>
                                        <Select
                                            name="taskType"
                                            labelId="taskType"
                                            label="Task Type"
                                            value={values.taskType}
                                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                            onChange={handleChange}>
                                            {[{
                                                name: "Application",
                                                value: "application"
                                            },
                                            {
                                                name: "Design",
                                                value: "design"
                                            },
                                            {
                                                name: "Evaluation",
                                                value: "evaluation"
                                            },
                                            {
                                                name: "Examination",
                                                value: "examination"
                                            },
                                            {
                                                name: "Refactoring",
                                                value: "refactoring"
                                            },
                                            {
                                                name: "Reporting",
                                                value: "reporting"
                                            },
                                            {
                                                name: "Research",
                                                value: "research"
                                            }].map((type) => <MenuItem value={type.value} key={type.value}>
                                                {type.name}
                                            </MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth >
                                        <InputLabel id="priority">Priority</InputLabel>
                                        <Select
                                            name="priority"
                                            labelId="priority"
                                            label="Priority"
                                            value={values.priority}
                                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                            onChange={handleChange}>
                                            {[{
                                                name: "High",
                                                value: "high"
                                            },
                                            {
                                                name: "Low",
                                                value: "low"
                                            },
                                            {
                                                name: "Normal",
                                                value: "normal"
                                            }].map((priority) => <MenuItem value={priority.value} key={priority.value}>
                                                {priority.name}
                                            </MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth >
                                        <InputLabel id="difficulty">Difficulty</InputLabel>
                                        <Select
                                            name="difficulty"
                                            labelId="difficulty"
                                            label="Difficulty"
                                            value={values.difficulty}
                                            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                            onChange={handleChange}>
                                            {[{
                                                name: "Easy",
                                                value: "easy"
                                            },
                                            {
                                                name: "Hard",
                                                value: "hard"
                                            },
                                            {
                                                name: "Medium",
                                                value: "medium"
                                            }].map((difficulty) => <MenuItem value={difficulty.value} key={difficulty.value}>
                                                {difficulty.name}
                                            </MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        name="duration"
                                        label="Duration"
                                        type="number"
                                        value={values?.duration}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                                        <Typography variant="body1">Attachment</Typography>
                                        <Box bgcolor={'#fff'} borderRadius={1} border={'1px solid #ddd'}
                                            onClick={() => document.getElementById('file-input').click()}
                                            width={53} height={53} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ cursor: 'pointer' }}>
                                            <AttachmentOutlined sx={{ color: '#888' }} />
                                        </Box>
                                        <input
                                            id="file-input"
                                            type="file"
                                            accept="application/pdf"
                                            style={{ display: 'none' }}
                                            key={selectedFile ? selectedFile.name : ''}
                                            onChange={e => handleFileChange(e)}
                                        />
                                        {selectedFile && <Typography
                                            variant="body2"
                                            sx={{
                                                bgcolor: 'white', gap: 2, borderRadius: 1,
                                                border: '1px solid #ddd', p: 1,
                                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 1
                                            }}
                                        >
                                            {selectedFile.name}
                                            <CancelOutlined onClick={() => setSelectedFile(null)} sx={{ cursor: 'pointer' }} />
                                        </Typography>}
                                    </Stack>
                                </Stack>

                                <Button size="large"
                                    disabled={loading}
                                    variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
                                    {loading ? <CircularProgress size={"1rem"} /> : "Create Task"}
                                </Button>
                            </Grid>

                        </Grid>
                    </Card>


                </Grid>
            </Grid>


        </DashboardLayout >
    );
};

export default CreateTask;
