import React, { useEffect, useState, useRef } from 'react'
import { Box, Grid, Typography, useMediaQuery, Card, Divider, Pagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { InternshipCard, AnimatedTitle, AnimatedMappingCard } from 'components'
import {
    intern_internshipFunc, intern_internshipsFunc, intern_saveInternship, intern_setInternshipNull,
    intern_unsaveInternship, user_parametersFunc, anonymous_view, anonymous_citiesFunc, anonymous_countriesFunc,
    user_parameters_industriesFunc, intern_savedInternshipsFunc
} from 'context'
import { DashboardLayout } from 'layouts'
import { InternshipDetailDrawer, InternshipsFilterDrawer } from 'page-sections'
import { intern_internshipTips, intern_internshipTips_title, intern_internshipTips_tr, intern_internshipTips_title_tr } from 'utils'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const info = [
    "Sağ taraftaki kısım sadece xl'de görünecek şekilde ayarlandı. Eğer bu bölümler ok ise fonksiyonları eklenecek"
]



const Internships = () => {

    const { t } = useTranslation();
    const tipTitle = i18next.language === "tr" ? intern_internshipTips_title_tr : intern_internshipTips_title;
    const tipDescription = i18next.language === "tr" ? intern_internshipTips_tr : intern_internshipTips;
    const dispatch = useDispatch()
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const { accessToken } = useSelector(state => state.user);
    const { intern_internshipData, intern_internshipLoading } = useSelector(state => state.intern_internship)
    const { intern_savedInternshipsData } = useSelector(state => state.intern_savedInternships)
    const { intern_internshipsData: internships } = useSelector(state => state.intern_internships)
    const [filterDrawer, setFilterDrawer] = useState(false)
    const [internshipDetailDrawer, setInternshipDetailDrawer] = useState(false)
    const [actionSave, setActionSave] = useState(false)
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [searchOnFocus, setSearchOnFocus] = useState(false)
    const [updatedInternships, setUpdatedInternships] = useState([])
    const [internshipsType, setInternshipsType] = useState("all")
    const bottomRef = useRef(null);

    const [variables, setVariables] = useState({
        filter: {
            positionName: "",
            sort: "most recent",
            internshipArea: null,
            timePeriodInWeeks: null,
            preferredGPA: null,
            preferredCollegeYear: null,
            applicationType: null,
            salaryType: null,
            coffee: null,
            country: null,
            city: null,
            language: null,
            industry: null,
            startDate: null,
            status: null,
        },
        pagination: {
            sort: null
        }
    })

    const handleTogleIntenshipDetail = (internshipId) => {
        if (!internshipId) {
            setInternshipDetailDrawer(false)
            dispatch(intern_setInternshipNull())
        } else {
            setInternshipDetailDrawer(true)
            dispatch(intern_internshipFunc({ internshipId, accessToken })).then(res => {
                anonymous_view({ accessToken, viewedId: internshipId, viewedType: "Internship" })
            })
        }
    }

    const handleLikeAndDislike = async (internship, detail = false) => {
        if (internship?.isSaved) {
            await intern_unsaveInternship({ internshipId: internship?._id, accessToken })
            setActionSave(true)
        } else {
            await intern_saveInternship({ internshipId: internship?._id, accessToken })
            setActionSave(true)
        }

        detail && dispatch(intern_internshipFunc({ internshipId: internship?._id, accessToken }))
    }



    const handleSetFilters = (name, value) => {
        setVariables(prevState => ({
            ...prevState,
            filter: {
                ...prevState?.filter,
                [name]: value
            }
        }))
    }

    const handleFilter = () => {
        dispatch(intern_internshipsFunc({ variables, accessToken, page: 1, pageSize }));
    }

    const handleChangeInternships = (type) => {
        type === "saved" && dispatch(intern_savedInternshipsFunc({ accessToken }));
        type === "all" && dispatch(intern_internshipsFunc({ variables, accessToken, page: 1, pageSize }))
        setInternshipsType(type)
    }

    const handleReset = () => {
        setVariables({
            filter: {
                positionName: "",
                sort: "most recent",
                internshipArea: null,
                timePeriodInWeeks: null,
                preferredGPA: null,
                preferredCollegeYear: null,
                applicationType: null,
                salaryType: null,
                coffee: null,
                country: null,
                city: null,
                language: null,
                industry: null,
                startDate: null,
                status: null,
            },
            pagination: {
                sort: null
            }
        })

        dispatch(intern_internshipsFunc({
            variables: {
                filter: {
                    positionName: "",
                    sort: "most recent",
                    internshipArea: null,
                    timePeriodInWeeks: null,
                    preferredGPA: null,
                    preferredCollegeYear: null,
                    applicationType: null,
                    salaryType: null,
                    coffee: null,
                    country: null,
                    city: null,
                    language: null,
                    industry: null,
                    startDate: null,
                    status: null,
                },
                pagination: {
                    sort: null
                }
            }, accessToken, page: 1, pageSize
        }))

    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [page]);


    useEffect(() => {
        accessToken && dispatch(intern_internshipsFunc({ variables, accessToken, page, pageSize }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, variables, accessToken, page, pageSize])

    useEffect(() => {
        variables?.filter?.positionName.length > 1 && dispatch(intern_internshipsFunc({ variables, accessToken, page: 1, pageSize }));
        variables?.filter?.positionName.length === 0 && dispatch(intern_internshipsFunc({ variables, accessToken, page: 1, pageSize }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variables?.filter?.positionName, dispatch, accessToken, searchOnFocus])



    useEffect(() => {
        accessToken && dispatch(intern_internshipsFunc({ variables, accessToken, page: 1, pageSize }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, accessToken])

    useEffect(() => {
        accessToken && dispatch(user_parametersFunc({ accessToken }))
    }, [dispatch, accessToken])


    useEffect(() => {
        if (variables?.filter?.country?._id) {
            dispatch(anonymous_citiesFunc({ accessToken, countryId: variables?.filter?.country?._id }))
        }
    }, [variables?.filter?.country?._id, dispatch, accessToken])

    useEffect(() => {
        accessToken && dispatch(anonymous_countriesFunc({ accessToken }));
        accessToken && dispatch(user_parameters_industriesFunc({ accessToken }));
    }, [accessToken, dispatch]);

    useEffect(() => {
        actionSave && dispatch(intern_savedInternshipsFunc({ accessToken }))
        actionSave && dispatch(intern_internshipsFunc({ variables, accessToken, page, pageSize }))
        setActionSave(false)
    }, [dispatch, accessToken, actionSave])

    console.log(internships)


    return <DashboardLayout info={info} >

        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} >
                <AnimatedTitle title={`${t("Internships")}`} />
            </Grid>
            <Grid item xs={12} >

                <InternshipsFilterDrawer
                    filterDrawer={filterDrawer}
                    setFilterDrawer={setFilterDrawer}
                    variables={variables}
                    handleSetFilters={handleSetFilters}
                    handleFilter={handleFilter}
                    handleChangeInternships={handleChangeInternships}
                    handleReset={handleReset}
                    setSearchOnFocus={setSearchOnFocus}
                    internshipsType={internshipsType}
                    setInternshipsType={setInternshipsType}
                />
            </Grid>
            <Grid item xs={12} lg={8} xl={9} mt={2} >


                <InternshipDetailDrawer
                    internship={intern_internshipData}
                    internshipDetailDrawer={internshipDetailDrawer}
                    handleCloseDetail={handleTogleIntenshipDetail}
                    setInternshipDetailDrawer={setInternshipDetailDrawer}
                    handleLikeAndDislike={() => handleLikeAndDislike(intern_internshipData, true)}
                />


                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: 2 }}>
                    {internshipsType !== "saved" ?
                        internships?.data?.map((item, index) => {
                            return <AnimatedMappingCard index={index} key={index}>
                                <InternshipCard
                                    key={index}
                                    item={item}
                                    onOpenDetail={() => handleTogleIntenshipDetail(item?._id)}
                                    handleLikeAndDislike={() => handleLikeAndDislike(item)}
                                    loading={!!intern_internshipLoading}
                                />
                            </AnimatedMappingCard>
                        }) :
                        intern_savedInternshipsData?.data?.length > 0 ? intern_savedInternshipsData?.data?.map((item, index) => {
                            return <AnimatedMappingCard index={index} key={index}>
                                <InternshipCard
                                    item={item}
                                    onOpenDetail={() => handleTogleIntenshipDetail(item?._id)}
                                    handleLikeAndDislike={() => handleLikeAndDislike(item)}
                                    loading={!!intern_internshipLoading}
                                />
                            </AnimatedMappingCard>
                        }) : <Typography variant="h5" textAlign={"center"} mt={5} color="text.secondary">
                            {t('There is no saved internship.')}
                        </Typography>
                    }
                    {internships?.count > pageSize && internshipsType === "all" &&
                        <Pagination count={internships?.count ? Math.ceil(internships?.count / pageSize) : 0}
                            page={page} onChange={handlePageChange} sx={{ mt: 2 }} />}

                </Box>
            </Grid>

            {upLg && <Grid item xs={12} lg={4} xl={3} mt={2}>
                <Card sx={{
                    padding: 3, backgroundColor: "#F8F8F8", height: '100%', border: '1px solid #E0E0E0',
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                }}>
                    <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                        <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >{parse(tipTitle)}</Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body1" fontSize={"small"} fontWeight={"regular"}>
                            {parse(tipDescription)}
                        </Typography>
                    </Box>

                </Card>
            </Grid>}
        </Grid>
        <div ref={bottomRef}></div>
    </DashboardLayout>
}

export default Internships