
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import {
    Grid, Box, Typography, Link, Divider, Dialog, TextField,
    DialogTitle, DialogContentText, DialogContent, DialogActions, Button
} from '@mui/material';
import AuthLayout from 'layouts/AuthLayout';
import { enqueueSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as yup from 'yup';
import { confirmationEmailFunc, resendRegistrationConfirmationFunc } from 'context';
import { setAccessToken, setUserRole, setUserId, setEmail } from 'context/common/user';
import { MuiOtpInput } from 'mui-one-time-password-input'

import { AuthHead } from 'page-sections'
import { useLocation, useNavigate } from 'react-router-dom';



const Confirmation = () => {

    const [otp, setOtp] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");
    const [remainingTime, setRemainingTime] = useState(60);

    const handleChange = (newValue) => {
        setOtp(newValue)
    }



    const {
        setFieldValue,
        handleSubmit
    } = useFormik({
        initialValues: {
            code: ''
        },
        onSubmit: (values) => {
            console.log('asdf')
            handleVerifyEmail({ code: otp })
        }
    })

    const handleVerifyEmail = ({ code }) => {
        dispatch(confirmationEmailFunc({
            token: token,
            code: code
        })).then((res) => {
            enqueueSnackbar('Email confirmation has been completed. Please sign in!', { variant: 'success' })
            navigate('/signin');
        }).catch(() => {
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
        })

    }

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (remainingTime > 0) {
    //             setRemainingTime((prevTime) => prevTime - 1);
    //         } else {
    //             clearInterval(intervalId);
    //         }
    //     }, 1000); 

    //     return () => clearInterval(intervalId);
    // }, [remainingTime]);


    return <AuthLayout>
        <Grid item xs={12} justifyContent="center" alignItems="center" flexDirection={"column"}>


            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Verify Registration" description={`Please use the code to verify your email address and gain international work experience by working remotely.`} />
            </Box>

            <form action="" style={{ width: '100%' }} onSubmit={handleSubmit}>

                <MuiOtpInput
                    value={otp}
                    onChange={handleChange}
                    length={6}
                    style={{ width: '100%', borderColor: '#000', marginTop: 20 }}
                />

                <Button
                    disabled={otp?.length < 6}
                    variant='contained'
                    color='primary'
                    sx={{ width: '100%', minHeight: '50px', mt: 5 }}
                    type="submit"
                >
                    Verify
                </Button>


            </form>


            <Divider sx={{ width: "100%", marginTop: "30px" }} />


            {/* <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"35px"} mb={"30px"}>
                <Typography color={"textSecondary"} variant="h7" >Do you want to log in again?<a href="/" style={{ textDecoration: 'none' }}> <Typography variant="h7" color="info.main" ml={"5px"}>
                    Sign In
                </Typography>
                </a>
                </Typography>
            </Box> */}

        </Grid >


    </AuthLayout >
}


export default Confirmation