import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormControl, Grid, InputLabel, TextField, MenuItem, Select } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { anonymous_citiesFunc } from 'context';


const FormComponents = ({
    name = [0, 12, 12, 6, 6, 6],
    surname = [0, 12, 12, 6, 6, 6],
    identityNumber = [0, 12, 12, 6, 6, 6],
    email = [0, 12, 12, 6, 6, 6],
    country = [0, 12, 12, 6, 6, 6],
    city = [0, 12, 12, 6, 6, 6],
    address = [0, 12, 12, 6, 6, 6],
    postcode = [0, 12, 12, 6, 6, 6],
    parameters,
    setSelectedCountry
}) => {


    const countries = parameters?.countries;
    const cities = parameters?.cities;

    const HelperText = ({ text }) => <div style={{ color: '#961650', fontWeight: 400, fontSize: "0.75rem", marginLeft: '14px', marginTop: '8px' }}>{text}</div>

    const initialValues = {
        name: '',
        surname: '',
        identityNumber: '',
        email: '',
        country: '',
        city: '',
        address: '',
        postcode: '',
    }

    const onSubmit = values => {
        console.log('Form data', values)
    }

    const validationSchema = Yup.object({
        name: name[0] > 0 && Yup.string().required('Required').min(2, 'Minimum 2 characters'),
        surname: surname[0] > 0 && Yup.string().required('Required').min(2, 'Minimum 2 characters'),
        identityNumber: identityNumber[0] > 0 && Yup.string().required('Required'),
        email: email[0] > 0 && Yup.string().email('Invalid email format').required('Required'),
        country: country[0] > 0 && Yup.string().required('Required'),
        city: city[0] > 0 && Yup.string().required('Required'),
        address: address[0] > 0 && Yup.string().required('Required'),
        postcode: postcode[0] > 0 && Yup.string().required('Required'),
    })

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit,
        validationSchema
    })


    const nameInput = <Grid item xs={name[1]} sm={name[2]} md={name[3]} lg={name[4]} xl={name[5]} key={name[0]}>
        <TextField
            id="name"
            name="name"
            label="Name"
            fullWidth
            value={values.name}
            onChange={(e => {
                const capitalizedValue = e.target.value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                setFieldValue("name", capitalizedValue);
            })}
            onBlur={handleBlur}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
        />
    </Grid>;

    const surnameInput = <Grid item xs={surname[1]} sm={surname[2]} md={surname[3]} lg={surname[4]} xl={surname[5]} key={surname[0]}>
        <TextField
            id="surname"
            name="surname"
            label="Surname"
            fullWidth
            value={values.surname}
            onChange={(e => {
                const capitalizedValue = e.target.value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                setFieldValue("surname", capitalizedValue);
            })}
            onBlur={handleBlur}
            error={Boolean(touched.surname && errors.surname)}
            helperText={touched.surname && errors.surname}
        />
    </Grid>;

    const identityNumberInput = <Grid item xs={identityNumber[1]} sm={identityNumber[2]} md={identityNumber[3]} lg={identityNumber[4]} xl={identityNumber[5]} key={identityNumber[0]}>
        <TextField
            id="identityNumber"
            name="identityNumber"
            label="Identity Number"
            type='number'
            fullWidth
            value={values.identityNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.identityNumber && errors.identityNumber)}
            helperText={touched.identityNumber && errors.identityNumber}
        />
    </Grid>;

    const emailInput = <Grid item xs={email[1]} sm={email[2]} md={email[3]} lg={email[4]} xl={email[5]} key={email[0]}>
        <TextField
            id="email"
            name="email"
            label="Email"
            value={values.email}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
        />
    </Grid>;

    const countryInput = <Grid item xs={country[1]} sm={country[2]} md={country[3]} lg={country[4]} xl={country[5]} key={country[0]}>
        <FormControl fullWidth error={Boolean(touched.country && errors.country)}>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
                labelId="country-label"
                id="country"
                value={values.country}
                error={Boolean(touched.country && errors.country)}
                label={"Country"}
                onChange={event => {
                    setFieldValue("country", event.target.value)
                    setSelectedCountry(event.target.value)
                }}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
                {countries?.map((option, index) => (
                    <MenuItem key={index} value={option?._id} sx={{ display: "flex", alignItems: "center", }}>
                        <img
                            loading="lazy"
                            style={{ width: "20px", height: "20px", borderRadius: '15px', objectFit: 'cover', marginRight: "10px" }}
                            src={`https://flagcdn.com/${option?.code.toLowerCase()}.svg`}
                            alt={option?.code.toLowerCase()}
                        />
                        {option?.name}
                    </MenuItem>
                ))}
            </Select>
            {/* helper text  */}
            {touched.country && errors.country && <HelperText text={errors.country} />}
        </FormControl>
    </Grid>;

    const cityInput = <Grid item xs={city[1]} sm={city[2]} md={city[3]} lg={city[4]} xl={city[5]} key={city[0]}>
        <FormControl fullWidth error={Boolean(touched.city && errors.city)} disabled={!values.country}>
            <InputLabel id="city-label">City</InputLabel>
            <Select
                labelId="city-label"
                id="city"
                value={values.city}
                error={Boolean(touched.city && errors.city)}
                label={"City"}
                onChange={event => setFieldValue("city", event.target.value)}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
                {cities?.map((option, index) => (
                    <MenuItem key={index} value={option?._id} sx={{ display: "flex", alignItems: "center", }}>
                        {option?.name}
                    </MenuItem>
                ))}
            </Select>
            {touched.city && errors.city && <HelperText text={errors.city} />}
        </FormControl>
    </Grid>;

    const addressInput = <Grid item xs={address[1]} sm={address[2]} md={address[3]} lg={address[4]} xl={address[5]} key={address[0]}>
        <TextField
            id="address"
            name="address"
            label="Address"
            multiline
            rows={1}
            value={values.address}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
        />
    </Grid>;

    const postcodeInput = <Grid item xs={postcode[1]} sm={postcode[2]} md={postcode[3]} lg={postcode[4]} xl={postcode[5]} key={postcode[0]}>
        <TextField
            id="postcode"
            name="postcode"
            label="Postcode"
            value={values.postcode}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.postcode && errors.postcode)}
            helperText={touched.postcode && errors.postcode}
        />
    </Grid>;


    const formElements = [
        name[0] > 0 && nameInput,
        surname[0] > 0 && surnameInput,
        identityNumber[0] > 0 && identityNumberInput,
        email[0] > 0 && emailInput,
        country[0] > 0 && countryInput,
        city[0] > 0 && cityInput,
        address[0] > 0 && addressInput,
        postcode[0] > 0 && postcodeInput,
    ]


    return <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            {formElements.sort((a, b) => a.key - b.key)}
        </Grid>
    </form>
}


const FormExample = () => {

    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.user);
    const { anonymous_countriesData: countries, anonymous_citiesData: cities } = useSelector((state) => state.parameters);
    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        if (selectedCountry) {
            accessToken && dispatch(anonymous_citiesFunc({ accessToken, countryId: selectedCountry }));
        }
    }, [accessToken, dispatch, selectedCountry]);

    return <DashboardLayout>
        <Grid mt={5} container >
            <h1>Form Example</h1>

            <Grid item xs={12} >
                <FormComponents
                    parameters={{ countries, cities }}
                    setSelectedCountry={setSelectedCountry}
                    name={[5, 12, 12, 6, 6, 6]}
                    surname={[2, 12, 12, 6, 6, 6]}
                    identityNumber={[3, 12, 12, 6, 6, 6]}
                    email={[4, 12, 12, 6, 6, 6]}
                    country={[1, 12, 12, 6, 6, 6]}
                    city={[6, 12, 12, 6, 6, 6]}
                    postcode={[8, 12, 12, 6, 6, 6]}
                    address={[7, 12, 12, 6, 6, 6]}
                />
            </Grid>
        </Grid>
    </DashboardLayout>
}

export default FormExample