import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    resetPasswordUsingOldData: null,
    resetPasswordUsingOldLoading: false,
    resetPasswordUsingOldError: null,
};


export const resetPasswordUsingOldFunc = createAsyncThunk("resetPasswordUsingOld/resetPasswordUsingOldFunc", async (values) => {

    var data1 = JSON.stringify({
        "oldPassword": values.oldPassword,
        "password": values.newPassword
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/auth/resetpassword',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + values?.accessToken
        },
        data: data1
    };

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});



const resetPasswordUsingOld = createSlice({
    name: "resetPasswordUsingOld",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetPasswordUsingOldFunc.pending, (state) => {
            state.resetPasswordUsingOldLoading = true;
        });
        builder.addCase(resetPasswordUsingOldFunc.fulfilled, (state, action) => {
            state.resetPasswordUsingOlLoading = false;
            state.resetPasswordUsingOldData = action.payload;
            state.resetPasswordUsingOldError = action.payload.message;
        });
        builder.addCase(resetPasswordUsingOldFunc.rejected, (state, action) => {
            state.resetPasswordUsingOldLoading = false;
            state.resetPasswordUsingOldError = action.error.message;
        });
    }
});


export default resetPasswordUsingOld.reducer;