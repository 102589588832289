export const InternshipGoalForCompany = {
  BrAndAwarenessAndReputation: {
    name: "Brand Awareness and Reputation",
    value: "BrandAwarenessAndReputation",
  },
  CommunityEngagement: {
    name: "Community Engagement",
    value: "CommunityEngagement",
  },
  CorporateSocialResponsibility: {
    name: "Corporate Social Responsibility",
    value: "CorporateSocialResponsibility",
  },
  CulturalFitAssessment: {
    name: "Cultural Fit Assessment",
    value: "CulturalFitAssessment",
  },
  EmployeeEngagement: {
    name: "Employee Engagement",
    value: "EmployeeEngagement",
  },
  EmployeeSatisfactionAndRetention: {
    name: "Employee Satisfaction and Retention",
    value: "EmployeeSatisfactionAndRetention",
  },
  EmployeeTrainingAndDevelopment: {
    name: "Employee Training and Development",
    value: "EmployeeTrainingAndDevelopment",
  },
  InnovationAndFreshPerspectives: {
    name: "Innovation and Fresh Perspectives",
    value: "InnovationAndFreshPerspectives",
  },
  MarketResearch: {
    name: "Market Research",
    value: "MarketResearch",
  },
  NetworkingAndIndustryConnections: {
    name: "Networking and Industry Connections",
    value: "NetworkingAndIndustryConnections",
  },
  ProductivityBoost: {
    name: "Productivity Boost",
    value: "ProductivityBoost",
  },
  ProjectAssistance: {
    name: "Project Assistance",
    value: "ProjectAssistance",
  },
  PromotingIndustryInterest: {
    name: "Promoting Industry Interest",
    value: "PromotingIndustryInterest",
  },
  RecruitmentAndTalentPipeline: {
    name: "Recruitment and Talent Pipeline",
    value: "RecruitmentAndTalentPipeline",
  },
  ResearchAndDataCollection: {
    name: "Research and Data Collection",
    value: "ResearchAndDataCollection",
  },
  SkillsDevelopment: {
    name: "Skills Development",
    value: "SkillsDevelopment",
  },
  SuccessionPlanning: {
    name: "Succession Planning",
    value: "SuccessionPlanning",
  },
  TestingNewRoles: {
    name: "Testing New Roles",
    value: "TestingNewRoles",
  },
  WorkForceDiversity: {
    name: "Workforce Diversity",
    value: "WorkforceDiversity",
  },
  WorkForceExpansion: {
    name: "Workforce Expansion",
    value: "WorkforceExpansion",
  },
};


export const InternshipGoalForCompanyList = Object.values(InternshipGoalForCompany);

export const InternshipGoalForCompanyValueList = Object.values(InternshipGoalForCompany).map(
  (period) => period.value
);

export const InternshipGoalForCompanyNameList = Object.values(InternshipGoalForCompany).map(
  (period) => period.name
);

export function getValueOfInternshipGoalForCompany(name) {
  for (const key of Object.keys(InternshipGoalForCompany)) {
    if (InternshipGoalForCompany[key].name === name) {
      return InternshipGoalForCompany[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipGoalForCompany(value) {
  for (const key of Object.keys(InternshipGoalForCompany)) {
    if (InternshipGoalForCompany[key].value === value) {
      return InternshipGoalForCompany[key].name;
    }
  }
  return null;
}
