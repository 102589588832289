import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";

const initialState = {
  companyUser_applicationsData: null,
  companyUser_applicationsLoading: false,
  companyUser_applicationsError: null,
};

export const companyUser_applicationsFunc = createAsyncThunk(
  "user/companyUser_applicationsFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `query CompanyUser_applications($internshipId: ID!, $filter: FilterApplicationInput) {
        companyUser_applications(internshipId: $internshipId, filter: $filter) {
          data {
            _id
            intern {
              _id
              # role
              displayName
              displayImageUrl
            }
            createdAt
            status
            updatedAt
          }
        }
      }
      `,
      variables: {
        "internshipId": values.internshipId,
        "filter": {
          "statuses": values.statuses
        }
      }
    });

    const config = initialConfig(
      process.env.REACT_APP_BASE_URL + "/internship/graphql",
      data1,
      values?.accessToken
    );

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        console.error("applicationError:", err);
        return err;
      });

    const data = await response;
    return data;
  }
);

const companyUser_applications = createSlice({
  name: "companyUser_applications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyUser_applicationsFunc.pending, (state) => {
      state.companyUser_applicationsLoading = true;
    });
    builder.addCase(companyUser_applicationsFunc.fulfilled, (state, action) => {
      state.companyUser_applicationsLoading = false;
      state.companyUser_applicationsData =
        action.payload?.companyUser_applications;
      state.companyUser_applicationsError = action?.payload?.message;
    });
    builder.addCase(companyUser_applicationsFunc.rejected, (state, action) => {
      state.companyUser_applicationsLoading = false;
      state.companyUser_applicationsError = action.error.message;
    });
  },
});

export default companyUser_applications.reducer;
