import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom'
import { Box, Typography, Link, CircularProgress } from '@mui/material'

import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import { ExampleCompanyLogoImage, ExampleCompanyLogoNetflixImage, ExampleHeaderBackgroundImage, ExampleHeaderBackgroundNetflixImage } from 'assets'



const companyDetailData = null
const companyDetailLoading = false

export const SideCompanyInfoSection = ({ isDemo }) => {

    const { id } = useParams()
    const dispatch = useDispatch()
    // const [tasks, setTasks] = useState([])
    // const { accessToken } = useAuthContextSingleApp()

    // const { internshipDetailData } = useSelector(state => state?.internshipDetail);
    // const { companyDetailData, companyDetailLoading } = useSelector(state => state?.companyDetail);

    // console.log({ companyDetailData })

    // useEffect(() => {
    //     if (id && accessToken) {
    //         dispatch(internshipDetailFunc({ accessToken, internshipId: id }))
    //     }
    // }, [accessToken, id, dispatch]);

    // useEffect(() => {
    //     if (accessToken && internshipDetailData) {
    //         setTimeout(() => {
    //             dispatch(companyDetailFunc({ accessToken, companyId: internshipDetailData?.company?._id }))
    //         }, 52)
    //     }
    // }, [accessToken, dispatch, internshipDetailData]);


    return (
        <Box
            sx={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #E5EAED",
                '&:hover': {
                    boxShadow: "0px 3px 16px rgba(22, 53, 67, 0.06)",
                },
                borderRadius: "8px",
                // height: "100%",
                overflow: "hidden",
                width: "100%",
                // backgroundColor: "red",
            }}
        >
            {

                companyDetailLoading ? (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress sx={{ color: 'orange' }} />
                    </Box>
                ) : (
                    <>
                        <Box
                            sx={{
                                backgroundImage: `url(${!isDemo
                                    ? (companyDetailData?.companyInformation?.backgroundImageUrl ?? ExampleHeaderBackgroundImage)
                                    : ExampleHeaderBackgroundNetflixImage
                                    })`,
                                height: "130px",
                                backgroundSize: "cover",
                                position: "relative",
                            }}
                        >

                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: '-30px',
                                    left: '15px',

                                    backgroundImage: `url(${!isDemo
                                        ? (companyDetailData?.companyInformation?.logoImageUrl ?? ExampleCompanyLogoImage)
                                        : ExampleCompanyLogoNetflixImage
                                        })`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    width: "70px",
                                    height: "70px",

                                    borderRadius: '50%',
                                    backgroundColor: '#fff',
                                }}
                            />

                        </Box>


                        <Box
                            sx={{
                                px: { xs: '16px', },
                                pt: { xs: '46px' },
                            }}
                        >


                            <Box>
                                <Link
                                    to={`/company/${!isDemo ? companyDetailData?._id : "netflix"}`}
                                    component={RouterLink}
                                    sx={{

                                        fontFamily: 'Causten-SemiBold',
                                        fontSize: '24px',
                                        lineHeight: '28.8px',
                                        color: '#000',

                                        textDecoration: 'none',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {!isDemo ? companyDetailData?.companyInformation?.companyName : "Netflix Inc."}
                                </Link>

                                <Typography
                                    sx={{

                                        fontFamily: 'Causten-Regular',
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        color: '#000',
                                    }}
                                >
                                    {!isDemo ? companyDetailData?.companyInformation?.city?.name : 'San Francisco'}, &nbsp;
                                    {!isDemo ? companyDetailData?.companyInformation?.country?.name : 'USA'}
                                </Typography>
                            </Box>


                            <Box
                                sx={{
                                    mt: { xs: '19px' },
                                    mb: { xs: '24px' },
                                }}
                            >
                                {
                                    (isDemo || companyDetailData) && (
                                        <>
                                            <InfoItem
                                                icon={<ApartmentOutlinedIcon sx={{ width: '16px', height: '16px', color: '#7D8898', }} />}
                                                text={!isDemo ? companyDetailData?.companyInformation?.industry?.name : "Entertainment"}
                                            />

                                            <InfoItem
                                                icon={<CreditCardOutlinedIcon sx={{ width: '16px', height: '16px', color: '#7D8898', }} />}
                                                text={!isDemo ? companyDetailData?.email : 'info@netflix.com'}
                                            />

                                            <InfoItem
                                                icon={<GroupsOutlinedIcon sx={{ width: '16px', height: '16px', color: '#7D8898', }} />}
                                                text={!isDemo ? companyDetailData?.companyInformation?.numberOfEmployee?.name : '1000-1500 Employees'}
                                            />
                                        </>
                                    )
                                }
                            </Box>


                            <Box
                                sx={{
                                    // borderBottom: '1px solid #E3E7F2',
                                    pb: '13px',
                                    mb: { xs: '24px' }
                                }}
                            >

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: { xs: '8px' },
                                    }}
                                >
                                    <Typography
                                        sx={{

                                            fontFamily: 'Causten-SemiBold',
                                            fontSize: '14px',
                                            lineHeight: '16.8px',
                                            color: '#191922',
                                        }}
                                    >
                                        About Company
                                    </Typography>

                                    {/* <Link
                                        href={!isDemo ? companyDetailData?.companyInformation?.webSite : 'https://www.netflix.com'}
                                        sx={{
                                            fontFamily: 'Causten-SemiBold',
                                            fontSize: '10px',
                                            lineHeight: '12px',
                                            color: '#A1ABC5',
                                        }}
                                    >
                                        Visit Website
                                    </Link> */}
                                </Box>

                                <Typography
                                    sx={{
                                        fontFamily: 'Causten-Regular',
                                        fontSize: '12px',
                                        lineHeight: '19.5px',
                                        color: '#7D8898',
                                    }}
                                >
                                    {!isDemo ? companyDetailData?.companyInformation?.description : 'Netflix is the world\'s leading streaming entertainment service with 222 million paid memberships in over 190 countries enjoying TV series, documentaries, feature films and mobile games across a wide variety of genres and languages. Members can watch as much as they want, anytime, anywhere, on any Internet-connected screen. Members can play, pause and resume watching, all without commercials or commitment. Show More'}

                                </Typography>

                                {/* <Typography
                                    sx={{
                                        fontFamily: 'Causten-Bold',
                                        fontSize: '12px',
                                        lineHeight: '19.5px',
                                        color: '#7D8898',
                                    }}
                                >
                                    Show More
                                </Typography> */}

                            </Box>


                            <Box>

                                {/* <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: { xs: '10px' }
                        }}
                    >

                        <Typography
                            sx={{

                                fontFamily: 'Causten-SemiBold',
                                fontSize: '14px',
                                lineHeight: '16.8px',
                                color: '#191922',
                            }}
                        >
                            Open Positions
                        </Typography>

                        <Typography
                            sx={{

                                fontFamily: 'Causten-SemiBold',
                                fontSize: '12px',
                                lineHeight: '14.4px',
                                color: '#A1ABC5',
                            }}
                        >
                            View All
                        </Typography>

                    </Box>

                    <Box>

                        {
                            [1, 2, 3].map((item) => {

                                return (
                                    <PositionItem key={item} />
                                )
                            })
                        }
                    </Box> */}


                            </Box>


                        </Box>
                    </>
                )
            }

        </Box>
    )
}


// const PositionItem = () => {

//     return (
//         <Box
//             sx={{
//                 border: '0.5px solid #E5EAED',
//                 borderRadius: '4px',
//                 p: '8px',
//                 mb: { xs: '8px' },
//             }}
//         >

//             <Typography
//                 sx={{

//                     fontFamily: 'Causten-SemiBold',
//                     fontSize: '12px',
//                     lineHeight: '24px',
//                     color: '#0E1436',
//                 }}
//             >
//                 Product Designer Fully Remote
//             </Typography>

//             <Typography
//                 sx={{

//                     fontFamily: 'Causten-Regular',
//                     fontSize: '10px',
//                     lineHeight: '16px',
//                     color: '#0E1436',
//                 }}
//             >
//                 Company Name · Sector ·  1-3 Month
//             </Typography>

//             <Typography
//                 sx={{
//                     fontFamily: 'Causten-Regular',
//                     fontSize: '10px',
//                     lineHeight: '16px',
//                     color: '#70798D',
//                 }}
//             >
//                 Country, City
//             </Typography>

//             <Typography
//                 sx={{
//                     fontFamily: 'Causten-Regular',
//                     fontSize: '10px',
//                     lineHeight: '16px',
//                     color: '#70798D',
//                 }}
//             >
//                 Applied 3 days ago
//             </Typography>

//         </Box>
//     )
// }


const InfoItem = ({ icon, text }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                mb: { xs: '8px' }
            }}
        >

            {icon}

            <Typography
                sx={{

                    fontFamily: 'Causten-Regular',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#7D8898',
                }}
            >
                {text}
            </Typography>
        </Box>

    )
}

