import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_useInternshipPackage = async (values) => {

    const data = JSON.stringify({
        query: `mutation Intern_useInternshipPackage($internshipPackageId: ID!, $internshipInfo: InternshipInfoInput!) {
            intern_useInternshipPackage(internshipPackageId: $internshipPackageId, internshipInfo: $internshipInfo) {
              success
            }
          }`,
        variables: {
            "internshipPackageId": values?.internshipPackageId,
            "internshipInfo": {
                "country": values?.internshipInfo?.country,
                "date": values?.internshipInfo?.date,
                "extraInfo": values?.internshipInfo?.extraInfo,
                "sector": values?.internshipInfo?.internshipArea2,
                "weeklyWorkHour": values?.internshipInfo?.weeklyWorkHour,
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data, values?.accessToken));
        return res?.data?.data?.intern_useInternshipPackage || res?.data?.errors
    } catch (err) {
        return err
    }
}
