import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../../initialConfig";


const initialState = {
  self_internData: null,
  self_internLoading: false,
  self_internError: null,
  onboardedIntern: null
};

export const self_internFunc = createAsyncThunk("self_intern/self_internFunc", async (values) => {

  console.log(values?.highSchool)
  var data1 = !values?.highSchool ? JSON.stringify({
    query: `
    query PersonalInformation {
            self_intern {


              _id
              internOnboardingStatus
              updatedAt



              personalInformation {
                name
                surname
                birthDate
                address
                description
                country {
                  _id
                  name
                  code
                }
                city {
                  _id
                  name
                }
                nationality {
                  _id
                  name
                  code
                }
                gender
                phoneNumber
                profileImageUrl
                backgroundImageUrl
              }



              
              contact {
                phoneNumber
                email
                phoneNumberVerified
                emailVerified
              }





              cv {
                certificates {
                  _id
                  expirationDate
                  issueDate
                  name
                  organization
                }
                coverLetters {
                  _id
                  name
                  content
                  isArchived
                  createdAt
                  updatedAt
                }
                # createdAt
                education {
                  highSchoolEducations {
                    _id
                    name
                    department {
                      _id
                      name
                    }
                    startDate
                    finishDate
                  }
                  universityEducations {
                    _id
                    university {
                      _id
                      name
                    }
                    department {
                      _id
                      name
                    }
                    startDate
                    finishDate
                  }
                }
                experiences {
                  _id
                  companyName
                  position {
                    _id
                    name
                  }
                  positionDetail
                  country {
                    _id
                    name
                    code
                    codeISO3
                    flagUrl
                  }
                  city {
                    _id
                    name
                    code
                  }
                  startDate
                  endDate
                }
                foreignLanguages {
                  _id
                  language {
                    _id
                    name
                    metadata {
                      code
                      nativeName
                    }
                  }
                  level {
                    _id
                    name
                  }
                }
                # isArchived
                name
                skills {
                  _id
                  name
                }
                # updatedAt
              }



            }
          }
    `
  }) : JSON.stringify({
    query: `
    query PersonalInformation {
            self_intern {


              _id
              internOnboardingStatus
              updatedAt



              personalInformation {
                name
                surname
                birthDate
                address
                description
                country {
                  _id
                  name
                  code
                }
                city {
                  _id
                  name
                }
                nationality {
                  _id
                  name
                  code
                }
                gender
                phoneNumber
                profileImageUrl
                backgroundImageUrl
              }



              
              contact {
                phoneNumber
                email
                phoneNumberVerified
                emailVerified
              }





              cv {
                certificates {
                  _id
                  expirationDate
                  issueDate
                  name
                  organization
                }
                coverLetters {
                  _id
                  name
                  content
                  isArchived
                  createdAt
                  updatedAt
                }
                # createdAt
                education {
                  highSchoolEducations {
                    _id
                    name
                    department {
                      _id
                      name
                    }
                    startDate
                    finishDate
                  }
                  universityEducations {
                    _id
                    university {
                      _id
                      name
                    }
                    department {
                      _id
                      name
                    }
                    startDate
                    finishDate
                  }
                }
                foreignLanguages {
                  _id
                  language {
                    _id
                    name
                    metadata {
                      code
                      nativeName
                    }
                  }
                  level {
                    _id
                    name
                  }
                }
                # isArchived
                name
                skills {
                  _id
                  name
                }
                # updatedAt
              }



            }
          }
    `
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config)?.then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const self_intern = createSlice({
  name: "self_intern",
  initialState,
  reducers: {
    setOnboardedIntern: (state, action) => {
      state.onboardedIntern = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(self_internFunc.pending, (state) => {
      state.self_internLoading = true;
    });
    builder.addCase(self_internFunc.fulfilled, (state, action) => {
      state.self_internLoading = false;
      state.self_internData = action.payload?.self_intern;
      state.onboardedIntern = action.payload?.self_intern?.internOnboardingStatus;
      state.self_internError = action.payload.message;
    });
    builder.addCase(self_internFunc.rejected, (state, action) => {
      state.self_internLoading = false;
      state.self_internError = action.error.message;
    });
  }
});


export const { setOnboardedIntern } = self_intern.actions;

export default self_intern.reducer;