import moment from "moment"
import parse from 'html-react-parser'
import { Box, Typography } from "@mui/material"


export const RecentActivityCard = ({ item, len, index, isSideInfo }) => {
    // console.log({ len, index })

    if (isSideInfo) {

        return (
            <Box
                sx={{
                    mb: { xs: '10px' },
                    position: 'relative',
                }}
            >


                <Box
                    sx={{
                        display: 'flex',
                        gap: '4px',
                        alignItems: 'center',
                        // mb: { xs: '8px' },
                    }}
                >

                    <Box
                        sx={{
                            width: '32px',
                            height: '32px',
                            flex: '0 0 32px',
                            borderRadius: '50%',
                            backgroundColor: '#ccc',
                            backgroundImage: `url("${item.user?.displayImage}")`,
                            backgroundSize: 'cover'
                        }}
                    />

                    <Box>
                        <Typography
                            sx={{
                                fontFamily: 'Causten-SemiBold',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#0E1436',
                            }}
                        >
                            {item?.user?.displayName}
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Causten-Regular',
                                fontSize: '10px',
                                lineHeight: '16px',
                                color: '#D1D5D8',
                            }}
                        >
                            {moment(item.createdAt).format("DD MMM YYYY MM:HH")}
                        </Typography>
                    </Box>

                </Box>

                <Box
                    sx={{
                        ml: { xs: '38px' },
                    }}
                >

                    <Box
                        sx={{
                            borderLeft: (len - 1) !== index ? '1px dashed rgba(125, 136, 152, 0.285)' : 'none',
                            position: 'absolute',
                            top: '38px',
                            left: '15px',

                            height: { xs: '100%' }
                        }}
                    />

                    <Typography
                        sx={{
                            fontFamily: 'Causten-Regular',
                            fontSize: '10px',
                            lineHeight: '16px',
                            color: '#70798D',
                        }}
                    >
                        {item?.notificationPreview?.subject}
                    </Typography>

                    <Box
                        sx={{
                            border: "0.5px solid #E3E7F2",
                            borderRadius: "4px",
                            backgroundColor: '#E3E7F2',
                            p: '8px',
                        }}
                    >
                        <Box
                            sx={{

                                fontFamily: 'Causten-Regular',
                                fontSize: '10px',
                                lineHeight: '14px',
                                color: '#191922',
                            }}
                        >
                            {parse(item?.notificationPreview?.content ?? "")}
                        </Box>
                    </Box>

                </Box>


            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',

                mb: { xs: '24px' },


            }}
        >
            <Box
                sx={{
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#7D8898'
                    }}
                />

                {
                    (len - 1) !== index && (
                        <Box
                            sx={{
                                height: "30px",
                                border: '1px dashed #A1ABC5',
                                position: 'absolute',
                                bottom: '-40px',
                                left: '2px',
                            }}
                        />
                    )
                }
            </Box>

            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    flex: '0 0 40px',
                    borderRadius: '50%',
                    // overflow: 'hidden',

                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center',

                    backgroundColor: '#ccc',
                    backgroundImage: `url("${item.user?.displayImage}")`,
                    backgroundSize: 'cover'
                }}
            />

            <Box sx={{ width: '100%' }}>

                <Typography
                    sx={{

                        fontFamily: 'Causten-SemiBold',
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: '#000',

                    }}
                >
                    {item?.user?.displayName}
                </Typography>



                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        flexWrap: 'wrap'
                    }}
                >

                    <Typography
                        sx={{

                            fontFamily: 'Causten-Medium',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#A1ABC5',
                            display: 'inline-block',
                        }}
                    >
                        {/* Added &nbsp; */}
                        {item?.notificationPreview?.subject}
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Causten-Regular',
                            fontSize: '10px',
                            lineHeight: '16px',
                            color: '#D1D5D8',
                        }}
                    >
                        {moment(item.createdAt).format("DD MMM YYYY MM:HH")}
                    </Typography>
                </Box>
                <Box
                    sx={{

                        fontFamily: 'Causten-Bold',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#A1ABC5',
                        display: 'inline-block',
                    }}
                >
                    {parse(item?.notificationPreview?.content ?? "")}
                </Box>

            </Box>

        </Box>
    )
}

// const SideInfo = ({ item, len, index }) => {

//     return (
//         <Box
//             sx={{
//                 mb: { xs: '10px' },
//                 position: 'relative',
//             }}
//         >


//             <Box
//                 sx={{
//                     display: 'flex',
//                     gap: '4px',
//                     alignItems: 'center',
//                     // mb: { xs: '8px' },
//                 }}
//             >

//                 <Box
//                     sx={{
//                         width: '32px',
//                         height: '32px',
//                         flex: '0 0 32px',
//                         borderRadius: '50%',
//                         backgroundColor: '#ccc',
//                         backgroundImage: `url("${item.user?.displayImage}")`,
//                         backgroundSize: 'cover'
//                     }}
//                 />

//                 <Box>
//                     <Typography
//                         sx={{
//                             fontFamily: 'Causten-SemiBold',
//                             fontSize: '12px',
//                             lineHeight: '16px',
//                             color: '#0E1436',
//                         }}
//                     >
//                         {item?.user?.displayName}
//                     </Typography>

//                     <Typography
//                         sx={{
//                             fontFamily: 'Causten-Regular',
//                             fontSize: '10px',
//                             lineHeight: '16px',
//                             color: '#D1D5D8',
//                         }}
//                     >
//                         {moment(item.createdAt).format("DD MMM YYYY MM:HH")}
//                     </Typography>
//                 </Box>

//             </Box>

//             <Box
//                 sx={{
//                     ml: { xs: '38px' },
//                 }}
//             >

//                 <Box
//                     sx={{
//                         borderLeft: (len - 1) !== index ? '1px dashed rgba(125, 136, 152, 0.285)' : 'none',
//                         position: 'absolute',
//                         top: '38px',
//                         left: '15px',

//                         height: { xs: '100%' }
//                     }}
//                 />

//                 <Typography
//                     sx={{
//                         fontFamily: 'Causten-Regular',
//                         fontSize: '10px',
//                         lineHeight: '16px',
//                         color: '#70798D',
//                     }}
//                 >
//                     {item?.notificationPreview?.subject}
//                 </Typography>

//                 <Box
//                     sx={{
//                         border: "0.5px solid #E3E7F2",
//                         borderRadius: "4px",
//                         backgroundColor: '#E3E7F2',
//                         p: '8px',
//                     }}
//                 >
//                     <Box
//                         sx={{

//                             fontFamily: 'Causten-Regular',
//                             fontSize: '10px',
//                             lineHeight: '14px',
//                             color: '#191922',
//                         }}
//                     >
//                         {parse(item?.notificationPreview?.content ?? "")}
//                     </Box>
//                 </Box>

//             </Box>


//         </Box>
//     )
// }
