
import axios from "axios";
import initialConfig from "context/initialConfig";

export const user_subscribeFunc = async (values) => {
    console.log({ values }, "user_subscribeFunc")

    const data = JSON.stringify({
        query: `mutation Mutation($input: SubscribeInput_user!) {
            user_subscribe(input: $input) {
              _id
            }
          }`,
        variables: {
            "input": {
              "channelTarget": values.email,
              "channelType": "email",
              "subscriptionList": {
                "_id": values.listId
                // "_id": "66053aaff4973ff3e354374b"
              }
            }
          }
    })


    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/notification/graphql', data, values?.accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data
    } catch (err) {
        return err
    }

}