import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_failAssignedTask = async ({ accessToken, assignTaskId, rate }) => {

    const data = JSON.stringify({
        query: `mutation companyAdmin_failAssignedTask($filter: CompleteAssignedTaskByAssignerFilterInput!, $input: CompleteAssignedTaskByAssignerInput!) {
          companyAdmin_failAssignedTask(filter: $filter, input: $input) {
            _id
          }
        }`,
        variables: {
            "filter": {
              "id": assignTaskId
            },
            "input": {
              "evaluations": [
                {
                  "questionId": "1",
                  "rate": rate
                }
              ]
            }
          }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.companyAdmin_failAssignedTask
    } catch (err) {
        return err
    }
}
