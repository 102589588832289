import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import { ChatNav, ChatMessageList, ChatMessageInput, ChatHeaderDetail } from './sections';

const ChatView = ({ user }) => {

  return (
    <Stack component={Card} direction="row" sx={{ height: '72vh' }}>

      <ChatNav user={user} />

      <Stack
        sx={{
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          flexShrink={0}
          sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72 }}
        >
          <ChatHeaderDetail />
        </Stack>

        <Stack
          direction="row"
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
            borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          <Stack
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
            }}
          >
            <ChatMessageList />
            <ChatMessageInput />
          </Stack>

        </Stack>
      </Stack>
    </Stack>
  );
}

export { ChatView }