import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyUser_updateCompanyUserOnboardingStatusFunc = async ({ values, accessToken }) => {

  const data = JSON.stringify({
    query: `mutation CompanyUser_updateCompanyUserOnboardingStatus($status: CompanyUserOnboardingStatus!) {
          companyUser_updateCompanyUserOnboardingStatus(status: $status) {
            success
          }
        }`,
    variables: {
      "status": "completed"
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    return res?.data?.data?.companyUser_updateCompanyUserOnboardingStatus
  } catch (err) {
    return err
  }
}