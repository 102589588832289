import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box, Grid, Typography, useMediaQuery, Card, Divider, Button, Stack, Tab, Tabs,
    Rating, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material'

import { ApplicationCard, AnimatedTitle, AnimatedMappingCard, TaskCardForIntern } from 'components'
import { ApplicationDetailDrawer, TaskDetailDrawer } from 'page-sections'
import { StaticDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';

import { DashboardLayout } from 'layouts'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { East } from '@mui/icons-material'
import Iconify from 'minimal/components/iconify'
import {
    intern_packagesFunc, intern_assignedTasksFunc, intern_joinedInternshipsFunc,
} from 'context'
import { intern_acceptAssignedTask } from 'context/task/mutation/intern/intern_acceptAssignedTask';
import { intern_addCommentToAssignTask } from 'context/task/mutation/intern/intern_addCommentToAssignTask';
import { intern_addAttachmentToAssignTask } from 'context/task/mutation/intern/intern_addAttachmentToAssignTask';
import { enqueueSnackbar } from 'notistack'
import { fileUpload } from 'utils/helpers/_fileUpload'
import { intern_evaluateTask } from 'context/task/mutation/intern/intern_evaluateTask';
import { intern_completeAssignedTask } from 'context/task/mutation/intern/intern_completeAssignedTask';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import moment from 'moment'

export const CalendarComponent = ({ events }) => {
    // const backgroundEvents = [
    //     {
    //         start: '2023-06-16', // Başlangıç tarihi
    //         end: '2023-06-18', // Bitiş tarihi
    //         display: 'background', // Arka plan olarak göster
    //         backgroundColor: 'red', // Arka plan rengi
    //     },
    //     {
    //         start: '2023-06-19',
    //         end: '2023-06-20',
    //         display: 'background',
    //         backgroundColor: 'blue',
    //     }
    // ];

    return (
        <FullCalendar
            height={'auto'}
            headerToolbar={{
                right: 'prev,next',
                left: 'title',
            }}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
            events={events}
        />
    );
};


const MyInternships = () => {

    const dispatch = useDispatch()
    const { userRole } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [selectedTabSectionValue, setSelectedTabSectionValue] = useState(0);
    const bottomRef = useRef(null);
    const { intern_applicationsData: applications } = useSelector(state => state.intern_applications)
    const { intern_assignedTasksData: tasks, intern_assignedTasksCount: tasksCount, intern_assignedTasksLoading: tasksLoading } = useSelector(state => state.intern_assignedTasks)
    const { intern_packagesCount: packagesCount } = useSelector(state => state.intern_packages)
    const { intern_joinedInternshipsData: joinedInternships, intern_joinedInternshipsCount: joinedInternshipsCount, intern_joinedInternshipsLoading: joinedInternshipsLoading } = useSelector(state => state.intern_joinedInternships)
    const { accessToken } = useSelector(state => state.user);
    const [value, setValue] = useState(null);
    const [selectedItemForAccept, setSelectedItemForAccept] = useState(null);
    const [selectedItemForDetail, setSelectedItemForDetail] = useState(null);
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [ongoingTasks, setOngoingTasks] = useState(null)
    const [ongoingCount, setOngoingCount] = useState(0)
    const [completedTasks, setCompletedTasks] = useState([])
    const [completedCount, setCompletedCount] = useState(0)
    const [selectedItemForEvaluate, setSelectedItemForEvaluate] = useState(null)
    const [ratingValue, setRatingValue] = useState(0);
    const [events, setEvents] = useState([])

    const getTasks = useCallback(() => {
        dispatch(intern_assignedTasksFunc({ accessToken, status: ["pending", "accepted", "waitingForReview"] })).then(res => {
            setOngoingTasks(res?.payload?.intern_assignedTasks?.data)
            setOngoingCount(res?.payload?.intern_assignedTasks?.count)
        }).then(() => {
            dispatch(intern_assignedTasksFunc({ accessToken, status: ["completed"] })).then(res => {
                setCompletedTasks(res?.payload?.intern_assignedTasks?.data)
                setCompletedCount(res?.payload?.intern_assignedTasks?.count)
            })
        })
    }, []);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    console.log(ongoingTasks)

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ py: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const handleSetSelectedTabSectionValue = (event, newValue) => {
        setSelectedTabSectionValue(newValue);
    };

    const handleAcceptTask = (item) => {
        console.log(item, "item");
        setSelectedItemForAccept(item);
    }

    const handleDetailTask = (item) => {
        setSelectedItemForDetail(item);
    }

    const handleAcceptTaksAccept = async () => {
        try {
            const acceptTask = await intern_acceptAssignedTask({ accessToken, taskId: selectedItemForAccept?._id })

            if (acceptTask) {
                enqueueSnackbar(t("Task accepted successfully."), { variant: 'success' })
                setSelectedItemForAccept(null)
                getTasks()
            }
        } catch (err) {
            enqueueSnackbar(t("An error occurred while accepting the task."), { variant: 'error' })
        }
    }

    const handleAddComment = async (commentContent, setCommentContent) => {

        try {
            const addComment = await intern_addCommentToAssignTask({ accessToken, taskId: selectedItemForDetail?._id, content: commentContent })

            if (addComment) {
                enqueueSnackbar(t("Comment added successfully."), { variant: 'success' })
                getTasks()
                setSelectedItemForDetail(null)
                setCommentContent("")
            }
        } catch (err) {
            enqueueSnackbar(t("An error occurred while adding the comment."), { variant: 'error' })
        }
    }

    const handleSendAttachment = async (attachmentFile) => {
        try {
            setLoadingUpload(true)
            const urls = await fileUpload({ accessToken, presignedType: "commentFile", file: attachmentFile, fileType: "pdfFile", fileName: attachmentFile?.name })


            const addAttachment = await intern_addAttachmentToAssignTask({ accessToken, taskId: selectedItemForDetail?._id, attachment: urls?.attachment })

            if (addAttachment) {
                enqueueSnackbar(t("Attachment added successfully"), { variant: 'success' })
                setSelectedItemForDetail(null)
                setLoadingUpload(false)
                getTasks()
            }
        }
        catch {
            enqueueSnackbar(t("An error occurred while adding the attachment."), { variant: 'error' })
            setSelectedItemForDetail(null)
            setLoadingUpload(false)
        }
    }

    const handleEvaluateAndCompleteTask = async () => {
        try {

            const completeTask = await intern_completeAssignedTask({ accessToken, taskId: selectedItemForEvaluate?._id })

            if (completeTask) {


                setSelectedItemForEvaluate(null)
                setSelectedItemForDetail(null)
                enqueueSnackbar(t("Task completed successfully."), { variant: 'success' })
                getTasks()

            }
        } catch (err) {
            enqueueSnackbar(t("An error occurred while completing the task."), { variant: 'error' })
        }
    }



    useEffect(() => {
        if (accessToken) {
            getTasks()
            dispatch(intern_packagesFunc({ accessToken }))
            dispatch(intern_joinedInternshipsFunc({ accessToken }))
        }
    }, [accessToken, dispatch])

    useEffect(() => {
        if (!tasksLoading) {
            console.log(tasks, tasksLoading);
        }
    }, [tasks, tasksLoading]);


    useEffect(() => {
        if (ongoingTasks) {
            const formattedEvents = ongoingTasks.map(item => ({
                // title: item.task.name,
                start: moment(item.task.startDate).format('YYYY-MM-DD'),
                // tarihin bir gün sonrasını seç
                end: moment(item.task.dueDate).add(1, 'days').format('YYYY-MM-DD'),
                backgroundColor: 'orange', // İstediğiniz renk
                display: 'background'
            }));
            // formmattedEventsin sonuna 
            // [
            //     {
            //         start: '2023-06-16', // Başlangıç tarihi
            //         end: '2023-06-18', // Bitiş tarihi
            //         display: 'background', // Arka plan olarak göster
            //         backgroundColor: 'red', // Arka plan rengi
            //     },
            //     {
            //         start: '2023-06-19',
            //         end: '2023-06-20',
            //         display: 'background',
            //         backgroundColor: 'blue',
            //     }
            // ]
            // bunları ekleyerek setEventse atabilirsiniz.
            setEvents(formattedEvents.concat([]))
        }
    }, [ongoingTasks])

    return <DashboardLayout >
        {joinedInternshipsCount > 0 ? <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} >
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    <img src={joinedInternships?.[0]?.company?.displayImageUrl} alt="company logo" style={{ width: "45px", height: "45px", borderRadius: "23px" }} />
                    <Typography variant="h4" fontWeight={"regular"} textAlign={"center"}>
                        {joinedInternships?.[0]?.company?.displayName}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} >
                <AnimatedTitle title={joinedInternships?.[0]?.positionName} />
            </Grid>

            <Grid item xs={12} lg={8} xl={9} mt={2} >
                <Grid container spacing={2} mb={5} >
                    <Grid item xs={userRole === "intern" ? 4 : 6} sx={{ cursor: 'pointer' }} >
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="pajamas:work" width={150} />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="pajamas:work" fontSize="30px" />
                                </Box>}
                                <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"}>
                                    {t("Completed Tasks")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} textAlign={{ xs: 'center', sm: 'left' }}>
                                {completedCount ?? 0}
                            </Typography>
                        </Box>
                    </Grid>
                    {userRole === "intern" && <Grid item xs={4} sx={{ cursor: 'pointer' }}>
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="jam:task-list" width={150} />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="jam:task-list" fontSize="30px" />
                                </Box>}
                                <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"} >
                                    {t("Ongoing Tasks")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} textAlign={{ xs: 'center', sm: 'left' }}>
                                {ongoingCount ?? 0}
                            </Typography>
                        </Box>
                    </Grid>}
                    <Grid item xs={userRole === "intern" ? 4 : 6} sx={{ cursor: 'pointer' }}>
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="solar:crown-linear" width={150} />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="solar:crown-linear" fontSize="30px" />
                                </Box>}
                                <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"} >
                                    {t("Remaining Days")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} textAlign={{ xs: 'center', sm: 'left' }}>
                                {new Date() > new Date('2024-07-06') ? 0 : moment('2024-07-06').diff(moment(), 'days')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        borderBottom: 1, borderColor: 'divider', backgroundColor: '#fff',
                        position: 'sticky', top: { xs: 60, sm: 70, lg: 10 }, zIndex: 3, width: '100%',
                    }}
                >
                    <Tabs value={selectedTabSectionValue} onChange={handleSetSelectedTabSectionValue}
                        style={{ marginBottom: "-7px" }}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            style: {
                                bottom: 7,
                                backgroundColor: "#FF9737",
                                height: "4px",
                                borderRadius: "10px",
                            }
                        }}>
                        <Tab label={t("Ongoing Tasks")} {...a11yProps(0)} />
                        <Tab label={t("Completed Tasks")} {...a11yProps(1)} />
                    </Tabs>
                </Box>

                {/* ***********************  ongoing tasks ****************** */}
                <CustomTabPanel value={selectedTabSectionValue} index={0} p={0}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0, m: 0 }}  >
                        {ongoingTasks?.length > 0 && ongoingTasks?.map((item, index) => (
                            <TaskCardForIntern
                                item={item}
                                key={index}
                                handleDetailTask={handleDetailTask}
                                handleAcceptTask={handleAcceptTask}
                            />
                        ))}
                        {ongoingTasks?.length === 0 &&
                            <Typography variant="h6" color={"gray"} textAlign={"center"} mb={1} >No task found!</Typography>}
                    </Box>
                </CustomTabPanel>

                {/* ***********************  suitable internships ****************** */}
                <CustomTabPanel value={selectedTabSectionValue} index={1}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0, m: 0 }}  >
                        {completedTasks?.length > 0 && completedTasks?.map((item, index) => (
                            <TaskCardForIntern
                                item={item}
                                key={index}
                                handleDetailTask={handleDetailTask}
                                handleAcceptTask={handleAcceptTask}
                            />
                        ))}
                        {completedTasks?.length === 0 &&
                            <Typography variant="h6" color={"gray"} textAlign={"center"} mb={1} >No task found!</Typography>}
                    </Box>


                </CustomTabPanel>



            </Grid>

            {upLg && <Grid item xs={12} lg={4} xl={3} mt={2}>
                <Card sx={{
                    backgroundColor: "#F8F8F8", border: '1px solid #E0E0E0',
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                    minWidth: 320,
                    p: 1,
                    height: '100%'
                }}>
                    <CalendarComponent events={events} />
                </Card>
            </Grid>}
        </Grid> : joinedInternshipsCount === 0 ? <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} >
                <AnimatedTitle title={`${t("My Internships")}`} />
            </Grid>

            <Grid item xs={12} lg={8} xl={9} mt={2} >
                <Grid container spacing={2} mb={5} >
                    <Grid item xs={userRole === "intern" ? 4 : 6} sx={{ cursor: 'pointer' }} >
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="pajamas:work" width={150} />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="pajamas:work" fontSize="30px" />
                                </Box>}
                                <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"}>
                                    {t("My Internships")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} textAlign={{ xs: 'center', sm: 'left' }}>
                                0
                            </Typography>
                        </Box>
                    </Grid>
                    {userRole === "intern" && <Grid item xs={4} onClick={() => navigate('/applications')} sx={{ cursor: 'pointer' }}>
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="jam:task-list" width={150} />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="jam:task-list" fontSize="30px" />
                                </Box>}
                                <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"} >
                                    {t("My Applications")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} textAlign={{ xs: 'center', sm: 'left' }}>
                                {applications?.count ?? 0}
                            </Typography>
                        </Box>
                    </Grid>}
                    <Grid item xs={userRole === "intern" ? 4 : 6} onClick={() => navigate('/premium')} sx={{ cursor: 'pointer' }}>
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="solar:crown-linear" width={150} />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="solar:crown-linear" fontSize="30px" />
                                </Box>}
                                <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"} >
                                    {t("My Premium Packages")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} textAlign={{ xs: 'center', sm: 'left' }}>
                                {packagesCount}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>


                <Typography variant="h6" fontWeight={"regular"} mb={1} >
                    {t("You do not have an active internship yet.")}<br /><br />
                </Typography>
                {userRole !== "highSchoolIntern" && <Typography variant="h6" fontWeight={"regular"} mb={2}>
                    <East sx={{ mb: -0.8, mr: 1 }} /> {t("Apply for internships on the ")}<strong><Link to="/internship" style={{ color: "#ff9737", textDecoration: 'underline', textDecorationColor: 'black', borderRadius: 10, padding: 5 }} >{t("internship search page.")}</Link></strong>
                </Typography>}
                <Typography variant="h6" fontWeight={"regular"} mb={2}>
                    <East sx={{ mb: -0.8, mr: 1 }} /> {t("Purchase a guaranteed internship placement service with the ")} <strong>
                        <Link to='/premium' style={{ color: "#ff9737", textDecoration: 'underline', textDecorationColor: 'black', borderRadius: 10, padding: 5 }} >{t("InternIF Premium Package.")}</Link></strong>
                </Typography>
            </Grid>

            {upLg && <Grid item xs={12} lg={4} xl={3} mt={2}>
                <Card sx={{
                    padding: 3, backgroundColor: "#F8F8F8", border: '1px solid #E0E0E0',
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                }}>
                    <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                        <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >
                            <strong> {t("Tips")}</strong></Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body1" fontSize={"small"} fontWeight={"regular"} mb={2}>
                            🌟    {t("What kind of screen will you encounter when your internship starts?")}
                        </Typography>
                        <Button variant='outlined' color='primary' size='large' fullWidth
                            onClick={() => navigate('/my-internship')}
                        >{t("Demo Internship")}</Button>
                    </Box>
                </Card>
            </Grid>}
        </Grid> : null}

        <TaskDetailDrawer
            task={selectedItemForDetail}
            internship={joinedInternships?.[0]}
            handleCloseDetail={() => setSelectedItemForDetail(null)}
            handleAddComment={handleAddComment}
            handleSendAttachment={handleSendAttachment}
            loadingUpload={loadingUpload}
            setSelectedItemForEvaluate={setSelectedItemForEvaluate}
        />


        <Dialog open={selectedItemForAccept !== null} onClose={() => setSelectedItemForAccept(null)} fullWidth>
            <DialogTitle>
                {t("Accept Task")}: {selectedItemForAccept?.task?.name}
            </DialogTitle>
            <DialogContent>
                {t("You have started the task. Do you want to accept it?")}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedItemForAccept(null)} color="primary" variant='outlined'>
                    {t("Cancel")}
                </Button>
                <Button onClick={() => {
                    handleAcceptTaksAccept()
                    setSelectedItemForAccept(null)
                }} color="secondary" variant='contained'>
                    {t("Accept")}
                </Button>
            </DialogActions>
        </Dialog>


        <Dialog open={selectedItemForEvaluate !== null}
            onClose={() => {
                setRatingValue(0)
                setSelectedItemForEvaluate(null)
            }}
            fullWidth>
            <DialogTitle sx={{ textAlign: 'center' }}>
                {t("Complete Task")}
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" sx={{ textAlign: "center" }} mb={2}>
                    {t("You are about to complete your task process!")}<br />
                    {/* {t("If you want to rate this task out of 5, how many points would you give?")}<br /><br />
                    <Rating
                        name="simple-controlled"
                        size='large'
                        value={ratingValue}
                        onChange={(event, newValue) => {
                            setRatingValue(newValue);
                        }}
                    /> */}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setRatingValue(0)
                    setSelectedItemForEvaluate(null)
                }} color="primary" variant='outlined'>
                    {t("Cancel")}
                </Button>
                <Button
                    onClick={() => {
                        handleEvaluateAndCompleteTask()
                        setSelectedItemForEvaluate(null)
                    }} color="secondary" variant='contained'>
                    {t("Complete Task")}
                </Button>
            </DialogActions>
        </Dialog>

    </DashboardLayout>
}

export default MyInternships