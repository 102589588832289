import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Stack, Drawer, AppBar, Toolbar, IconButton } from '@mui/material'
import { useResponsive } from "hooks"
import { usePathname } from 'minimal/routes/hook';
import { useBoolean } from 'minimal/hooks/use-boolean';
import { useNavData } from '../components/navigations/ConfigNavigations';
import MenuIcon from "@mui/icons-material/Menu";
import { InternifLogoOnly, InternifLogoWithText, InternIFLogo } from "assets";
import { PageOpening, InformationDrawer } from "components";
import { removeUser } from "context/common/user";
import { setOnboardedIntern } from "context";
import { useNavigate } from "react-router";
import NavToggleButton from "../components/navigations/NavToggleButton";
import NavMini from "../components/navigations/NavMini";
import NavDefault from "components/navigations/NavDefault";
import { user_userNotificationCountFunc } from "context";

export const DashboardLayout = ({ children, info }) => {


  const lgUp = useResponsive('up', 'lg');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userRole, remember, accessToken } = useSelector((state) => state.user);
  const nav = useBoolean();
  const { self_companyUserData } = useSelector((state) => state.self_companyUser);
  const { self_internData } = useSelector((state) => state.self_intern);
  const { isNavigationMini } = useSelector((state) => state.theme);
  const navData = useNavData();
  const pathname = usePathname();
  const [anchorEl, setAnchorEl] = useState(null);
  const openNav = nav?.value;
  const open = Boolean(anchorEl);
  const { user_userNotificationsCount: notificationCount, user_userNotificationsData, user_userNotificationsError } = useSelector(state => state.user_userNotifications)

  const onCloseNav = nav.onFalse

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
    setAnchorEl(null);
  }

  const handleAccount = () => {
    handleNavigation('/my-account');
  };

  const handleMembership = () => {
    handleNavigation('/membership');
  }

  const handleCompany = () => {
    handleNavigation('/my-company');
  }

  const handleNotepad = () => {
    handleNavigation('/notepad');
  }

  const handleResume = () => {
    handleNavigation('/my-resume');
  }

  const handleCoverLetters = () => {
    handleNavigation('/my-cover-letters');
  }

  const handleSettings = () => {
    handleNavigation('/settings');
  }

  const handleOrders = () => {
    handleNavigation('/my-orders');
  }

  const handleLogout = () => {
    handleClose();
    dispatch(removeUser(remember));
    dispatch(setOnboardedIntern(null));
    navigate('/');
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname, openNav, onCloseNav]);


  useEffect(() => {

    accessToken && dispatch(user_userNotificationCountFunc({ accessToken: accessToken }))

    const interval = setInterval(() => {
      dispatch(user_userNotificationCountFunc({ accessToken: accessToken }))
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch, accessToken]);


  return (
    <Grid container padding={2} spacing={{ xs: 0, md: 2 }}>

      {/* {process.env.REACT_APP_TEST === "true" && info && <InformationDrawer info={info} />} */}

      <Box
        component="nav"
        sx={{ flexShrink: { lg: 0 }, width: { lg: !isNavigationMini ? 280 : 120 } }}
      >

        {!isNavigationMini && <NavToggleButton />}

        {lgUp && !isNavigationMini ? (
          <NavDefault handleClick={handleClick}
            userRole={userRole}
            self_companyUserData={self_companyUserData}
            self_internData={self_internData}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            handleAccount={handleAccount}
            handleMembership={handleMembership}
            handleCompany={handleCompany}
            handleNotepad={handleNotepad}
            handleCoverLetters={handleCoverLetters}
            handleSettings={handleSettings}
            handleResume={handleResume}
            handleOrders={handleOrders}
            handleLogout={handleLogout}
            navData={navData}
          />
        ) :
          lgUp && isNavigationMini ? (
            <NavMini />
          )
            : (
              <>
                {/* AppBar */}
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer - 1, boxShadow: 1 }} color="inherit" height={60} >
                  <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Stack display="flex" flexDirection="row" justifyContent='flex-end' my={1} borderRadius={2} spacing={0.5}>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { lg: "none" } }}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Stack>

                    <Box height={35} >
                      {/* <InternifLogoWithText /> */}
                      <img src={InternIFLogo} alt="Internif Logo" style={{height: 32 }} />
                    </Box>

                    <IconButton hidden />


                  </Toolbar>
                </AppBar>

                {/* Drawer */}
                <Drawer
                  variant="temporary"
                  anchor="left"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  sx={{
                    marginTop: 80,
                    width: 280,
                    flexShrink: { lg: 0 },
                    '& .MuiDrawer-paper': {
                      boxSizing: 'border-box',
                      width: 280,
                      backgroundColor: '#F9FBFD',
                    },
                  }}
                >
                  <NavDefault handleClick={handleClick}
                    userRole={userRole}
                    self_companyUserData={self_companyUserData}
                    self_internData={self_internData}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleAccount={handleAccount}
                    handleCompany={handleCompany}
                    handleResume={handleResume}
                    handleNotepad={handleNotepad}
                    handleMembership={handleMembership}
                    handleSettings={handleSettings}
                    handleLogout={handleLogout}
                    handleCoverLetters={handleCoverLetters}
                    navData={navData}
                  />

                </Drawer>
              </>
            )


        }


      </Box>

      <Grid item xs={12} lg={!isNavigationMini ? 9 : 10}>
        <PageOpening>
          {children}
        </PageOpening>
      </Grid>

    </Grid>
  );

}