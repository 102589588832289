import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_startTask = async ({ accessToken, assignTaskId }) => {

    const data = JSON.stringify({
        query: `mutation CompanyAdmin_startTask($filter: StartTaskFilterInput!) {
  companyAdmin_startTask(filter: $filter) {
    _id
  }
}`,
        variables: {
          "filter": {
            "id": assignTaskId
          }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.companyAdmin_startTask
    } catch (err) {
        return err
    }
}
