import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_submitRevisionInternshipPackage = async (values) => {

    const data = JSON.stringify({
        query: `mutation Intern_submitRevisionInternshipPackage($internshipPackageId: ID!, $revisionSubmitDetail: String!) {
            intern_submitRevisionInternshipPackage(internshipPackageId: $internshipPackageId, revisionSubmitDetail: $revisionSubmitDetail) {
              success
            }
          }`,
        variables: {
            "internshipPackageId": values?.internshipPackageId,
            "revisionSubmitDetail": values?.revisionSubmitDetail
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data, values?.accessToken));
        return res?.data?.data?.intern_submitRevisionInternshipPackage
    } catch (err) {
        return err
    }
}
