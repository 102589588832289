
export const InternshipGoalForIntern = {
  ProfessionalExperience: {
    name: "Professional Experience",
    value: "ProfessionalExperience",
  },
  SkillDevelopment: {
    name: "Skill Development",
    value: "SkillDevelopment",
  },
  IndustryExposure: {
    name: "Industry Exposure",
    value: "IndustryExposure",
  },
  NetworkingOpportunities: {
    name: "Networking Opportunities",
    value: "NetworkingOpportunities",
  },
  CareerExploration: {
    name: "Career Exploration",
    value: "CareerExploration",
  },
  ResumeEnhancement: {
    name: "Resume Enhancement",
    value: "ResumeEnhancement",
  },
  MentorshipAndGuidance: {
    name: "Mentorship and Guidance",
    value: "MentorshipAndGuidance",
  },
  ConfidenceBuilding: {
    name: "Confidence Building",
    value: "ConfidenceBuilding",
  },
  PersonalGrowth: {
    name: "Personal Growth",
    value: "PersonalGrowth",
  },
  ReferencesAndRecommendations: {
    name: "References and Recommendations",
    value: "ReferencesAndRecommendations",
  },
  UnderstAndingCompanyCulture: {
    name: "Understanding Company Culture",
    value: "UnderstAndingCompanyCulture",
  },
  ProjectOwnership: {
    name: "Project Ownership",
    value: "ProjectOwnership",
  },
  TeamworkExperience: {
    name: "Teamwork Experience",
    value: "TeamworkExperience",
  },
  UnderstAndingJobExpectations: {
    name: "Understanding Job Expectations",
    value: "UnderstAndingJobExpectations",
  },
  FutureEmploymentOpportunities: {
    name: "Future Employment Opportunities",
    value: "FutureEmploymentOpportunities",
  },
  ProblemSolvingAbilities: {
    name: "Problem Solving Abilities",
    value: "ProblemSolvingAbilities",
  },
  EarningPotential: {
    name: "Earning Potential",
    value: "EarningPotential",
  },
  CulturalExposure: {
    name: "Cultural Exposure",
    value: "CulturalExposure",
  },
  FeedbackAndEvaluation: {
    name: "Feedback and Evaluation",
    value: "FeedbackAndEvaluation",
  },
  DiscoveringStrengthsAndInterests: {
    name: "Discovering Strengths and Interests",
    value: "DiscoveringStrengthsAndInterests",
  },
};

export const InternshipGoalForInternList = Object.values(InternshipGoalForIntern);

export const InternshipGoalForInternValueList = Object.values(InternshipGoalForIntern).map(
  (period) => period.value
);

export const InternshipGoalForInternNameList = Object.values(InternshipGoalForIntern).map(
  (period) => period.name
);

export function getValueOfInternshipGoalForIntern(name) {
  for (const key of Object.keys(InternshipGoalForIntern)) {
    if (InternshipGoalForIntern[key].name === name) {
      return InternshipGoalForIntern[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipGoalForIntern(value) {
  for (const key of Object.keys(InternshipGoalForIntern)) {
    if (InternshipGoalForIntern[key].value === value) {
      return InternshipGoalForIntern[key].name;
    }
  }
  return null;
}
