import { Box } from "@mui/material";
import DefaultBackground from 'assets/images/default-background.png';


export const ResumeBackgroundImage = ({ image }) => {
    return <Box sx={{ width: '100%', height: "150px", position: 'relative' }}>
        <img src={image ?? DefaultBackground}
            alt="my account background"
            style={{
                width: "100%", height: "150px", objectFit: "cover", zIndex: "-1", borderTopLeftRadius: "20px", borderTopRightRadius: "20px",
            }}
        />
    </Box>
}

