import React from 'react'
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';



export const NoItemsPlaceHolder = ({ text, onlyText, onlyIcon }) => {
    return (
        <Box
            sx={{
                width: '100%',
                textAlign: 'center',
                py: {
                    xs: !onlyText ? '30px' : '0px'
                },
            }}
        >
            {
                !onlyText && (
                    <ErrorOutlineIcon
                        sx={{
                            width: '100px',
                            height: '100px',
                            color: '#D1D5D8',
                        }}
                    />
                )
            }
            <Typography
                sx={{
                    fontFamily: 'Causten-Medium',
                    color: '#D1D5D8',
                    fontSize: '20px',
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}

NoItemsPlaceHolder.prototype = {
    text: PropTypes.string.isRequired,
    onlyText: PropTypes.string
}
