import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";

const initialState = {
    orderData: null,
    orderLoading: false,
    orderError: null,
};

export const orderFunc = createAsyncThunk("order/orderFunc", async (values) => {
    const data1 = JSON.stringify({
        query: `query Order($orderId: ID!) {
                order(orderId: $orderId) {
                  orderStatus
                }
              }`,
        variables: {
            "orderId": values?.orderId
        }
    });

    const config = initialConfig(process.env.REACT_APP_BASE_URL + "/payment/graphql", data1, values?.accessToken);

    const response = axios(config)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => {
            console.log("err:", err);
            return err;
        });

    const data = await response;
    return data;
}
);

const order = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(orderFunc.pending, (state) => {
            state.orderLoading = true;
        });
        builder.addCase(orderFunc.fulfilled, (state, action) => {
            state.orderLoading = false;
            state.orderData = action.payload?.order;
            state.orderError = action?.payload?.message;
        });
        builder.addCase(orderFunc.rejected, (state, action) => {
            state.orderLoading = false;
            state.orderError = action.error.message;
        });
    },
});

export default order.reducer;
