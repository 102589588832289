import Confetti from 'react-confetti'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

// import TaskDetail from '../taskCardDetails/TaskDetail';

import { NoItemsPlaceHolder, TaskCard } from 'components';
// import { taskDetailForCompanyFunc } from 'redux/index';
// import { useAuthContextSingleApp } from 'contexts/authContext/authContextSingleApp';
// import { useParams } from 'react-router-dom';
// import { ResponsiveDrawer } from 'components';

// import { AlertPopup } from 'components';
// import { useWindowDimensions } from 'hooks';
// import { useDashBoardContext } from 'layouts/dashboardLayout/DashboardContext/DashboardContext';

// import { updateAssigneeTaskStatusForInternFunc } from 'redux/index';


const selectedTask = null

export const TasksListSection = ({ filteredTasks, tasks }) => {


    const isMobile = useMediaQuery('(max-width:600px)')
    const isDesktop = useMediaQuery('(min-width:900px)')

    const tabMenuList = [
        {
            id: '1',
            label: `${isMobile ? "Ongoing" : "Ongoing Tasks"}`,
            value: "inprogress",
            index: 0,
        },
        {
            id: '2',
            label: `${isMobile ? "Completed" : "Completed Tasks"}`,
            value: "completed",
            index: 1,
        },
    ]



    const { id } = useParams()
    const dispatch = useDispatch()
    const [taskDetailDrawer, setTaskDetailDrawer] = useState(false)
    const [completeTastPoupup, setCompleteTastPoupup] = useState(false)

    // const { width } = useWindowDimensions()
    // const { dashboardSideBarWidth } = useDashBoardContext()

    // const { taskDetailForCompanyData: selectedTask } = useSelector(state => state.taskDetailForCompany)





    // ************** tab menu ******************
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue, e) => {
        setTabValue(newValue);
    };

    // ************** filter tasks when tab changed ******************
    const [filteredTasksList, setFilteredTasksList] = useState([])
    useEffect(() => {
        setFilteredTasksList(tasks?.filter((item) => item.status === (tabValue ? "completed" : "inprogress")))
    }, [tabValue, tasks])





    const handleClickToTask = async (task) => {
        console.log(task)
    }

    const [isConfeti, setIsConfeti] = useState(false)
    const handleTaskDone = () => {
        console.log({ selectedTask })

    }

    return (
        <>

            <>
                <Confetti
                    width={isDesktop ? 250 : 20}
                    numberOfPieces={isConfeti ? 500 : 0}
                    wind={0.00}
                    gravity={0.05}
                />

                {/* <AlertPopup
                    open={completeTastPoupup}
                    handleClose={() => setCompleteTastPoupup(false)}
                    handleClick={() => handleTaskDone()}
                    buttonText="Complete"
                    questionText="Do you want to complete this task?"
                /> */}

                {/* <ResponsiveDrawer
                    openDrawer={taskDetailDrawer}
                    setOpenDrawer={setTaskDetailDrawer}

                    sx={{
                        width: { xs: '80%', },
                        display: { xs: "none", sm: "block" },
                    }}
                >
                    <TaskDetail
                        setTaskDetailDrawer={setTaskDetailDrawer}
                        handleCompleteTask={() => setCompleteTastPoupup(true)}
                    />
                </ResponsiveDrawer> */}

            </>

            <Box className="tab_menu_container">

                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{ mb: '30px' }}
                    aria-label="basic tabs example"
                    style={{ marginBottom: "-7px" }}
                    TabIndicatorProps={{
                        style: {
                            bottom: 7,
                            backgroundColor: "#FF9737",
                            height: "4px",
                            borderRadius: "10px",
                        },
                    }}
                >
                    {
                        tabMenuList?.map((item, index) => {
                            return (
                                <Tab
                                    key={item?.id}
                                    label={item.label}
                                    index={item.index}
                                />
                            )
                        })
                    }

                </Tabs>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px'
                    }}
                >
                    {
                        filteredTasksList?.length ?
                            (
                                filteredTasksList?.map((task, index) => {

                                    return (
                                        <TaskCard
                                            noEdit
                                            task={task}
                                            key={task?._id ?? index}
                                            // handleEditTask={() => handleEditTask(task)}
                                            // handleClickToTask={() => handleClickToTask(task)}
                                            handleTaskDone={() => setCompleteTastPoupup(task)}
                                            handleOpenTaskDetail={() => setTaskDetailDrawer(task)}
                                        />
                                    )
                                })

                            ) : (

                                <NoItemsPlaceHolder
                                    text="Didn't post any task!"
                                />

                            )
                    }
                </Box>

            </Box>

        </>
    )
}
