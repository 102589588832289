import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyUser_updateCompanyUserFunc = async ({ values, accessToken, profileImage, backgroundImage }) => {

  const data = JSON.stringify({
    query: `mutation CompanyUser_updateCompanyUser($updateInput: UpdateUserInput!) {
            companyUser_updateCompanyUser(updateInput: $updateInput) {
              _id
            }
          }`,
    variables: {
      "updateInput": {
        "personalInformation": {
          "name": values.name,
          "surname": values.surname,
          "description": values.description,
          "birthDate": values.birthDate,
          "profileImageUrl": profileImage,
          "backgroundImageUrl": backgroundImage,
          "phoneNumber": values.phoneNumber,
          "address": values.address,
          "country": {
            "_id": values.country
          },
          "city": {
            "_id": values.city
          },
          "nationality": {
            "_id": values.nationality
          },
          "gender": values.gender
        }
      }
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    return res?.data?.data?.companyUser_updateCompanyUser
  } catch (err) {
    return err
  }
}