import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";

const initialState = {
  self_user_billingInfoData: null,
  self_user_billingInfoLoading: false,
  self_user_packagesData: null,
  self_user_billingInfoError: null,
};

export const self_user_billingInfoFunc = createAsyncThunk(
  "self_user_billingInfo/self_user_billingInfoFunc",
  async (values) => {
    const data1 = JSON.stringify({
      query: `query Self_user {
              self_user {
                _id
                invoiceInfo {
                  personal {
                    address
                    city
                    country
                    email
                    id
                    phoneNumber
                    name
                    surname
                    zip
                  }
                  type
                }
                packages {
                  standard {
                    total
                    usable
                  }
                  special {
                    total
                    usable
                  }
                  pro {
                    total
                    usable
                  }
                  coffee {
                    total
                    usable
                  }
                }
              }
            }`
    });

    const config = initialConfig(process.env.REACT_APP_BASE_URL + "/payment/graphql", data1, values?.accessToken);

    const response = axios(config)
      .then((res) => {
        const data = res.data.data;
        return data;
      })
      .catch((err) => {
        console.log("err:", err);
        return err;
      });

    const data = await response;
    return data;
  }
);

const self_user_billingInfo = createSlice({
  name: "self_user_billingInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(self_user_billingInfoFunc.pending, (state) => {
      state.self_user_billingInfoLoading = true;
    });
    builder.addCase(self_user_billingInfoFunc.fulfilled, (state, action) => {
      state.self_user_billingInfoLoading = false;
      state.self_user_billingInfoData = action.payload?.self_user;
      state.self_user_packagesData = action.payload?.self_user?.packages;
      state.self_user_billingInfoError = action?.payload?.message;
    });
    builder.addCase(self_user_billingInfoFunc.rejected, (state, action) => {
      state.self_user_billingInfoLoading = false;
      state.self_user_billingInfoError = action.error.message;
    });
  },
});

export default self_user_billingInfo.reducer;
