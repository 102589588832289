export const Expections = {
  PracticalWorkExperience: {
    value: "PracticalWorkExperience",
    name: "Practical Work Experience",
  },
  SkillDevelopment: {
    value: "SkillDevelopment",
    name: "Skill Development",
  },
  IndustryInsights: {
    value: "IndustryInsights",
    name: "Industry Insights",
  },
  NetworkingOpportunities: {
    value: "NetworkingOpportunities",
    name: "Networking Opportunities",
  },
  ResumeBuilding: {
    value: "ResumeBuilding",
    name: "Resume Building",
  },
  CareerExploration: {
    value: "CareerExploration",
    name: "Career Exploration",
  },
  SelfDiscoveryAndPersonalGrowth: {
    value: "SelfDiscoveryAndPersonalGrowth",
    name: "Self-Discovery and Personal Growth",
  },
  MentorshipAndGuidance: {
    value: "MentorshipAndGuidance",
    name: "Mentorship and Guidance",
  },
  ImprovedJobProspects: {
    value: "ImprovedJobProspects",
    name: "Improved Job Prospects",
  },
  PersonalAndProfessionalConnections: {
    value: "PersonalAndProfessionalConnections",
    name: "Personal and Professional Connections",
  }
};


export const expectionsList = Object.values(Expections);

export const expectionsValueList = Object.values(Expections).map(
  (period) => period.value
);

export const expectionsNameList = Object.values(Expections).map(
  (period) => period.name
);

export function getValueOfExpections(name) {
  for (const key of Object.keys(Expections)) {
    if (Expections[key].name === name) {
      return Expections[key].value;
    }
  }
  return null;
}

export function getNameOfExpections(value) {
  for (const key of Object.keys(Expections)) {
    if (Expections[key].value === value) {
      return Expections[key].name;
    }
  }
  return null;
}