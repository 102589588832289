
import axios from "axios";
import initialConfig from "context/initialConfig";

export const user_readUserNotificationFunc = async (values) => {
    console.log({ values }, "user_readUserNotificationFunc")

    const data = JSON.stringify({
        query: `mutation User_readUserNotification($uid: String!) {
            user_readUserNotification(uid: $uid) {
              success
            }
          }`,
        variables: {
            "uid": values.uid
        }
    })


    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/notification/graphql', data, values?.accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data
    } catch (err) {
        return err
    }

}