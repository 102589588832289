import { useRef, useState, useCallback, useMemo } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

// components
import Iconify from 'minimal/components/iconify';
// import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useDispatch, useSelector } from 'react-redux';
// import { graphqlApi } from 'src/hophop/api';
import { useParams } from 'react-router';
// import { enqueueSnackbar } from 'notistack';
// import { reduxFunc } from 'src/hophop/redux';

// ----------------------------------------------------------------------

const disabled = false
// const selectedConversationId = "dslf"

export const ChatMessageInput = () => {

  const fileRef = useRef(null);
  // const dispatch = useDispatch()
  // const { handleFetchData } = useFetchData()
  const [message, setMessage] = useState('');
  // const { user } = useSelector(state => state.auth)
  // const { accessToken } = useSelector(state => state.auth)


  // const urlParams = new URLSearchParams(window.location.search);
  // const activityId = urlParams.get('activityId');
  // const receiverId = urlParams.get('receiverId');

  // console.log({ user })

  const handleChangeMessage = useCallback((event) => {
    setMessage(event.target.value);
  }, []);

  const handleSendMessage = useCallback(async (event) => {

    try {

      // if (event.key === 'Enter' || event.type === 'click') {

      //   if (message) {

      //     if (selectedConversationId) {

      //       if (user.role === "moderator") {
      //         // await sendMessage(selectedConversationId, messageData);
      //         const response = await handleFetchData({
      //           data: graphqlApi.activity.mutations.messagesForModerator.createMessageForModerators({
      //             activityId,
      //             receiverId,
      //             content: message
      //           }),
      //           accessToken
      //         })

      //         console.log({ response })
      //         if (response?.data?.data?.moderator_createActivityConversationMessage?._id) {
      //           dispatch(reduxFunc.messageFunc.fetchSelectedUserMessagesForModeratorFunc({
      //             variables: {
      //               activityId,
      //               receiverId
      //             },
      //             accessToken
      //           }))
      //         } else {
      //           enqueueSnackbar('Mesaj iletilemedi!', {
      //             variant: "error"
      //           })
      //         }
      //       } else {
      //         // await sendMessage(selectedConversationId, messageData);
      //         const response = await handleFetchData({
      //           data: graphqlApi.activity.mutations.messagesForOrganizer.createMessage({
      //             activityId,
      //             parentIds: [{ "_id": receiverId }],
      //             content: message
      //           }),
      //           accessToken
      //         })

      //         console.log({ response })
      //         if (response?.data?.data?.organizer_createMessages?.success) {
      //           dispatch(reduxFunc.messageFunc.fetchSelectedUserMessagesForOrganizerFunc({
      //             variables: {
      //               activityId,
      //               receiverId
      //             },
      //             accessToken
      //           }))
      //         } else {
      //           enqueueSnackbar('Mesaj iletilemedi!', {
      //             variant: "error"
      //           })
      //         }
      //       }



      //     } else {
      //       // const res = await createConversation(conversationData);

      //       // router.push(`${paths.dashboard.chat}?id=${res.conversation.id}`);

      //       // onAddRecipients([]);
      //     }

      //   }
      //   setMessage('');
      // }


    } catch (error) {
      console.error(error);
    }

  }, []);


  return (
    <>
      <InputBase
        value={message}
        onKeyUp={handleSendMessage}
        onChange={handleChangeMessage}
        placeholder="Type a message"
        disabled={disabled}
        // startAdornment={
        //   <IconButton>
        //     <Iconify icon="eva:smiling-face-fill" />
        //   </IconButton>
        // }
        endAdornment={
          <Stack direction="row" sx={{ flexShrink: 0 }}>

            <IconButton onClick={handleSendMessage}>
              <Iconify icon="mingcute:send-fill" />
            </IconButton>

            {/* <IconButton onClick={handleAttach}>
              <Iconify icon="solar:gallery-add-bold" />
            </IconButton>
            <IconButton onClick={handleAttach}>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>
            <IconButton>
              <Iconify icon="solar:microphone-bold" />
            </IconButton> */}
          </Stack>
        }
        sx={{
          px: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      <input type="file" ref={fileRef} style={{ display: 'none' }} />
    </>
  );
}

ChatMessageInput.propTypes = {
  // disabled: PropTypes.bool,
  // onAddRecipients: PropTypes.func,
  // recipients: PropTypes.array,
  // selectedConversationId: PropTypes.string,
};
