import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Tab, Tabs, Typography, useMediaQuery, Card, Divider } from '@mui/material'
import { ApplicationCard, AnimatedTitle, AnimatedMappingCard } from 'components'
import { ApplicationDetailDrawer } from 'page-sections'
import { intern_applicationFunc, intern_applicationsFunc, intern_removeApplication, intern_setApplicationNull } from 'context'
import { DashboardLayout } from 'layouts'
import { enqueueSnackbar } from 'notistack'
import { intern_applicationTips_title, intern_applicationTips, intern_applicationTips_title_tr, intern_applicationTips_tr } from 'utils/tips/intern_applicationTips'
import parse from 'html-react-parser'
import { t } from 'i18next'
import i18next from 'i18next'
import { useOnStickyRoot, StickyContainer, useOnSticky } from 'react-on-sticky';

const info = [
    "Faz 2 bitti."
]



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Applications = () => {

    const dispatch = useDispatch()
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const { accessToken } = useSelector(state => state.user);
    const [selectedTabSectionValue, setSelectedTabSectionValue] = useState(0);
    const { intern_applicationData: application } = useSelector(state => state.intern_application)
    const { intern_applicationsData: applications } = useSelector(state => state.intern_applications)
    const [stickyRootRef] = useOnStickyRoot();
    const tipTitle = i18next.language === "tr" ? intern_applicationTips_title_tr : intern_applicationTips_title;
    const tipDescription = i18next.language === "tr" ? intern_applicationTips_tr : intern_applicationTips;

    useEffect(() => {
        accessToken && dispatch(intern_applicationsFunc({ accessToken }))
    }, [dispatch, accessToken])


    const handleSetSelectedTabSectionValue = (event, newValue) => {
        setSelectedTabSectionValue(newValue);
    };



    const handleTogleApplicationDetail = (applicationId) => {
        if (!!application) {
            dispatch(intern_setApplicationNull())
        } else {
            dispatch(intern_applicationFunc({ applicationId, accessToken }))
        }
    }


    const handleCancelApplication = async (applicationId) => {
        const response = await intern_removeApplication({
            accessToken,
            applicationId
        })
        if (response?.success) {
            enqueueSnackbar("The application has been cancelled!", { variant: "success" })
            dispatch(intern_applicationsFunc({ accessToken }))
            handleTogleApplicationDetail()
        } else {
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" })
        }
    }

    const id = "someId";

    const [isStuck] = useOnSticky(id);

    return (
        <DashboardLayout info={info}
            ref={stickyRootRef}>
            <StickyContainer>
                <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
                    <Grid item xs={12} >
                        <AnimatedTitle title={`${t("Applications")}`} />
                    </Grid>
                    <Grid item xs={12} lg={8} xl={9} >
                        <Box
                            className="sticky"
                            id={id}
                            sx={{
                                borderBottom: 1, borderColor: 'divider', backgroundColor: '#fff',
                                position: 'sticky', top: { xs: 60, sm: 70, lg: 10 }, zIndex: 3, width: '100%',
                                // pt: isStuck ? 4 : 0,
                                pl: isStuck ? 2 : 0,
                                pb: isStuck ? -1 : 0,
                                boxShadow: isStuck ? "0px 2px 10px rgba(0, 0, 0, 0.1)" : "none",
                                borderRadius: isStuck ? 2 : 0,
                            }}
                        // style = {{
                        //     borderBottomLeftRadius: isStuck ? 13 : 0,
                        //     borderBottomRightRadius: isStuck ? 13 : 0,
                        // }}
                        >
                            <Tabs value={selectedTabSectionValue} onChange={handleSetSelectedTabSectionValue}
                                style={{ marginBottom: "-7px" }}
                                aria-label="basic tabs example"
                                TabIndicatorProps={{
                                    style: {
                                        bottom: 7,
                                        backgroundColor: "#FF9737",
                                        height: "4px",
                                        borderRadius: "10px",
                                    },
                                }}>
                                <Tab label={t("All Applications")} {...a11yProps(0)} />
                                <Tab label={t("Scheduled")} {...a11yProps(1)} />
                                <Tab label={t("Indefinite")} {...a11yProps(2)} />
                                {/* <Tab label="Pending for Approval" {...a11yProps(3)} /> */}
                            </Tabs>
                        </Box>

                        {/* ***********************  applications ****************** */}
                        <CustomTabPanel value={selectedTabSectionValue} index={0} p={0}>

                            {/* ***********************   application detail drawer ****************** */}
                            <ApplicationDetailDrawer
                                application={application}
                                handleCloseDetail={handleTogleApplicationDetail}
                                onCancelApplication={() => handleCancelApplication(application?._id)}
                            />


                            {/* ***********************  applications list ****************** */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0, m: 0 }}  >
                                {applications?.data?.map((item, index) => (
                                    <AnimatedMappingCard index={index}>
                                        <ApplicationCard
                                            key={index}
                                            item={item}
                                            onCancelApplication={() => handleCancelApplication(item?._id)}
                                            onOpenDetail={() => handleTogleApplicationDetail(item?._id)}
                                        // loading={!!intern_internshipLoading}
                                        />
                                    </AnimatedMappingCard>
                                ))}
                                {applications?.data?.length === 0 &&
                                    <Typography variant="h6" color={"gray"} textAlign={"center"} mb={1} >No application found!</Typography>}
                            </Box>

                        </CustomTabPanel>

                        {/* ***********************  suitable internships ****************** */}
                        <CustomTabPanel value={selectedTabSectionValue} index={1}>



                        </CustomTabPanel>

                    </Grid>
                    {upLg && <Grid item xs={12} lg={4} xl={3}>
                        <Card sx={{
                            padding: 3, backgroundColor: "#F8F8F8", height: '100%', border: '1px solid #E0E0E0',
                            display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                        }}>
                            <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                                <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >{parse(tipTitle)}</Typography>
                                <Divider sx={{ mb: 2 }} />
                                <Typography variant="body1" fontSize={"small"} fontWeight={"regular"}>
                                    {parse(tipDescription)}
                                </Typography>
                            </Box>

                        </Card>
                    </Grid>}
                </Grid>
            </StickyContainer>
        </DashboardLayout >
    )
}

export default Applications
