import axios from "axios";
import initialConfig from "context/initialConfig";


export const intern_addSkillFunc = async ({ selectedSkill, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_addSkill($skillInput: ParameterInput!) {
            intern_addSkill(skillInput: $skillInput) {
              _id
            }
          }
        `,
        variables: {
            "skillInput": selectedSkill?._id ? {
                "_id": selectedSkill?._id ?? null,
                "name": selectedSkill?.name ?? null,
            } : {
                "name": selectedSkill?.name ?? null,
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        console.log(res?.data)
        return res?.data?.data.intern_addSkill;

    } catch (err) {
        return err
    }
}
