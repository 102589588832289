import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { enqueueSnackbar } from 'notistack';
import { SwipeableDrawer, Box, Button, Typography, Grid, Avatar, Card } from '@mui/material'
import {
    companyAdmin_acceptApplication,
    companyAdmin_rejectedApplication,
    companyAdmin_evaluatingApplication,
    user_internFunc
} from 'context';
import moment from 'moment';
import parse from 'html-react-parser';
import EducationIcon from 'assets/icons/onboarding/upper-text-education-detail-icon.png';
import HighSchoolIcon from 'assets/icons/onboarding/high-school-icon.png';
import ExperienceIcon from 'assets/icons/onboarding/upper-text-experiences-icon.png';
import CertificateIcon from 'assets/icons/onboarding/upper-text-certificates-icon.png';
import { CheckCircle, InventoryOutlined, MessageOutlined } from '@mui/icons-material';



export const ApplicantDetailDrawer = ({ selectedApplicant, setSelectedApplicant, selectedApplication, setSelectedApplication }) => {

    const dispatch = useDispatch()
    const { accessToken } = useSelector((state) => state.user);
    const { user_internData: intern } = useSelector((state) => state.user_intern);
    const { intern_applicationData: application } = useSelector(state => state.intern_application);
    const [showMoreEdu, setShowMoreEdu] = useState(false)
    const [showMoreDescription, setShowMoreDescription] = useState(false)
    const [showMoreExp, setShowMoreExp] = useState(false)
    const [showMoreCer, setShowMoreCer] = useState(false)


    const handleAcceptApplication = async () => {
        try {
            const response = await companyAdmin_acceptApplication({ applicationId: selectedApplication?._id, accessToken })
            setSelectedApplicant(null)
            if (response?.success) {
                enqueueSnackbar("The application has been accepted!", { variant: "success" })
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: "info" })
            }
        } catch (error) {
            console.log({ error })
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" })
        }
    }

    const handleRejectApplication = async () => {
        try {
            const response = await companyAdmin_rejectedApplication({ applicationId: selectedApplication?._id, accessToken })
            setSelectedApplicant(null)
            if (response?.success) {
                enqueueSnackbar("The application has been rejected!", { variant: "success" })
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: "info" })
            }
        } catch (error) {
            console.log({ error })
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" })
        }
    }

    const handleEvaluatingApplication = async () => {
        try {
            const response = await companyAdmin_evaluatingApplication({ applicationId: selectedApplication?._id, accessToken })
            setSelectedApplicant(null)
            if (response?.success) {
                enqueueSnackbar("The application has been saved!", { variant: "success" })
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "info" })
            }
        } catch (error) {
            console.log({ error })
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" })
        }
    }


    console.log(selectedApplication, 'selectedApplication')
    useEffect(() => {
        if (selectedApplicant && accessToken) {
            dispatch(user_internFunc({ accessToken, internId: selectedApplicant?._id }))
        }
    }, [selectedApplicant, accessToken, dispatch])

    return (
        <SwipeableDrawer
            anchor="right"
            style={{ width: '100%' }}
            open={selectedApplicant}
            onClose={() => {
                setSelectedApplicant(null)
                setSelectedApplication(null)
            }}
        >
            <Box
                sx={{
                    minWidth: { xs: '100%', md: '800px' },
                    maxWidth: { xs: '100%', md: '800px' },
                }}
            >
                <Grid container >
                    <Grid item xs={12} md={8} sx={{
                        p: { xs: 1, sm: 2 }, borderRight: '1px solid #ddd',
                        maxHeight: { xs: 'auto', sm: '100vh' },
                        overflowY: 'scroll'
                    }}>
                        <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={2} my={2} >
                            <Avatar src={intern?.personalInformation?.profileImageUrl}
                                sx={{ width: 120, height: 120, borderRadius: '50%', border: '5px solid #fff' }} />
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"} gap={1} >
                                <Box height={20} />
                                <Typography variant='h3' >{intern?.personalInformation?.name} {intern?.personalInformation?.surname}</Typography>
                                <Box display={"flex"} flexDirection={{ xs: 'column', sm: "row" }} justifyContent={"space-between"} alignItems={"center"} gap={2} >
                                    <img
                                        loading="lazy"
                                        style={{ width: "20px", height: "20px", borderRadius: '15px', objectFit: 'cover', marginRight: "10px" }}
                                        src={`https://flagcdn.com/${intern?.personalInformation?.nationality?.code?.toLowerCase()}.svg`}
                                        alt={intern?.personalInformation?.nationality?.code?.toLowerCase()}
                                    />
                                    <Typography variant='h7' sx={{ color: 'alternative1.main' }} >{intern?.personalInformation?.city?.name}, {intern?.personalInformation?.country?.name}</Typography>
                                    <Typography variant='h7' sx={{ color: 'alternative1.main' }} >{intern?.personalInformation?.phoneNumber}</Typography>
                                    <Typography variant='h7' sx={{ color: 'alternative1.main' }} >{intern?.personalInformation?.gender?.charAt(0).toUpperCase() + intern?.personalInformation?.gender?.slice(1)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={3} position={"relative"} >
                            <Typography variant="h7">Personal Profile</Typography>
                            {!showMoreDescription ? <Typography variant="body2" sx={{ color: 'alternative1.dark' }}>{intern?.personalInformation?.description?.length > 300 ? intern?.personalInformation?.description?.slice(0, 300) + "..." : intern?.personalInformation?.description}</Typography> :
                                <Typography variant="body2" sx={{ color: 'alternative1.dark' }}>{intern?.personalInformation?.description}</Typography>}
                            <Typography fontSize={"small"}
                                onClick={() => setShowMoreDescription(!showMoreDescription)}
                                sx={{ textDecoration: 'underline', position: 'absolute', right: 10, bottom: 0, cursor: 'pointer' }}>
                                {showMoreDescription ? 'Show less' : 'Show more'}</Typography>
                        </Box>
                        <Box mt={1} position={"relative"} >
                            <Typography variant="h7">Education</Typography>
                            {showMoreEdu ? <>
                                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={2} px={2} py={1} sx={{ backgroundColor: '#FFFFFF77' }} borderRadius={2} >
                                    <img src={HighSchoolIcon} alt="education" width={40} />
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                        <Typography variant="h7">{intern?.cv?.education?.highSchoolEducations?.[0]?.name}</Typography>
                                        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={2} >
                                            <Typography fontSize={"small"}>{intern?.cv?.education?.highSchoolEducations?.[0]?.department?.name}</Typography>
                                            <Typography fontSize={"small"}>{moment(intern?.cv?.education?.highSchoolEducations?.[0]?.startDate).format("MMM YYYY")} - {moment(intern?.cv?.education?.highSchoolEducations?.[0]?.finishDate).format("MMM YYYY")}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {intern?.cv?.education?.universityEducations?.map(university => <Box key={university?._id} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} mt={1} gap={2} px={2} py={1} sx={{ backgroundColor: '#FFFFFF77' }} borderRadius={2} >
                                    <img src={EducationIcon} alt="education" width={40} />
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                        <Typography variant="h7">{university?.university?.name}</Typography>
                                        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={2} >
                                            <Typography fontSize={"small"}>{university?.department?.name}</Typography>
                                            <Typography fontSize={"small"}>{moment(university?.startDate).format("MMM YYYY")} - {moment(intern?.cv?.education?.universityEducations?.[0]?.finishDate).format("MMM YYYY")}</Typography>
                                        </Box>
                                    </Box>
                                </Box>)}
                            </>
                                :
                                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={2} px={2} py={1} sx={{ backgroundColor: '#FFFFFF77' }} borderRadius={2} >
                                    <img src={EducationIcon} alt="education" width={40} />
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                        <Typography variant="h7">{intern?.cv?.education?.universityEducations?.[0]?.university?.name}</Typography>
                                        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={2} >
                                            <Typography fontSize={"small"}>{intern?.cv?.education?.universityEducations?.[0]?.department?.name}</Typography>
                                            <Typography fontSize={"small"}>{moment(intern?.cv?.education?.universityEducations?.[0]?.startDate).format("MMM YYYY")} - {moment(intern?.cv?.education?.universityEducations?.[0]?.finishDate).format("MMM YYYY")}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            }
                            <Typography fontSize={"small"}
                                onClick={() => setShowMoreEdu(!showMoreEdu)}
                                sx={{ textDecoration: 'underline', position: 'absolute', right: 10, bottom: 0, cursor: 'pointer' }}>
                                {showMoreEdu ? 'Show less' : 'Show more'}</Typography>
                        </Box>
                        <Box mt={1} position={"relative"} >
                            <Typography variant='h7'>Experiences</Typography>
                            {showMoreExp ? intern?.cv?.experiences?.map(experience => <Box key={experience?._id} display={"flex"} flexDirection={"row"} mb={1} justifyContent={"flex-start"} alignItems={"center"} gap={2} px={2} py={1} sx={{ backgroundColor: '#FFFFFF77' }} borderRadius={2} >
                                <img src={ExperienceIcon} alt="education" width={50} />
                                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                    <Typography variant="h7">{experience?.position?.name}, {experience?.companyName}</Typography>
                                    <Typography fontSize={"small"}>{experience?.city?.name}, {experience?.country?.name}</Typography>
                                    <Typography fontSize={"small"} sx={{ color: 'alternative1.main' }}>{experience?.positionDetail}</Typography>
                                    <Typography fontSize={"small"}>{moment(experience?.startDate).format("MMM YYYY")} - {moment(experience?.finishDate).format("MMM YYYY")}</Typography>
                                </Box>
                            </Box>) :
                                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={2} px={2} py={1} sx={{ backgroundColor: '#FFFFFF77' }} borderRadius={2} >
                                    <img src={ExperienceIcon} alt="education" width={50} />
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                        <Typography variant="h7">{intern?.cv?.experiences?.[0]?.position?.name}, {intern?.cv?.experiences?.[0]?.companyName}</Typography>
                                        <Typography fontSize={"small"}>{intern?.cv?.experiences?.[0]?.city?.name}, {intern?.cv?.experiences?.[0]?.country?.name}</Typography>
                                        <Typography fontSize={"small"}>{moment(intern?.cv?.experiences?.[0]?.startDate).format("MMM YYYY")} - {moment(intern?.cv?.experiences?.[0]?.finishDate).format("MMM YYYY")}</Typography>
                                    </Box>
                                </Box>}
                            <Typography fontSize={"small"}
                                onClick={() => setShowMoreExp(!showMoreExp)}
                                sx={{ textDecoration: 'underline', position: 'absolute', right: 10, bottom: 0, cursor: 'pointer' }}>
                                {showMoreExp ? 'Show less' : 'Show more'}</Typography>
                        </Box>
                        <Box mt={1} position={"relative"} >
                            <Typography variant='h7'>Licenses & Certificates</Typography>
                            {showMoreCer ? intern?.cv?.certificates?.map(certificate => <Box key={certificate?._id} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} mb={1} gap={2} px={2} py={1} sx={{ backgroundColor: '#FFFFFF77' }} borderRadius={2} >
                                <img src={CertificateIcon} alt="education" width={50} />
                                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                    <Typography variant="h7">{certificate?.name}</Typography>
                                    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={2} >
                                        <Typography fontSize={"small"}>{certificate?.organization}</Typography>
                                        <Typography fontSize={"small"}>{moment(certificate?.issueDate).format("MMM YYYY")} - {moment(certificate?.expirationDate).format("MMM YYYY")}</Typography>
                                    </Box>
                                </Box>
                            </Box>) :
                                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={2} px={2} py={1} sx={{ backgroundColor: '#FFFFFF77' }} borderRadius={2} >
                                    <img src={CertificateIcon} alt="education" width={50} />
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                        <Typography variant="h7">{intern?.cv?.certificates?.[0]?.name}</Typography>
                                        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={2} >
                                            <Typography fontSize={"small"}>{intern?.cv?.certificates?.[0]?.organization}</Typography>
                                            <Typography fontSize={"small"}>{moment(intern?.cv?.certificates?.[0]?.issueDate).format("MMM YYYY")} - {moment(intern?.cv?.certificates?.[0]?.expirationDate).format("MMM YYYY")}</Typography>
                                        </Box>
                                    </Box>
                                </Box>}
                            <Typography fontSize={"small"}
                                onClick={() => setShowMoreCer(!showMoreCer)}
                                sx={{ textDecoration: 'underline', position: 'absolute', right: 10, bottom: 0, cursor: 'pointer' }}>
                                {showMoreCer ? 'Show less' : 'Show more'}</Typography>
                        </Box>
                        <Box mt={1} position={"relative"} >
                            <Typography variant='h7'>Foreign Languages</Typography>
                            <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={1} py={1} >
                                {intern?.cv?.foreignLanguages?.map(language => <Box key={language?._id} display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"center"} gap={2} px={2} py={1} sx={{ backgroundColor: '#FFFFFF77' }} borderRadius={2} >
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-start"}>
                                        <Typography variant="h7">{language?.language?.name}</Typography>
                                        <Typography fontSize={"small"}>{language?.level?.name}</Typography>
                                    </Box>
                                </Box>)}
                            </Box>
                        </Box>
                        <Box mt={1} >
                            <Typography variant='h7'>Skills</Typography>
                            <Box display={"flex"} flexDirection={{ xs: 'column', sm: "row" }} gap={1} >
                                {intern?.cv?.skills?.map(skill =>
                                    <Box key={skill?._id}
                                        sx={{
                                            display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", px: 1, py: { xs: 0.5, sm: 0 },
                                            backgroundColor: 'primary.main', color: 'white', borderRadius: 2, mb: { xs: 1, sm: 2 }, textAlign: 'center'
                                        }}>
                                        <Typography variant="h7">   {skill?.name} </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={4} sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box>
                            <Typography variant='h6'>Candidate</Typography>
                            <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={1} my={2} >
                                <InventoryOutlined color='secondary' fontSize='small' />
                                <Typography fontSize={'small'} sx={{ color: 'alternative1.darken' }}>{intern?.personalInformation?.name + ' ' + intern?.personalInformation?.surname}</Typography>
                                <Typography fontSize={'small'} fontWeight={'bold'} sx={{ color: 'alternative1.darken' }}> applied  </Typography>
                                <Typography fontSize={'small'} sx={{ color: 'alternative1.darken' }}> {moment(selectedApplication?.createdAt).fromNow()}          </Typography>
                            </Box>
                            <Button variant='outlined' color='info' sx={{ width: '100%', borderRadius: '50px', fontSize: '12px', mb: 3 }} >
                                <MessageOutlined fontSize='small' sx={{ mr: 1 }} /> Message to {intern?.personalInformation?.name}</Button>
                            <Typography variant='h7'>Cover Letter</Typography>
                            <Box mt={2}>
                                <Typography variant='h7' sx={{ color: 'alternative1.darken' }}>{application?.coverLetter?.name}</Typography>
                                <Typography variant='body2' sx={{ color: 'alternative1.darken' }}>{parse(application?.coverLetter?.content ?? ' ')}</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box mb={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                                <Button fullWidth variant='outlined' color='primary' onClick={handleRejectApplication} >
                                    Reject</Button>
                                <Button fullWidth variant='contained' color='primary' onClick={handleEvaluatingApplication} >
                                    Save</Button>
                            </Box>
                            <Button sx={{ width: '100%' }} variant='contained' color='secondary' size='large' onClick={handleAcceptApplication} >
                                <CheckCircle fontSize='small' sx={{ mr: 1 }} /> Accept</Button>
                        </Box>

                    </Grid>
                </Grid>


            </Box>
        </SwipeableDrawer >
    )
}
