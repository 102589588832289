import React from "react";
import { Box, Typography } from "@mui/material";

export const BasicInput = ({
  name = "name",
  label = "Name",
  placeholder = "Enter your name",
  type = "text",

  error = false,
  value = "",
  handleBlur = () => null,
  handleChange = () => null,

  disabled,
}) => {
  return (
    <Box>
      <Typography variant="h7" color={value ? "primary.light" : "primary"}>
        {label}
      </Typography>

      <Box
        sx={{
          borderRadius: "12px",
          border: "1px solid",
          borderColor: error ? "error.main" : "primary.light",
          overflow: "hidden",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
          height: error ? "46px" : "48px",
        }}
      >
        <input
          type={type}
          disabled={disabled}
          name={name}
          className="input_placeholder"
          placeholder={placeholder}
          style={{
            border: "none",
            outline: "none",
            width: "100%",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "16px",
            paddingBottom: "10px",
            paddingTop: "10px",
            fontFamily: "Causten-SemiBold",
            color: "#0E1436",
          }}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>
      <Box height={17}>
        {error && (
          <Typography
            sx={{
              fontFamily: "Causten-Medium",
              color: "error.main",
              fontSize: { xs: "12px" },
              lineHeight: { xs: "20px" },
              textAlign: "left",
              mt: "2px",
              marginLeft: "16px",
            }}
          >
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
