export const InternshipSalaryType = {
  Volunteer: {
    value: "Volunteer",
    name: "Volunteer",
  },
  Paid: {
    value: "Paid",
    name: "Paid",
  },
};

export const internshipSalaryTypeList = Object.values(InternshipSalaryType);

export const internshipSalaryTypeValueList = Object.values(
  InternshipSalaryType
).map((period) => period.value);

export const InternshipSalaryTypeNameList = Object.values(
  InternshipSalaryType
).map((period) => period.name);

export function getValueOfInternshipSalaryType(name) {
  for (const key of Object.keys(InternshipSalaryType)) {
    if (InternshipSalaryType[key].name === name) {
      return InternshipSalaryType[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipSalaryType(value) {
  for (const key of Object.keys(InternshipSalaryType)) {
    if (InternshipSalaryType[key].value === value) {
      return InternshipSalaryType[key].name;
    }
  }
  return null;
}
