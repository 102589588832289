import React from "react";
import { Button, IconButton } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

export const AddButtonInternshipSection = ({ onClick }) => {

  const upSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Button onClick={onClick} variant="contained" color="primary" size="large" sx={{ mt: { xs: 5, lg: 0 } }}>
      <AddRounded sx={{ mr: 1 }} />
      Create {!upSm && <br />}Internship
    </Button >
  );
};
