export const ApplicationStatusTabs = {
  all : {
    name: "All",
    value: "all",
  },
  applied: {
    name: "Pending",
    value: "applied",
  },
  accepted: {
    name: "Accepted",
    value: "accepted",
  },
  aborted: {
    name: "Aborted",
    value: "aborted",
  }
};

export const ApplicationStatusTabsList = Object.values(ApplicationStatusTabs);

export const ApplicationStatusTabsValueList = Object.values(ApplicationStatusTabs).map(
  (status) => status.value
);

export const ApplicationStatusTabsNameList = Object.values(ApplicationStatusTabs).map(
  (status) => status.name
);

export function getValueOfApplicationStatusTabs(name) {
  for (const key of Object.keys(ApplicationStatusTabs)) {
    if (ApplicationStatusTabs[key].name === name) {
      return ApplicationStatusTabs[key].value;
    }
  }
  return null;
}

export function getNameOfApplicationStatusTabs(value) {
  for (const key of Object.keys(ApplicationStatusTabs)) {
    if (ApplicationStatusTabs[key].value === value) {
      return ApplicationStatusTabs[key].name;
    }
  }
  return null;
}
