export const InternshipLanguage = {
  en : {
    value: "en",
    name: "English",
  },
  fr : {
    value: "fr",
    name: "French",
  },
  de : {
    value: "de",
    name: "German",
  },
  it : {
    value: "it",
    name: "Italian",
  },
  pt : {
    value: "pt",
    name: "Portuguese",
  },
  ru : {
    value: "ru",
    name: "Russian",
  },
  es : {
    value: "es",
    name: "Spanish",
  },
  tr : {
    value: "tr",
    name: "Turkish",
  }
};

export const internshipLanguageList = Object.values(InternshipLanguage);

export const internshipLanguageValueList = Object.values(InternshipLanguage).map(
  (period) => period.value
);

export const internshipLanguageNameList = Object.values(InternshipLanguage).map(
  (period) => period.name
);

export function getValueOfInternshipLanguage(name) {
  for (const key of Object.keys(InternshipLanguage)) {
    if (InternshipLanguage[key].name === name) {
      return InternshipLanguage[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipLanguage(value) {
  for (const key of Object.keys(InternshipLanguage)) {
    if (InternshipLanguage[key].value === value) {
      return InternshipLanguage[key].name;
    }
  }
  return null;
}
