export const demoRecentActivitiesData = [
    {
        id: '1',
        user: {
            displayImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
            displayName: 'Ellen Rose',
        },
        notificationPreview: {
            subject: 'Commented on Report Files',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',

        },
        createdAt: '4-03-2023 3:3',
    },
    {
        id: '1',
        user: {
            displayImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
            displayName: 'Ellen Rose',
        },
        notificationPreview: {
            subject: 'Commented on Report Files',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',

        },
        createdAt: '4-03-2023 3:3',
    },
    {
        id: '1',
        user: {
            displayImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
            displayName: 'Ellen Rose',
        },
        notificationPreview: {
            subject: 'Commented on Report Files',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',

        },
        createdAt: '4-03-2023 3:3',
    },
]