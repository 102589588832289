import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";


const initialState = {
  self_companyUserData: null,
  self_companyUserLoading: false,
  self_companyUserError: null,
  onboardedCompany: null,
};

export const self_companyUserFunc = createAsyncThunk("user/self_companyUserFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `
    query Self_companyUser {
      self_companyUser {
        _id
        company {
          _id
          companyInformation {
            backgroundImageUrl
            city {
              _id
              code
              name
            }
            companyName
            companyWebsite
            contactInfo
            country {
              _id
              name
              code
              codeISO3
              flagUrl
            }
            description
            detailedDescription
            industry {
              _id
              name
            }
            logoImageUrl
            foundedIn
            socialMediaInfo {
              twitter
              linkedIn
              instagram
              facebook
            }
            numberOfEmployee {
              _id
              name
            }
            profileImageUrl
          }
        }
        companyUserOnboardingStatus
        contact {
          email
          emailVerified
          phoneNumber
          phoneNumberVerified
        }
        createdAt
        #createdBy {
         # _id
         # displayImageUrl
         # displayName
         # role
        #}
        personalInformation {
          address
          backgroundImageUrl
          birthDate
          city {
            _id
            code
            name
          }
          country {
            _id
            name
            code
            codeISO3
            flagUrl
          }
          description
          gender
          nationality {
            _id
            name
            code
            codeISO3
            flagUrl
          }
          phoneNumber
          profileImageUrl
          surname
          name
        }
        rating {
          updatedAt
          numberOfReviews
          average
          createdAt
        }
        role
        updatedAt
      }
    }
    `
  });

  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const self_companyUser = createSlice({
  name: "self_companyUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(self_companyUserFunc.pending, (state) => {
      state.self_companyUserLoading = true;
    });
    builder.addCase(self_companyUserFunc.fulfilled, (state, action) => {
      state.self_companyUserLoading = false;
      state.self_companyUserData = action.payload?.self_companyUser;
      state.onboardedCompany = action.payload?.self_companyUser?.companyUserOnboardingStatus;
      state.self_companyUserError = action.payload.message;
    });
    builder.addCase(self_companyUserFunc.rejected, (state, action) => {
      state.self_companyUserLoading = false;
      state.self_companyUserError = action.error.message;
    });
  }
});


export default self_companyUser.reducer;