import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_saveInternship = async ({ internshipId, accessToken }) => {

    const data = JSON.stringify({
        query: `
            mutation Intern_saveInternship($internshipId: ID!) {
                intern_saveInternship(internshipId: $internshipId) {
                success
                }
            }
        `,
        variables: {
            "internshipId": internshipId
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data, accessToken));
        return res?.data?.data?.companyAdmin_createCompany
    } catch (err) {
        return err
    }
}
