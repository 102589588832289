import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_removeCertificateFunc = async({ id, accessToken }) => {

  const data = JSON.stringify({
    query: `
        mutation Intern_removeCertificate($certificateId: ID!) {
            intern_removeCertificate(certificateId: $certificateId) {
              success
            }
          }
        `,
    variables: {
      "certificateId": id
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }

    return res?.data?.data?.intern_removeCertificate;

  } catch (err) {
    return err
  }
}