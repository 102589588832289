import { ExampleProfileImage } from "assets";

export const demoTaskData = [
    {
        id: 1,
        name: "Update new company culture policy on Google Drive",
        userProfileImage: ExampleProfileImage,
        userName: "Kevin Ryson",
        isChecked: false,
        info: "Lorem Ipsum is Lorem Ipsum",
        attachments: [1, 2],
        type: "Examination",
        dateOfTask: "12/03 - 16:30 pm",
        status: "inprogress",
        "description": "Description of the task to be updated on Google Drive (updated on Google Drive only when the task",
        "priority": "Urgent",

        "assigner": {
            "fullName": "Mesut"
        },
        "dueDate": "12/9/2023",

        "assignees": [
            {
                "intern": {
                    _id: '645141fb34caf64745095ad3',
                    displayName: 'macid',
                    displayImageUrl: 'https://internif.fra1.digitaloceanspaces.com/images/user/645141fb34caf64745095ad3-profile-1683129144891-AswiM-2xTXOMpthCtxm0G.jpeg'
                },
                status: "inprogress"
            }
        ]

    },
    {
        id: 2,
        name: "Update new company culture policy on Google Drive",
        userProfileImage: ExampleProfileImage,
        userName: "Kevin Ryson",
        isChecked: false,
        info: "Lorem I",
        attachments: [1, 2],
        type: "Examination",
        dateOfTask: "12/03 - 16:30 pm",
        "description": "Description of the task to be updated on Google Drive (updated on Google Drive only when the task",

        "priority": "Urgent",
        "assigner": {
            "fullName": "Mesut"
        },
        "dueDate": "12/9/2023",
        status: "completed",

        "assignees": [
            {
                "intern": {
                    _id: '645141fb34caf64745095ad3',
                    displayName: 'macid',
                    displayImageUrl: 'https://internif.fra1.digitaloceanspaces.com/images/user/645141fb34caf64745095ad3-profile-1683129144891-AswiM-2xTXOMpthCtxm0G.jpeg'
                },
                "status": "inprogress"
            }
        ]
    },
    {
        id: 3,
        name: "Update new company culture policy on Google Drive",
        userProfileImage: ExampleProfileImage,
        userName: "Kevin Ryson",
        isChecked: false,
        info: "Lorem I",
        attachments: [1, 2],
        type: "Examination",
        dateOfTask: "12/03 - 16:30 pm",
        "description": "Description of the task to be updated on Google Drive (updated on Google Drive only when the task",

        "priority": "Urgent",
        "assigner": {
            "fullName": "Mesut"
        },
        "dueDate": "12/9/2023",
        status: "inprogress",
        "assignees": [
            {
                "intern": {
                    _id: '645141fb34caf64745095ad3',
                    displayName: 'macid',
                    displayImageUrl: 'https://internif.fra1.digitaloceanspaces.com/images/user/645141fb34caf64745095ad3-profile-1683129144891-AswiM-2xTXOMpthCtxm0G.jpeg'
                },
                "status": "inprogress"
            }
        ]
    },
    {
        id: 4,
        name: "Update new company culture policy on Google Drive",
        userProfileImage: ExampleProfileImage,
        userName: "Kevin Ryson",
        isChecked: false,
        info: "Lorem I",
        attachments: [1, 2],
        type: "Examination",
        dateOfTask: "12/03 - 16:30 pm",
        "description": "Description of the task to be updated on Google Drive (updated on Google Drive only when the task",

        "priority": "Urgent",
        "assigner": {
            "fullName": "Mesut"
        },
        "dueDate": "12/9/2023",
        status: "completed",
        "assignees": [
            {
                "intern": {
                    _id: '645141fb34caf64745095ad3',
                    displayName: 'macid',
                    displayImageUrl: 'https://internif.fra1.digitaloceanspaces.com/images/user/645141fb34caf64745095ad3-profile-1683129144891-AswiM-2xTXOMpthCtxm0G.jpeg'
                },
                "status": "inprogress"
            }
        ]
    },
    {
        id: 5,
        name: "Update new company culture policy on Google Drive",
        userProfileImage: ExampleProfileImage,
        userName: "Kevin Ryson",
        isChecked: false,
        info: "Lorem I",
        attachments: [1, 2],
        type: "Examination",
        dateOfTask: "12/03 - 16:30 pm",
        "description": "Description of the task to be updated on Google Drive (updated on Google Drive only when the task",
        status: "inprogress",
        "priority": "Urgent",
        "assigner": {
            "fullName": "Mesut"
        },
        "dueDate": "12/9/2023",

        "assignees": [
            {
                "intern": {
                    _id: '645141fb34caf64745095ad3',
                    displayName: 'macid',
                    displayImageUrl: 'https://internif.fra1.digitaloceanspaces.com/images/user/645141fb34caf64745095ad3-profile-1683129144891-AswiM-2xTXOMpthCtxm0G.jpeg'
                },
                "status": "inprogress"
            }
        ]
    },


]
