import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Scrollbar from 'minimal/components/scrollbar';

import { useMessagesScroll } from '../hooks';
import { ChatMessageItem } from '../components';

const selectedUserMessages = null
export const ChatMessageList = () => {

  // const { selectedUserMessages } = useSelector(state => state?.message)
  const messages = [...(selectedUserMessages?.data ?? [])]?.reverse()
  const { messagesEndRef } = useMessagesScroll(messages);

  return (
    <Scrollbar ref={messagesEndRef} sx={{ px: 3, py: 5, height: 1 }}>
      <Box>
        {messages.map((message) => (
          <ChatMessageItem
            key={message?._id}
            message={message}
          />
        ))}
      </Box>
    </Scrollbar>
  );
}

ChatMessageList.propTypes = {};
