import { useSelector } from 'react-redux';
import {
    Box, Radio, Button, Stack, Typography, RadioGroup, SwipeableDrawer,
    FormControlLabel, Grid, Autocomplete, TextField
} from '@mui/material'
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
    internshipApplicationTypeList, internshipAreaList, internshipPreferredCollegeYearList,
    internshipPreferredGPAList, internshipSalaryTypeList, internshipTimePeriodList, internshipCoffeeList
} from 'utils'
import { FilterList } from '@mui/icons-material';
import { SearchInputComponent } from 'components'
import { t } from 'i18next';

const radioStyle = {
    color: "rgba(112, 121, 141, 0.51)",
    '&.Mui-checked': {
        color: "#FF9737",
    },
}

const radioLabelStyle = {
    color: '#70798D',
    '& span': {
        fontFamily: 'Causten-Regular!important',
        fontSize: { xs: '14px', sm: '16px' },
        lineHeight: { xs: '16px', sm: '24px' },
    },

}


export const InternshipsFilterDrawer = ({ variables, filterDrawer, setSearchOnFocus, internshipsType, setInternshipsType,
    setFilterDrawer, handleFilter, handleChangeInternships, handleReset, handleSetFilters }) => {

    const { anonymous_countriesData, anonymous_citiesData, user_parametersData } = useSelector(state => state.parameters);


    return <>
        <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
            <SearchInputComponent
                search={variables?.filter?.positionName}
                setSearch={(e) => handleSetFilters("positionName", e)}
                setSearchOnFocus={setSearchOnFocus}
            />
            <Stack direction={'row'} gap={2}>
                <Button variant='outlined' onClick={() => {
                    setFilterDrawer(true)
                    setInternshipsType("all")
                }} sx={{ width: { xs: '33%', md: 150 } }}>
                    <FilterList fontSize='small' sx={{ mr: 1 }} />{t('Filter & Sort')}
                </Button>
                <Button variant='contained' color='primary' onClick={() => handleChangeInternships("saved")}
                    sx={{ width: { xs: '33%', md: 150 }, opacity: internshipsType === "all" && 0.5 }}>
                    {t('Saved Internships')}
                </Button>
                <Button variant='contained' color='secondary' onClick={() => handleChangeInternships("all")}
                    sx={{ width: { xs: '33%', md: 150 }, opacity: internshipsType === "saved" && 0.5 }}>
                    {t('All Internships')}
                </Button>
            </Stack>
        </Stack>

        <SwipeableDrawer
            anchor="right"
            open={filterDrawer}
            onClose={() => setFilterDrawer(false)}
            onOpen={() => setFilterDrawer(true)}
        >
            <Box sx={{
                minWidth: { xs: '100%' },
                maxWidth: { xs: '100%', md: '600px' },
                backgroundColor: '#fff',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                py: '50px'
            }}>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: '#fff',
                        zIndex: 10,
                        width: '100%',
                        boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.05)',
                        p: '24px',
                        px: { xs: '15px', sm: '15px', },
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button variant='contained' sx={{ px: 5 }} color='secondary' size='large' onClick={handleFilter}>
                        {t('Filter')}
                    </Button>

                    <Button variant='outlined' sx={{ px: 5 }} color='primary' size='large' onClick={handleReset}>
                        {t('Reset')}
                    </Button>
                </Box>

                <Box sx={{
                    borderBottom: '1px solid #D6DCE8',
                    mx: '20px',
                    pb: '16px',
                }}>
                    <Typography variant='h5' pb='16px'>{t('Sort by')}</Typography>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="sort"
                        value={variables?.filter?.sort}
                        onChange={(event) => {
                            handleSetFilters("sort", event.target.value)
                        }}>
                        <FormControlLabel
                            label={t("Most Recent")}
                            value="most recent"
                            control={<Radio sx={radioStyle} />}
                            sx={{
                                ...radioLabelStyle,
                                mr: '50px',
                                color: variables?.filter?.sort.toLowerCase() === 'most recent'.toLowerCase() ? '#0E1436' : '#70798D',
                            }} />
                        <FormControlLabel
                            label={t("Most Relevant")}
                            value="most relevant"
                            control={<Radio sx={radioStyle} />}
                            sx={{
                                ...radioLabelStyle,
                                color: variables?.filter?.sort.toLowerCase() === 'most relevant'.toLowerCase() ? '#0E1436' : '#70798D',
                            }} />
                    </RadioGroup>
                </Box>

                <Box sx={{ p: '24px', px: '24px' }}>

                    <Typography variant='h5' pb='16px'>
                        {t('Filter by')}
                    </Typography>

                    <Grid container spacing={2}  >
                        <Grid item xs={12} md={6} >
                            <Typography variant='h7' color={'text.secondary'}>
                                {t('Internship Area')}
                            </Typography>
                            <Autocomplete
                                sx={{ mt: '5px' }}
                                options={internshipAreaList ?? []}
                                autoHighlight
                                getOptionLabel={(option) => t(option.name)}
                                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                value={variables?.filter?.internshipArea}
                                onChange={(event, newValue) => { handleSetFilters("internshipArea", newValue); }}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {t(option.name)}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={t("Choose an internship Area")}
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Typography variant='h7' color={'text.secondary'}>
                                {t('Industry')}
                            </Typography>
                            <Autocomplete
                                sx={{ mt: '5px' }}
                                options={user_parametersData?.industries?.data ?? []}
                                autoHighlight
                                getOptionLabel={(option) => t(option.name)}
                                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                                value={variables?.filter?.industry}
                                onChange={(event, newValue) => {
                                    handleSetFilters("industry", newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {t(option.name)}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        // error={!!errors.industry}

                                        {...params}
                                        placeholder={t("Choose an industry")}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant='h7' color={'text.secondary'}>
                                {t('Start Date')}
                            </Typography>
                            <MDPicker
                                sx={{ width: '100%', mt: '5px' }}
                                value={variables?.filter?.birthDate ? new Date(variables?.filter?.birthDate) : null}
                                onChange={(newValue) => handleSetFilters("startDate", newValue)}
                                name="startDate"
                                maxDate={new Date()}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Typography variant='h7' color={'text.secondary'}>
                                {t('Languages')}
                            </Typography>
                            <Autocomplete
                                sx={{ mt: '5px' }}
                                options={user_parametersData?.languages?.data ?? []}
                                autoHighlight
                                getOptionLabel={(option) => t(option.name)}
                                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                                value={variables?.filter?.language}
                                onChange={(event, newValue) => {
                                    handleSetFilters("language", newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {t(option.name)}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        // error={!!errors.industry}

                                        {...params}
                                        placeholder={t("Choose a language")}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Typography variant='h7' color={'text.secondary'}>
                                {t('Country')}
                            </Typography>
                            <Autocomplete
                                sx={{ mt: '5px' }}
                                options={anonymous_countriesData ?? []}
                                autoHighlight
                                getOptionLabel={(option) => t(option.name)}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                value={variables?.filter?.country}
                                onChange={(event, newValue) => {
                                    handleSetFilters("country", newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            alt=""
                                        />
                                        {t(option.name)} 
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={t("Choose a country")}
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <Typography variant='h7' color={'text.secondary'}>
                                {t('City')}
                            </Typography>
                            <Autocomplete
                                sx={{ mt: '5px' }}
                                name="city"
                                disabled={!variables?.filter?.country}
                                options={anonymous_citiesData ?? []}
                                autoHighlight
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                value={variables?.filter?.city}
                                onChange={(event, newValue) => {
                                    handleSetFilters("city", newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        name="city"
                                        value={variables?.filter?.city?.name}
                                        {...params}
                                        placeholder= {t("Choose a city")}
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <Typography variant='h7' color={'text.secondary'}>
                            {t('Application Type')}
                            </Typography>

                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="timePeriodInWeeks"
                                value={variables?.filter?.applicationType}
                                onChange={(event) => {
                                    handleSetFilters("applicationType", event.target.value)
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    mr: { xs: '15px' },
                                    flexWrap: 'wrap',
                                }}  >
                                    {internshipApplicationTypeList?.map(item => {
                                        return <FormControlLabel
                                            key={item.value}
                                            label={t(item.name)}
                                            value={item.value}
                                            control={<Radio sx={radioStyle} />}
                                            sx={{
                                                ...radioLabelStyle,
                                                color: variables?.filter?.timePeriodInWeeks === item.value ? '#0E1436' : '#70798D',
                                            }}
                                        />
                                    })}
                                </Box>
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Typography variant='h7' color={'text.secondary'}>
                            {t('Salary Type')}
                            </Typography>

                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="timePeriodInWeeks"
                                value={variables?.filter?.salaryType}
                                onChange={(event) => {
                                    handleSetFilters("salaryType", event.target.value)
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        mr: { xs: '15px' },
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {
                                        internshipSalaryTypeList?.map(item => {
                                            return (
                                                <FormControlLabel
                                                    key={item.value}
                                                    label={t(item.name)}
                                                    value={item.value}
                                                    control={<Radio sx={radioStyle} />}
                                                    sx={{
                                                        ...radioLabelStyle,
                                                        color: variables?.filter?.timePeriodInWeeks === item.value ? '#0E1436' : '#70798D',
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </Box>
                            </RadioGroup>
                        </Grid>

                    </Grid>
                </Box>

                <Box sx={{ px: "24px" }}>
                    <Typography variant='h7' color={'text.secondary'}>
                        {t('Time Period')}
                    </Typography>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="timePeriodInWeeks"
                        value={variables?.filter?.timePeriodInWeeks}
                        onChange={(event) => {
                            handleSetFilters("timePeriodInWeeks", event.target.value)
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            mr: { xs: '0px', md: '15px' },
                            width: '100%',
                            flexWrap: 'wrap',
                            justifyContent: { xs: 'flex-start', md: 'space-between' },

                        }} >
                            {internshipTimePeriodList?.map(item => {
                                return <FormControlLabel
                                    key={item.value}
                                    label={t(item.name)}
                                    value={item.value}
                                    control={<Radio sx={radioStyle} />}
                                    sx={{
                                        ...radioLabelStyle,
                                        width: { xs: 'auto', md: '150px' },
                                        color: variables?.filter?.timePeriodInWeeks === item.value ? '#0E1436' : '#70798D',
                                    }}
                                />
                            })}
                        </Box>
                    </RadioGroup>
                </Box>

                <Box sx={{ px: '24px', pt: '24px' }} >
                    <Typography variant='h7' color={'text.secondary'}>
                       {t('Preferred GPA')}
                    </Typography>

                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="timePeriodInWeeks"
                        value={variables?.filter?.preferredGPA}
                        onChange={(event) => {
                            handleSetFilters("preferredGPA", event.target.value)
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            mr: { xs: '0px', md: '15px' },
                            width: '100%',
                            flexWrap: 'wrap',
                        }} >
                            {internshipPreferredGPAList?.map(item => {
                                return <FormControlLabel
                                    key={item.value}
                                    label={t(item.name)}
                                    value={item.value}
                                    control={<Radio sx={radioStyle} />}
                                    sx={{
                                        ...radioLabelStyle,
                                        width: '90px',
                                        color: variables?.filter?.timePeriodInWeeks === item.value ? '#0E1436' : '#70798D',
                                    }}
                                />
                            })}
                        </Box>
                    </RadioGroup>
                </Box>

                <Box sx={{ px: '24px', pt: '24px' }} >
                    <Typography variant='h7' color={'text.secondary'}>
                        {t('Preferred College Year')}
                    </Typography>

                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="timePeriodInWeeks"
                        value={variables?.filter?.preferredCollegeYear}
                        onChange={(event) => {
                            handleSetFilters("preferredCollegeYear", event.target.value)
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            mr: { xs: '15px' },
                            flexWrap: 'wrap',
                        }}  >
                            {internshipPreferredCollegeYearList?.map(item => {
                                return <FormControlLabel
                                    key={item.value}
                                    label={t(item.name)}
                                    value={item.value}
                                    control={<Radio sx={radioStyle} />}
                                    sx={{
                                        ...radioLabelStyle,
                                        width: '90px',
                                        color: variables?.filter?.timePeriodInWeeks === item.value ? '#0E1436' : '#70798D',
                                    }}
                                />
                            })}
                        </Box>
                    </RadioGroup>
                </Box>

                <Box sx={{ px: '24px', pt: '24px' }} >
                    <Typography variant='h7' color={'text.secondary'}>
                        {t('Coffee')}
                    </Typography>

                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="timePeriodInWeeks"
                        value={variables?.filter?.coffee}
                        onChange={(event) => {
                            handleSetFilters("coffee", (event.target.value))
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            {internshipCoffeeList?.map(item => {
                                return <FormControlLabel
                                    key={item.value}
                                    label={t(item.name)}
                                    value={item.value}
                                    control={<Radio sx={radioStyle} />}
                                    sx={{
                                        ...radioLabelStyle,
                                        mr: '30px',
                                        color: variables?.filter?.timePeriodInWeeks === item.value ? '#0E1436' : '#70798D',
                                    }}
                                />
                            })}
                        </Box>
                    </RadioGroup>
                </Box>



                <Box
                    sx={{
                        p: 5
                    }}
                />


            </Box>
        </SwipeableDrawer >
    </>
}
