import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";


const initialState = {
  user_companyData: null,
  user_companyLoading: false,
  user_companyError: null,
  onboardedCompany: null,
};

export const user_companyFunc = createAsyncThunk("user_company/user_companyFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `
    query User_company($companyId: ID!) {
        user_company(companyId: $companyId) {
          _id
          companyInformation {
            backgroundImageUrl
            city {
              name
              code
              _id
            }
            companyName
            companyWebsite
            contactInfo
            country {
              name
              flagUrl
              codeISO3
              code
              _id
            }
            description
            detailedDescription
            foundedIn
            industry {
              name
              _id
            }
            logoImageUrl
            numberOfEmployee {
              name
              _id
            }
            profileImageUrl
            socialMediaInfo {
              twitter
              linkedIn
              instagram
              facebook
            }
          }
          createdAt
        }
      }
    `,
    variables: {
      "companyId": values?.companyId,
    }

  });


  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const user_company = createSlice({
  name: "user_company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(user_companyFunc.pending, (state) => {
      state.user_companyLoading = true;
    });
    builder.addCase(user_companyFunc.fulfilled, (state, action) => {
      console.log(action.payload)
      state.user_companyLoading = false;
      state.user_companyData = action.payload?.user_company;
      state.onboardedCompany = action.payload?.user_company?.data;
      state.user_companyError = action.payload.message;
    });
    builder.addCase(user_companyFunc.rejected, (state, action) => {
      state.user_companyLoading = false;
      state.user_companyError = action.error.message;
    });
  }
});


export default user_company.reducer;