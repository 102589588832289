import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    get2faData: null,
    get2faLoading: false,
    get2faError: null,
};


export const get2faFunc = createAsyncThunk("get2fa/get2faFunc", async (values) => {
    let data1 = '';

    var config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/user/twoFactorAuthOptions',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + values.accessToken
        },
        data: data1
    };

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const get2fa = createSlice({
    name: "get2fa",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // get2fa
        builder.addCase(get2faFunc.pending, (state) => {
            state.get2faLoading = true;
        }
        );
        builder.addCase(get2faFunc.fulfilled, (state, action) => {
            state.get2faLoading = false;
            state.get2faData = action.payload?.data;
            state.get2faError = action.payload.message;
        }
        );
        builder.addCase(get2faFunc.rejected, (state, action) => {
            state.get2faLoading = false;
            state.get2faError = action.error.message;
        }
        );
    }
});


export default get2fa.reducer;