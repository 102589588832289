import React from 'react'
import { Link, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const BackToHome = () => {

    const handleClick = () => {
        window.location.replace('https://www.internif.com')
    }

    return (
        <Link
            onClick={handleClick}
            sx={{
                marginLeft: '20px',
                position: 'absolute',
                top: '20px',
                gap: '5px',
                display: { xs: 'none', sm: 'flex' },
                textDecoration: 'none',
                cursor: 'pointer',
            }}
        >
            <ArrowBackIcon sx={{ color: '#96A1BA' }} fontSize="small" />
            <Typography
                sx={{
                    color: '#96A1BA',
                    fontFamily: 'Causten-Medium',
                    fontSize: '14px',
                    lineHeight: '21px',
                }}
            >
                Back to Home
            </Typography>
        </Link>
    )
}
