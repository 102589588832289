import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, IconButton, Grid, Card, Autocomplete, TextField } from '@mui/material';
import { SettingsContainer } from 'components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSnackbar } from 'notistack';
import { useFormik } from "formik";
import { intern_addSkillFunc, self_internFunc, intern_removeSkillFunc } from 'context';
import moment from 'moment';
import { t} from 'i18next'


export const ResumeSkills = ({ user }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector((state) => state.user);
    const { user_parametersData: parameters, } = useSelector((state) => state.parameters);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [openInput, setOpenInput] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleSkillChange = (event, value) => {

        const finalValue = value?.name ? value : { name: value }
        setLoading(true)
        intern_addSkillFunc({ selectedSkill: finalValue, accessToken }).then((res) => {
            if (res?._id) {
                enqueueSnackbar('The skills have been added!', { variant: 'success' });
                dispatch(self_internFunc({ accessToken }))
            }
            else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            }
            setLoading(false)
        }).catch((err) => {
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            setLoading(false)
        })
    };

    const handleRemoveSkill = (id) => {
        intern_removeSkillFunc({ id, accessToken }).then((res) => {
            if (res?.success) {
                enqueueSnackbar('The skills have been removed!', { variant: 'success' });
                dispatch(self_internFunc({ accessToken }))
            }
            else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            }
        }
        ).catch((err) => {
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
        })
    };


    const userInitialValues = {
        skills: [],
        skill: "",
    }

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        onSubmit: () => { }
    })

    useEffect(() => {
        setSelectedSkills(user?.cv?.skills)
    }, [user])


 
    return <>
        <SettingsContainer pb={2} mb={3}>

            <Box pl={3} pt={3} pr={3}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h5">
                       {t('Skills')}
                    </Typography>
                    <IconButton
                        disabled={selectedSkills?.length >= 25}
                        sx={{ ml: 1 }}
                        aria-label="edit"
                        onClick={() => setOpenInput(!openInput)}
                    >
                        {openInput ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                </Box>
                {(selectedSkills?.length === 0 || !selectedSkills) && <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" color={"grey.600"} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center', pb:1 }}>   {t("No skill added yet.")} </Typography>
                    </Grid>
                </Grid>}
                <>
                    <Grid container spacing={2} sx={{ width: '100%', mt: "10px" }}>
                        {openInput && <Grid item xs={12} md={12} mb={2}>
                            {selectedSkills?.length <= 24 && <Autocomplete
                                freeSolo
                                key={moment().valueOf()}
                                options={parameters?.skills?.data ?? []}
                                getOptionLabel={(option) => typeof option === 'object' ? option.name : option}
                                onChange={(event, value) => { handleSkillChange(event, value) }}
                                renderInput={(params) => <TextField {...params} label="Add your skill" value={values.skill} disabled={loading} />}
                            />}
                        </Grid>}
                        {
                            selectedSkills?.map((item, index) => (
                                <Card
                                    onClick={() => handleRemoveSkill(item?._id)}
                                    sx={{
                                        display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", pl: 2, py: 0,
                                        backgroundColor: 'primary.main', color: 'white', borderRadius: '100px', cursor: "pointer", mb: 2, ml: 2,
                                        '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.2)" }
                                    }}>
                                    <Typography variant="h7" sx={{ fontWeight: 'bold' }}>   {item?.name} </Typography>
                                    <IconButton >
                                        <CloseIcon fontSize="small" sx={{ color: 'white' }} />
                                    </IconButton>
                                </Card>
                            ))

                        }
                    </Grid>
                </>


            </Box>
        </SettingsContainer>
    </>
}
