export const ApplicationStatus = {
  applied: {
    name: "Applied",
    value: "applied",
  },
  viewed: {
    name: "Viewed",
    value: "viewed",
  },
  evaluating: {
    name: "Evaluating",
    value: "evaluating",
  },
  suitable: {
    name: "Evaluating",
    value: "suitable",
  },
  unsuitable: {
    name: "Viewed",
    value: "unsuitable",
  },
  rejected: {
    name: "Rejected",
    value: "rejected",
  },
  accepted: {
    name: "Accepted",
    value: "accepted",
  },
  approved: {
    name: "Approved",
    value: "approved",
  },
  disapproved: {
    name: "Disapproved",
    value: "disapproved",
  },
};

export const applicationStatusList = Object.values(ApplicationStatus);

export const applicationStatusValueList = Object.values(ApplicationStatus).map(
  (status) => status.value
);

export const applicationStatusNameList = Object.values(ApplicationStatus).map(
  (status) => status.name
);

export function getValueOfApplicationStatus(name) {
  for (const key of Object.keys(ApplicationStatus)) {
    if (ApplicationStatus[key].name === name) {
      return ApplicationStatus[key].value;
    }
  }
  return null;
}

export function getNameOfApplicationStatus(value) {
  for (const key of Object.keys(ApplicationStatus)) {
    if (ApplicationStatus[key].value === value) {
      return ApplicationStatus[key].name;
    }
  }
  return null;
}
