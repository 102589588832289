import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_addCoverLetterFunc = async ({ values, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_addCoverLetter($coverLetterInput: CoverLetterInput!) {
            intern_addCoverLetter(coverLetterInput: $coverLetterInput) {
              _id
            }
          }
        `,
        variables: {
            "coverLetterInput": {
                "content": values.content,
                // "isArchived": null,
                "name": values.name,
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data?.data?.intern_addCoverLetter;
    } catch (err) {
        return err
    }
}
