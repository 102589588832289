import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box, Button, Card, Typography, Stepper, Step, StepLabel, StepContent, Paper,
    TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Dialog,
    DialogTitle, DialogActions, DialogContent, FormControlLabel, Checkbox, Link, FormHelperText
} from "@mui/material";
import OnboardingLayout from "layouts/OnboardingLayout";
import { imageCompress, imageUpload } from "utils/helpers";
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
    user_parametersFunc, anonymous_countriesFunc, anonymous_citiesFunc, anonymous_policiesFunc,
    companyAdmin_createCompanyFunc, companyUser_updateCompanyUserFunc, companyUser_updateCompanyUserOnboardingStatusFunc
} from 'context';
import { useSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as Yup from "yup";
import companyImage from 'assets/images/common/company.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AuthorizedIcon from 'assets/icons/dashboard/authorized.png';
import parse from 'html-react-parser';

const info = [
    "Test edilecek."
]


export const OnboardingCompany = ({ accountType }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector((state) => state.user);
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const { anonymous_countriesData: countries, user_parametersData: parameters } = useSelector((state) => state.parameters);
    const { anonymous_policiesData: policies, anonymousPoliciesLoading: policiesLoading } = useSelector((state) => state.anonymous_policies);
    const [cities, setCities] = useState([]);
    const [companyCities, setCompanyCities] = useState([]);
    const [compressedImage, setCompressedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [checkOpen, setCheckOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { self_companyUserData } = useSelector((state) => state.self_companyUser);

    const userValidationSchema = Yup.object().shape({
        name: Yup.string().min(2, "Name must be at least 2 characters").required("Name is required"),
        surname: Yup.string().min(2, "Name must be at least 2 characters").required("Surname is required"),
        gender: Yup.string().required("Gender is required"),
        // birthDate: Yup.date().required("BirthDate is required"),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),
        nationality: Yup.string().required("Nationality is required"),
        companyName: Yup.string().required("Company Name is required"),
        industry: Yup.string().required("Industry is required"),
        companyCountry: Yup.string().required("Company Country is required"),
        companyCity: Yup.string().required("Company City is required"),
        logoImageUrl: Yup.string().nullable(),
        description: Yup.string().max(250, "Max 250 characters").required("Company Summary is required").min(20, "Min 20 characters"),
        companyWebsite: Yup.string().matches(/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*$/, "Please enter a valid URL").required("Company Website is required"),
        contactInfo: Yup.string().email("Please enter a valid email address").required("Company Email is required"),
        linkedin: Yup.string().nullable().matches(/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*$/, "Please enter a valid URL"),
        companyType: Yup.string().required("Company Type is required"),
        foundedIn: Yup.date().required("Founded In is required"),
    })

    const userInitialValues = {
        name: self_companyUserData?.personalInformation?.name ?? "",
        surname: self_companyUserData?.personalInformation?.surname ?? "",
        gender: "",
        birthDate: null,
        nationality: "",
        country: "",
        city: "",
        companyName: "",
        industry: "",
        companyCountry: "",
        companyCity: "",
        companyWebsite: "",
        numberOfEmployee: "",
        foundedIn: "",
        logoImageUrl: "",
        description: "",
        contactInfo: "",
        accept: false,
        linkedin: "",
        companyType: "",
    }

    const {
        values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
            enableReinitialize: true,
            initialValues: userInitialValues,
            validationSchema: userValidationSchema,
            onSubmit: (values) => {
                !values?.logoImageUrl
                    ? enqueueSnackbar(t("Please upload your organization logo."), { variant: 'error' })
                    : setCheckOpen(true)
            }
        })

    const steps = [
        {
            label: t("Personal Information"),
            description: t(`Could you give us some personal information about yourself?`),
        },
        {
            label: t('Company Information'),
            description:
                t('Can you give us some information about your company information?'),
        },
        {
            label: t('Company Detail'),
            description: t(`Could you give us some detailed information about your company?`),
        },
    ];


    // dispatch(anonymous_policiesFunc({ accessToken, policyType: 'company', target: 'sales_company_credit' }))

    const handleNext = () => {

        if (activeStep === 0) {
            if (values.name && values.surname && values.birthDate && values.gender && values.country && values.city && values.nationality &&
                !errors.name && !errors.surname && !errors.birthDate && !errors.gender && !errors.country && !errors.city && !errors.nationality) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        if (activeStep === 1) {
            if (values.companyName && values.industry && values.companyCountry && values.city && values.description &&
                !errors.companyName && !errors.industry && !errors.companyCountry && values.city && !errors.description) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }

    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChangeCompanyLogoButton = () => {
        const fileInput = document.getElementById('file-input2');
        fileInput.click();
    };

    const handleCompanyLogoChange = (e) => {
        const file = e.target.files[0];
        imageCompress({ file, enqueueSnackbar, setCompressedImage, ratio: 0.4, maxWidth: 300 });
    };

    const handleUpdateUserInfo = async () => {
        try {
            setLoading(true);

            const resLogoImage = compressedImage && await imageUpload({
                presignedType: 'organizationLogo',
                accessToken: accessToken,
                image: compressedImage?.image,
                imageType: compressedImage?.type
            });


            const companyUserRes = await companyUser_updateCompanyUserFunc({ values: values, accessToken: accessToken });
            if (!(companyUserRes._id)) throw new Error(companyUserRes ?? "Company user update failed.");


            const companyRes = await companyAdmin_createCompanyFunc({ values: values, accessToken: accessToken, logoImage: resLogoImage ?? values.logoImageUrl });
            if (!(companyRes?.success)) throw new Error(companyRes ?? "Company creation failed.");

            const statusRes = await companyUser_updateCompanyUserOnboardingStatusFunc({ accessToken: accessToken });

            if (statusRes?.success) {
                enqueueSnackbar(t("Welcome to InternIF!"), { variant: 'success' });
                window.location.reload()
            } else {
                throw new Error(statusRes ?? "An error has been detected. Please try again soon!");
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
            enqueueSnackbar(t("An error has been detected. Please try again soon!"), { variant: 'error' });
            setLoading(false);
            return err;
        }

    }




    useEffect(() => {
        if (compressedImage) {
            setFieldValue("logoImageUrl", compressedImage?.url)
        }
    }, [compressedImage, setFieldValue])

    useEffect(() => {
        dispatch(user_parametersFunc({ accessToken }));
        dispatch(anonymous_countriesFunc({ accessToken }));
    }, [dispatch, accessToken]);


    useEffect(() => {
        accessToken && values?.country && dispatch(anonymous_citiesFunc({ accessToken, countryId: values?.country })).then((res) => {
            setCities(res.payload?.anonymous_cities?.data);
        });
    }, [accessToken, dispatch, values?.country]);

    useEffect(() => {
        accessToken && values?.companyCountry && dispatch(anonymous_citiesFunc({ accessToken, countryId: values?.companyCountry })).then((res) => {
            setCompanyCities(res.payload?.anonymous_cities?.data);
        });
    }, [accessToken, dispatch, values?.companyCountry]);



    return <OnboardingLayout info={info} activeStep={activeStep}>
        <Box>
            <Typography variant="h1" textAlign={{ xs: "center", lg: "left" }} mt={{ xs: 5, lg: 10 }}>{t("Welcome to InternIF!")}</Typography>
            <Card sx={{
                p: 3, mt: 3, mb: 3, borderRadius: '12px', width: { xs: '100%', lg: 800 },
            }} >
                <Box>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label} sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: 'darkseagreen',
                                },
                            }}>
                                <StepLabel                                >
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    <Box sx={{ mb: 2 }} flexDirection="column">
                                        <Typography>{step.description}</Typography>
                                        {index === 0 && <>
                                            <Box flexDirection={"row"} justifyContent="space-between" width="100%">

                                                <TextField label={t("First Name") + " *"}
                                                    name="name"
                                                    sx={{ width: '48%', mr: '4%', mt: 2 }}
                                                    onChange={(e => {
                                                        const enteredValue = e.target.value;
                                                        const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                                        setFieldValue("name", capitalizedValue)
                                                    })}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                    value={values?.name} />

                                                <TextField label={t("Last Name") + " *"}
                                                    name="surname"
                                                    sx={{ width: '48%', mt: 2 }}
                                                    onChange={(e => {
                                                        const enteredValue = e.target.value;
                                                        const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                                        setFieldValue("surname", capitalizedValue)
                                                    })}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.surname && errors.surname)}
                                                    helperText={touched.surname && errors.surname}
                                                    value={values?.surname} />
                                            </Box>
                                            <Box flexDirection={"row"} justifyContent="space-between" width="100%">
                                                <MDPicker
                                                    sx={{ width: '48%', mr: '4%', mt: 2, mb: 2 }}
                                                    name="birthDate"
                                                    label={t("Birthday") + " *"}
                                                    value={values.birthDate}
                                                    onChange={(newValue) => setFieldValue("birthDate", newValue)}
                                                    className="customInput"
                                                    openTo="year"
                                                    format="dd.MM.yyyy"
                                                    views={['year', 'month', 'day']}
                                                    maxDate={new Date() - 1000 * 60 * 60 * 24 * 365 * 16}
                                                    minDate={new Date() - 1000 * 60 * 60 * 24 * 365 * 100}
                                                />
                                                <FormControl sx={{ width: '48%', mt: 2, mb: 2 }}>
                                                    <InputLabel id="demo-simple-select-label">
                                                        {t("Gender") + "*"}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='gender'
                                                        value={values.gender}
                                                        label={t("Gender") + "*"}
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={"female"}>{t("Female")}</MenuItem>
                                                        <MenuItem value={"male"}>{t("Male")}</MenuItem>
                                                        <MenuItem value={"other"}>{t('Other')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <FormControl sx={{ width: '32%', mr: '2%', mt: 2 }}>
                                                <InputLabel id="demo-simple-select-label">{t('Nationality')}*</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='nationality'
                                                    value={values.nationality}
                                                    label={t('Nationality') + "*"}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        disableScrollLock: true,
                                                        PaperProps: { style: { maxHeight: 300 } }
                                                    }}
                                                >
                                                    {countries?.map((item, index) => (
                                                        <MenuItem key={index} value={item._id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{ width: '32%', mr: '2%', mt: 2 }}>
                                                <InputLabel>{t('Country')}*</InputLabel>
                                                <Select
                                                    name='country'
                                                    value={values.country}
                                                    label={t('Country') + "*"}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        disableScrollLock: true,
                                                        PaperProps: { style: { maxHeight: 300 } }
                                                    }}
                                                >
                                                    {countries?.map((item, index) => (
                                                        <MenuItem key={index} value={item._id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl disabled={!values?.country} sx={{ width: '32%', mt: 2 }}>
                                                <InputLabel id="demo-simple-select-label">{t('City')}*</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={values.city}
                                                    name='city'
                                                    label={t('City') + "*"}
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        disableScrollLock: true,
                                                        PaperProps: { style: { maxHeight: 300 } }
                                                    }}
                                                >
                                                    {cities?.map((item, index) => (
                                                        <MenuItem key={index} value={item._id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </>}
                                        {index === 1 && <>
                                            <Box flexDirection={"row"} justifyContent="space-between" width="100%">

                                                <TextField label={t("Company Name") + " *"}
                                                    name="companyName"
                                                    sx={{ width: '48%', mr: '4%', mt: 2 }}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.companyName && errors.companyName)}
                                                    helperText={touched.companyName && errors.companyName}
                                                    value={values?.companyName} />

                                                <FormControl sx={{ width: '48%', mt: 2 }}>
                                                    <InputLabel id="demo-simple-select-label">
                                                        {t("Industry") + "*"}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='industry'
                                                        value={values.industry}
                                                        label={t("Industry") + "*"}
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                            PaperProps: { style: { maxHeight: 300 } }
                                                        }}
                                                    >
                                                        {parameters?.industries?.data?.map((item, index) => (
                                                            <MenuItem key={index} value={item._id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                <FormControl sx={{ width: '48%', mr: '4%', mt: 2 }}>
                                                    <InputLabel id="demo-simple-select-label">{t('Country')}*</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='companyCountry'
                                                        value={values.companyCountry}
                                                        label={t('Country') + "*"}
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                            PaperProps: { style: { maxHeight: 300 } }
                                                        }}
                                                    >
                                                        {countries?.map((item, index) => (
                                                            <MenuItem key={index} value={item._id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl disabled={!values?.companyCountry} sx={{ width: '48%', mt: 2 }}>
                                                    <InputLabel id="demo-simple-select-label">{t('City')}*</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.companyCity}
                                                        name='companyCity'
                                                        label={t('City') + "*"}
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                            PaperProps: { style: { maxHeight: 300 } }
                                                        }}
                                                    >
                                                        {companyCities?.map((item, index) => (
                                                            <MenuItem key={index} value={item._id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    label={t("Company Summary")}
                                                    name="description"
                                                    sx={{ width: '100%', mt: 2 }}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.description && errors.description)}
                                                    helperText={errors.description ? touched.description && errors.description + ' ' + values?.description?.length + "/250"
                                                        : values?.description?.length + "/250"}
                                                    FormHelperTextProps={{ sx: { textAlign: 'right', position: 'absolute', bottom: 5, right: 0 } }}
                                                    value={values?.description}
                                                    multiline
                                                    rows={4}
                                                />
                                            </Box>
                                        </>}
                                        {index === 2 && <>
                                            <Box display={"flex"} sx={{ flexDirection: { xs: "column", lg: "row" } }}
                                                justifyContent="space-between" width="100%">
                                                <Box
                                                    sx={{
                                                        width: { xs: '100%', lg: '48%' }, mt: 2, display: "flex", justifyContent: "center",
                                                        alignItems: "center", backgroundColor: '#f6f6f6',
                                                        borderRadius: "12px", cursor: "pointer"
                                                    }} mr="4%"
                                                    style={{ borderWidth: 1, borderStyle: 'solid', borderColor: "#ddd" }}
                                                    onClick={handleChangeCompanyLogoButton}>
                                                    <img
                                                        src={compressedImage?.url ? compressedImage?.url : companyImage}
                                                        alt="profile" style={{ objectFit: 'cover', borderRadius: "50%", width: 70, height: 70, borderWidth: 1, borderStyle: 'dashed', borderColor: "#bbb", padding: 2 }} />
                                                    <Typography display="flex" sx={{ ml: 2 }}>{t('Organization Logo')} <CloudUploadIcon sx={{ ml: 1, color: '#555' }} />   </Typography>

                                                    <input
                                                        id="file-input2"
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        onChange={handleCompanyLogoChange}
                                                    />
                                                </Box>
                                                <Box flexDirection={"column"} sx={{ width: { xs: '100%', lg: '48%' } }}>
                                                    <FormControl sx={{ width: '100%', mt: 2 }}>
                                                        <InputLabel id="demo-simple-select-label">
                                                            {t("Number of Employees") + "*"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name='numberOfEmployee'
                                                            value={values.numberOfEmployee}
                                                            label={t("Number of Employees") + "*"}
                                                            onChange={handleChange}
                                                            MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                                        >
                                                            {parameters?.numberOfEmployees?.data?.map((item, index) => (
                                                                <MenuItem key={index} value={item._id}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <MDPicker
                                                        sx={{ width: '100%', mt: 2 }}
                                                        label="Founded In*"
                                                        value={values.foundedIn ? new Date(values.foundedIn) : null}
                                                        openTo="year"
                                                        onChange={(value) => setFieldValue("foundedIn", value)}
                                                        name="foundedIn"
                                                        views={['year']}
                                                        maxDate={new Date()}
                                                        minDate={new Date() - 1000 * 60 * 60 * 24 * 365 * 200}
                                                    />
                                                </Box>

                                            </Box>
                                            <Box display={"flex"} flexDirection={"row"} justifyContent="space-between" width="100%">
                                                <Box flexDirection={"column"} width="48%">
                                                    <FormControl sx={{ width: { xs: '100%' }, mt: 2 }} error={touched.companyType && errors.companyType}>
                                                        <InputLabel id="demo-simple-select-label">{t("Company Type*")}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            name='companyType'
                                                            id="demo-simple-select"
                                                            value={values.companyType}
                                                            onBlur={handleBlur}
                                                            label="Company Type*"
                                                            onChange={handleChange}
                                                            MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                                                        >
                                                            <MenuItem value={"publicCompany"}> {t("Public Company")} </MenuItem>
                                                            <MenuItem value={"selfEmployed"}> {t("Self-employed")} </MenuItem>
                                                            <MenuItem value={"governmentAgency"}> {t("Government Agency")} </MenuItem>
                                                            <MenuItem value={"nonprofit"}> {t("Nonprofit")}</MenuItem>
                                                            <MenuItem value={"soleProprietorship "}> {t("Sole Proprietorship")} </MenuItem>
                                                            <MenuItem value={"limitedLiabilityCompany"}> {t("Limited Liability Company")}</MenuItem>
                                                            <MenuItem value={"corporation"}> {t("Corporation")}</MenuItem>
                                                            <MenuItem value={"partnership"}> {t("Partnership")}</MenuItem>
                                                        </Select>
                                                        {touched.companyType && errors.companyType && <FormHelperText style={{ color: '#961650' }}>{errors.companyType}</FormHelperText>}

                                                    </FormControl>
                                                </Box>
                                                <Box flexDirection={"column"} width="48%">
                                                    <TextField label={t("Company Website*")}
                                                        name="companyWebsite"
                                                        sx={{ width: '100%', mt: 2 }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.companyWebsite && errors.companyWebsite)}
                                                        helperText={touched.companyWebsite && errors.companyWebsite}
                                                        value={values?.companyWebsite} />
                                                </Box>
                                            </Box>
                                            <Box display={"flex"} flexDirection={"row"} justifyContent="space-between" width="100%">
                                                <Box flexDirection={"column"} width="48%">
                                                    <TextField label={t("Company Linkedin")}
                                                        name="linkedin"
                                                        sx={{ width: '100%', mt: 2 }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.linkedin && errors.linkedin)}
                                                        helperText={touched.linkedin && errors.linkedin}
                                                        value={values?.linkedin} />
                                                </Box>

                                                <Box flexDirection={"column"} width="48%">
                                                    <TextField label={t("Company Email*")}
                                                        name="contactInfo"
                                                        sx={{ width: '100%', mt: 2 }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.contactInfo && errors.contactInfo)}
                                                        helperText={touched.contactInfo && errors.contactInfo}
                                                        value={values?.contactInfo} />
                                                </Box>
                                            </Box>

                                        </>}



                                    </Box>
                                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {t('Back')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            disabled={loading ||
                                                (index === 0 && (!values.name || !values.surname || !values.birthDate || !values.gender || !values.nationality || !values.country || !values.city)) ||
                                                (index === 1 && (!values.companyName || !values.industry || !values.companyCountry || !values.companyCity || !values.description || errors.description)) ||
                                                (index === 2 && (!values.logoImageUrl || !values.numberOfEmployee || !values.foundedIn))
                                            }
                                            onClick={index === steps.length - 1 ?
                                                handleSubmit
                                                : handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {loading ? <CircularProgress size="1rem" /> : index === steps.length - 1 ? t('Finish') : t('Continue')}
                                        </Button>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button type="submit" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                Reset
                            </Button>
                        </Paper>
                    )}
                </Box>


            </Card>

            <Box height={40} />
        </Box >

        <Dialog open={checkOpen} onClose={() => setCheckOpen(false)} >
            <DialogTitle sx={{ textAlign: 'center' }} >
                Authorization <img src={AuthorizedIcon} alt="Check" style={{ width: 20, height: 20, marginLeft: 5, marginBottom: 4 }} />
            </DialogTitle>

            <DialogContent>
                <Typography textAlign={"center"}>I am authorized to share all information about the company whose information I have added and to manage the internships.</Typography>

                {/* <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%" mb={1}>
                    <FormControlLabel
                        control={<Checkbox
                            color="primary"
                            checked={values?.accept}
                            onChange={handleChange}
                            name="accept"
                        />}
                        label={<Typography variant="h8" color="primary.main">
                            I’ve read and accept the <Link component="span" onClick={handleClickOpen("preliminary_information_form")} color="info.main" variant="h8">
                                Preliminary Information Form
                            </Link> and <Link component="span" onClick={handleClickOpen("sales_agreement")} color="info.main" variant="h8">
                                Sales Agreement</Link>  of InternIF.
                        </Typography>}
                    />
                </Box> */}
            </DialogContent>

            <DialogActions>
                <Button onClick={() => setCheckOpen(false)} color="primary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    disabled={loading}
                    onClick={handleUpdateUserInfo} color="primary" variant="contained" autoFocus>
                    {loading ? <CircularProgress size={"1rem"} /> : "Approve"}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogContent dividers={true} sx={{ minWidth: 'md', width: '500px', mt: 4 }}>
                {policiesLoading
                    ? <CircularProgress />
                    : parse(policies?.data?.[0]?.content?.tr ?? '')}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => setOpenDialog(false)}>OK</Button>
            </DialogActions>
        </Dialog>

    </OnboardingLayout >
}
