export const statusColorForInternship = (text) => {

    switch (text) {
        case "pending":
            return ({
                color: '#EBA900',
                backgroundColor: 'rgba(235, 168, 0, 0.2)',
                text: "pending",
            })
        case "active":
            return ({
                color: '#00B543',
                backgroundColor: 'rgba(0, 181, 66, 0.2)',
                text: "active",
            })
        case "cancelled":
            return ({
                color: '#999',
                backgroundColor: 'rgba(153, 153, 153, 0.2)',
                text: "cancelled",
            })
        case "archived":
            return ({
                color: '#999',
                backgroundColor: 'rgba(153, 153, 153, 0.2)',
                text: "archived",
            })
        case "completed":
            return ({
                color: '#3BA1EB',
                backgroundColor: 'rgba(59, 162, 235, 0.2)',
                text: "completed",
            })
        case "expired":
            return ({
                color: '#EB3641',
                backgroundColor: 'rgba(235, 54, 66, 0.2)',
                text: "expired",
            })

        default:
            return ({
                color: '#FF9737',
                backgroundColor: 'rgba(255, 152, 55, 0.2)',
                text: text,
            })
    }
}

export const statusColorForApplication = (text) => {

    switch (text) {
        case "pendingForAttendanceApproval":
            return ({
                text: 'awaiting acceptance',
                color: '#3BA1EB',
                backgroundColor: 'rgba(59, 162, 235, 0.2)',
            })
        case "pendingForCompanyApproval":
            return ({
                text: 'awaiting approval',
                color: '#EBA900',
                backgroundColor: 'rgba(235, 168, 0, 0.2)',
            })
        case "approved":
            return ({
                text: 'approved',
                color: '#00B543',
                backgroundColor: 'rgba(0, 181, 66, 0.2)',
            })
        case "rejectedByApplicant":
            return ({
                text: 'rejected by applicant',
                color: '#EB3641',
                backgroundColor: 'rgba(235, 54, 66, 0.2)',
            })
        case "rejectedByCompany":
            return ({
                text: 'rejected',
                color: '#EB3641',
                backgroundColor: 'rgba(235, 54, 66, 0.2)',
            })
        case "cancelled":
            return ({
                text: 'cancelled',
                color: '#999',
                backgroundColor: 'rgba(153, 153, 153, 0.2)',
            })
        default:
            return ({
                text: text ?? 'status',
                color: '#FF9737',
                backgroundColor: 'rgba(255, 152, 55, 0.2)',
            })
    }
}

export const priorityColorForTask = (text) => {

    switch (text) {
        case "low":
            return ({
                backgroundColor: "rgba(0, 181, 66, 0.2)",
                color: "#00B543",
                text: "Low",
            })
        case "normal":
            return ({
                backgroundColor: "rgba(235, 168, 0, 0.2)",
                color: "#EBA900",
                text: "Normal",
            })
        case "high":
            return ({
                backgroundColor: "rgba(255, 88, 55, 0.2)",
                color: "#FF5A37",
                text: "High",
            })

        default:
            return ({
                backgroundColor: "rgba(0, 181, 66, 0.2)",
                color: "#00B543",
                text: "Low",
            })
    }
}

export const statusColorForTask = (text) => {

    switch (text) {
        case "pending":
            return ({
                backgroundColor: "rgba(235, 168, 0, 0.2)",
                color: "#EBA900",
                text: "Pending",
            })
        case "inprogress":
            return ({
                backgroundColor: "rgba(61, 123, 248, 0.2)",
                color: "#3D7CF8",
                text: "Inprogress",
            })
        case "feedback":
            return ({
                backgroundColor: "rgba(235, 168, 0, 0.2)",
                color: "#EBA900",
                text: "Feedback",
            })
        case "completed":
            return ({
                backgroundColor: "rgba(0, 181, 66, 0.2)",
                color: "#00B543",
                text: "Completed",
            })
        case "expired":
            return ({
                backgroundColor: "rgba(255, 88, 55, 0.2)",
                color: "#FF5A37",
                text: "Expired",
            })

        default:
            return ({
                backgroundColor: "rgba(0, 181, 66, 0.2)",
                color: "#00B543",
                text: "Low",
            })
    }
}



// for company internships
// pending sarı olsun
// active olan yeşil
// canceled, archived gri
// completed olan mavi
// expired olna kırmızı


// for application
// awaiting approval sarı olsun
// accepted(approved backendden gelen) olanlar yeşil olsun
// awaiting acceptence olan mavi
// 2 rejectejtedlar  kırmızı olsun
// canceled gri olsun (bunu sadece biz göreceğiz)

// pendingForCompanyApproval
// rejectedByCompany
// approved
// rejectedByApplicant
// pendingForAttendanceApproval
// canceled
