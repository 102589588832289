import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_addExperienceFunc = async ({ values, position, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_addExperience($experienceInput: ExperienceInput!) {
            intern_addExperience(experienceInput: $experienceInput) {
              _id
            }
          }
        `,
        variables: {
            "experienceInput": {
                "country":
                {
                    "_id": values?.country,
                },
                "city":
                {
                    "_id": values?.city,
                },
                "position":
                    position?._id ? {
                        "_id": position?._id ?? null,
                    } : {
                        "name": position?.name ?? null,
                    },
                "companyName": values.companyName,
                "positionDetail": values.positionDetail,
                "startDate": values.startDate,
                "endDate": values.endDate ?? null,
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }

        return res?.data?.data?.intern_addExperience;

    } catch (err) {
        return err
    }
}

