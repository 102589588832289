import { Box, Typography } from "@mui/material"


export const AuthSeperator = ({ type }) => {
    return <Box
        sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: 2,
            mt: 2,
            mb: 1,
        }}
    >
        <Box sx={{ bgcolor: "primary.light", height: "1px", width: "100%" }} />
        <Typography variant='h6' whiteSpace={'nowrap'} color={"primary.light"}>
            or {type} with email
        </Typography>
        <Box sx={{ bgcolor: "primary.light", height: "1px", width: "100%" }} />
    </Box>
}
