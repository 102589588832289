import React, { useState } from 'react'
import { Avatar, Box, Button, Grid, IconButton, SwipeableDrawer, Typography, Menu, MenuItem } from '@mui/material'

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,

    FacebookShareCount,
    GabShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceShareButton,
} from "react-share";
import { enqueueSnackbar } from 'notistack'
import { CopyAllRounded, ShareRounded } from '@mui/icons-material';


export const ShareButtonsMenuContainer = ({ shareUrl, title }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleCopy = (event) => {
        navigator.clipboard.writeText(shareUrl);
        enqueueSnackbar("Share url copied to clipboard!")
    }

    return (
        <Box>

            <IconButton
                // id="basic-button"
                // aria-controls={open ? 'basic-menu' : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ flexDirection: 'column', width: 70, height: 70 }}
                color='primary'
                size='large'
            >
                <ShareRounded />
                <Typography variant="h7">{title}</Typography>
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                <MenuItem onClick={handleClose} sx={{ px: 2, py:1}}>
                    <FacebookShareButton url={shareUrl} style={{ display: 'flex', gap: 5, alignItems: "center" }}>
                        <FacebookIcon size={24} round={true} />
                        Facebook
                    </FacebookShareButton>
                </MenuItem>

                <MenuItem onClick={handleClose} sx={{ px: 2, py:1}}>
                    <TwitterShareButton url={shareUrl} style={{ display: 'flex', gap: 5, alignItems: "center" }}>
                        <TwitterIcon size={24} round={true} />
                        Twitter
                    </TwitterShareButton>
                </MenuItem>

                <MenuItem onClick={handleClose} sx={{ px: 2, py:1}}>
                    <WhatsappShareButton url={shareUrl} style={{ display: 'flex', gap: 5, alignItems: "center" }}>
                        <WhatsappIcon size={24} round={true} />
                        Whatsapp
                    </WhatsappShareButton>
                </MenuItem>

                <MenuItem onClick={handleClose} sx={{ px: 2, py:1}}>
                    <LinkedinShareButton url={shareUrl} style={{ display: 'flex', gap: 5, alignItems: "center" }}>
                        <LinkedinIcon size={24} round={true} />
                        Linkedin
                    </LinkedinShareButton>
                </MenuItem>

                <MenuItem onClick={handleClose} sx={{ px: 2, py:1}}>
                    <EmailShareButton url={shareUrl} style={{ display: 'flex', gap: 5, alignItems: "center" }}>
                        <EmailIcon size={24} round={true} />
                        Email
                    </EmailShareButton>
                </MenuItem>

                <MenuItem onClick={handleClose} sx={{ px: 2, py:1}}>
                    <Box onClick={handleCopy} sx={{ display: 'flex', gap: 0, alignItems: "center", fontWeight: 'bold', color: "#212b36" }}>
                        <CopyAllRounded size={24} round={true} />
                        Copy
                    </Box>
                </MenuItem>

            </Menu>
        </Box>
    )
}
