import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Dropdown, MobileDatePicker } from 'components';
import { genderOptions } from 'utils';
import { t } from 'i18next';
import { MuiTelInput } from 'mui-tel-input'

export const MyAccountForm = ({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched,
    errors, loading, anonymous_countriesData, anonymous_citiesData }) => {

    const handlePhoneNumberChange = (event) => {
        const phoneNumber = event.target.value.replace(/[^0-9]/g, '');
        handleChange({
            target: {
                name: 'phoneNumber',
                value: phoneNumber ? `+${phoneNumber}` : '',
            },
        });
    };

    return <Grid container spacing={3} sx={{ width: '100%', pl: 3 }}>
        <Grid item xs={12} md={6}>
            <TextField
                name="name"
                label={t("Name")}
                value={values?.name}
                onChange={(e => {
                    const enteredValue = e.target.value;
                    const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                    setFieldValue("name", capitalizedValue)
                })}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                name="surname"
                label={t("Surname")}
                value={values?.surname}
                onChange={(e => {
                    const enteredValue = e.target.value;
                    const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                    setFieldValue("surname", capitalizedValue)
                })}
                onBlur={handleBlur}
                error={Boolean(touched.surname && errors.surname)}
                helperText={touched.surname && errors.surname}
                fullWidth
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <MuiTelInput
                label={t("Phone Number")}
                name="phoneNumber"
                value={values.phoneNumber || '+90'}
                onChange={(e) => setFieldValue('phoneNumber', e)}
                defaultCountry={'tr'}
                variant="outlined"
                inputProps={{ maxLength: 20 }}
                fullWidth
                error={touched.phoneNumber && errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                onBlur={handleBlur}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Dropdown
                setFieldValue={setFieldValue}
                errors={errors}
                data={genderOptions}
                label={t("Gender")}
                labelId={"gender-label"}
                value={values.gender}
                id={"gender"}
                type={"withoutId"}
            />
            {/* <FormControl fullWidth>
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                    error={!!errors.gender}
                    name="gender"
                    labelId="gender"
                    label="Gender"
                    value={values.gender}
                    onChange={handleChange}
                >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                </Select>
            </FormControl> */}
        </Grid>
        <Grid item xs={12} md={6}>
            <MobileDatePicker
                label={t("Birthdate")}
                value={values.birthDate}
                setFieldValue={setFieldValue}
                name="birthDate"
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Dropdown
                setFieldValue={setFieldValue}
                errors={errors}
                data={anonymous_countriesData}
                label={t("Nationality")}
                labelId={"nationality-label"}
                value={values.nationality}
                id={"nationality"}
                type={"withFlag"}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Dropdown
                setFieldValue={setFieldValue}
                errors={errors}
                data={anonymous_countriesData}
                label={t("Country")}
                labelId={"country-label"}
                value={values.country}
                id={"country"}
                type={"withFlag"}
            />

        </Grid>
        <Grid item xs={12} md={6}>
            <Dropdown
                setFieldValue={setFieldValue}
                disabled={!values.country}
                errors={errors}
                data={anonymous_citiesData}
                label={t("City")}
                labelId={"city-label"}
                value={values.city}
                id={"city"}
            />
        </Grid>

        <Grid item xs={12}>
            <LoadingButton variant="contained" onClick={handleSubmit} loading={loading} loadingIndicator={<CircularProgress size={"1rem"} />} type="submit" size="large">
                {t('Save Changes')}
            </LoadingButton>
        </Grid>
    </Grid>

}

