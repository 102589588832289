import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";


const initialState = {
  user_internData: null,
  user_internLoading: false,
  user_internError: null,
};

export const user_internFunc = createAsyncThunk("user_intern/user_internFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `query User_intern($userInternId: ID!) {
      user_intern(id: $userInternId) {
        _id
        personalInformation {
          name
          surname
          profileImageUrl
          phoneNumber
          nationality {
            name
            code
          }
          gender
          description
          country {
            name
          }
          city {
            name
          }
          birthDate
        }
        cv {
          education {
            highSchoolEducations {
              name
              startDate
              finishDate
              department {
                name
              }
            }
            universityEducations {
              university {
                name
              }
              startDate
              finishDate
              department {
                name
              }
            }
          }
          experiences {
            position {
              name
            }
            positionDetail
            startDate
            endDate
            country {
              name
            }
            city {
              name
            }
            companyName
          }
          foreignLanguages {
            language {
              name
              metadata {
                code
              }
            }
            level {
              name
            }
          }
          skills {
            name
          }
          certificates {
            organization
            name
            issueDate
            expirationDate
          }
          coverLetters {
            name
            content
          }
        }
      }
    }`,
    variables: {
      "userInternId": values.internId
    }

  });


  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const user_intern = createSlice({
  name: "user_intern",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(user_internFunc.pending, (state) => {
      state.user_internLoading = true;
    });
    builder.addCase(user_internFunc.fulfilled, (state, action) => {
      state.user_internLoading = false;
      state.user_internData = action.payload?.user_intern;
      state.user_internError = action.payload.message;
    });
    builder.addCase(user_internFunc.rejected, (state, action) => {
      state.user_internLoading = false;
      state.user_internError = action.error.message;
    });
  }
});


export default user_intern.reducer;