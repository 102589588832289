export const InternshipStatus = {
  activeWithListing: {
    value: "activeWithListing",
    name: "Listing",
  },
  activeWithoutListing: {
    value: "activeWithoutListing",
    name: "Waiting to Start",
  },
  started: {
    value: "started",
    name: "Started",
  },
  decision: {
    value: "decision",
    name: "Decision",
  },
  completed: {
    value: "completed",
    name: "Completed",
  },
  failed: {
    value: "failed",
    name: "Failed",
  },
  expired: {
    value: "expired",
    name: "Expired",
  },
  archived: {
    value: "archived",
    name: "Archived",
  },
};

export const internshipStatusList = Object.values(InternshipStatus);

export const internshipStatusValueList = Object.values(InternshipStatus).map(
  (period) => period.value
);

export const internshipStatusNameList = Object.values(InternshipStatus).map(
  (period) => period.name
);

export function getValueOfInternshipStatus(name) {
  for (const key of Object.keys(InternshipStatus)) {
    if (InternshipStatus[key].name === name) {
      return InternshipStatus[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipStatus(value) {
  for (const key of Object.keys(InternshipStatus)) {
    if (InternshipStatus[key].value === value) {
      return InternshipStatus[key].name;
    }
  }
  return null;
}
