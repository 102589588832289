import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";


const initialState = {
  intern_applicationData: null,
  intern_applicationLoading: false,
  intern_applicationError: null,
};

export const intern_applicationFunc = createAsyncThunk("intern_application/intern_applicationFunc", async (values) => {
  console.log({ values })
  const data1 = JSON.stringify({
    query: `
        query User_application($applicationId: ID!) {
            user_application(applicationId: $applicationId) {
              _id
              intern {
                _id
              }
              updatedAt
              status
              internshipGoal
              createdAt
              coverLetter {
                name,
                content
              }
              internship {
                _id
                application {
                  _id
                  status
                }
                applicationType
                coffee
                company {
                  _id
                  city {
                    name
                    _id
                  }
                  country {
                    name
                    flagUrl
                    code
                    _id
                  }
                  displayImageUrl
                  displayName
                  industry {
                    name
                    _id
                  }
                  numberOfEmployee {
                    name
                    _id
                  }
                }
                createdAt
                description
                descriptionSummary
                dueDate
                interns {
                  _id
                  displayImageUrl
                  displayName
                  role
                }
                internshipArea
                internshipGoal
                # numberOfAcceptedInterns
                # numberOfAppliedInterns
                positionName
                # isSaved
                preferredCollegeYear
                preferredGPA
                preferredLanguages
                preferredSkills
                quota
                salary
                salaryType
                startDate
                status
                timePeriodInWeeks
                updatedAt
              }
            }
          }
        `,
    variables: {
      "applicationId": values?.applicationId,
    }

  });


  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data1, values?.accessToken);

  const response = axios(config)
    .then((res) => {
      const data = res.data.data;
      return data;
    })
    .catch((err) => {
      return err;
    });

  const data = await response;
  console.log({ data })
  return data;
});


const intern_application = createSlice({
  name: "intern_application",
  initialState,
  reducers: {
    intern_setApplicationNull: (state, action) => {
      state.intern_applicationData = null
      state.intern_applicationLoading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(intern_applicationFunc.pending, (state) => {
      state.intern_applicationLoading = true;
    });
    builder.addCase(intern_applicationFunc.fulfilled, (state, action) => {
      state.intern_applicationLoading = false;
      state.intern_applicationData = action.payload?.user_application
      state.intern_applicationError = action.payload.message;
    });
    builder.addCase(intern_applicationFunc.rejected, (state, action) => {
      state.intern_applicationLoading = false;
      state.intern_applicationError = action.error.message;
    });
  }
});


export default intern_application.reducer;

export const { intern_setApplicationNull } = intern_application.actions