import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../../initialConfig";


const initialState = {
  self_userData: null,
  self_userLoading: false,
  self_userError: null,
  defaultLanguageData: null,
};

export const self_userFunc = createAsyncThunk("self_user/self_userFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Self_user {
      self_user {
        settings {
          defaultLanguage
        }
      }
    }`
  });

  var config = initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const self_user = createSlice({
  name: "self_user",
  initialState,
  reducers: {
    setOnboardedIntern: (state, action) => {
      state.onboardedIntern = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(self_userFunc.pending, (state) => {
      state.self_userLoading = true;
    });
    builder.addCase(self_userFunc.fulfilled, (state, action) => {
      state.self_userLoading = false;
      state.self_userData = action.payload?.self_user;
      state.defaultLanguageData = action.payload?.self_user?.settings?.defaultLanguage;
      state.self_userError = action.payload.message;
    });
    builder.addCase(self_userFunc.rejected, (state, action) => {
      state.self_userLoading = false;
      state.self_userError = action.error.message;
    });
  }
});


export default self_user.reducer;