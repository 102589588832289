import { Box, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DefaultBackground from 'assets/images/default-background.png';


export const BackgroundImageAccount = ({ values, handleBackgroundChange, compressedBackground }) => {
    return <Box sx={{ width: '100%', height: "150px", position: 'relative' }}>
        <img src={
            compressedBackground?.url ? compressedBackground?.url :
                values?.backgroundImageUrl ? values?.backgroundImageUrl :
                    DefaultBackground}
            alt="my account background"
            style={{
                width: "100%", height: "150px", objectFit: "cover", zIndex: "-1", borderTopLeftRadius: "20px", borderTopRightRadius: "20px",
            }}
        />
        <IconButton onClick={() => document.getElementById('background-image-input').click()} sx={{
            position: 'absolute', top: 5, right: 5, zIndex: 2, backgroundColor: 'white', borderRadius: "50px",
            boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)"
        }}>
            <EditIcon />
        </IconButton>
        <input
            id="background-image-input"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleBackgroundChange}
        />
    </Box>
}

