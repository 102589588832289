
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Grid, Typography, Box, Divider, useMediaQuery } from "@mui/material";
import { DashboardLayout } from "layouts";
import { AddButtonInternshipSection } from "page-sections/dashboard/company/internship/_AddButtonInternshipSection";
import InternshipsSection from "page-sections/dashboard/company/internship/_InternshipsSection";
import { internshipStatusNameList, internshipStatusTabsNameList } from "utils";
import { AnimatedTitle } from "components";
import { GroupOutlined, TollOutlined, WorkHistoryOutlined } from "@mui/icons-material";
import { company_internshipTips, company_internshipTips_title } from "utils";
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";

const info = ["Faz 2 bitti."];
const tipTitle = company_internshipTips_title;
const tipDescription = company_internshipTips;

const Internships = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const { companyUser_internshipsCount: internshipsCount } = useSelector((state) => state.companyUser_internships);
  const { self_organization_billingInfoData: billingInfo } = useSelector((state) => state.self_organization_billingInfo);

  const handleAddButtonInternshipSectionClick = () => {
    navigate("/internship/createInternship");
  };

  return (
    <DashboardLayout info={info}>
      <Grid container sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
        <Grid item xs={12} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <AnimatedTitle title={`${t("Internships")}`} />
          <AddButtonInternshipSection onClick={handleAddButtonInternshipSectionClick} />
        </Grid>
        <Grid container spacing={{ xs: 0, sm: 2 }} mt={{ xs: 0, sm: 1 }} gap={{ xs: 2, sm: 0 }} >
          <Grid item xs={12} lg={7.6} xl={8.7}   >
            <Grid container spacing={{ xs: 0, sm: 3 }} gap={{ xs: 2, sm: 0 }} >
              <Grid item xs={12} sm={4}>
                <Card sx={{ backgroundColor: '#FF9737dd', padding: 2, alignItems: 'center', height: '100%' }}>
                  <WorkHistoryOutlined sx={{ color: 'white', fontSize: '35px', }} />
                  <Typography sx={{ fontSize: "25pt", fontWeight: 'bold' }} color={"white"}>{internshipsCount}</Typography>
                  <Typography variant="h5" color={"white"}>Internship</Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card sx={{ backgroundColor: '#248E9Ddd', padding: 2, alignItems: 'center', height: '100%' }} >
                  <GroupOutlined sx={{ color: 'white', fontSize: '35px', }} />
                  <Typography sx={{ fontSize: "25pt", fontWeight: 'bold' }} color={"white"}>0</Typography>
                  <Typography variant="h5" color={"white"}>Application</Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card sx={{ backgroundColor: '#3D7CF8dd', padding: 2, alignItems: 'center', height: '100%' }} >
                  <TollOutlined sx={{ color: 'white', fontSize: '35px', }} />
                  <Typography sx={{ fontSize: "25pt", fontWeight: 'bold' }} color={"white"}>{billingInfo?.credit?.credit?.usable}</Typography>
                  <Typography variant="h5" color={"white"}>Credit</Typography>
                </Card>
              </Grid>

              <Grid item xs={12} mt={3}>
                <InternshipsSection
                  internshipStatuses={internshipStatusNameList}
                  internshipStatusTabs={internshipStatusTabsNameList}
                  pageSize={10}
                />
              </Grid>
            </Grid>
          </Grid>
          {upLg && <Grid item xs={12} lg={4.4} xl={3.3}>
            <Card sx={{
              padding: 3, backgroundColor: "#F8F8F8", height: '100%', border: '1px solid #E0E0E0',
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
            }}>
              <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >{parse(tipTitle)}</Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="body1" fontSize={"small"} fontWeight={"regular"}>
                  {parse(tipDescription)}
                </Typography>
              </Box>

            </Card>
          </Grid>}
        </Grid>



      </Grid>
    </DashboardLayout>
  );
};

export default Internships;
