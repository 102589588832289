import React from 'react'
import { Avatar, AvatarGroup, Box, Typography } from '@mui/material'

import {
  CompletedTasksIcon,
  // InternshipProgressIcon,
  WorkedHoursIcon,
} from 'assets'
import { TaskWidget } from 'components'


const Widget3 = ({ interns }) => {

  return (
    <AvatarGroup
      total={interns?.length}
      max={4}
      sx={{
        alignItems: 'center',
        justifyContent: 'start',
        position: 'relative',
        top: '-30px',

        '& > div:first-of-type': {
          zIndex: 10,
          right: `${5 * 2}px !important`,
          width: '32px',
          height: '32px',
        }
      }}
    >
      {
        interns?.map((item, index) => {

          return (
            <Avatar
              key={index}
              alt="Remy Sharp"
              src={item?.personalInformation?.profileImageUrl}
              sx={{
                zIndex: index,
                right: `${5 * index}px !important`,
                width: '30px',
                height: '30px',
              }}

            />
          )
        })
      }
    </AvatarGroup>
  )
}



const tasks = []
const selectedApplication = null
export const TasksWidgetSection = () => {

  // const { tasks } = useSelector(state => state.tasks)
  // const { selectedApplication } = useSelector(state => state.applications)
  // console.log(selectedApplication?.internship?.interns)

  const completedTasksLength = tasks?.filter(task => task.status !== 'inprogress')?.length ?? 0
  const ongoingTasksLength = tasks?.filter(task => task.status === 'inprogress')?.length ?? 0

  return (
    <Box
      sx={{

        display: 'flex',
        flexDirection: { xs: 'row', md: 'row', lg: 'row', xl: 'row' },
        justifyContent: { md: 'space-between' },

        // overflow: 'auto',
        gap: '16px',
        // backgroundColor: 'red',
        overflow: 'auto',
        pb: { xs: '15px' },

        '&::-webkit-scrollbar': {
          width: '3px',
          height: '5px',
        },
        '&::-webkit-scrollbar-track': {
          // boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          // webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          // outline: '1px solid slategrey'
        }
      }}
    >

      <TaskWidget
        color='#248E9D'
        icon={CompletedTasksIcon}
        text={`${completedTasksLength}`}
        bottomText="Completed Tasks"
      />

      <TaskWidget
        color='#3D7CF8'
        icon={WorkedHoursIcon}
        text={`${ongoingTasksLength}`}
        bottomText="Ongoing Tasks"
      />

      <TaskWidget
        color='#FF9737'
        text={`${selectedApplication?.internship?.interns?.length ?? 0}`}
        bottomText="Total Team Members"
      >
        <Widget3 interns={selectedApplication?.internship?.interns ?? [1, 2, 3]} />
      </TaskWidget>

    </Box>
  )
}