import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    confirm2faData: null,
    confirm2faLoading: false,
    confirm2faError: null,
};


export const confirm2faFunc = createAsyncThunk("confirm2fa/confirm2faFunc", async (values) => {
    let data1 = JSON.stringify({
        "token": values.token,
        "code": values.code
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/confirmTwoFactorAuth', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const confirm2fa = createSlice({
    name: "confirm2fa",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // confirm2fa
        builder.addCase(confirm2faFunc.pending, (state) => {
            state.confirm2faLoading = true;
        }
        );
        builder.addCase(confirm2faFunc.fulfilled, (state, action) => {
            state.confirm2faLoading = false;
            state.confirm2faData = action.payload;
            state.confirm2faError = action.payload.message;
        }
        );
        builder.addCase(confirm2faFunc.rejected, (state, action) => {
            state.confirm2faLoading = false;
            state.confirm2faError = action.error.message;
        }
        );
    }
});


export default confirm2fa.reducer;