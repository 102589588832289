import { useRef } from 'react';
import { Grid } from '@mui/material'
import { useMediaQuery } from '@mui/material';
import { BackToHome } from 'components'
import { AuthSlider, PageOpening } from 'components';
import { BackgroundImage, AuthImage2, AuthImage1, CreateNewPassword } from 'assets'
import { useLocation } from 'react-router-dom';

const AuthLayout = ({ children }) => {
  // test comment

  const path = useLocation().pathname.split('/')[1];
  const pageRef = useRef(null);
  const downXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));


  return <PageOpening>
    <Grid container ref={pageRef}>
      {!downXs &&
        <Grid item xs={12} sm={5}
          sx={{
            backgroundImage: `url(${path === 'signin' ? AuthImage1 :
              path === 'check-your-email' ? AuthImage2 :
                path === 'create-new-password' ? CreateNewPassword :
                  path === 'forgot-password' ? CreateNewPassword :
                    AuthImage1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center',
          }}
        >
          <AuthSlider
            style={{
              height: pageRef.current ? pageRef.current.clientHeight : '100vh',
              maxHeight: pageRef.current ? pageRef.current.clientHeight : '100vh',
              display: { xs: 'none', md: 'block' },
              position: 'relative',
              zIndex: 1,
              pageHeight: pageRef.current ? pageRef.current.clientHeight : '100vh',
            }}
          />
        </Grid>}
      <Grid item xs={12} sm={7} display="flex" >
        <BackToHome />
        <Grid py={5} container justifyContent="center" alignItems="center">
          <Grid item xs={11} sm={9} md={6} lg={5} justifyContent="center" alignItems="center" flexDirection={"column"}>
            {children}
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  </PageOpening>
}

export default AuthLayout