import { Button, CircularProgress, Typography, Box } from '@mui/material'

export const WhiteButton = ({ isActive = true, handleSubmit, loading, name }) => {


    return (
        <Button
            variant='outlined'
            disabled={!isActive || loading}
            onClick={handleSubmit}
            sx={{
                backgroundColor: 'white',
                color: 'primary.light',
                width: '100%',
                borderRadius: '12px',
                textTransform: 'none',
                marginTop: '10px',
                gap: 2,
                height: '48px',
                borderWidth: '1px',
                borderColor: 'primary.lighter',
            }}
        >
            <Typography variant='h6' >
                {loading ? <Box mt={1}><CircularProgress size={20} pt={10} /></Box> : name}
            </Typography>
        </Button>
    )
}