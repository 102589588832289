import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "context/initialConfig";

const initialState = {
    self_organization_billingInfoData: null,
    self_organization_billingInfoLoading: false,
    self_organization_billingInfoError: null,
};

export const self_organization_billingInfoFunc = createAsyncThunk("self_organization_billingInfo/self_organization_billingInfoFunc",
    async (values) => {
        const data1 = JSON.stringify({
            query: `query Self_organization {
                self_organization {
                  invoiceInfo {
                    personal {
                      name
                      surname
                      email
                      id
                      phoneNumber
                      country
                      city
                      zip
                      address
                    }
                    type
                    business {
                      name
                      surname
                      email
                      corporateName
                      taxOffice
                      taxNumber
                      phoneNumber
                      country
                      city
                      zip
                      address
                    }
                  }
                  credit {
                    freeCredit {
                      usable
                      total
                      totalSpent
                      locked
                    }
                    credit {
                      usable
                      withdrawable
                      totalWithdrawable
                      locked
                      total
                      totalSpent
                    }
                  }
                }
              }`
        });

        const config = initialConfig(process.env.REACT_APP_BASE_URL + "/payment/graphql", data1, values?.accessToken);

        const response = axios(config)
            .then((res) => {
                const data = res.data.data;
                return data;
            })
            .catch((err) => {
                console.log("err:", err);
                return err;
            });

        const data = await response;
        return data;
    }
);

const self_organization_billingInfo = createSlice({
    name: "self_organization_billingInfo",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(self_organization_billingInfoFunc.pending, (state) => {
            state.self_organization_billingInfoLoading = true;
        });
        builder.addCase(self_organization_billingInfoFunc.fulfilled, (state, action) => {
            state.self_organization_billingInfoLoading = false;
            state.self_organization_billingInfoData = action.payload?.self_organization;
            state.self_organization_billingInfoError = action?.payload?.message;
        });
        builder.addCase(self_organization_billingInfoFunc.rejected, (state, action) => {
            state.self_organization_billingInfoLoading = false;
            state.self_organization_billingInfoError = action.error.message;
        });
    },
});

export default self_organization_billingInfo.reducer;
