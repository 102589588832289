import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Card, Button } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import animateScrollTo from 'animated-scroll-to';
import Lottie from 'lottie-react';
import PaymentFailed from "assets/animations/payment_failed.json";
import { AnimatedTitle } from 'components';
import { t } from 'i18next'





const PaymentFailIntern = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId');
    const [localOrderId, setLocalOrderId] = useState(localStorage.getItem('orderId'))
    const [localSelectedProduct, setLocalSelectedProduct] = useState(localStorage.getItem('selectedProduct'))

    useEffect(() => {
        animateScrollTo(0, { maxDuration: 1000 })
    }, [])


    return <DashboardLayout>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', gap: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                <AnimatedTitle title={`${t("Payment Result")}`} />
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"} >
                <Card sx={{ p: 2, mb: 2, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box width="100%" >
                        <Typography variant="h6" textAlign={"center"} mb={5}>{t("Payment is failed.")}</Typography>
                    </Box>
                    <Lottie
                        animationData={PaymentFailed}
                        style={{ width: '100%', height: '280px' }}
                        autoplay={true}
                        loop={false}
                    />

                    {/* <Typography variant="body1" textAlign={"center"} mt={5}>{t("Your payment has been completed successfully. However, it may take up to 5 minutes for your package to be reflected in your account.")}</Typography> */}

                    <Button variant="contained" color="primary" size='large'
                        onClick={() => navigate('/premium?orderId=' + localOrderId + '&selectedProduct=' + localSelectedProduct)}
                        sx={{ mt: 5 }}>{t("Re Order")}</Button>
                </Card>

            </Grid>
        </Grid>
    </DashboardLayout>
}

export default PaymentFailIntern