import { useState, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box, Typography, IconButton, TextField, Grid, Card, Slide, Dialog, DialogTitle, DialogContent,
    DialogActions, Button, FormGroup, FormControlLabel, Checkbox,
} from '@mui/material';
import { SettingsContainer } from 'components';
import CertificateIcon from 'assets/icons/onboarding/upper-text-certificates-icon.png';
import AddIcon from '@mui/icons-material/Add';
import { DeleteOutline, Update } from '@mui/icons-material';
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import { format, set } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useFormik } from "formik";
import {
    self_internFunc, intern_updateCertificateFunc, intern_addCertificateFunc, intern_removeCertificateFunc
} from 'context';
import {t} from 'i18next'


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ResumeCertificates = ({ user }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector((state) => state.user);
    const { user_parameters_educationsData: educations, user_parametersData: parameters,
        anonymous_countriesData: countries, anonymous_citiesData: cities
    } = useSelector((state) => state.parameters);
    const [checkStillAttending, setCheckStillAttending] = useState(false)
    const [editableCertificate, setEditableCertificate] = useState(null)
    const [openCertificate, setOpenCertificate] = useState(false)
    const [position, setPosition] = useState("")
    const [selectedCertificates, setSelectedCertificates] = useState([])


    const userInitialValues = {
        name: "",
        organization: "",
        issueDate: null,
        expirationDate: null,
        birthDate: user?.personalInformation?.birthDate ? new Date(user?.personalInformation?.birthDate) : null,
    }

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        onSubmit: null
    })

    const handleClick = () => {
        setOpenCertificate(true)
        setFieldValue("name", "");
        setFieldValue("organization", "");
        setFieldValue("issueDate", null);
        setFieldValue("expirationDate", null);
        setCheckStillAttending(false);
        setEditableCertificate(null)
        setPosition(null)
    };


    const handleAddCertificate = () => {
        intern_addCertificateFunc({
            values: values,
            accessToken: accessToken
        }).then(res => {
            if (res?._id) {
                setOpenCertificate(false)
                enqueueSnackbar("The certificate has been added!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })
    }

    const handleUpdateCertificate = () => {

        intern_updateCertificateFunc({
            values: values,
            id: editableCertificate.id,
            accessToken: accessToken
        }).then(res => {
            if (res?._id) {
                setOpenCertificate(false)
                enqueueSnackbar("The certificate has been updated!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        }
        )
    }

    const handleRemoveCertificate = () => {

        intern_removeCertificateFunc({
            id: editableCertificate.id,
            accessToken: accessToken
        }).then(res => {
            if (res?.success) {
                setOpenCertificate(false)
                enqueueSnackbar("The certificate has been removed!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        }
        )



    }


    useEffect(() => {
        setSelectedCertificates(user?.cv?.certificates)
    }, [user?.cv?.certificates])

    return <>
        <SettingsContainer pb={2} mb={3}>

            <Box pl={3} pt={3} pr={3}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h5">
                       {t('Certificates')}
                    </Typography>
                    <IconButton
                        disabled={selectedCertificates?.length >= 8}
                        sx={{ ml: 1 }}
                        aria-label="edit"
                        onClick={handleClick}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
                <>
                    {(selectedCertificates?.length === 0 || !selectedCertificates) && <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" color={"grey.600"} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center', pb:4 }}>   {t("No certificate added yet.")} </Typography>
                        </Grid>
                    </Grid>}
                    <Grid container sx={{ width: '100%', mt: "10px", gap: { xs: 1, sm: 0 } }} spacing={{ xs: 0, sm: 2 }} >
                        {selectedCertificates?.length > 0 &&
                            selectedCertificates?.map((item, index) => (
                                <Grid item xs={12} md={3}>
                                    <Card sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2,
                                        '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)", cursor: "pointer" }
                                    }} onClick={() => {
                                        setOpenCertificate(true)
                                        setFieldValue("name", item?.name);
                                        setFieldValue("organization", item?.organization);
                                        setFieldValue("issueDate", new Date(item?.issueDate));
                                        setFieldValue("expirationDate", new Date(item?.expirationDate));
                                        setCheckStillAttending(!item?.expirationDate);
                                        setEditableCertificate({ id: item?._id })
                                    }}>
                                        <img src={CertificateIcon} alt="certificate" style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>   {item?.name} </Typography>
                                        <Typography variant="h7" sx={{ fontWeight: 'bold', textAlign: 'center' }}> {item?.organization}</Typography>
                                        <Typography variant="caption" sx={{ textAlign: 'center' }}>
                                            {format(new Date(item?.issueDate), 'MMM yyyy')} /
                                            {item?.expirationDate ? format(new Date(item?.expirationDate), ' MMM yyyy') : ' Does not expire'}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))
                        }

                    </Grid>
                </>
            </Box>

        </SettingsContainer>


        <Dialog
            open={openCertificate}
            keepMounted
            onClose={() => setOpenCertificate(false)}
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={Transition}
        >
            <DialogTitle>{editableCertificate ? 'Update Certificate' : 'Add Certificate'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ width: '100%', mt: "10px", justifyContent: 'flex-end' }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Certificate Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="name"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Organization"
                            value={values.organization}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="organization"
                            error={touched.organization && Boolean(errors.organization)}
                            helperText={touched.organization && errors.organization}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDPicker
                            sx={{ width: '100%' }}
                            label="Issue Date"
                            value={values.issueDate ? new Date(values.issueDate) : null}
                            openTo="month"
                            onChange={(value) => setFieldValue("issueDate", value)}
                            name="issueDate"
                            views={['month', 'year']}
                            maxDate={new Date()}
                            minDate={values.birthDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDPicker
                            disabled={checkStillAttending || !values.issueDate}
                            sx={{ width: '100%' }}
                            label={!checkStillAttending ? "Expiration Date" : "Does not expire"}
                            value={values.expirationDate ? new Date(values.expirationDate) : null}
                            openTo="month"
                            onChange={(value) => setFieldValue("expirationDate", value)}
                            name="expirationDate"
                            views={['month', 'year']}
                            maxDate={new Date()}
                            minDate={new Date(values.issueDate)}
                        />
                        <FormGroup sx={{ mt: 1 }}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={checkStillAttending}
                                    onChange={(e) => {
                                        setCheckStillAttending(e.target.checked)
                                        setFieldValue("expirationDate", null)
                                    }}
                                />} label="This credential does not expire" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>

                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button color="primary" variant="outlined" onClick={() => setOpenCertificate(false)}>Cancel</Button>
                {editableCertificate && <Button color="error" variant="outlined" onClick={handleRemoveCertificate}>
                    <DeleteOutline sx={{ mr: 1 }} fontSize="small" />
                    Remove University</Button>}
                <Button
                    disabled={!(values.name && values.organization && values.issueDate && (values.expirationDate || checkStillAttending))}
                    color="primary" variant="contained" onClick={editableCertificate ? handleUpdateCertificate : handleAddCertificate}><Update sx={{ mr: 1 }} fontSize="small" /> {editableCertificate ? 'Update Certificate' : 'Add Certificate'}</Button>
            </DialogActions>
        </Dialog>




    </>
}
