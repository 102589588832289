import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    resendRegistrationConfirmationData: null,
    resendRegistrationConfirmationLoading: false,
    resendRegistrationConfirmationError: null,
};


export const resendRegistrationConfirmationFunc = createAsyncThunk("resendRegistrationConfirmation/resendRegistrationConfirmationFunc", async (values) => {
    let data1 = JSON.stringify({
        "email": values.email
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/resendRegistrationConfirmation', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});



const resendRegistrationConfirmation = createSlice({
    name: "resendRegistrationConfirmation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resendRegistrationConfirmationFunc.pending, (state) => {
            state.resendRegistrationConfirmationLoading = true;
        });
        builder.addCase(resendRegistrationConfirmationFunc.fulfilled, (state, action) => {
            state.resendRegistrationConfirmationLoading = false;
            state.resendRegistrationConfirmationData = action.payload;
            state.resendRegistrationConfirmationError = action.payload.message;
        });
        builder.addCase(resendRegistrationConfirmationFunc.rejected, (state, action) => {
            state.resendRegistrationConfirmationLoading = false;
            state.resendRegistrationConfirmationError = action.error.message;
        });
    }
});


export default resendRegistrationConfirmation.reducer;