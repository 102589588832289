import React from "react"
import { Box, IconButton, useMediaQuery, TextField } from "@mui/material"

import { SearchRounded } from "@mui/icons-material"
import { t } from "i18next";


export const SearchInputComponent = ({ search, setSearch, style,setSearchOnFocus }) => {

    const isMobile = useMediaQuery('(max-width:600px)');

    if (!isMobile) {
        return (
            <Box
                sx={{
                    width: '100%',
                    borderRadius: '12px',
                    backgroundColor: 'white',
                    position: 'relative',
                    ...style
                }}
            >
                <IconButton sx={{ position: 'absolute', right: '8px', top: '5px', zIndex: 3 }}>
                    <SearchRounded />
                </IconButton>
                <TextField
                    value={search ?? ""}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t('Search')}
                    fullWidth
                    onFocus={() => setSearchOnFocus(true)}
                    onBlur={() => setSearchOnFocus(false)}
                />
            </Box>
        )
    }


    return (
        <Box
            sx={{
                width: '100%',
                borderRadius: '8px',
                border: '1px solid #E3E7F2',
                backgroundColor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <TextField
                value={search ?? ""}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t('Search')}
                fullWidth
            />
            <IconButton>
                <SearchRounded />
            </IconButton>
        </Box>
    )
}
