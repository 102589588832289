import { Grid } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import { DocumentsListSection, DocumentsWidgetSection, RecentActivitiesSection } from 'page-sections'
import { demoDocumentsData, demoRecentActivitiesData } from 'dummy-data'
import { t } from 'i18next'

const info = [
    "Faz 0 bitti.",
    "Faz 1 başlamadı",
]

const Documents = () => {

    return (
        <DashboardLayout info={info}>
            <Grid container spacing={{xs:0, sm:3}} sx={{ p: {xs:0, sm:5}, pr: 0, width: '100%', }}>

                <Grid item xs={12} >
                    <AnimatedTitle title={t("Documents")} />
                </Grid>

                <Grid item xs={12} xl={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <DocumentsWidgetSection />
                        </Grid>

                        <Grid item xs={12} >
                            <DocumentsListSection documentsList={demoDocumentsData} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} xl={3}>
                    <RecentActivitiesSection recentActivities={demoRecentActivitiesData} isSideInfo />
                </Grid>

            </Grid>
        </DashboardLayout>
    )
}

export default Documents