import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";


const initialState = {
  companyAdmin_tasksData: null,
  companyAdmin_tasksCount: 0,
  companyAdmin_tasksLoading: false,
  companyAdmin_tasksError: null,
};

export const companyAdmin_tasksFunc = createAsyncThunk("companyAdmin_tasks/companyAdmin_tasksFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `query CompanyAdmin_tasks($input: TasksInput) {
  companyAdmin_tasks(input: $input) {
    count
    data {
      _id
      allowableFileFormats
      assignedTasks {
        _id
        comments {
          _id
          content
          createdAt
          parentModel {
            _id
          }
          replies {
            _id
            content
            createdAt
            tags
            updatedAt
          }
          tags
          type
          updatedAt
          createdBy {
            _id
          }
        }
        createdAt
          attachments {
          _id
         # name
          fileName
        }
        intern {
          _id
        }
        status
  
        updatedAt
      }
      assigner {
        _id
      }
      attachments {
        _id
        caption
        createdAt
        fileName
        isPrivate
       # name
        thumbnailUrl
        updatedAt
        url
      }
      createdAt
      createdBy {
        _id
      }
      description
      difficulty
      dueDate
      duration
      internship {
        _id
      }
      name
      priority
      reminder
      startDate
      statusHistory {
        _id
        createdAt
        date
        prevStatus
        reason
        updatedAt
        updatedStatus
      }
      taskStatus
      taskType
      updatedAt
      updatedBy {
        _id
      }
    }
  }
}`,
    variables: {
      "input": {
        "filter": {
          "allowableFileFormats": values?.allowableFileFormats ?? null,
          "assignedTasks": values?.assignedTasks ?? null,
          "difficulty": values?.difficulty ?? null,
          "duration": values?.duration ?? null,
          "isEvaluated": values?.isEvaluated ?? null,
          "name": values?.name ?? null,
          "priority": values?.priority ?? null,
          "reminder": values?.reminder ?? null,
          "taskStatus": values?.taskStatus ?? null,
          "taskType": values?.taskType ?? null
        },
        "pagination": {
          "page": values?.page ?? 1,
          "pageSize": values?.pageSize ?? 10,
          "sort": values?.sort ?? null
        }
      }
    }

  });

  const config = initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const companyAdmin_tasks = createSlice({
  name: "companyAdmin_tasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyAdmin_tasksFunc.pending, (state) => {
      state.companyAdmin_tasksLoading = true;
    });
    builder.addCase(companyAdmin_tasksFunc.fulfilled, (state, action) => {
      state.companyAdmin_tasksLoading = false;
      state.companyAdmin_tasksData = action.payload?.companyAdmin_tasks?.data;
      state.companyAdmin_tasksCount = action.payload?.companyAdmin_tasks?.count;
      state.companyAdmin_tasksError = action?.payload?.message;
    });
    builder.addCase(companyAdmin_tasksFunc.rejected, (state, action) => {
      state.companyAdmin_tasksLoading = false;
      state.companyAdmin_tasksError = action.error.message;
    });
  }
});


export default companyAdmin_tasks.reducer;