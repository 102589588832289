import React from 'react'
import { Grid } from '@mui/material'
import { t } from 'i18next'
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import { DocumentsForMyInternshipSection, LastMessagesSection, MyInternshipWidgetSection, RecentActivitiesSection, SideCompanyInfoSection, TasksListSection } from 'page-sections'
import { demoTaskData, demoRecentActivitiesData, demoLastMessagesData, demoDocumentsData } from 'dummy-data'


const info = [
    "Faz 2 bitti."
]

const MyCompany = () => {
    return (
        <DashboardLayout info={info}>
            <Grid container spacing={{xs:0, sm:3}} sx={{ p: {xs:0, sm:5}, width: '100%', }}>

                <Grid item xs={12} >
                    <AnimatedTitle title={`${t("My Internship")}`} />
                </Grid>

                <Grid item xs={12} xl={9}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} >
                            <MyInternshipWidgetSection />
                        </Grid>

                        <Grid item xs={12} >
                            <TasksListSection tasks={demoTaskData} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RecentActivitiesSection recentActivities={demoRecentActivitiesData} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <LastMessagesSection lastMessages={demoLastMessagesData} />
                        </Grid>

                        <Grid item xs={12} >
                            <DocumentsForMyInternshipSection documentsList={demoDocumentsData.slice(0, 4)} />
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12} xl={3}>
                    <SideCompanyInfoSection isDemo />
                </Grid>

            </Grid>
        </DashboardLayout>
    )
}

export default MyCompany