import moment from 'moment'
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Grid, SwipeableDrawer, Typography, Tooltip } from '@mui/material'
import {
    ExpandMore, CalendarMonth, FactoryOutlined, PeopleOutline, SummarizeOutlined, SchoolOutlined, SettingsSuggestOutlined,
    LibraryAddCheckOutlined, PaymentsOutlined, CoffeeOutlined, LanguageOutlined, Favorite, FavoriteBorder, BusinessCenterOutlined,
    InfoOutlined
} from '@mui/icons-material'

import { ShareButtonsMenuContainer } from 'components'
import { ApplyToInternship } from './ApplyToInternship'
import {
    getNameOfInternshipTimePeriod, getNameOfInternshipArea, getNameOfInternshipLanguage,
    getNameOfInternshipPreferredGPA, getNameOfInternshipPreferredCollegeYear, getNameOfInternshipCoffee
} from 'utils'
import Iconify from 'minimal/components/iconify/iconify'
import { t } from 'i18next'



export const InternshipDetailDrawer = ({ handleCloseDetail, internship, handleLikeAndDislike, internshipDetailDrawer }) => {

    const navigate = useNavigate();

    function removeHtmlTags(textWithHtml) {
        return textWithHtml?.replace(/<[^>]*>/g, '')?.slice(0, 350) + '...';
    }

    return (
        <SwipeableDrawer
            anchor="right"
            open={internshipDetailDrawer && internship}
            onClose={() => handleCloseDetail()}
            onOpen={() => null}
        >
            <Box
                sx={{
                    minWidth: { xs: '100%', md: '600px' },
                    maxWidth: { xs: '100%', md: '600px' },
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 3,
                    overflowY: 'scroll',
                    pb: 15,
                    height: '100vh',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        right: 0,
                        width: { xs: '100%', md: '600px' },
                        backgroundColor: '#fff',
                        zIndex: 10,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 1, cursor: 'pointer' }} onClick={() => navigate(`/internship/${internship?._id}`)}>
                        <Typography variant="h7" sx={{ color: '#555', textDecoration: 'underline' }} >{t("View Details")}</Typography>
                        {/* <ExpandMore sx={{ color: '#555' }} /> */}
                    </Box>

                    <Box
                        sx={{
                            p: '24px',
                            px: { xs: '15px', sm: '15px', },
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.05)',
                        }}
                    >
                        <Button variant='outlined' sx={{ px: 5, width: '45%' }} color='primary' size='large' onClick={handleLikeAndDislike}>
                            {internship?.isSaved ? <Favorite color='secondary' /> : <FavoriteBorder color='primary' />}
                            <Box width={5} />
                            {internship?.isSaved ? t('Unsave') : t('Save')}
                        </Button>
                        <ApplyToInternship internship={internship} />
                    </Box>

                </Box>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar src={internship?.company?.displayImageUrl} sx={{ width: '80px', height: '80px' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h4">{internship?.positionName}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography onClick={() => navigate(`/company/${internship?.company?._id}`)}
                                    style={{ cursor: 'pointer' }}
                                    variant="h7">{internship?.company?.displayName}</Typography>
                                <Typography variant="h5" color={"GrayText"} >•</Typography>
                                <Typography variant="h7" color={"GrayText"} >Posted {moment(internship?.createdAt).fromNow()}</Typography>
                                <Typography variant="h5" color={"GrayText"} >•</Typography>
                                <Typography variant="h7" color={"GrayText"} >{getNameOfInternshipTimePeriod(internship?.timePeriodInWeeks)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Iconify icon="carbon:view" color="GrayText" width="17px" />
                                <Typography fontSize={"small"} color={"GrayText"} >    {internship?.viewCount} view{internship?.viewCount > 1 && 's'}  </Typography>
                            </Box>
                        </Box>


                        <Box
                            sx={{
                                // bgcolor: 'red',
                                flex: 1,
                                height: '100%',
                                display: 'flex',
                                justifyContent: "end"
                            }}
                        >
                            <ShareButtonsMenuContainer shareUrl={`https://internif.com/internship/${internship?._id}`} />
                        </Box>

                    </Grid>
                </Grid>

                <Grid container sx={{ mb: 2 }}>
                    <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <img src={require('assets/icons/dashboard/internship-details-country.png')} alt="internship-details-country" />
                        <Typography variant="h8">{internship?.company?.country?.name}</Typography>
                    </Grid>
                    <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <img src={require('assets/icons/dashboard/internship-details-employee.png')} alt="internship-details-employee" />
                        <Typography variant="h8">{internship?.company?.numberOfEmployee?.name} Employees</Typography>
                    </Grid>
                    <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <img src={require('assets/icons/dashboard/internship-details-healt.png')} alt="internship-details-area" />
                        <Typography variant="h8">{getNameOfInternshipArea(internship?.internshipArea)?.slice(0, 150)}...</Typography>
                    </Grid>
                </Grid>

                <Grid container sx={{ my: 2 }}>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                        <BusinessCenterOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Internship Type")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.type === "indefinite" ? t("Indefinite") : t("Scheduled")}</Typography>
                    </Grid>
                    {internship?.type !== "indefinite" && <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <CalendarMonth color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Start Date")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{moment(internship?.startDate).format("DD MMMM YYYY")}</Typography>
                    </Grid>}
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <LibraryAddCheckOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Application Type")}:</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.applicationType.charAt(0).toUpperCase() + internship?.applicationType.slice(1)}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <FactoryOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Industry")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.company?.industry?.name}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <PaymentsOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Salary")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.salaryType === "Paid" ? internship?.salary :
                            internship?.salaryType === "Volunteer" ? "Volunteer" : null}</Typography>
                        <Tooltip title={t("Salary")} placement="top" sx={{ mt: -0.5, cursor: 'pointer' }}>
                            <InfoOutlined color='primary' fontSize='inherit' />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <PeopleOutline color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Quota")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.quota}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <CoffeeOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Coffee")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipCoffee(internship?.coffee)}</Typography>
                        <Tooltip title={t("Coffee")} placement="top" sx={{ mt: -0.5, cursor: 'pointer' }}>
                            <InfoOutlined color='primary' fontSize='inherit' />
                        </Tooltip>
                    </Grid>

                </Grid>

                <Grid container sx={{ mb: 2, overflowX: 'clip' }} flexDirection={"column"} >
                    <Typography variant="h7">{t("Description")}</Typography>
                    <Typography variant="body2">{removeHtmlTags(internship?.description)}</Typography>
                </Grid>

                <Grid container sx={{ my: 2 }}>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                        <LanguageOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Preferred Languages")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.preferredLanguages?.map((language, index) => <span key={language}>{index !== 0 && ','}  {getNameOfInternshipLanguage(language)} </span>)}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <SummarizeOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Preferred GPA")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipPreferredGPA(internship?.preferredGPA)}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <SchoolOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Preferred College Year")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipPreferredCollegeYear(internship?.preferredCollegeYear)}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', mt: 1 }}>
                        <SettingsSuggestOutlined color='alternative1' />
                        <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >{t("Preferred Skills")} :</Typography>
                        <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.preferredSkills?.map((skill, index) => <span key={skill}>{index !== 0 && ','} {skill}</span>)}</Typography>
                    </Grid>
                </Grid>


            </Box>
        </SwipeableDrawer >

    )
}
