import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Grid, Typography, Card, Divider } from '@mui/material'
import moment from 'moment'
import { ApplicantDetailDrawer, ApplicationsSection } from 'page-sections'
import { AnimatedTitle } from 'components'
import { DashboardLayout } from 'layouts'
import {
    ApplicationStatusTabsNameList, getNameOfInternshipArea, getNameOfInternshipCoffee,
    getNameOfInternshipFollowUpUserType, getNameOfInternshipPreferredCollegeYear, getNameOfInternshipPreferredGPA,
    getNameOfInternshipGoalForCompany, getNameOfInternshipStatus, getNameOfInternshipLanguage
} from 'utils'
import { companyUser_internshipFunc } from 'context'
import {
    ChevronLeft, CalendarMonth, LibraryAddCheckOutlined, PaymentsOutlined, SummarizeOutlined, SchoolOutlined,
    PeopleOutline, CoffeeOutlined, ManageAccountsOutlined, LanguageOutlined, SettingsSuggestOutlined
} from '@mui/icons-material';
import Label from 'minimal/components/label'
import parse from 'html-react-parser';
import Iconify from 'minimal/components/iconify/iconify'


const info = [
    "faz 2 bitti."
]


const InternshipDetail = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedApplicant, setSelectedApplicant] = useState(null)
    const [selectedApplication, setSelectedApplication] = useState(null)
    const { accessToken } = useSelector(state => state.user);
    const { companyUser_internshipData: internship } = useSelector(state => state.companyUser_internship);

    useEffect(() => {
        dispatch(companyUser_internshipFunc({ internshipId: id, accessToken }))
    }, [accessToken, dispatch, id])


    return (
        <DashboardLayout info={info}>
            <Button
                style={{ position: 'absolute', top: 0, left: 20 }}
                onClick={() => {
                    navigate("/internship")
                }}
            >
                <ChevronLeft /> Back
            </Button>
            <Grid container sx={{ p: { xs: 0, md: 4 } }}>
                <Grid item xs={12} sm={7} lg={8} xl={9} p={2} pr={{ xs: 0, md: 5 }} >
                    <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ mb: 2, mt: 2 }}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <AnimatedTitle title={internship?.positionName} />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="h7" color={"GrayText"} >Posted {moment(internship?.createdAt).fromNow()}</Typography>
                                </Box>
                            </Box>
                            <Box mt={{ xs: 2, sm: 0 }}>
                                <Label color="secondary"
                                    sx={{ borderRadius: 200, minWidth: 90 }}
                                > {getNameOfInternshipStatus(internship?.status)} </Label>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mb: 2, mt: 5, pr: 4, gap: { xs: 1, md: 0 } }}>


                        <Grid xs={12} md={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, justifyContent: { xs: 'flex-start', md: 'flex-start' } }}>
                            <img src={require('assets/icons/dashboard/internship-details-healt.png')} alt="internship-details-area" />
                            <Typography variant="h8">{getNameOfInternshipArea(internship?.internshipArea)}</Typography>
                        </Grid>

                        <Grid xs={12} md={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'flex-start', md: 'center' }, gap: 1 }}>
                            <img src={require('assets/icons/dashboard/internship-details-country.png')} alt="internship-details-country" />
                            <Typography variant="h8">{getNameOfInternshipGoalForCompany(internship?.internshipGoal)}</Typography>
                        </Grid>
                        <Grid xs={12} md={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'flex-start', md: 'flex-end' }, gap: 1 }}>
                            <img src={require('assets/icons/dashboard/internship-details-employee.png')} alt="internship-details-employee" />
                            <Typography variant="h8">{internship?.quota} intern quota</Typography>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mb: 2, overflowX: 'clip' }} flexDirection={"column"} >
                        <Typography variant="body2">{parse(internship?.description ?? '')}</Typography>
                    </Grid>

                    <ApplicationsSection
                        pageSize={2}
                        setSelectedApplicant={setSelectedApplicant}
                        setSelectedApplication={setSelectedApplication}
                        applicationStatuses={ApplicationStatusTabsNameList}
                    />

                </Grid>
                <Grid item xs={12} sm={5} lg={4} xl={3} >
                    <Card sx={{ py: 3, px: 2, display: "flex", flexDirection: "column", justifyContent: 'flex-start' }}>
                        <Typography variant="h6" textAlign={"center"}>Internship Info</Typography>
                        <Divider sx={{ width: '100%', mt:1 }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 3 }}>
                            <Iconify icon="ion:calendar-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Start Date :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{moment(internship?.startDate).format("DD MMMM YYYY")}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:calendar-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Due Date :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{moment(internship?.dueDate).format("DD MMMM YYYY")}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:albums-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Application Type :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.applicationType.charAt(0).toUpperCase() + internship?.applicationType.slice(1)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ic:baseline-language" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Language:</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipLanguage(internship?.language)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:cash-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Salary :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.salaryType === "Paid" ? internship?.salary :
                            internship?.salaryType === "Volunteer" ? "Volunteer" : null}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:people-circle-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Quota :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.quota}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="line-md:buy-me-a-coffee-filled" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Coffee :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipCoffee(internship?.coffee)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:eye-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Follow up User Type :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipFollowUpUserType(internship?.followUpUserType)}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                            <Typography variant="h7" >Preferences</Typography>
                            <Divider sx={{ width: '100%' }} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:language-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Languages :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.preferredLanguages?.map((language, index) => <span key={language}>{index !== 0 && ','}  {getNameOfInternshipLanguage(language)} </span>)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:ribbon-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >GPA :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipPreferredGPA(internship?.preferredGPA)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:school-outline" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >College Year :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{getNameOfInternshipPreferredCollegeYear(internship?.preferredCollegeYear)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:flask" color='alternative1' />
                            <Typography variant="h8" sx={{ fontWeight: 'regular' }}    >Skills :</Typography>
                            <Typography variant="h8" sx={{ fontWeight: 'bold' }} >{internship?.preferredSkills?.map((skill, index) => <span key={skill}>{index !== 0 && ','}  {skill}</span>)}</Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>


            <ApplicantDetailDrawer
                selectedApplicant={selectedApplicant}
                setSelectedApplicant={setSelectedApplicant}
                selectedApplication={selectedApplication}
                setSelectedApplication={setSelectedApplication}

            />

        </DashboardLayout >
    )
}


export default InternshipDetail