import { Box, Typography } from "@mui/material";
import MyAccountIcon from 'assets/icons/dashboard/my-account.png';
import { t } from "i18next"



export const CardHeaderAccount = () => {
    return <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} pl={3} mt={2} mb={5}>
        <img src={MyAccountIcon} alt="my account icon" style={{ width: "55px", height: "55px", objectFit: "contain" }} />
        <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"} ml={2}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t('Account Information')}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "400" }}>
                {t('You can enter information about your account.')}
            </Typography>
        </Box>
    </Box>
}

