import React from 'react'
import moment from 'moment/moment';
import parse from 'html-react-parser';
import { Box, Button, Typography } from '@mui/material'

import DoneIcon from '@mui/icons-material/Done';
// import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import TourIcon from '@mui/icons-material/Tour';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

import { TaskOngoingIcon } from 'assets';
import { CardContainer } from 'components';
import { priorityColorForTask } from 'utils';


export const TaskCard = ({
    noEdit = false,
    task = null,
    handleTaskDone = () => null,
    handleEditTask = () => null,
    handleClickToTask = () => null,
    handleOpenTaskDetail = () => null,
}) => {

    const isDone = task?.status === 'completed'

    return (
        <CardContainer>
            <Box
                onClick={handleClickToTask}
                sx={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E5EAED",
                    borderRadius: "8px",
                    position: "relative",
                    display: "flex",
                    gap: { xs: '5px', sm: '16px' },
                    // cursor: "pointer",
                    overflow: "hidden",
                    '&:hover': {
                        border: "1px solid #fff",
                    },
                    '&::-webkit-scrollbar': {
                        width: '3px',
                        height: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.1)',
                    }
                }}
            >

                <Box
                    onClick={handleTaskDone}
                    sx={{
                        backgroundColor: isDone ? "#00B54366" : '#FF973799',
                        p: { xs: '12px', sm: '16px' },
                    }}
                >
                    <Box

                        sx={{
                            width: "22px",
                            height: "22px",
                            borderRadius: "50%",
                            border: isDone ? "1px solid #00B543" : "1px solid #ccc",
                            backgroundColor: isDone ? '#00B543' : '#fff',
                            cursor: "pointer",

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            p: { xs: '2px' },
                        }}
                    >
                        {
                            isDone ? (
                                <DoneIcon
                                    sx={{
                                        color: '#fff',
                                        width: "20px",
                                        height: "20px",
                                    }}

                                />
                            ) : (
                                <>

                                    {/* <img src={TaskOngoingIcon} alt="" style={{ width: "20px", height: "20px" }} /> */}
                                </>
                            )
                        }
                        {/* <CheckCircleOutlineRoundedIcon
                        sx={{
                            color: '#fff',
                            width: "20px",
                            height: "20px",
                        }}

                    /> */}
                    </Box>
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: { xs: "column", sm: 'row' },
                        justifyContent: "space-between",
                        p: { xs: '12px', sm: '16px' },
                    }}
                >

                    <Box>

                        <Box sx={{ mb: { xs: '12px', } }} >

                            <Typography
                                onClick={handleOpenTaskDetail}
                                sx={{
                                    fontFamily: 'Causten-SemiBold',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: isDone ? '#A1ABC5' : '#0E1436',
                                    textDecoration: isDone ? 'line-through' : 'none',
                                    textTransform: 'capitalize',
                                    cursor: "pointer",
                                    display: "inline",

                                    '&:hover': {
                                        textDecoration: 'underline',
                                    }
                                }}
                            >
                                {task?.name}
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: { xs: '14px', },
                                    color: "#666",
                                    fontFamily: 'Causten-Regular',
                                }}
                            >
                                {parse(task?.description?.slice(0, 250))}
                            </Typography>

                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: { xs: '10px', sm: '30px' },
                                flexWrap: 'wrap',
                            }}
                        >

                            <Box  //############# assigner
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'end',
                                    gap: '7px',
                                    order: { xs: '1', sm: '0' },
                                }}
                            >

                                <Box
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                        border: '1px solid #ccc',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        bottom: '-2px',
                                        overflow: 'hidden',
                                        flex: { xs: '0 0 20px', md: '0 0 20px' },
                                        background: `url(${task?.createdBy?.displayImageUrl})`,
                                        backgroundSize: 'cover',
                                        backgroundColor: '#ccc',
                                    }}
                                />

                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Causten-Medium',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: '#70798D',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {task?.createdBy?.displayName}
                                </Typography>

                            </Box>

                            <Box //############# due Date
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    gap: '7px',
                                    order: { xs: '3', sm: '0' },
                                }}
                            >

                                <EventOutlinedIcon
                                    sx={{
                                        color: '#70798D',
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />

                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Causten-Medium',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: '#70798D',
                                    }}
                                >
                                    {moment(task?.dueDate).format('DD / MMM / YYYY')}
                                </Typography>

                            </Box>

                            <Box //############# priority 
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    gap: '7px',
                                    order: { xs: '2', sm: '0' },
                                }}
                            >

                                <TourIcon
                                    sx={{
                                        color: priorityColorForTask(task?.priority).color,
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />

                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Causten-Medium',
                                        fontSize: '10px',
                                        lineHeight: '16px',
                                        color: priorityColorForTask(task?.priority).color,
                                    }}
                                >
                                    {priorityColorForTask(task?.priority).text}
                                </Typography>

                            </Box>

                            <Box //############# type
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    order: { xs: '5', sm: '0' },
                                }}
                            >

                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Causten-Medium',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: '#70798D',
                                    }}
                                >
                                    Task type:&nbsp;
                                </Typography>

                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Causten-Bold',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: '#A1ABC5',
                                    }}
                                >
                                    {task?.type}
                                </Typography>

                            </Box>

                            <Box  //############# attachments count
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    order: { xs: '4', sm: '0' },
                                    gap: '7px',
                                }}
                            >

                                <AttachFileIcon
                                    sx={{
                                        color: '#70798D',
                                        width: '15px',
                                        height: '15px',
                                        transform: 'rotate(45deg)',
                                    }}
                                />

                                <Typography
                                    sx={{

                                        fontFamily: 'Causten-Medium',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: '#70798D',
                                    }}
                                >
                                    {task?.attachmentCount ?? 0} Attachments
                                </Typography>

                            </Box>

                        </Box>

                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'row', sm: 'column', },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            gap: '10px',
                        }}
                    >
                        <Typography
                            sx={{

                                backgroundColor: isDone ? 'rgba(0, 181, 66, 0.3)' : 'rgba(61, 123, 248, 0.3)',

                                fontFamily: 'Causten-Regular',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: isDone ? '#00b542' : '#3D7CF8',

                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '16px',
                                // py: { xs: '5px' },
                                px: { xs: '10px' },
                                whiteSpace: 'nowrap',

                                height: '24px',
                                // width: '100%',
                                flex: { xs: 1, sm: 0 },

                                my: { xs: '16px', sm: '0px' }
                            }}
                        >
                            {task?.status}
                        </Typography>

                        {
                            !noEdit && (
                                <Button
                                    onClick={handleEditTask}
                                    sx={{
                                        backgroundColor: '#10870a33',
                                        color: '#10870a',
                                        borderRadius: '55px',
                                        px: { xs: '10px' },
                                        lineHeight: '16px',
                                        textTransform: 'none',
                                        fontFamily: 'Causten-Medium',
                                        height: '24px',
                                        flex: { xs: 1, sm: 0 },
                                    }}
                                >
                                    Edit
                                </Button>
                            )
                        }
                    </Box>
                </Box>

            </Box>
        </CardContainer>
    )
}