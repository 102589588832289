import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    signinData: null,
    signinLoading: false,
    signinError: null,
};


export const signinFunc = createAsyncThunk("signin/signinFunc", async (values) => {
    var data1 = JSON.stringify({
        "email": values.email,
        "password": values.password
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/login', data1);

    const response = axios(config).then((res) => {
        const data = res.data.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});



const signin = createSlice({
    name: "signin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(signinFunc.pending, (state) => {
            state.signinLoading = true;
        });
        builder.addCase(signinFunc.fulfilled, (state, action) => {
            state.signinLoading = false;
            state.signinData = action.payload
            state.signinError = action.payload.message;
        });
        builder.addCase(signinFunc.rejected, (state, action) => {
            state.signinLoading = false;
            state.signinError = action.error.message;
        });
    }
});


export default signin.reducer;