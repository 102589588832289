import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    update2faData: null,
    update2faLoading: false,
    update2faError: null, 
};


export const update2faFunc = createAsyncThunk("update2fa/update2faFunc", async (values) => {
    let data1 = JSON.stringify({
        "email": values.email
    });

    var config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/user/twoFactorAuthOptions',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + values.accessToken
        },
        data: data1
    };

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const update2fa = createSlice({
    name: "update2fa",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // update2fa
        builder.addCase(update2faFunc.pending, (state) => {
            state.update2faLoading = true;
        }
        );
        builder.addCase(update2faFunc.fulfilled, (state, action) => {
            state.update2faLoading = false;
            state.update2faData = action.payload;
            state.update2faError = action.payload.message;
        }
        );
        builder.addCase(update2faFunc.rejected, (state, action) => {
            state.update2faLoading = false;
            state.update2faError = action.error.message;
        }
        );
    }
});


export default update2fa.reducer;