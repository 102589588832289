export const internshipStatusTabs = {
  active: {
    value: "active",
    name: "Active",
  },
  completed: {
    value: "completed",
    name: "Completed",
  },
  canceled: {
    value: "failed",
    name: "Failed",
  },
  archived: {
    value: "archived",
    name: "Archived",
  },
};

export const internshipStatusTabsList = Object.values(internshipStatusTabs);

export const internshipStatusTabsValueList = Object.values(internshipStatusTabs).map(
  (period) => period.value
);

export const internshipStatusTabsNameList = Object.values(internshipStatusTabs).map(
  (period) => period.name
);

export function getValueOfinternshipStatusTabs(name) {
  for (const key of Object.keys(internshipStatusTabs)) {
    if (internshipStatusTabs[key].name === name) {
      return internshipStatusTabs[key].value;
    }
  }
  return null;
}

export function getNameOfinternshipStatusTabs(value) {
  for (const key of Object.keys(internshipStatusTabs)) {
    if (internshipStatusTabs[key].value === value) {
      return internshipStatusTabs[key].name;
    }
  }
  return null;
}
