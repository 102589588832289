import React from 'react'
import { Stack, Box } from '@mui/material'
import ProfileBox from './ProfileBox'
import { InternIFLogoWhite } from "assets";
import { NavSectionVertical } from "minimal/components/nav-section";
import Scrollbar from "minimal/components/scrollbar/scrollbar";




const NavDefault = ({ handleClick, userRole, self_companyUserData, self_internData, anchorEl, handleResume,
    open, handleClose, handleAccount, handleNotepad, handleCompany, handleSettings, handleLogout, navData, handleMembership, handleCoverLetters, handleOrders }) => {

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >

            <Box sx={{ mb: 2, mt: 4, ml: 4 }}>
                <img width={"70%"} src={InternIFLogoWhite} alt="InternIF Logo" />
            </Box>

            <NavSectionVertical
                data={navData}
                config={{
                    currentRole: userRole || 'intern',
                }}
            />

            <Box sx={{ flexGrow: 1 }} />

        </Scrollbar>
    );


    return (
        <Stack
            sx={{
                height: 1, position: 'fixed', backgroundColor: '#101426', width: 280,
                borderRight: () => ` 0px #dedede`,
            }}
            justifyContent="space-between"
        >
            {renderContent}
            <ProfileBox
                handleClick={handleClick}
                userRole={userRole}
                self_companyUserData={self_companyUserData}
                self_internData={self_internData}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                handleAccount={handleAccount}
                handleMembership={handleMembership}
                handleCompany={handleCompany}
                handleNotepad={handleNotepad}
                handleSettings={handleSettings}
                handleLogout={handleLogout}
                handleResume={handleResume}
                handleCoverLetters={handleCoverLetters}
                handleOrders={handleOrders}
            />
        </Stack>
    )
}

export default NavDefault