import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_updateUniversityEducationFunc = async ({ selectedUniversity, id, accessToken }) => {

    console.log(selectedUniversity, '!!!!!?')
    console.log(id, '!!!!!?')


    const data = JSON.stringify({
        query: `mutation Intern_updateUniversityEducation($universityEducationId: ID!, $updateUniversityEducationInput: UpdateUniversityEducationInput!) {
            intern_updateUniversityEducation(universityEducationId: $universityEducationId, updateUniversityEducationInput: $updateUniversityEducationInput) {
              _id
            }
          }`,
        variables: {
            "universityEducationId": id,
            "updateUniversityEducationInput": {
                "university": {
                    "_id": selectedUniversity?.university?._id ?? null,
                    "name": selectedUniversity?.university?.name ?? null
                },
                "department": {
                    "_id": selectedUniversity?.department?._id ?? null,
                    "name": selectedUniversity?.department?.name ?? null
                },
                "finishDate": selectedUniversity?.finishDate ?? null,
                "startDate": selectedUniversity?.startDate ?? null
            }
        }

    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        // console.log(res?.data)
        return res?.data?.data?.intern_updateUniversityEducation;

    } catch (err) {
        return err
    }
}
