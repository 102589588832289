import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_createCompanyFunc = async ({ values, accessToken, logoImage }) => {

    const data = JSON.stringify({
        query: `mutation CompanyAdmin_createCompany($companyInput: CompanyInput!) {
            companyAdmin_createCompany(companyInput: $companyInput) {
              success
            }
          } `,
        variables: {
            "companyInput": {
                "companyInformation": {
                    "companyName": values?.companyName,
                    "industry": {
                        "_id": values?.industry
                    },
                    "country": {
                        "_id": values?.companyCountry
                    },
                    "city": {
                        "_id": values?.companyCity
                    },
                    "companyWebsite": values?.companyWebsite,
                    "socialMediaInfo": {
                        "linkedIn": values?.linkedIn
                    },
                    "numberOfEmployee": {
                        "_id": values?.numberOfEmployee
                    },
                    "foundedIn": values?.foundedIn,
                    "contactInfo": values?.contactInfo,
                    "logoImageUrl": logoImage ?? "",
                    "description": values?.description,
                }
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data?.data?.companyAdmin_createCompany
    } catch (err) {
        return err
    }

}