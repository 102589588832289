import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, IconButton, Card, Grid, Avatar, Typography, Tooltip } from '@mui/material'
import moment from 'moment'
import { Favorite, FavoriteBorder } from '@mui/icons-material'
import { getNameOfInternshipArea, getNameOfInternshipTimePeriod } from 'utils'
import Paid from 'assets/icons/dashboard/paid.png';
import Coffee from 'assets/icons/dashboard/coffee-cup.png';
import Label from 'minimal/components/label'


export const InternshipCard = ({ item, onOpenDetail, handleLikeAndDislike }) => {

    const navigate = useNavigate();

    console.log(item)

    return <Card
        sx={{
            p: 2, borderRadius: 2, backgroundColor: 'rgba(255,255,255, 0.5)', backdropFilter: 'blur(6px)', cursor: 'pointer',
            '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)" }, width: '100%'
        }}
        onClick={onOpenDetail}
    >

        <Grid container spacing={2} alignItems='center' justifyContent={'center'}>
            <Grid item xs={12}  >
                <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} justifyContent={"flex-start"} alignItems={"center"} gap={1} >
                    <Card sx={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 0, borderRadius: 2, boxShadow: '0px 0px 10px rgba(30, 30, 30, 0.2)' }}>
                        <Avatar
                            alt={item.company?.displayName}
                            src={item.company?.displayImageUrl}
                            sx={{ width: 50, height: 50 }}
                        />
                    </Card>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={{ xs: "center", sm: "flex-start" }} ml={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' }, gap: 1 }}>
                            <Typography variant='h5' color={'#444'} mr={1} >  {item.positionName} </Typography>
                            {item?.salaryType === 'Paid' ?
                                <Tooltip title="Paid"> <img src={Paid} alt="Paid" style={{ width: 20, height: 20, marginBottom: 3 }} /> </Tooltip> : null}
                            {item?.coffee !== "No" ?
                                <Tooltip title=
                                    {item?.coffee === "OneCoffeePerInternship" ? "One Coffee Per Internship" : item?.coffee === "OneCoffeePerWeek" ? "One Coffee Per Week" : item?.coffee === "OneCoffeePerDay" ? "One Coffee Per Day" : ""}>
                                    <img src={Coffee} alt="Coffee" style={{ width: 20, height: 20, marginBottom: 3 }} /> </Tooltip> : null}
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ flexDirection: { xs: 'column', sm: 'row' } }} >
                            <Typography variant='h7' mr={1} color={'#666'} onClick={() => navigate(`/company/${item?.company?._id}`)}>{item.company?.displayName} </Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >•</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >{getNameOfInternshipArea(item?.internshipArea)}</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >•</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} > {getNameOfInternshipTimePeriod(item?.timePeriodInWeeks)} </Typography>
                        </Box>
                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>{item?.applicationType.charAt(0).toUpperCase() + item?.applicationType.slice(1)} Internship, {item?.company?.country?.name}</Typography>
                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>
                            Posted {moment(item?.createdAt).fromNow()}
                            {item?.type !== "indefinite" && ` - Starts on ${moment(item?.startDate).format("DD.MM.YYYY")}`}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item right={10} top={0} position={'absolute'}>
                {item?.type === "indefinite" && <Label color={'info'}>Indefinite</Label>}
                <IconButton sx={{ color: item.isSaved ? '#DC143C' : "#9999" }}
                    onClick={e => {
                        e.stopPropagation();
                        handleLikeAndDislike()
                    }} >
                    {(item?.isSaved ? <Favorite color='secondary' /> : <FavoriteBorder sx={{ color: '#aaa' }} />)}
                </IconButton>
            </Grid>

        </Grid>



    </Card >
}
