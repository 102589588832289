export const InternshipPreferredGPA = {
  None: {
    value: "None",
    name: "None",
  },
  TwoOrHigher: {
    value: "TwoOrHigher",
    name: "2 +",
  },
  TwoAndHalfOrHigher: {
    value: "TwoAndHalfOrHigher",
    name: "2.5 +",
  },
  ThreeOrHigher: {
    value: "ThreeOrHigher",
    name: "3 +",
  },
  ThreeAndHalfOrHigher: {
    value: "ThreeAndHalfOrHigher",
    name: "3.5 +",
  },
};

export const internshipPreferredGPAList = Object.values(InternshipPreferredGPA);

export const internshipPreferredGPAValueList = Object.values(
  InternshipPreferredGPA
).map((period) => period.value);

export const internshipPreferredGPANameList = Object.values(
  InternshipPreferredGPA
).map((period) => period.name);

export function getValueOfInternshipPreferredGPA(name) {
  for (const key of Object.keys(InternshipPreferredGPA)) {
    if (InternshipPreferredGPA[key].name === name) {
      return InternshipPreferredGPA[key].value;
    }
  }
  return null;
}

export function getNameOfInternshipPreferredGPA(value) {
  for (const key of Object.keys(InternshipPreferredGPA)) {
    if (InternshipPreferredGPA[key].value === value) {
      return InternshipPreferredGPA[key].name;
    }
  }
  return null;
}
