
import { Box, Card, Grid, Avatar, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getNameOfApplicationStatus } from 'utils'
import Label from 'minimal/components/label'

export const ApplicationCard = ({ item, onOpenDetail }) => {

    const navigate = useNavigate();

    const internship = item?.internship;
    const company = item?.internship?.company;

    return <Card
        sx={{
            p: 2, borderRadius: 2, backgroundColor: 'rgba(255,255,255, 0.5)', backdropFilter: 'blur(6px)', cursor: 'pointer',
            '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)" }
        }}
        onClick={onOpenDetail}
    >

        <Grid container spacing={2} alignItems='center' justifyContent={'center'}>
            <Grid item xs={12}  >
                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={1} >
                    <Card sx={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 0, borderRadius: 2, boxShadow: '0px 0px 10px rgba(30, 30, 30, 0.2)' }}>
                        <Avatar
                            alt={company?.displayName}
                            src={company?.displayImageUrl}
                            sx={{ width: 50, height: 50 }}
                        />
                    </Card>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"} ml={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' }, gap: 1 }}>
                            <Typography variant='h5' color={'#444'} mr={1} >  {internship.positionName} </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ flexDirection: { xs: 'column', sm: 'row' } }} >
                            <Typography variant='h7' mr={1} color={'#666'} onClick={() => navigate(`/company/${company?._id}`)}>{company?.displayName} </Typography>
                        </Box>
                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>Applied {moment(item?.createdAt).fromNow()}</Typography>
                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>
                            {internship?.type === "indefinite"
                                ? <Label sx={{mt:0.5}} color={'info'}>Indefinite</Label>
                                : ` Starts on ${moment(internship?.startDate).format("DD.MM.YYYY")}`}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item right={10} top={0} position={'absolute'}>
                <Label
                    color={item?.status === 'applied' ? 'secondary' : item?.status === 'viewed' ? 'info' : item?.status === 'rejected' ? 'error' : 'primary'}
                    sx={{ borderRadius: 200, minWidth: 90 }}
                >
                    {getNameOfApplicationStatus(item.status)}
                </Label>
            </Grid>

        </Grid>



    </Card >
}
