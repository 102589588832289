
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box } from "@mui/material";
import { DashboardLayout } from "layouts";
import { user_parameters_industriesFunc, anonymous_countriesFunc, self_companyUserFunc, anonymous_citiesFunc } from "context";
import { imageCompress, imageUpload } from "utils/helpers";
import { companyAdmin_updateCompanyFunc } from 'context';
import { BackgroundImageCompany, CompanyLogo, MyCompanyForm, CardHeaderCompany } from "page-sections";
import { SettingsContainer, AnimatedTitle } from "components";

const info = [
    "Faz 2 bitti."
]

const userValidationSchema = Yup.object().shape({
    "companyName": Yup.string().required("Please enter your company name").min(2, "Name must be at least 2 characters"),
    "companyWebsite": Yup.string().required("Please enter your company website"),
    "contactInfo": Yup.string().email().required("Please enter your contact information"),
    "description": Yup.string().required("Please enter your description"),
    "twitter": Yup.string().matches(/twitter.com/, "Plesae enter a valid url. Example: twitter.com/company"),
    "facebook": Yup.string().matches(/facebook.com/, "Plesae enter a valid url. Example: facebook.com/company"),
    "instagram": Yup.string().matches(/instagram.com/, "Plesae enter a valid url. Example: instagram.com/company"),
    "linkedin": Yup.string().matches(/linkedin.com/, "Plesae enter a valid url. Example: linkedin.com/company"),
});

const MyCompany = () => {

    console.warn = () => { };

    const dispatch = useDispatch();
    const { accessToken } = useSelector(state => state.user);
    const { self_companyUserData: company } = useSelector(state => state.self_companyUser);
    const { anonymous_countriesData, anonymous_citiesData, user_parameters_industriesData } = useSelector(state => state.parameters);
    const [compressedBackground, setCompressedBackground] = useState(null);
    const [compressedCompanyLogo, setCompressedCompanyLogo] = useState(null);
    const [loading, setLoading] = useState(false);

    const userInitialValues = useMemo(() => ({
        "companyId": company?.company?._id ?? null,
        "companyName": company?.company?.companyInformation?.companyName ?? "",
        "contactInfo": company?.company?.companyInformation?.contactInfo ?? "",
        "companyWebsite": company?.company?.companyInformation?.companyWebsite ?? "",
        "description": company?.company?.companyInformation?.description ?? "",
        "detailedDescription": company?.company?.companyInformation?.detailedDescription ?? "",
        "industry": company?.company?.companyInformation?.industry?._id ?? null,
        "city": company?.company?.companyInformation?.city?._id ?? null,
        "country": company?.company?.companyInformation?.country?._id ?? null,
        "numberOfEmployee": company?.company?.companyInformation?.numberOfEmployee?._id ?? null,
        "companyLogo": company?.company?.companyInformation?.logoImageUrl ?? "",
        "backgroundImageUrl": company?.company?.companyInformation?.backgroundImageUrl ?? "",
        "foundedIn": company?.company?.companyInformation?.foundedIn ? new Date(company?.company?.companyInformation?.foundedIn).getFullYear().toString() : "",
        "twitter": company?.company?.companyInformation?.socialMediaInfo?.twitter ?? "",
        "facebook": company?.company?.companyInformation?.socialMediaInfo?.facebook ?? "",
        "linkedin": company?.company?.companyInformation?.socialMediaInfo?.linkedin ?? "",
        "instagram": company?.company?.companyInformation?.socialMediaInfo?.instagram ?? "",
    }), [company])



    const handleBackgroundChange = (e) => {
        const file = e.target.files[0];
        imageCompress({ file, enqueueSnackbar, setCompressedImage: setCompressedBackground, ratio: 0.4, maxWidth: 1000 });
    };

    const handleCompanyLogoChange = (e) => {
        const file = e.target.files[0];
        imageCompress({ file, enqueueSnackbar, setCompressedImage: setCompressedCompanyLogo, ratio: 0.4, maxWidth: 400 });
    }

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        validationSchema: userValidationSchema,
        onSubmit: (values) => {
            handleUpdateUserInfo({ values });
        },
    });

    const handleUpdateUserInfo = async ({ values }) => {

        try {
            setLoading(true);
            const resBackgroundImage = compressedBackground && await imageUpload({
                presignedType: 'backgroundImage',
                accessToken: accessToken,
                image: compressedBackground?.image,
                imageType: compressedBackground?.type
            });
            const resCompanyLogo = compressedCompanyLogo && await imageUpload({
                presignedType: 'organizationLogo',
                accessToken: accessToken,
                image: compressedCompanyLogo?.image,
                imageType: compressedCompanyLogo?.type
            });

            const updateCompanyRes = await companyAdmin_updateCompanyFunc({
                values: values, accessToken: accessToken, backgroundImageUrl: resBackgroundImage ?? values?.backgroundImageUrl, logoImageUrl: resCompanyLogo ?? values?.companyLogo
            });

            if (!(updateCompanyRes._id)) {
                throw new Error(updateCompanyRes ?? "Company update failed.");
            } else if (updateCompanyRes._id) {
                enqueueSnackbar("The company information has been updated!", { variant: "success" });
                dispatch(self_companyUserFunc({ accessToken }));
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        accessToken && dispatch(self_companyUserFunc({ accessToken }));
        accessToken && dispatch(anonymous_countriesFunc({ accessToken }));
        accessToken && dispatch(user_parameters_industriesFunc({ accessToken }));
    }, [accessToken, dispatch]);


    useEffect(() => {
        values?.country && accessToken && dispatch(anonymous_citiesFunc({ accessToken, countryId: values?.country }));
    }, [accessToken, dispatch, values?.country]);



    return <DashboardLayout info={info}>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ width: '100%', display:"flex", flexDirection:"column" }}>
            <Box ml={{ xs: 0, sm: 9 }} mt={{ xs: 0, sm: 5 }}>
                <AnimatedTitle title="My Company" />
            </Box>
            <Grid item xs={12} xl={10} >
                <SettingsContainer>
                    <BackgroundImageCompany
                        values={values}
                        handleBackgroundChange={handleBackgroundChange}
                        compressedBackground={compressedBackground}
                    />
                    <CompanyLogo
                        values={values}
                        handleProfileImageChange={handleCompanyLogoChange}
                        compressedProfileImage={compressedCompanyLogo}
                    />
                    <CardHeaderCompany />
                    {values?.companyId && <MyCompanyForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        loading={loading}
                        countryOptions={anonymous_countriesData}
                        cityOptions={anonymous_citiesData}
                        industryOptions={user_parameters_industriesData?.industries?.data}
                        employeeOptions={user_parameters_industriesData?.numberOfEmployees?.data}
                    />}
                </SettingsContainer>
            </Grid>
        </Grid>
    </DashboardLayout>
}

export default MyCompany