import axios from "axios";
import initialConfig from "context/initialConfig";

export const self_updateUserFunc = async ({ values, accessToken, profileImage, backgroundImage }) => {

    const data = JSON.stringify({
        query: `
            mutation Self_updateUser($updateUserInput: UpdateUserInput!) {
                self_updateUser(updateUserInput: $updateUserInput) {
                _id
                }
            }
        `,
        variables: {
            "updateUserInput": {
                // "settings": {
                //     "defaultLanguage": values?.defaultLanguage ?? null,
                // },
                "personalInformation": {
                    "address": values?.address ?? null,
                    "backgroundImageUrl": backgroundImage ?? null,
                    "birthDate": values?.birthDate ?? null,
                    "city": {
                        "_id": values?.city ?? null
                    },
                    "country": {
                        "_id": values?.country ?? null
                    },
                    "description": values?.description ?? null,
                    "gender": values?.gender ?? null,
                    // "language": {
                    //     "_id": values?.language ?? null
                    // },
                    // "languageLevel": {
                    //     "_id": values?.languageLevel ?? null
                    // },
                    "name": values?.name ?? null,
                    "nationality": {
                        "_id": values?.nationality ?? null
                    },
                    "phoneNumber": values?.phoneNumber ?? null,
                    "profileImageUrl": profileImage ?? null,
                    "surname": values?.surname ?? null
                }
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + "/user/graphql", data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data?.data?.self_updateUser;
    } catch (err) {
        return err;
    }
}