import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#E7E7F3',
  light: '#9E9EBC',
  main: '#191922',
  dark: '#0C0C18',
  darker: '#040410',
  contrastText: '#FFFFFF',
};


const SECONDARY = {
  lighter: '#FFF2D7',
  light: '#FFCD86',
  main: '#FF9737',
  dark: '#B7561B',
  darker: '#7A290A',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#CFE4FB',
  light: '#70A1E9',
  main: '#194EB7',
  dark: '#0C2C83',
  darker: '#041557',
  contrastText: '#FFFFFF',
};

const MAIN = {
  lighter: '#CFE4FB',
  light: '#70A1E9',
  main: '#194EB7',
  dark: '#0C2C83',
  darker: '#041557',
  contrastText: '#FFFFFF',
};


const SUCCESS = {
  lighter: '#DDF8D1',
  light: '#80D771',
  main: '#1A7C1D',
  dark: '#0D591C',
  darker: '#043B19',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FCEFC9',
  light: '#EDBE5F',
  main: '#C47301',
  dark: '#8D4600',
  darker: '#5E2700',
  contrastText: GREY[100],
};


const ERROR = {
  lighter: '#F9CFD1',
  light: '#DF6D8A',
  main: '#961650',
  dark: '#6C0B4A',
  darker: '#48043E',
  contrastText: '#FFFFFF',
};


const ALTERNATIVE1 = {
  lighter: '#bbb',
  light: '#aaa',
  main: '#999',
  dark: '#888',
  darker: '#777',
  contrastText: '#FFFFFF',
};

const ALTERNATIVE2 = {
  lighter: '#bbb',
  light: '#aaa',
  main: '#ccc',
  dark: '#888',
  darker: '#777',
  contrastText: '#FFFFFF',
};


const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  main: MAIN,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  alternative1: ALTERNATIVE1,
  alternative2: ALTERNATIVE2,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      white : '#FFFFFF',
      neutral : GREY[300],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
