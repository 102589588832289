import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";

const initialState = {
    intern_joinedInternshipsData: null,
    intern_joinedInternshipsCount: null,
    intern_joinedInternshipsLoading: false,
    intern_joinedInternshipsError: null,
};

export const intern_joinedInternshipsFunc = createAsyncThunk("intern_joinedInternships/intern_joinedInternshipsFunc", async (values) => {

    const data1 = JSON.stringify({
        query: `query Intern_joinedInternships {
                    intern_joinedInternships {
                        count
                        data {
                        _id
                        type
                        company {
                            _id
                            displayName
                            displayImageUrl
                            country {
                            _id
                            name
                            code
                            flagUrl
                            }
                            city {
                            _id
                            name
                            }
                            industry {
                            _id
                            name
                            }
                            numberOfEmployee {
                            _id
                            name
                            }
                        }
                        positionName
                        timePeriodInWeeks
                        startDate
                        quota
                        numberOfAcceptedInterns
                        numberOfAppliedInterns
                        status
                        createdAt
                        updatedAt
                        createdByType
                        internshipArea
                        preferredLanguages
                        applicationType
                        salaryType
                        salary
                        coffee
                        language
                        }
                    }
                }`
    });

    const config = initialConfig(process.env.REACT_APP_BASE_URL + "/internship/graphql", data1, values?.accessToken);

    const response = axios(config)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => {
            console.log("err:", err);
            return err;
        });

    const data = await response;
    return data;
}
);

const intern_joinedInternships = createSlice({
    name: "intern_joinedInternships",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(intern_joinedInternshipsFunc.pending, (state) => {
            state.intern_joinedInternshipsLoading = true;
        });
        builder.addCase(intern_joinedInternshipsFunc.fulfilled, (state, action) => {
            state.intern_joinedInternshipsLoading = false;
            state.intern_joinedInternshipsData = action.payload?.intern_joinedInternships?.data;
            state.intern_joinedInternshipsCount = action.payload?.intern_joinedInternships?.count;
            state.intern_joinedInternshipsError = action?.payload?.message;
        });
        builder.addCase(intern_joinedInternshipsFunc.rejected, (state, action) => {
            state.intern_joinedInternshipsLoading = false;
            state.intern_joinedInternshipsError = action.error.message;
        });
    },
});

export default intern_joinedInternships.reducer;
