import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyUser_createTaskByAI = async (values) => {
  const data = JSON.stringify({
    query: `mutation CompanyUser_createTaskByAI($parameters: TaskCreationParameters!) {
        companyUser_createTaskByAI(parameters: $parameters) {
          title
          overview
          objectives {
            duration
            content
          }
          duration
          deliverables {
            name
            content
          }
        }
      }`,
    variables: {
        "parameters": {
          "taskType": values?.taskType,
          "taskLength": values?.taskLength,
          "numberOfEmployee": values?.numberOfEmployee,
          "internshipGoal": values?.internshipGoal,
          "industry": values?.industry,
          "internshipArea": values?.internshipArea,
          "foundedIn": values?.foundedIn,
          "detailedDescription": values?.detailedDescription,
          "country": values?.country,
          "city": values?.city,
        }
      }
  });

  try {
    const res = await axios(
      initialConfig(
        process.env.REACT_APP_BASE_URL + "/internship/graphql",
        data,
        values.accessToken
      )
    );
    if (res.data?.errors && res.data?.errors.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    return res?.data?.data?.companyUser_createTaskByAI;
  } catch (err) {
    return err;
  }
};
