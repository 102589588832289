import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_reassignAssignedTask = async ({ accessToken, assignTaskId }) => {

    const data = JSON.stringify({
        query: `mutation CompanyAdmin_reassignAssignedTask($filter: ReassignAssignedTaskByAssignerFilterInput!) {
          companyAdmin_reassignAssignedTask(filter: $filter) {
            _id
          }
        }`,
        variables: {
          "filter": {
            "id": assignTaskId
          }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.companyAdmin_reassignAssignedTask
    } catch (err) {
        return err
    }
}
