import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types';
import { Box, Button, SwipeableDrawer, Grid, Avatar, Typography } from '@mui/material'
import { getNameOfInternshipTimePeriod, getNameOfInternshipArea, getNameOfApplicationStatus } from 'utils'
import { CancelOutlined, InfoOutlined } from '@mui/icons-material';
import Label from 'minimal/components/label';
import parse from 'html-react-parser';


export const ApplicationDetailDrawer = ({ handleCloseDetail, application, loading, onCancelApplication }) => {

    const navigate = useNavigate();

    console.log(application, "application")
    return <SwipeableDrawer
        anchor="right"
        open={!!application}
        onClose={() => handleCloseDetail()}
        onOpen={() => null}
    >
        <Box
            sx={{
                minWidth: { xs: '100%', md: '600px' },
                maxWidth: { xs: '100%', md: '600px' },
                backgroundColor: '#fff',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                p: 3
            }}
        >
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 10,
                    width: '100%',
                }}
            >
                <Box sx={{
                    p: '24px',
                    px: { xs: '15px', sm: '15px', },
                    display: 'flex',
                    justifyContent: 'flex-end',
                    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.05)',
                }}>

                    <Button size='large' disabled={loading} color='primary' variant='outlined' onClick={onCancelApplication}>
                        <CancelOutlined fontSize='small' sx={{ mr: 1 }} /> Cancel Application
                    </Button>
                </Box>
            </Box>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar src={application?.internship?.company?.displayImageUrl} sx={{ width: '80px', height: '80px' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h4">{application?.internship?.positionName}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography onClick={() => navigate(`/company/${application?.internship?.company?._id}`)}
                                style={{ cursor: 'pointer' }}
                                variant="h7">{application?.internship?.company?.displayName}</Typography>
                            <Typography variant="h5" color={"GrayText"} >•</Typography>
                            <Typography variant="h7" color={"GrayText"} >Applied {moment(application?.createdAt).fromNow()}</Typography>
                            <Typography variant="h5" color={"GrayText"} >•</Typography>
                            <Typography variant="h7" color={"GrayText"} >{getNameOfInternshipTimePeriod(application?.internship?.timePeriodInWeeks)}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ mb: 3 }}>
                <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <img src={require('assets/icons/dashboard/internship-details-country.png')} alt="internship-details-country" />
                    <Typography variant="h8">{application?.internship?.company?.country?.name}</Typography>
                </Grid>
                <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <img src={require('assets/icons/dashboard/internship-details-employee.png')} alt="internship-details-employee" />
                    <Typography variant="h8">{application?.internship?.company?.numberOfEmployee?.name} Employees</Typography>
                </Grid>
                <Grid xs={4} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <img src={require('assets/icons/dashboard/internship-details-healt.png')} alt="internship-details-area" />
                    <Typography variant="h8">{getNameOfInternshipArea(application?.internship?.internshipArea)}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ mb: 3 }}>
                <Grid xs={12} item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                    <Box
                        bgcolor={"secondary.lighter"}
                        sx={{ borderRadius: '12px', p: 2, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                            <InfoOutlined sx={{ mr: 1 }}
                                color='secondary'
                            />
                            <Typography
                                color={"secondary.darker"}
                                variant="h8" fontWeight={500}><Typography variant="h8" fontWeight={'bold'}>Info: </Typography>You applied this position.</Typography>
                        </Box>
                        <Label variant="filled"
                            color="secondary"
                            sx = {{ borderRadius: 200, minWidth: 90 }}
                        >
                            {getNameOfApplicationStatus(application?.status)}
                        </Label>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ mb: 2 }} flexDirection={"column"}>
                <Typography variant="h7">Description</Typography>
                <Typography variant="body2">{parse(application?.internship?.description ?? '')}</Typography>
            </Grid>


        </Box>
    </SwipeableDrawer >
}


ApplicationDetailDrawer.propTypes = {
    handleCloseDetail: PropTypes.func,
    handleLikeAndDislike: PropTypes.func,
    application: PropTypes.object
}