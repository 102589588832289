import axios from "axios";
import initialConfig from "context/initialConfig";

export const anonymous_createRemoteTransformForm = async ({ values, accessToken, profileImage, backgroundImage }) => {
    console.log(values, 'values')

    const data = JSON.stringify({
        query: `mutation Anonymous_createRemoteTransformForm($remoteTransformFormInput: RemoteTransformFormInput!) {
            anonymous_createRemoteTransformForm(remoteTransformFormInput: $remoteTransformFormInput) {
              _id
            }
          }`,
        variables:{
            "remoteTransformFormInput": {
              "companyName": values?.companyName,
              "industry": values?.industry,
              "currentTeamSize": Number(values?.currentTeamSize),
              "primaryRemoteWorkChallenges": values?.primaryRemoteWorkChallenges,
              "additionalCommentsRequests": values?.additionalComments,
              "contactName": values?.name,
              "contactPosition": values?.position,
              "contactEmail": values?.email,
              "contactPhone": values?.phone,
            }
          }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + "/user/graphql", data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data?.data?.anonymous_createRemoteTransformForm;
    } catch (err) {
        return err;
    }
}