import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_updateIntern_aboutFunc = async ({ description, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_updateIntern($updateInternInput: UpdateInternInput!) {
            intern_updateIntern(updateInternInput: $updateInternInput) {
              _id
            }
          }
        `,
        variables: {
            "updateInternInput": {
                "personalInformation": {
                    "description": description
                }
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data?.data?.intern_updateIntern;
    } catch (err) {
        return err
    }
}