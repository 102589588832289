import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import {
    Button, Card, Dialog, DialogContent, DialogTitle, Grid, Typography, Box, DialogActions, FormControlLabel, Checkbox, Link,
    TextField, useMediaQuery, Divider, FormControl, InputLabel, Select, MenuItem, FormHelperText, CircularProgress
} from '@mui/material'
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import { TollTwoTone } from '@mui/icons-material'
import Coin from 'assets/images/dashboard/coin.png';
import {
    anonymous_policiesFunc, user_productsFunc,
    self_organization_billingInfoFunc, user_internshipPriceFunc, orderFunc,
    user_createOrderForOrganizationWithStripeCheckoutFormPayment
} from 'context'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';
import {
    internshipFollowUpUserTypeList,
    internshipTimePeriodList,
} from "utils";

const info = [
    "Faz 2 bitti."
]

const tipTitle = `<b>Simple Calculator</b>`

const Membership = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [checkOpen, setCheckOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const { self_organization_billingInfoData: billingInfoData } = useSelector(state => state.self_organization_billingInfo)
    const { accessToken } = useSelector(state => state.user)
    const { user_productsData } = useSelector(state => state.user_products);
    const { user_internshipPriceData: internshipPrice, user_internshipPriceLoading: internshipPriceLoading } = useSelector((state) => state.user_internshipPrice)
    const [values, setValues] = useState({
        timePeriodInWeeks: null,
        followUpUserType: null,
        quota: null,
        creationType: null,
        coffee: null,
    });
    const { anonymous_policiesData: policies, anonymousPoliciesLoading: policiesLoading } = useSelector((state) => state.anonymous_policies);
    const [openPolicies, setOpenPolicies] = useState(false);
    const [accept, setAccept] = useState(false);
    const [stripeUrl, setStripeUrl] = useState('')
    const [selectedCredit, setSelectedCredit] = useState(null)
    const [errMsg, setErrMsg] = useState(null)


    const creditA = user_productsData?.filter(item => item?.name === 'Credit - A')?.[0];
    const creditB = user_productsData?.filter(item => item?.name === 'Credit - B')?.[0];
    const creditC = user_productsData?.filter(item => item?.name === 'Credit - C')?.[0];


    const handleBuyCredits = async (productId) => {
        setCheckOpen(true)
        await user_createOrderForOrganizationWithStripeCheckoutFormPayment({ productId, accessToken }).then(res => {

            setErrMsg(res?.errorMessage)
            if (res?.errorMessage === "BillingInfo of Organization is not found") {
                setOpenDialog(false)
                // enqueueSnackbar(<div>
                //     You need to add billing information!
                //     <Button
                //         color="secondary"
                //         size="small"
                //         onClick={() => navigate('/settings?tab=invoiceInfo')} >
                //         Go to Edit
                //     </Button>
                // </div >, { variant: 'warning', autoHideDuration: 5000 });
            } else if (res?.errorMessage?.includes('invoice')) {
                setOpenDialog(false)
                // enqueueSnackbar(<div>
                //     You need to add invoice information!
                //     <Button
                //         color="secondary"
                //         size="small"
                //         onClick={() => navigate('/settings?tab=invoiceInfo')} >
                //         Go to Edit
                //     </Button>
                // </div >, { variant: 'warning', autoHideDuration: 5000 });
            } else if (res?.errorMessage?.includes('email')) {
                setOpenDialog(false)
                enqueueSnackbar(<div>
                    You need to add billing information!
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate('/settings?tab=invoiceInfo')} >
                        Go to Edit
                    </Button>
                </div >, { variant: 'warning', autoHideDuration: 5000 });
            } else if (res?.errorMessage?.includes('country')) {
                setOpenDialog(false)
                enqueueSnackbar(<div>
                    You need to add billing information!
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate('/settings?tab=invoiceInfo')} >
                        Go to Edit
                    </Button>
                </div >, { variant: 'warning', autoHideDuration: 5000 });
            } else if (res?.errorMessage?.includes('city')) {
                setOpenDialog(false)
                enqueueSnackbar(<div>
                    You need to add billing information!
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate('/settings?tab=invoiceInfo')} >
                        Go to Edit
                    </Button>
                </div >, { variant: 'warning', autoHideDuration: 5000 });
            } else if (res?.errorMessage?.includes('postcode')) {
                setOpenDialog(false)
                enqueueSnackbar(<div>
                    You need to add billing information!
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate('/settings?tab=invoiceInfo')} >
                        Go to Edit
                    </Button>
                </div >, { variant: 'warning', autoHideDuration: 5000 });
            } else if (res?.errorMessage?.includes('address')) {
                setOpenDialog(false)
                enqueueSnackbar(<div>
                    You need to add billing information!
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate('/settings?tab=invoiceInfo')} >
                        Go to Edit
                    </Button>
                </div >, { variant: 'warning', autoHideDuration: 5000 });
            } else if (res?.errorMessage?.includes('identityNumber')) {
                setOpenDialog(false)
                enqueueSnackbar(<div>
                    You need to add billing information!
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => navigate('/settings?tab=invoiceInfo')} >
                        Go to Edit
                    </Button>
                </div >, { variant: 'warning', autoHideDuration: 5000 });
            }

            if (res?.order?._id) {
                setStripeUrl(res?.paymentResult?.url)
                window.open(res?.paymentResult?.url)
                setOpenDialog(false)
                setCheckOpen(false)
            }
        })
    }

    // const handleCheck = async () => {

    // }



    const handleCalculate = () => {
        values.timePeriodInWeeks && values.followUpUserType && values.quota && values.creationType && values.coffee &&
            dispatch(user_internshipPriceFunc({
                accessToken: accessToken,
                timePeriod: values.timePeriodInWeeks,
                followUpUserType: values.followUpUserType,
                quota: Number(values.quota),
                creationType: values.creationType,
                coffee: values.coffee,
            }))
    }

    const handleClickOpen = (type) => () => {

        dispatch(anonymous_policiesFunc({ domain: "app_internif", type: type, target: "sales_company_credit" }))

        if (type) {
            setOpenPolicies(true);
        }
    };


    useEffect(() => {
        accessToken && dispatch(user_productsFunc({ accessToken, type: 'credit' }))
        accessToken && dispatch(self_organization_billingInfoFunc({ accessToken }))
    }, [dispatch, accessToken])




    return <>
        <DashboardLayout info={info}>
            <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', gap: { xs: 2, sm: 0 } }}>
                <Grid item xs={12} >
                    <AnimatedTitle title="IF Credit" />
                </Grid>
                <Grid item xs={12} lg={7.6} xl={8.3}>
                    <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ width: '100%', gap: { xs: 2, sm: 0 } }}>
                        <Grid item xs={12} >
                            <Card>
                                <Typography fontSize={'medium'} fontWeight={'bold'} sx={{ px: 2, py: 2 }}>
                                    My IF Credits:  {((billingInfoData?.credit?.credit?.usable + billingInfoData?.credit?.freeCredit?.usable).toFixed(2)) || 0 } <img src={Coin} alt="Coin" style={{ width: 20, height: 20, marginLeft: 5, marginBottom: 4 }} />
                                </Typography>
                            </Card>
                        </Grid>
                        {/* <Grid item xs={12} >
                            <Card>
                                <Typography fontSize={'medium'} fontWeight={'bold'} sx={{ p: 2 }}>
                                    Elevate Your Internship Productivity
                                </Typography>
                                <Typography variant='body2' color={'gray'} sx={{ px: 2, pb: 2 }}>
                                    Designed exclusively for businesses, IF Credit provides a flexible and cost-effective way to access premium features and optimize your internship programs.
                                </Typography>
                                <Typography variant='body2' color={'black'} sx={{ px: 2 }}>
                                    •	Flexible Usage, Maximum Value
                                </Typography>
                                <Typography variant='body2' color={'gray'} sx={{ px: 2, pb: 2, pl: 3 }}>
                                    IF Credit empowers you with the flexibility to choose and use InternIF's premium features as per your business needs. From AI-driven candidate matching to advanced analytics, tailor your internship management toolkit to match your unique requirements.
                                </Typography>
                                <Typography variant='body2' color={'black'} sx={{ px: 2 }}>
                                    •	Streamlined Internship Processes
                                </Typography>
                                <Typography variant='body2' color={'gray'} sx={{ px: 2, pb: 2, pl: 3 }}>
                                    With IF Credit, experience a streamlined internship management process. From posting positions to evaluating candidates, each credit is a step towards making your internship programs more efficient and productive.
                                </Typography>
                                <Typography variant='body2' color={'black'} sx={{ px: 2 }}>
                                    • Cost-Effective Solutions
                                </Typography>
                                <Typography variant='body2' color={'gray'} sx={{ px: 2, pb: 2, pl: 3 }}>
                                    Say goodbye to rigid subscription models. IF Credit offers a cost-effective solution where you only pay for what you use. Manage your budget efficiently while enjoying the benefits of premium internship management features.
                                </Typography>
                                <Typography variant='body2' color={'black'} sx={{ px: 2 }}>
                                    • Exclusive Access to Advanced Features
                                </Typography>
                                <Typography variant='body2' color={'gray'} sx={{ px: 2, pb: 2, pl: 3 }}>
                                    Unlock the full potential of InternIF with IF Credit. Gain exclusive access to advanced features such as in-depth candidate assessments, personalized recruitment insights, and comprehensive reporting tools to make data-driven decisions.
                                </Typography>
                            </Card>
                        </Grid> */}
                        <Grid item xs={12} >
                            <Card sx={{ pb: 2 }}>
                                <Typography fontSize={'medium'} fontWeight={'bold'} sx={{ p: 2 }}>
                                    How It Works
                                </Typography>
                                <Typography variant='body2' color={'black'} sx={{ px: 2 }}>
                                    1.	Purchase IF Credit
                                </Typography>
                                <Typography variant='body2' color={'gray'} sx={{ px: 2, pb: 2, pl: 3 }}>
                                    Choose a credit package that suits your business needs. The more credits you purchase, the more value you unlock.
                                </Typography>
                                <Typography variant='body2' color={'black'} sx={{ px: 2 }}>
                                    2.	Allocate Credits
                                </Typography>
                                <Typography variant='body2' color={'gray'} sx={{ px: 2, pb: 2, pl: 3 }}>
                                    Allocate credits based on your usage. Whether you're posting internships, accessing AI-driven insights, or managing a high volume of applications, IF Credit adapts to your requirements. You can use the basic "IF Credit Calculator" to determine how many credits you need to post an internship.                                </Typography>
                                <Typography variant='body2' color={'black'} sx={{ px: 2 }}>
                                    3.	Enjoy Premium Features
                                </Typography>
                                <Typography variant='body2' color={'gray'} sx={{ px: 2, pb: 2, pl: 3 }}>
                                    As you use credits, enjoy the benefits of premium features designed to enhance your internship management experience.
                                </Typography>
                                <Typography variant='h7' color={'secondary'} sx={{ px: 2, pb: 3 }}>
                                    Choose from our flexible pricing plans to get started:
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12} >
                            <Button size='large' variant='contained' color='primary' onClick={() => setOpenDialog(true)}>
                                <TollTwoTone fontSize='small' sx={{ mr: 1 }} />  Get Started with IF Credit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {upLg && <Grid item xs={12} lg={4.4} xl={3.3}>
                    <Card sx={{
                        padding: 3, backgroundColor: "#F8F8F8", border: '1px solid #E0E0E0',
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                    }}>
                        <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                            <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >{parse(tipTitle)}</Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
                                    <InputLabel id="timePeriodInWeeks">Time Period</InputLabel>
                                    <Select
                                        name="timePeriodInWeeks"
                                        labelId="timePeriodInWeeks"
                                        label="Time Period"
                                        value={values.timePeriodInWeeks}
                                        onChange={(e) => setValues({ ...values, timePeriodInWeeks: e.target.value })}
                                    >
                                        {internshipTimePeriodList.map((type) => (
                                            <MenuItem value={type.value}>
                                                {type.name
                                                    .split(" ")
                                                    .map(
                                                        (name) => name[0].toUpperCase() + name.substring(1)
                                                    )
                                                    .join(" ")}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} mt={2} sx={{ backgroundColor: 'white' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="followUpUserType">Follow Up Type*</InputLabel>
                                    <Select
                                        name="followUpUserType"
                                        labelId="followUpUserType"
                                        label="Follow Up Type*"
                                        value={values.followUpUserType}
                                        MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                        onChange={(e) => setValues({ ...values, followUpUserType: e.target.value })}
                                    >
                                        {internshipFollowUpUserTypeList.map((type) => (
                                            <MenuItem value={type.value}>
                                                {type.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} mt={2} sx={{ backgroundColor: 'white' }}>
                                <TextField
                                    name="quota"
                                    label="Quota*"
                                    type="number"
                                    value={values?.quota}
                                    placeholder='Quota (max: 35)'
                                    onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        if (!isNaN(enteredValue) && enteredValue >= 0 && enteredValue <= 35) {
                                            setValues({ ...values, quota: enteredValue });
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} mt={2} sx={{ backgroundColor: 'white' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="creationType">Creation Type*</InputLabel>
                                    <Select
                                        name="creationType"
                                        labelId="creationType"
                                        label="Creation Type*"
                                        value={values.creationType}
                                        MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                        onChange={(e) => setValues({ ...values, creationType: e.target.value })}
                                    >
                                        <MenuItem value={'scheduled'}>Scheduled</MenuItem>
                                        <MenuItem value={'indefinite'}>Indefinite</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} mt={2} sx={{ backgroundColor: 'white' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="coffee">Coffee*</InputLabel>
                                    <Select
                                        name="coffee"
                                        labelId="coffee"
                                        label="Coffee*"
                                        value={values.coffee}
                                        MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                                        onChange={(e) => setValues({ ...values, coffee: e.target.value })}
                                    >
                                        <MenuItem value={'No'}>No</MenuItem>
                                        <MenuItem value={'OneCoffeePerInternship'}>1 Coffee Per Internship</MenuItem>
                                        <MenuItem value={'OneCoffeePerWeek'}>1 Coffee Per Week</MenuItem>
                                        <MenuItem value={'OneCoffeePerDay'}>1 Coffee Per Day</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} mt={2} sx={{ backgroundColor: 'white' }}>
                                <Button
                                    size='large'
                                    disabled={internshipPriceLoading}
                                    variant="outlined" color="primary" fullWidth onClick={handleCalculate}>
                                    Calculate
                                </Button>
                            </Grid>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: "column" }}>
                                    {/* {(values.followUpUserType && values.quota && values.timePeriodInWeeks) ? <Typography fontSize={"small"} color={"gray"}>{values.quota} Intern{values.quota > 1 && 's'} : </Typography> :
                                        <Typography fontSize={"small"} color={"gray"}>- Intern : </Typography>}
                                    {(values.followUpUserType && values.quota && values.timePeriodInWeeks) ? <Typography fontSize={"small"} color={"gray"}>{getNameOfInternshipTimePeriod(values.timePeriodInWeeks)} : </Typography> :
                                        <Typography fontSize={"small"} color={"gray"}>- Week : </Typography>} */}
                                    <Typography variant="h7">Price : </Typography><Typography variant="h7">Discount : </Typography>
                                    <Divider sx={{ width: '1500%', m: 1 }} />
                                    <Typography variant="h6">Net Price : </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: "column", ml: 1, alignItems: 'flex-end' }}>
                                    {/* {(values.followUpUserType && values.quota && values.timePeriodInWeeks) ? <Typography fontSize={"small"} color={"gray"}>{values.quota * 5} credits </Typography> :
                                        <Typography fontSize={"small"} color={"gray"}>Waiting</Typography>}
                                    {(values.followUpUserType && values.quota && values.timePeriodInWeeks) ? <Typography fontSize={"small"} color={"gray"}>{getNumberOfInternshipTimePeriod(values.timePeriodInWeeks) * 5} credits </Typography> :
                                        <Typography fontSize={"small"} color={"gray"}>Waiting</Typography>} */}
                                    <Typography variant="h7"> {(values?.quota && values.timePeriodInWeeks && values.followUpUserType) ? internshipPrice?.price ? internshipPrice?.price : 0 : 0} credit{internshipPrice?.price > 1 ? 's' : ''}</Typography>
                                    <Typography variant="h7"> {(values?.quota && values.timePeriodInWeeks && values.followUpUserType) ? internshipPrice?.discount ? internshipPrice?.discount : 0 : 0} credit{internshipPrice?.price > 1 ? 's' : ''}</Typography>
                                    <Box sx={{ m: 1 }} />
                                    <Typography variant="h6"> {
                                        (values?.quota && values.timePeriodInWeeks && values.followUpUserType) ?
                                            internshipPrice?.netPrice === 0 ? "~ Free" :
                                                internshipPrice?.netPrice ? internshipPrice?.netPrice + " credits" : "0 credit" : "0 credit"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                    </Card>
                </Grid>}
            </Grid>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle textAlign={"center"}>
                    If Credits
                </DialogTitle>
                <DialogContent sx={{ width: { xs: '100%', sm: 550 } }} >
                    <Typography variant='body2' color={'gray'} textAlign={'justify'}>
                        With IF Credit, you can post international internships, manage internships remotely, reach your potential employees, and use IF-AI's internship-specific tools.To purchase IF Credit, choose the package suitable for you.
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                        <Card
                            onClick={() => {
                                setCheckOpen(true)
                                setSelectedCredit('creditA')
                            }}
                            sx={{
                                px: 3, py: 4, mt: 2, border: "1px solid #ccc", minWidth: { xs: '100%', sm: 140 },
                                display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, justifyContent: 'center', alignItems: 'center',
                                gap: { xs: 2, sm: 0 },
                                ':hover': {
                                    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
                                    cursor: 'pointer'
                                }
                            }}>
                            <img src={Coin} alt="Coin" height={35} style={{ opacity: 0.8 }} />
                            <Typography variant="h6" textAlign={"center"}>
                                ${creditA?.price}
                            </Typography>
                            <Typography fontSize={'small'} fontWeight={'bold'} mt={{ xs: 0, sm: 1 }} textAlign={"center"}>
                                for  {creditA?.amount} IF Credits
                            </Typography>
                        </Card>
                        <Card
                            onClick={() => {
                                setCheckOpen(true)
                                setSelectedCredit('creditB')
                            }}
                            sx={{
                                px: 3, py: 4, mt: 2, border: "1px solid #ccc", minWidth: { xs: '100%', sm: 140 },
                                display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, justifyContent: 'center', alignItems: 'center',
                                gap: { xs: 2, sm: 0 },
                                ':hover': {
                                    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
                                    cursor: 'pointer'
                                }
                            }}>
                            <img src={Coin} alt="Coin" height={35} style={{ opacity: 0.8 }} />
                            <Typography variant="h6" textAlign={"center"}>
                                ${creditB?.price}
                            </Typography>
                            <Typography fontSize={'small'} fontWeight={'bold'} mt={1} textAlign={"center"}>
                                for  {creditB?.amount} IF Credits
                            </Typography>
                        </Card>
                        <Card
                            onClick={() => {
                                setCheckOpen(true)
                                setSelectedCredit('creditC')
                            }}
                            sx={{
                                px: 3, py: 4, mt: 2, border: "1px solid #ccc", minWidth: { xs: '100%', sm: 140 },
                                display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, justifyContent: 'center', alignItems: 'center',
                                gap: { xs: 2, sm: 0 },
                                ':hover': {
                                    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
                                    cursor: 'pointer'
                                }
                            }}>
                            <img src={Coin} alt="Coin" height={35} style={{ opacity: 0.8 }} />
                            <Typography variant="h6" textAlign={"center"}>
                                ${creditC?.price}
                            </Typography>
                            <Typography fontSize={'small'} fontWeight={'bold'} mt={1} textAlign={"center"}>
                                for  {creditC?.amount} IF Credits
                            </Typography>
                        </Card>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant='contained' color='primary' onClick={() => setOpenDialog(false)}>
                        Close
                    </Button>
                </DialogActions>

            </Dialog>


            <Dialog open={checkOpen} onClose={() => {
                setCheckOpen(false)
                setAccept(false)
                setErrMsg(null)
            }} >
                <DialogTitle sx={{ textAlign: 'center' }} >
                    Summary Payment <img src={Coin} alt="Check" style={{ width: 20, height: 20, marginLeft: 5, marginBottom: 4 }} />
                </DialogTitle>

                <DialogContent>
                    {selectedCredit === 'creditA' && <Typography variant="h5" textAlign={"center"}>
                        ${creditA?.price} for  {creditA?.amount} IF Credits
                    </Typography>}
                    {selectedCredit === 'creditB' && <Typography variant="h5" textAlign={"center"}>
                        ${creditB?.price} for  {creditB?.amount} IF Credits
                    </Typography>}
                    {selectedCredit === 'creditC' && <Typography variant="h5" textAlign={"center"}>
                        ${creditC?.price} for  {creditC?.amount} IF Credits
                    </Typography>}

                    <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%" mb={1} mt={2}>
                        <FormControlLabel
                            control={<Checkbox
                                color="primary"
                                checked={accept}
                                onChange={(e) => setAccept(e.target.checked)}
                                name="accept"
                            />}
                            label={<Typography variant="h8" color="primary.main">
                                I’ve read and accept the <Link component="span" onClick={handleClickOpen("preliminary_information_form")} color="info.main" variant="h8">
                                    Preliminary Information Form<br />
                                </Link> and <Link component="span" onClick={handleClickOpen("sales_agreement")} color="info.main" variant="h8">
                                    Sales Agreement</Link>  of InternIF.
                            </Typography>}
                        />
                    </Box>
                </DialogContent>
                {errMsg?.includes('invoice') && <Divider sx={{ my: 2 }} />}
                {errMsg?.includes('invoice') && <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="body2" textAlign={"center"} px={2} py={1} bgcolor={"warning.lighter"} borderRadius={3}>
                        You need to add invoice information!
                        <Button
                            color="info"
                            size="small"
                            onClick={() => navigate('/settings?tab=invoiceInfo')} >
                            Go to Edit
                        </Button>
                    </Typography >
                </DialogContent>}
                <DialogActions>
                    <Button onClick={() => setCheckOpen(false)} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        disabled={!accept || errMsg}
                        onClick={() =>
                            selectedCredit === 'creditA' ? handleBuyCredits(creditA?._id) :
                                selectedCredit === 'creditB' ? handleBuyCredits(creditB?._id) :
                                    selectedCredit === 'creditC' ? handleBuyCredits(creditC?._id) : null
                        }
                        color="primary" variant="contained" autoFocus>
                        {"Buy"}
                    </Button>
                </DialogActions>


            </Dialog>

            <Dialog
                open={openPolicies}
                onClose={() => setOpenPolicies(false)}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent dividers={true} sx={{ minWidth: 'md', width: '500px', mt: 4 }}>
                    {policiesLoading
                        ? <CircularProgress />
                        : parse(policies?.data?.[0]?.content?.tr ?? '')}
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => setOpenPolicies(false)}>OK</Button>
                </DialogActions>
            </Dialog>

        </DashboardLayout >


    </>
}

export default Membership






