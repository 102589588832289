import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DashboardLayout } from "layouts"
import { Box, Grid, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RichTip, AnimatedTitle } from "components"
import { createNoteToSelfFunc, getUserNotesToSelfFunc, updateNoteFunc, getUserNotesToUserFunc } from "context"
import { useSnackbar } from "notistack"

const info = [
    "Bitti"
]

const Notepad = () => {


    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { accessToken } = useSelector((state) => state.user);
    const { t } = useTranslation()
    const { getUserNotesToSelfData } = useSelector((state) => state.notes)
    const [value, setValue] = useState(null)
    const [changed, setChanged] = useState(false)
    const [editorKey, setEditorKey] = useState(0);


    const handleSave = () => {
        getUserNotesToSelfData?.length > 0 ?
            dispatch(updateNoteFunc({
                title: "Notepad",
                content: value,
                accessToken: accessToken,
                noteId: getUserNotesToSelfData?.[0]?._id
            })).then((res) => {
                console.log(res)
                enqueueSnackbar(t("Note updated successfully"), { variant: 'success', });
                dispatch(getUserNotesToSelfFunc({ accessToken: accessToken }));
            })
            : dispatch(createNoteToSelfFunc({
                title: "Notepad",
                content: value,
                accessToken: accessToken
            })).then((res) => {
                console.log(res)
                enqueueSnackbar(t("Note created successfully"), { variant: 'success', });
                dispatch(getUserNotesToSelfFunc({ accessToken: accessToken }));
            });
    }

    const handleFocus = () => {
        setChanged(true)
    }

    useEffect(() => {
        getUserNotesToSelfData && setValue(getUserNotesToSelfData?.[0]?.content)
        setEditorKey((prevKey) => prevKey + 1);
    }, [getUserNotesToSelfData])

    useEffect(() => {
        accessToken && dispatch(getUserNotesToSelfFunc({ accessToken: accessToken }));
    }, [dispatch, accessToken])


    return <DashboardLayout info={info}>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} >
                <AnimatedTitle title="Notepad" />
            </Grid>
            <Grid item xs={12}>
                <RichTip
                    value={value}
                    key={editorKey}
                    handleFocus={handleFocus}
                    setValue={(e) => {
                        setValue(e)
                    }}
                />
                <Button
                    onClick={handleSave}
                    disabled={value ? changed ? false : true : true}

                    variant="contained"
                    color="primary" size="large" sx={{ mt: 2, px: 5 }}>{t("Save")}</Button>
            </Grid>
        </Grid>

    </DashboardLayout>
}

export default Notepad