

const resources = {
  en: {
    translation: {
      'Dashboard': 'Dashboard',
      'Clients': 'Clients',
      'Universities': 'Universities',
      'Finance': 'Finance',
      'Package Sales': 'Package Sales',
      'Revenue': 'Revenue',
      'Withdrawn': 'Withdrawn',
      'Withdrawable': 'Withdrawable',
      'Open': 'Open',
      'Waiting': 'Waiting',
      'Answered': 'Answered',
      'Closed': 'Closed',
      'Tickets': 'Tickets',
      'Client Bronze': 'Client Bronze',
      'Client Silver': 'Client Sliver',
      'Client Gold': 'Client Gold',
      'Client VIP': 'Client VIP',
      'University Bronze': 'University Bronze',
      'University Silver': 'University Silver',
      'University Gold': 'University Gold',
      'University VIP': 'University VIP',
      'Business Management': 'Business Management',
      'Client': 'Client',
      'University': 'University',
      'Ticket': 'Ticket',
      'Sales Representative': 'Sales Representative',
      "VIP": "VIP",
      "Client List": "Client List",
      "Add Client": "Add Client",
      "Name": "Name",
      "Company Name": "Company Name",
      "Standard / Pro / Special Sales": "Standard / Pro / Special Sales",
      "Total Sales ($)": "Total Sales ($)",
      "Status": "Status",
      "Medal": "Medal",
      "To add a client, please enter the client information here.": "To add a client, please enter the client information here.",
      "Bronze": "Bronze",
      "Silver": "Silver",
      "Gold": "Gold",
      "University List": "University List",
      "Add University": "Add University",
      "University Name": "University Name",
      "City": "City",
      "Country": "Country",
      "No data found": "No data found",
      "Standard Package Sales": "Standard Package Sales",
      "Pro Package Sales": "Pro Package Sales",
      "Special Package Sales": "Special Package Sales",
      "Finance List": "Finance List",
      "History": "History",
      "Withdraw Money": "Withdraw Money",
      "Type": "Type",
      "Name Surname": "Name Surname",
      "Sales": "Sales",
      "Enter the amount you want to withdraw.": "Enter the amount you want to withdraw.",
      "Amount": "Amount",
      "Cancel": "Cancel",
      "Withdraw": "Withdraw",
      "There is no ticket yet!": "There is no ticket yet!",
      "Profile": "Profile",
      "Settings": "Settings",
      "Sign Out": "Sign Out",
      "Notifications": "Notifications",
      "Mark all as read": "Mark all as read",
      "All": "All",
      "Unread": "Unread",
      "Archived": "Archived",
      "There are no notifications.": "There are no notifications.",
      "Save Changes": "Save Changes",
      "Change Profile Photo": "Change Profile Photo",
      "User Type": "User Type",
      "Individual": "Individual",
      "Organization": "Organization",
      "University Club": "University Club",
      "Organization Type": "Organization Type",
      "Public Company": "Public Company",
      "Self-employed": "Self-employed",
      "Government Agency": "Government Agency",
      "Nonprofit": "Nonprofit",
      "Sole Proprietorship": "Sole Proprietorship",
      "Limited Liability Company": "Limited Liability Company",
      "Corporation": "Corporation",
      "Partnership": "Partnership",
      "Organization Name": "Organization Name",
      "Organization Logo": "Organization Logo",
      "University Logo": "University Logo",
      "Logo": "Logo",
      "Surname": "Surname",
      "Email": "Email",
      "Phone": "Phone",
      "Birthday": "Birthday",
      "Gender": "Gender",
      "Nationality": "Nationality",
      "Language": "Language",
      "Language Level": "Language Level",
      "Address": "Address",
      "Notification": "Notification",
      "Security": "Security",
      "Policies": "Policies",
      "You can manage your notification preferences below.": "You can manage your notification preferences below.",
      "Push Notifications": "Push Notifications",
      "Email Notifications": "Email Notifications",
      "SMS Notifications": "SMS Notifications",
      "Save Settings": "Save Settings",
      "You can change your password in the field below.": "You can change your password in the field below.",
      "Old Password": "Old Password",
      "New Password": "New Password",
      "New Password Again": "New Password Again",
      "Old password": "Old password",
      "New password": "New password",
      "New password again": "New password again",
      "Change Password": "Change Password",
      "You can update the following settings to perform 2-factor authentication.": "You can update the following settings to perform 2-factor authentication.",
      "Two Factor with Email": "Two Factor with Email",
      "Two Factor with SMS": "Two Factor with SMS",
      "Welcome to InternIF!": "Welcome to InternIF!",
      "Personal Information": "Personal Information",
      "Could you give us some personal information about yourself?": "Could you give us some personal information about yourself?",
      "Account Type": "Account Type",
      "We would like you to provide some information about your account.": "We would like you to provide some information about your account.",
      "Detailed Information": "Detailed Information",
      "Finally, we would like some of your detailed information.": "Finally, we would like some of your detailed information.",
      "Last step": "Last step",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Female": "Female",
      "Male": "Male",
      "Other": "Other",
      "Finish": "Finish",
      "Continue": "Continue",
      "Back": "Back",
      "Companies": "Companies",
      "Company": "Company",
      "Company Admin List": "Company Admin List",
      "Add Company Admin": "Add Company Admin",
      "To add a company admin, please enter the company admin information here.": "To add a company admin, please enter the company admin information here.",
      "Add": "Add",
      "Company Type": "Company Type",
      "Employee": "Employee",
      "Country / City": "Country / City",
      "Intern": "Intern",
      "Intern List": "Intern List",
      "Interns": "Interns",
      "Intern Representative": "Intern Representative",
      "Company Representative": "Company Representative",
      "Add Intern": "Add Intern",
      "To add a intern, please enter the intern information here.": "To add a intern, please enter the intern information here.",
      "Full Name": "Full Name",
      "Note": "Note",
      "Pending Client List": "Pending Client List",
      "Created": "Created",
      "Pending University List": "Pending University List",
      "To add a university, please enter the university information here.": "To add a university, please enter the university information here.",
      "Admin Email": "Admin Email",
      "Notepad": "Notepad",
      "Save": "Save",
      "Billing Info": "Billing Info",
      "You can update your billing information below.": "You can update your billing information below.",
      "Identity Number": "Identity Number",
      "Tax Office": "Tax Office",
      "Tax Number": "Tax Number",
      "Postcode": "Postcode",
      "Packages": "Packages",
      "Usable": "Usable",
      "Total": "Total",
      "Standard Package": "Standard Package",
      "Pro Package": "Pro Package",
      "Special Package": "Special Package",
      "Company Selection": "Company Selection",
      "Certificate of Participation": "Certificate of Participation",
      "Reference Letter": "Reference Letter",
      "1 Country": "1 Country",
      "General Support": "General Support",
      "Orientation": "Orientation",
      "Mentoring": "Mentoring",
      "Micro Enterprises": "Micro Enterprises",
      "2 Countries": "2 Countries",
      "Dedicated Support": "Dedicated Support",
      "Small Enterprises": "Small Enterprises",
      "3 Countries": "3 Countries",
      "Medium Enterprises": "Medium Enterprises",
      "Send": "Send",
      "Buy": "Buy",
      "Package List": "Package List",
      "Country/City": "Country/City",
      "Standard / Pro / Special Package": "Standard / Pro / Special Package",
      "Buy package to intern.": "Buy package to intern.",
      "Package Type": "Package Type",
      "#Internship Credit": "#Internship Credit",
      "Listing Price": "Listing Price",
      "Discount": "Discount",
      "Price": "Price",
      "Payment Method": "Payment Method",
      "The first part of your CV, positioned at the top of the page, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.": "The first part of your CV, positioned at the top of the page, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.",
      "When it comes to your contact details, your email address and phone number(s) are essential. We use your registered email on the CV. Once upon a time, it was customary to include your full address on your CV. Today, you simply need to list your town and county.": "When it comes to your contact details, your email address and phone number(s) are essential. We use your registered email on the CV. Once upon a time, it was customary to include your full address on your CV. Today, you simply need to list your town and county.",
      "Personal Details": "Personal Details",
      "When it comes to your contact details, yo, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.": "When it comes to your contact details, yo, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.",
      "When it comes toand phone registere was customary to include your full address on your CV. Today, you simply need to list your town and county.": "When it comes toand phone registere was customary to include your full address on your CV. Today, you simply need to list your town and county.",
      "Educational Information": "Educational Information",
      "Foreign Languages": "Foreign Languages",
      "Skills": "Skills",
      "Efficient Talent Matchmaking": "Efficient Talent Matchmaking",
      "InternIF's AI-driven recommendation system streamlines the talent matchmaking process for companies.": "InternIF's AI-driven recommendation system streamlines the talent matchmaking process for companies.",
      "By understanding the unique needs and preferences of each company, InternIF ensures that the intern selection process is not only efficient but also results in high-quality matches.": "By understanding the unique needs and preferences of each company, InternIF ensures that the intern selection process is not only efficient but also results in high-quality matches.",
      "Diverse and Inclusive Hiring": "Diverse and Inclusive Hiring",
      "Companies partnering with InternIF gain access to a diverse pool of global talent. This inclusivity promotes diverse perspectives within the workplace, fostering innovation and creativity.": "Companies partnering with InternIF gain access to a diverse pool of global talent. This inclusivity promotes diverse perspectives within the workplace, fostering innovation and creativity.",
      "InternIF actively supports companies in building teams that reflect a variety of backgrounds and experiences.": "InternIF actively supports companies in building teams that reflect a variety of backgrounds and experiences.",
      "Remote Work Adaptability": "Remote Work Adaptability",
      "Recognizing the evolving landscape of work, InternIF embraces and facilitates remote internships.": "Recognizing the evolving landscape of work, InternIF embraces and facilitates remote internships.",
      "Companies benefit from the flexibility of engaging with interns regardless of geographical constraints, tapping into a broader talent pool and adapting to the changing dynamics of the professional world.": "Companies benefit from the flexibility of engaging with interns regardless of geographical constraints, tapping into a broader talent pool and adapting to the changing dynamics of the professional world.",
      "Could you please provide some information about yourself?": "Could you please provide some information about yourself?",
      "Could you please tell us a little more about yourself?": "Could you please tell us a little more about yourself?",
      "Could you please provide some information about your education?": "Could you please provide some information about your education?",
      "Could you please provide some information about your foreign languages?": "Could you please provide some information about your foreign languages?",
      "Finally, we would like to learn about your skills.": "Finally, we would like to learn about your skills.",
      "Could you please provide some information about your skills?": "Could you please provide some information about your skills?",
      "Could you please provide some information about your certificates?": "Could you please provide some information about your certificates?",
      "Could you please provide some information about your experiences?": "Could you please provide some information about your experiences?",
      "Could you please provide some information about your personal profile?": "Could you please provide some information about your personal profile?",
      "Finally, we would like to learn about your personal profile.": "Finally, we would like to learn about your personal profile.",
      "Choose a language": "Choose a language",
      "First Name*": "First Name*",
      "Last Name*": "Last Name*",
      "Gender*": "Gender*",
      "Birthdate*": "Birthdate*",
      "Profile Image": "Profile Image",
      "Nationality*": "Nationality*",
      "Country*": "Country*",
      "City*": "City*",
      "No university found": "No university found",
      "Department": "Department",
      "Still attending": "Still attending",
      "You do not have an active internship yet.": "You do not have an active internship yet.",
      "Personal Profile": "Personal Profile",
      "Educations": "Educations",
      "Frequently Asked Questions": "Frequently Asked Questions",
      "General": "General",
      "What is InternIF Premium?": "What is InternIF Premium?",
      "InternIF Premium is a specialized internship placement service offered by InternIF, providing students with enhanced support and opportunities for international internships.": "InternIF Premium is a specialized internship placement service offered by InternIF, providing students with enhanced support and opportunities for international internships.",
      "How does InternIF Premium differ from regular internship services?": "How does InternIF Premium differ from regular internship services?",
      "InternIF Premium offers additional features such as dedicated support, company selection options, and tailored internship placements, providing a more personalized and comprehensive internship experience.": "InternIF Premium offers additional features such as dedicated support, company selection options, and tailored internship placements, providing a more personalized and comprehensive internship experience.",
      "Is InternIF Premium available to students from all countries?": "Is InternIF Premium available to students from all countries?",
      "Yes, InternIF Premium is available to students worldwide, offering internship opportunities in various countries and industries.": "Yes, InternIF Premium is available to students worldwide, offering internship opportunities in various countries and industries.",
      "What packages are available under InternIF Premium?": "What packages are available under InternIF Premium?",
      "InternIF Premium offers three packages: Standard, Pro, and Special, each with different features and levels of support to meet the diverse needs of students.": "InternIF Premium offers three packages: Standard, Pro, and Special, each with different features and levels of support to meet the diverse needs of students.",
      "How do I choose the right InternIF Premium package for me?": "How do I choose the right InternIF Premium package for me?",
      "You can choose the right package based on factors such as company selection options, support services, and internship location preferences to ensure it aligns with your goals and requirements.": "You can choose the right package based on factors such as company selection options, support services, and internship location preferences to ensure it aligns with your goals and requirements.",
      "Can I customize my internship preferences with InternIF Premium Packages?": "Can I customize my internship preferences with InternIF Premium Packages?",
      "Yes, you can specify your preferred internship location and sector when using any of the InternIF Premium Packages. However, the level of customization may vary depending on the package chosen.": "Yes, you can specify your preferred internship location and sector when using any of the InternIF Premium Packages. However, the level of customization may vary depending on the package chosen.",
      "Can I customize my InternIF Premium package?": "Can I customize my InternIF Premium package?",
      "While InternIF Premium packages come with predefined features, you can discuss customization options with InternIF support to tailor the package to your specific needs and preferences.": "While InternIF Premium packages come with predefined features, you can discuss customization options with InternIF support to tailor the package to your specific needs and preferences.",
      "What is included in the Standard Package?": "What is included in the Standard Package?",
      "The Standard Package includes essential features such as internship placement in micro-scale enterprises, general support, orientation, mentoring, and certificates upon successful completion.": "The Standard Package includes essential features such as internship placement in micro-scale enterprises, general support, orientation, mentoring, and certificates upon successful completion.",
      "What additional benefits does the Pro Package offer?": "What additional benefits does the Pro Package offer?",
      "The Pro Package provides the option to choose from three pre-selected companies, dedicated support from an InternIF member, and placement in small-scale enterprises, in addition to the features included in the Standard Package.": "The Pro Package provides the option to choose from three pre-selected companies, dedicated support from an InternIF member, and placement in small-scale enterprises, in addition to the features included in the Standard Package.",
      "What makes the Special Package unique?": "What makes the Special Package unique?",
      "The Special Package offers internship placement in medium-scale enterprises and personalized support from a dedicated InternIF member, along with the features included in the Standard Package.": "The Special Package offers internship placement in medium-scale enterprises and personalized support from a dedicated InternIF member, along with the features included in the Standard Package.",
      "Remote Internship Process": "Remote Internship Process",
      "Can you detail the Remote Internship Process with InternIF Premium?": "Can you detail the Remote Internship Process with InternIF Premium?",
      "The Remote Internship Process with InternIF Premium is designed to provide students with a seamless and enriching international internship experience. Here's a breakdown of the process:": "The Remote Internship Process with InternIF Premium is designed to provide students with a seamless and enriching international internship experience. Here's a breakdown of the process:",
      "Initiation": "Initiation",
      "Upon purchasing an InternIF Premium package, students initiate the internship process by clicking on the 'Use Package' button in their dashboard.": "Upon purchasing an InternIF Premium package, students initiate the internship process by clicking on the 'Use Package' button in their dashboard.",
      "Internship Configuration": "Internship Configuration",
      "Students specify their desired internship parameters, including the preferred time, country, sector, and weekly working hours (20 to 30 hours). They may also provide additional information relevant to their internship preferences.": "Students specify their desired internship parameters, including the preferred time, country, sector, and weekly working hours (20 to 30 hours). They may also provide additional information relevant to their internship preferences.",
      "InternIF Review": "InternIF Review",
      "The InternIF team reviews the submitted internship configuration and identifies suitable internship opportunities based on the student's preferences and qualifications.": "The InternIF team reviews the submitted internship configuration and identifies suitable internship opportunities based on the student's preferences and qualifications.",
      "Company Matching": "Company Matching",
      "InternIF matches students with internship opportunities at partner companies, taking into account factors such as the student's profile, company requirements, and available positions.": "InternIF matches students with internship opportunities at partner companies, taking into account factors such as the student's profile, company requirements, and available positions.",
      "Internship Commencement": "Internship Commencement",
      "Once the internship details are finalized, students receive confirmation of their internship placement and begin their remote internship at the designated company.": "Once the internship details are finalized, students receive confirmation of their internship placement and begin their remote internship at the designated company.",
      "Task Assignment": "Task Assignment",
      "Interns receive their first task from the company between 09:00-12:00, depending on the time zone of the internship location. Tasks may vary in complexity and duration, spanning implementation, design, review, evaluation, editing, reporting, and research.": "Interns receive their first task from the company between 09:00-12:00, depending on the time zone of the internship location. Tasks may vary in complexity and duration, spanning implementation, design, review, evaluation, editing, reporting, and research.",
      "Task Completion": "Task Completion",
      "Interns complete assigned tasks within the specified timeframe, typically over a period of 20 working days. Tasks may be assigned on a daily, two-day, or three-day basis, depending on the nature of the internship project.": "Interns complete assigned tasks within the specified timeframe, typically over a period of 20 working days. Tasks may be assigned on a daily, two-day, or three-day basis, depending on the nature of the internship project.",
      "Evaluation and Feedback": "Evaluation and Feedback",
      "Upon task completion, interns may receive feedback and evaluation from the company based on their performance and deliverables. Task evaluations play a crucial role in assessing the intern's overall success and performance during the internship.": "Upon task completion, interns may receive feedback and evaluation from the company based on their performance and deliverables. Task evaluations play a crucial role in assessing the intern's overall success and performance during the internship.",
      "Internship Completion": "Internship Completion",
      "Upon successful completion of the internship, interns may receive certificates of completion, practical training certificates (for Turkish citizens), and reference letters (for high-performing interns).": "Upon successful completion of the internship, interns may receive certificates of completion, practical training certificates (for Turkish citizens), and reference letters (for high-performing interns).",
      "Ongoing Support": "Ongoing Support",
      "Throughout the internship process, InternIF provides dedicated support to interns, addressing any queries, concerns, or challenges they may encounter during their internship journey.": "Throughout the internship process, InternIF provides dedicated support to interns, addressing any queries, concerns, or challenges they may encounter during their internship journey.",
      "The Remote Internship Process with InternIF Premium offers students a structured and supportive framework to gain valuable international work experience and advance their career goals.": "The Remote Internship Process with InternIF Premium offers students a structured and supportive framework to gain valuable international work experience and advance their career goals.",
      "Internship Placement": "Internship Placement",
      "How are internship placements determined in InternIF Premium?": "How are internship placements determined in InternIF Premium?",
      "Internship placements in InternIF Premium are determined based on factors such as student preferences, qualifications, and the availability of internship opportunities from partner companies.": "Internship placements in InternIF Premium are determined based on factors such as student preferences, qualifications, and the availability of internship opportunities from partner companies.",
      "Can I choose the location and sector of my internship?": "Can I choose the location and sector of my internship?",
      "Yes, InternIF Premium allows you to specify your preferred internship location and sector, ensuring that the placement aligns with your interests and career goals.": "Yes, InternIF Premium allows you to specify your preferred internship location and sector, ensuring that the placement aligns with your interests and career goals.",
      "What types of companies offer internships through InternIF Premium?": "What types of companies offer internships through InternIF Premium?",
      "InternIF Premium partners with a diverse range of companies, including micro, small, and medium-scale enterprises, offering internship opportunities across various industries and sectors.": "InternIF Premium partners with a diverse range of companies, including micro, small, and medium-scale enterprises, offering internship opportunities across various industries and sectors.",
      "During Internship": "During Internship",
      "What support services are available during the internship with InternIF Premium?": "What support services are available during the internship with InternIF Premium?",
      "Interns in InternIF Premium receive comprehensive support services, including orientation sessions, dedicated support from an InternIF member, and mentoring throughout the internship duration.": "Interns in InternIF Premium receive comprehensive support services, including orientation sessions, dedicated support from an InternIF member, and mentoring throughout the internship duration.",
      "What are the expectations during the internship with InternIF Premium?": "What are the expectations during the internship with InternIF Premium?",
      "Interns are expected to complete assigned tasks diligently, maintain regular communication with their supervisors, and adhere to the company's policies and guidelines throughout the internship period.": "Interns are expected to complete assigned tasks diligently, maintain regular communication with their supervisors, and adhere to the company's policies and guidelines throughout the internship period.",
      "Can I request additional assistance or support during my internship with InternIF Premium?": "Can I request additional assistance or support during my internship with InternIF Premium?",
      "Yes, interns enrolled in InternIF Premium can request additional assistance or support from InternIF, as needed. The support team is available to address any internship-related issues or concerns promptly.": "Yes, interns enrolled in InternIF Premium can request additional assistance or support from InternIF, as needed. The support team is available to address any internship-related issues or concerns promptly.",
      "Can you detail the evaluation of internship with InternIF Premium?": "Can you detail the evaluation of internship with InternIF Premium?",
      "The evaluation process for internships with InternIF Premium is designed to assess interns' performance and provide recognition for their achievements. Here's a breakdown of the evaluation process:": "The evaluation process for internships with InternIF Premium is designed to assess interns' performance and provide recognition for their achievements. Here's a breakdown of the evaluation process:",
      "Success Criteria": "Success Criteria",
      "InternIF establishes success criteria for interns, typically requiring a minimum success rate of 70% to consider the internship valid.": "InternIF establishes success criteria for interns, typically requiring a minimum success rate of 70% to consider the internship valid.",
      "Company Evaluation": "Company Evaluation",
      "Interns' performance during the internship is evaluated by the company they intern with. The company assesses interns based on their task completion, quality of work, professionalism, and adherence to deadlines.": "Interns' performance during the internship is evaluated by the company they intern with. The company assesses interns based on their task completion, quality of work, professionalism, and adherence to deadlines.",
      "Certificate of Completion": "Certificate of Completion",
      "Interns who achieve the minimum success rate of 70% receive a certificate of completion from the company they intern with. This certificate serves as formal recognition of their successful participation in the internship program.": "Interns who achieve the minimum success rate of 70% receive a certificate of completion from the company they intern with. This certificate serves as formal recognition of their successful participation in the internship program.",
      "Practical Training Certificate": "Practical Training Certificate",
      "For Turkish citizens who achieve a success rate of over 80%, InternIF provides a practical training certificate that can be viewed on their e-government platform. This certificate acknowledges their practical training experience and enhances their professional credentials.": "For Turkish citizens who achieve a success rate of over 80%, InternIF provides a practical training certificate that can be viewed on their e-government platform. This certificate acknowledges their practical training experience and enhances their professional credentials.",
      "Exceptional interns who attain a success rate of over 95% are eligible to receive a reference letter from the company where they interned. This personalized letter highlights the intern's outstanding performance and serves as a valuable endorsement for future career opportunities.": "Exceptional interns who attain a success rate of over 95% are eligible to receive a reference letter from the company where they interned. This personalized letter highlights the intern's outstanding performance and serves as a valuable endorsement for future career opportunities.",
      "InternIF Evaluation and Support": "InternIF Evaluation and Support",
      "InternIF also evaluates interns' performance and provides ongoing support throughout the internship process. Interns can reach out to InternIF for assistance with any challenges they encounter during their internship.": "InternIF also evaluates interns' performance and provides ongoing support throughout the internship process. Interns can reach out to InternIF for assistance with any challenges they encounter during their internship.",
      "The evaluation process with InternIF Premium ensures that interns receive recognition for their efforts and achievements, enhancing their professional development and career prospects.": "The evaluation process with InternIF Premium ensures that interns receive recognition for their efforts and achievements, enhancing their professional development and career prospects.",
      "How is internship success evaluated in InternIF Premium?": "How is internship success evaluated in InternIF Premium?",
      "Internship success in InternIF Premium is evaluated based on various factors, including task completion, performance feedback from the company, and overall professionalism demonstrated during the internship.": "Internship success in InternIF Premium is evaluated based on various factors, including task completion, performance feedback from the company, and overall professionalism demonstrated during the internship.",
      "What certificates or documents will I receive upon completing my internship with InternIF Premium?": "What certificates or documents will I receive upon completing my internship with InternIF Premium?",
      "Upon successful completion of the internship, interns may receive certificates of completion, practical training certificates, and reference letters, depending on their performance and achievements during the internship period.": "Upon successful completion of the internship, interns may receive certificates of completion, practical training certificates, and reference letters, depending on their performance and achievements during the internship period.",
      "Will InternIF Premium assist me with future career opportunities after completing my internship?": "Will InternIF Premium assist me with future career opportunities after completing my internship?",
      "Yes, InternIF Premium provides ongoing support to successful interns, offering guidance and assistance with future career opportunities, including job placement services, resume building, and networking opportunities within the industry.": "Yes, InternIF Premium provides ongoing support to successful interns, offering guidance and assistance with future career opportunities, including job placement services, resume building, and networking opportunities within the industry.",
      "Are you tired of endlessly searching for internship opportunities with no success? Say goodbye to uncertainty and hello to guaranteed international internship placement with InternIF Premium!": "Are you tired of endlessly searching for internship opportunities with no success? Say goodbye to uncertainty and hello to guaranteed international internship placement with InternIF Premium!",
      "To buy a package, you need to complete your billing information. Fill in the information in the Invoice section completely.": "To buy a package, you need to complete your billing information. Fill in the information in the Invoice section completely.",
      "Here's how our guaranteed internship placement works:": "Here's how our guaranteed internship placement works:",
      "Personalized Matching:": "Personalized Matching:",
      "We use cutting-edge technology to match you with internship opportunities that align with your skills, interests, and aspirations.": "We use cutting-edge technology to match you with internship opportunities that align with your skills, interests, and aspirations.",
      "Exclusive Access:": "Exclusive Access:",
      "InternIF Premium members get exclusive access to a curated selection of internship opportunities not available to the public. You'll have the first pick of the best internships before they're even advertised elsewhere.": "InternIF Premium members get exclusive access to a curated selection of internship opportunities not available to the public. You'll have the first pick of the best internships before they're even advertised elsewhere.",
      "Dedicated Support:": "Dedicated Support:",
      "Our team of internship placement specialists is here to support you every step of the way. We'll provide personalized guidance and support to ensure your success.": "Our team of internship placement specialists is here to support you every step of the way. We'll provide personalized guidance and support to ensure your success.",
      "Peace of Mind:": "Peace of Mind:",
      "With our guaranteed internship placement feature, you can rest assured knowing that you'll secure a valuable internship experience to boost your career prospects.": "With our guaranteed internship placement feature, you can rest assured knowing that you'll secure a valuable internship experience to boost your career prospects.",
      "Don't leave your internship placement to chance. Upgrade to InternIF Premium today and unlock guaranteed internship placement for a brighter future!": "Don't leave your internship placement to chance. Upgrade to InternIF Premium today and unlock guaranteed internship placement for a brighter future!",
      "My Applications": "My Applications",
      "My Premium Packages": "My Premium Packages",
      "Packages in Use": "Packages in Use",
      "Used Date": "Used Date",
      "Action": "Action",
      "Premium Package History": "Premium Package History",
      "Use": "Use",
      "Apply2": "Apply"
    },
  },
  tr: {
    translation: {
      'Dashboard': 'Panel',
      'Internships': 'Stajlar',
      'Applications': 'Başvurular',
      'Notifications': 'Bildirimler',
      'Premium': 'Premium',
      'IF-AI': 'IF-AI',
      'My Internships': 'Stajlarım',
      'Tasks': 'Görevler',
      'Messages': 'Mesajlar',
      'Documents': 'Belgeler',
      'My Account': 'Hesabım',
      'My Resume': 'Özgeçmişim',
      'My Cover Letters': 'Ön Yazılarım',
      "Settings": "Ayarlar",
      "Sign Out": "Çıkış Yap",
      "NEW": "YENİ",
      "Search": "Ara",
      "Filter & Sort": "Filtrele & Sırala",
      "Saved Internships": "Kaydedilen Stajlar",
      "All Internships": "Tüm Stajlar",
      "Sort by": "Sırala",
      "Most Recent": "En Yeni",
      "Most Relevant": "En İlgili",
      "Filter by": "Filtrele",
      "Internship Area": "Staj Alanı",
      "Industry": "Sektör",
      "Choose an internship Area": "Bir staj alanı seçin",
      "Choose an industry": "Bir sektör seçin",
      "Start Date": "Başlangıç Tarihi",
      "Languages": "Diller",
      "MM/DD/YYYY": "GG/AA/YYYY",
      "Choose a language": "Bir dil seçin",
      "Country": "Ülke",
      "Choose a country": "Bir ülke seçin",
      "City": "Şehir",
      "Choose a city": "Bir şehir seçin",
      "Application Type": "Başvuru Türü",
      "Global": "Global",
      "Local": "Yerel",
      "Salary Type": "Maaş Türü",
      "Paid": "Ücretli",
      "Volunteer": "Gönüllü",
      "Time Period": "Zaman Aralığı",
      "Preferred GPA": "Tercih Edilen GPA",
      "Preferred College Year": "Tercih Edilen Üniversite Yılı",
      "Coffee": "Kahve",
      "None": "Hiçbiri",
      "Just 1 Coffee": "Sadece 1 Kahve",
      "1 Coffee Per Week": "Haftada 1 Kahve",
      "20 Coffees": "20 Kahve",
      "Filter": "Filtrele",
      "Reset": "Sıfırla",
      "Business and Management": "İş Yönetimi",
      "Consulting": "Danışmanlık",
      "Education and Teaching": "Eğitim ve Öğretim",
      "Engineering and Architecture": "Mühendislik ve Mimarlık",
      "Environmental and Sustainability": "Çevre ve Sürdürülebilirlik",
      "Fashion and Design": "Moda ve Tasarım",
      "Finance and Accounting": "Finans ve Muhasebe",
      "Government and Public Policy": "Hükümet ve Kamu Politikası",
      "Graphic Design and Multimedia": "Grafik Tasarım ve Multimedya",
      "Healthcare and Medical": "Sağlık ve Tıp",
      "Human Resources": "İnsan Kaynakları",
      "Journalism and Media": "Gazetecilik ve Medya",
      "Legal": "Yasal",
      "Marketing and Advertising": "Pazarlama ve Reklamcılık",
      "Nonprofit and Social Services": "Kar Amacı Gütmeyen ve Sosyal Hizmetler",
      "Research and Development": "Araştırma ve Geliştirme",
      "Sales": "Satış",
      "Technology and Software Development": "Teknoloji ve Yazılım Geliştirme",
      "4 Weeks": "4 Hafta",
      "8 Weeks": "8 Hafta",
      "12 Weeks": "12 Hafta",
      "16 Weeks": "16 Hafta",
      "20 Weeks": "20 Hafta",
      "24 Weeks": "24 Hafta",
      "No": "Hayır",
      "Agriculture": "Tarım",
      "Engineering ": "Mühendislik",
      "Construction": "İnşaat",
      "Communications": "İletişim",
      "Sports and Recreation": "Spor ve Rekreasyon",
      "Financial Services": "Finansal Hizmetler",
      "Information Technologies": "Bilgi Teknolojileri",
      "Biotechnology": "Biyoteknoloji",
      "Pharmaceuticals": "İlaç",
      "R&D": "Ar-Ge",
      "Utilities": "Hizmetler",
      "Healthcare": "Sağlık",
      "Culture": "Kültür",
      "Mining": "Madencilik",
      "Electronics": "Elektronik",
      "Environment": "Çevre",
      "Manufacturing": "Üretim",
      "Textiles ": "Tekstil",
      "Chemicals": "Kimyasallar",
      "Media": "Medya",
      "Oil and Gas": "Petrol ve Gaz",
      "Commerce": "Ticaret",
      "Food and Drink": "Yiyecek ve İçecek",
      "Tourism": "Turizm",
      "Hotels": "Otel",
      "Retail": "Perakende",
      "Aerospace": "Havacılık",
      "Energy": "Enerji",
      "Creative": "Yaratıcı",
      "Shipping": "Nakliye",
      "Transport": "Taşımacılık",
      "No Relevance": "İlgisi Yok",
      "Forestry": "Ormancılık",
      "Water": "Su",
      "Education": "Eğitim",
      "English": "İngilizce",
      "Spanish": "İspanyolca",
      "Portuguese": "Portekizce",
      "French": "Fransızca",
      "Turkish": "Türkçe",
      "German": "Almanca",
      "Italian": "İtalyanca",
      "Russian": "Rusça",
      "Chinese": "Çince",
      "Arabic": "Arapça",
      "Japanese": "Japonca",
      "Korean": "Korece",
      "Turkey": "Türkiye",
      "There is no saved internship.": "Kaydedilen staj bulunmamaktadır.",
      "All Applications": "Tüm Başvurular",
      "Scheduled": "Planlı Stajlar",
      "Indefinite": "Süresiz Stajlar",
      "There is no notifications.": "Bildirim bulunmamaktadır.",
      "All": "Tümü",
      "Today": "Bugün",
      "Yesterday": "Dün",
      "Last Week": "Geçen Hafta",
      "Last Month": "Geçen Ay",
      "Mark All as Read": "Tümünü Okundu Olarak İşaretle",
      "Your Package": "Paketiniz",
      "Free": "Ücretsiz",
      "Standard": "Standard",
      "Pro": "Pro",
      "Special": "Special",
      "Company Selection": "Şirket Seçimi",
      "Certificate of Participation": "Katılım Sertifikası",
      "Reference Letter": "Referans Mektubu",
      "1 Country": "1 Ülke",
      "General Support": "Genel Destek",
      "Orientation": "Oryantasyon",
      "Mentoring": "Mentörlük",
      "Micro Enterprises": "Mikro Ölçekli İşletmeler",
      "2 Countries": "2 Ülke",
      "Dedicated Support": "Özel Destek",
      "Small Enterprises": "Küçük Ölçekli İşletmeler",
      "3 Countries": "3 Ülke",
      "Medium Enterprises": "Orta Ölçekli İşletmeler",
      "Send": "Gönder",
      "Buy": "Satın Al",
      "Package List": "Paket Listesi",
      "Country/City": "Ülke/Şehir",
      "Standard / Pro / Special Package": "Standard / Pro / Special Paket",
      "Buy package to intern.": "Stajyerler için paket satın al.",
      "Package Type": "Paket Türü",
      "#Internship Credit": "Staj Kredisi",
      "Listing Price": "Liste Ücreti",
      "Discount": "İndirim",
      "Price": "Fiyat",
      "Payment Method": "Ödeme Yöntemi",
      "Payment with Iyzico": "Iyzico ile Ödeme",
      "My Internship": "Stajım",
      "Account Information": "Hesap Bilgileri",
      "You can enter information about your account.": "Hesabınız hakkındaki bilgileri girebilirsiniz.",
      "Name": "İsim",
      "Surname": "Soyisim",
      "Phone Number": "Telefon Numarası",
      "Gender": "Cinsiyet",
      "Birthdate": "Doğum Tarihi",
      "Nationality": "Uyruk",
      "Save Changes": "Değişiklikleri Kaydet",
      "Print CV": "CV Yazdır",
      "Edit Account": "Hesabı Düzenle",
      "Resume fill rate": "CV doldurma oranı",
      "Personal Profile": "Kişisel Profil",
      "Educations": "Eğitimler",
      "Foreign Languages": "Yabancı Diller",
      "Experiences": "Deneyimler",
      "Skills": "Yetkinlikler",
      "Certificates": "Sertifikalar",
      "Add Cover Letter": "Ön Yazı Ekle",
      "Update Cover Letter": "Ön Yazı Güncelle",
      "Cover Letter Name": "Ön Yazı Başlığı",
      "Content": "İçerik",
      "Remove": "Kaldır",
      "No cover letter added yet.": "Ön yazınız bulunmamaktadır.",
      'Clients': 'Müşteriler',
      'Universities': 'Üniversiteler',
      'Finance': 'Finans',
      'Package Sales': 'Paket Satışları',
      'Revenue': 'Gelir',
      'Withdrawn': 'Çekilen Tutar',
      'Withdrawable': 'Çekilebilir Tutar',
      'Open': 'Açık',
      'Waiting': 'Bekleyen',
      'Answered': 'Cevaplanan',
      'Closed': 'Kapatılan',
      'Tickets': 'Destek Talepleri',
      'Client Bronze': 'Bronz Müşteri',
      'Client Silver': 'Gümüş Müşteri',
      'Client Gold': 'Altın Müşteri',
      'Client VIP': 'VIP Müşteri',
      'University Bronze': 'Bronz Üniversite',
      'University Silver': 'Gümüş Üniversite',
      'University Gold': 'Altın Üniversite',
      'University VIP': 'VIP Üniversite',
      'Business Management': 'İş Yönetimi',
      'Client': 'Müşteri',
      'University': 'Üniversite',
      'Ticket': 'Talep',
      'Sales Representative': 'Satış Temsilcisi',
      "VIP": "VIP",
      "Client List": "Müşteri Listesi",
      "Add Client": "Müşteri Ekle",
      "Company Name": "Şirket Adı",
      "Standard / Pro / Special Sales": "Standart / Pro / Özel Satış",
      "Total Sales ($)": "Toplam Satış ($)",
      "Status": "Durum",
      "Medal": "Madalya",
      "To add a client, please enter the client information here.": "Müşteri eklemek için lütfen müşteri bilgilerini buraya girin.",
      "Bronze": "Bronz",
      "Silver": "Gümüş",
      "Gold": "Altın",
      "University List": "Üniversite Listesi",
      "Add University": "Üniversite Ekle",
      "University Name": "Üniversite Adı",
      "No data found": "Veri bulunamadı",
      "Standard Package Sales": "Standart Paket Satışları",
      "Pro Package Sales": "Pro Paket Satışları",
      "Special Package Sales": "Özel Paket Satışları",
      "Finance List": "Finans Listesi",
      "History": "Geçmiş",
      "Withdraw Money": "Para Çek",
      "Type": "Tür",
      "Name Surname": "Ad Soyad",
      "Enter the amount you want to withdraw.": "Çekmek istediğiniz tutarı giriniz.",
      "Amount": "Tutar",
      "Cancel": "Kapat",
      "Withdraw": "Çek",
      "There is no ticket yet!": "Henüz destek talebi yok!",
      "Profile": "Profil",
      "Mark all as read": "Tümünü okundu olarak işaretle",
      "Unread": "Okunmamış",
      "Archived": "Arşivlenmiş",
      "There are no notifications.": "Henüz bildiriminiz yok.",
      "Change Profile Photo": "Profil Fotoğrafını Değiştir",
      "User Type": "Kullanıcı Türü",
      "Individual": "Bireysel",
      "Organization": "Kurumsal",
      "University Club": "Üniversite Kulübü",
      "Organization Type": "Kurum Türü",
      "Public Company": "Anonim Şirket",
      "Self-employed": "Serbest Meslek",
      "Government Agency": "Kamu Kurumu",
      "Nonprofit": "Kar Amacı Gütmeyen",
      "Sole Proprietorship": "Şahıs Şirketi",
      "Limited Liability Company": "Limited Şirket",
      "Corporation": "Kolektif Şirket",
      "Partnership": "Komandit Şirket",
      "Organization Name": "Kurum Adı",
      "Organization Logo": "Kurum Logosu",
      "University Logo": "Üniversite Logosu",
      "Logo": "Logo",
      "Email": "E-posta",
      "Phone": "Telefon",
      "Birthday": "Doğum Tarihi",
      "Language": "Dil",
      "Language Level": "Dil Seviyesi",
      "Address": "Adres",
      "Notification": "Bildirim",
      "Security": "Güvenlik",
      "Policies": "Politikalar",
      "You can manage your notification preferences below.": "Bildirim tercihlerinizi aşağıdan yönetebilirsiniz.",
      "Push Notifications": "Push Bildirimleri",
      "Email Notifications": "E-posta Bildirimleri",
      "SMS Notifications": "SMS Bildirimleri",
      "Save Settings": "Ayarları Kaydet",
      "You can change your password in the field below.": "Şifrenizi aşağıdaki alandan değiştirebilirsiniz.",
      "Old Password": "Eski Şifre",
      "New Password": "Yeni Şifre",
      "New Password Again": "Yeni Şifre Tekrar",
      "Old password": "Eski şifre",
      "New password": "Yeni şifre",
      "New password again": "Yeni şifre tekrar",
      "Change Password": "Şifre Değiştir",
      "You can update the following settings to perform 2-factor authentication.": "2 faktörlü kimlik doğrulama yapmak için aşağıdaki ayarları güncelleyebilirsiniz.",
      "Two Factor with Email": "E-posta ile 2 Faktörlü Doğrulama",
      "Two Factor with SMS": "SMS ile 2 Faktörlü Doğrulama",
      "Welcome to InternIF!": "InternIF'e Hoş Geldiniz!",
      "Personal Information": "Kişisel Bilgiler",
      "Could you give us some personal information about yourself?": "Bize kendinizle ilgili bazı kişisel bilgiler verebilir misiniz?",
      "Account Type": "Hesap Türü",
      "We would like you to provide some information about your account.": "Hesabınız hakkında bazı bilgiler vermenizi istiyoruz.",
      "Detailed Information": "Detaylı Bilgi",
      "Finally, we would like some of your detailed information.": "Son olarak, bazı ayrıntılı bilgilerinizi istiyoruz.",
      "Last step": "Son adım",
      "First Name": "Ad",
      "Last Name": "Soyad",
      "Female": "Kadın",
      "Male": "Erkek",
      "Other": "Diğer",
      "Finish": "Bitir",
      "Continue": "Devam Et",
      "Back": "Geri",
      "Companies": "Şirketler",
      "Company": "Şirket",
      "Company Admin List": "Şirket Yönetici Listesi",
      "Add Company Admin": "Şirket Yöneticisi Ekle",
      "To add a company admin, please enter the company admin information here.": "Şirket yöneticisi eklemek için lütfen şirket yöneticisi bilgilerini buraya girin.",
      "Add": "Ekle",
      "Company Type": "Şirket Türü",
      "Employee": "Çalışan",
      "Country / City": "Ülke / Şehir",
      "Intern": "Stajyer",
      "Intern List": "Stajyer Listesi",
      "Interns": "Stajyerler",
      "Intern Representative": "Stajyer Temsilcisi",
      "Company Representative": "Şirket Temsilcisi",
      "Add Intern": "Stajyer Ekle",
      "To add a intern, please enter the intern information here.": "Stajyer eklemek için lütfen stajyer bilgilerini buraya girin.",
      "Full Name": "Ad Soyad",
      "Note": "Not",
      "Pending Client List": "Bekleyen Müşteri Listesi",
      "Created": "Oluşturuldu",
      "Pending University List": "Bekleyen Üniversite Listesi",
      "To add a university, please enter the university information here.": "Üniversite eklemek için lütfen üniversite bilgilerini buraya girin.",
      "Admin Email": "Yönetici E-posta",
      "Notepad": "Not Defteri",
      "Save": "Kaydet",
      "Billing Info": "Fatura Bilgileri",
      "You can update your billing information below.": "Fatura bilgilerinizi aşağıdan güncelleyebilirsiniz.",
      "Identity Number": "Kimlik Numarası",
      "Tax Office": "Vergi Dairesi",
      "Tax Number": "Vergi Numarası",
      "Postcode": "Posta Kodu",
      "Packages": "Paketler",
      "Usable": "Kullanılabilir",
      "Total": "Toplam",
      "Standard Package": "Standart Paket",
      "Pro Package": "Pro Paket",
      "Special Package": "Special Paket",
      "The first part of your CV, positioned at the top of the page, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.": "CV'nizin sayfanın üst kısmında yer alan ilk kısmı, adınızı, doğum tarihinizi ve iletişim bilgilerinizi içermelidir. CV'nizi 'özgeçmiş' veya 'CV' başlığıyla başlıklandırmamalısınız, çünkü bu değerli alanın boşa harcanmasına neden olur. Bunun yerine adınızı başlık olarak kullanın.",
      "When it comes to your contact details, your email address and phone number(s) are essential. We use your registered email on the CV. Once upon a time, it was customary to include your full address on your CV. Today, you simply need to list your town and county.": "İletişim bilgileriniz söz konusu olduğunda, e-posta adresiniz ve telefon numaranız(alarınız) önemlidir. CV'de kayıtlı e-postanızı kullanıyoruz. Eskiden CV'nize tam adresinizi eklemek alışılmış bir durumdu. Bugün, sadece kasabanızı ve ilçenizi listelemeniz yeterlidir.",
      "Personal Details": "Kişisel Detaylar",
      "When it comes to your contact details, yo, should contain your name, birthday and contact details. Under no circumstances should you title your CV with ‘curriculum vitae’ or ‘CV’ as it’s a waste of valuable space. Treat your name as the title instead.": "İletişim bilgileriniz söz konusu olduğunda, yo, adınızı, doğum tarihinizi ve iletişim bilgilerinizi içermelidir. CV'nizi 'özgeçmiş' veya 'CV' başlığıyla başlıklandırmamalısınız, çünkü bu değerli alanın boşa harcanmasına neden olur. Bunun yerine adınızı başlık olarak kullanın.",
      "When it comes toand phone registere was customary to include your full address on your CV. Today, you simply need to list your town and county.": "İletişim bilgileriniz söz konusu olduğunda ve telefon registere tam adresinizi CV'nize eklemek alışılmış bir durumdu. Bugün, sadece kasabanızı ve ilçenizi listelemeniz yeterlidir.",
      "Educational Information": "Eğitim Bilgileri",
      "Efficient Talent Matchmaking": "Verimli Yetenek Eşleştirme",
      "InternIF's AI-driven recommendation system streamlines the talent matchmaking process for companies.": "InternIF'in AI destekli öneri sistemi, şirketler için yetenek eşleştirme sürecini hızlandırır.",
      "By understanding the unique needs and preferences of each company, InternIF ensures that the intern selection process is not only efficient but also results in high-quality matches.": "Her şirketin benzersiz ihtiyaçlarını ve tercihlerini anlayarak, InternIF stajyer seçim sürecinin sadece verimli olmasını değil, aynı zamanda yüksek kaliteli eşleşmelerle sonuçlanmasını sağlar.",
      "Diverse and Inclusive Hiring": "Çeşitli ve Kapsayıcı İstihdam",
      "Companies partnering with InternIF gain access to a diverse pool of global talent. This inclusivity promotes diverse perspectives within the workplace, fostering innovation and creativity.": "InternIF ile ortak olan şirketler, küresel yetenek havuzuna erişim sağlar. Bu kapsayıcılık, işyerinde çeşitli bakış açılarını teşvik ederek, yeniliği ve yaratıcılığı destekler.",
      "InternIF actively supports companies in building teams that reflect a variety of backgrounds and experiences.": "InternIF, çeşitli arka plan ve deneyimleri yansıtan takımlar oluşturmada şirketlere aktif destek sağlar.",
      "Remote Work Adaptability": "Uzaktan Çalışma Uyumlu",
      "Recognizing the evolving landscape of work, InternIF embraces and facilitates remote internships.": "İş dünayasının evrimleşen manzarasını tanıyan InternIF, uzaktan stajları benimser ve kolaylaştırır.",
      "Companies benefit from the flexibility of engaging with interns regardless of geographical constraints, tapping into a broader talent pool and adapting to the changing dynamics of the professional world.": "Şirketler, coğrafi kısıtlamalardan bağımsız olarak stajyerlerle etkileşimde bulunma esnekliğinden faydalanır, daha geniş bir yetenek havuzuna erişir ve profesyonel dünyanın değişen dinamiklerine uyum sağlar.",
      "Could you please provide some information about yourself?": "Lütfen kendiniz hakkında bazı bilgiler verir misiniz?",
      "Could you please tell us a little more about yourself?": "Lütfen kendiniz hakkında biraz daha fazla bilgi verir misiniz?",
      "Could you please provide some information about your education?": "Lütfen eğitiminiz hakkında bazı bilgiler verir misiniz?",
      "Could you please provide some information about your foreign languages?": "Lütfen yabancı dilleriniz hakkında bazı bilgiler verir misiniz?",
      "Finally, we would like to learn about your skills.": "Son olarak, yetenekleriniz hakkında bilgi almak isteriz.",
      "Choose your language": "Dilinizi seçin",
      "First Name*": "Ad*",
      "Last Name*": "Soyad*",
      "Gender*": "Cinsiyet*",
      "Birthdate*": "Doğum Tarihi*",
      "Profile Image": "Profil Resmi",
      "Nationality*": "Uyruk*",
      "Country*": "Ülke*",
      "City*": "Şehir*",
      "No university found": "Üniversite bulunamadı",
      "Department": "Bölüm",
      "Still attending": "Devam ediyor",
      "You do not have an active internship yet.": "Henüz aktif bir stajınız bulunmamaktadır.",
      "Apply for internships on the ": "Stajlara başvurmak için ",
      "internship search page.": "staj arama sayfasına gidin.",
      "Purchase a guaranteed internship placement service with the ": "Garantili staj yerleştirme hizmeti satın alın: ",
      "InternIF Premium Package.": "InternIF Premium Paket",
      "Tips": "İpuçları",
      "What kind of screen will you encounter when your internship starts?": "Stajınız başladığında hangi ekranla karşılaşacaksınız?",
      "Demo Internship": "Demo Staj",
      "Frequently Asked Questions": "Sıkça Sorulan Sorular",
      "General": "Genel",
      "What is InternIF Premium?": "InternIF Premium nedir?",
      "InternIF Premium is a specialized internship placement service offered by InternIF, providing students with enhanced support and opportunities for international internships.": "InternIF Premium, öğrencilere gelişmiş destek ve uluslararası staj fırsatları sunan InternIF tarafından sunulan özel bir staj yerleştirme hizmetidir.",
      "How does InternIF Premium differ from regular internship services?": "InternIF Premium, normal staj hizmetlerinden nasıl farklıdır?",
      "InternIF Premium offers additional features such as dedicated support, company selection options, and tailored internship placements, providing a more personalized and comprehensive internship experience.": "InternIF Premium, özel destek, şirket seçenekleri ve özel staj yerleştirmeleri gibi ek özellikler sunar, daha kişiselleştirilmiş ve kapsamlı bir staj deneyimi sağlar.",
      "Is InternIF Premium available to students from all countries?": "InternIF Premium, tüm ülkelerden öğrencilere açık mıdır?",
      "Yes, InternIF Premium is available to students worldwide, offering internship opportunities in various countries and industries.": "Evet, InternIF Premium, dünya çapındaki öğrencilere açıktır ve çeşitli ülkelerde ve sektörlerde staj fırsatları sunar.",
      "What packages are available under InternIF Premium?": "InternIF Premium altında hangi paketler mevcuttur?",
      "InternIF Premium offers three packages: Standard, Pro, and Special. Each package provides different features and benefits to enhance the internship experience.": "InternIF Premium, Standart, Pro ve Special olmak üzere üç paket sunar. Her paket, staj deneyimini geliştirmek için farklı özellikler ve avantajlar sunar.",
      "How do I choose the right InternIF Premium package for me?": "Benim için doğru InternIF Premium paketini nasıl seçerim?",
      "To choose the right InternIF Premium package, consider your internship goals, preferences, and budget. Review the features and benefits of each package to determine which one aligns best with your needs.": "Doğru InternIF Premium paketini seçmek için staj hedeflerinizi, tercihlerinizi ve bütçenizi göz önünde bulundurun. Her paketin özelliklerini ve avantajlarını inceleyerek, ihtiyaçlarınıza en iyi uyanı belirleyin.",
      "You can choose the right package based on factors such as company selection options, support services, and internship location preferences to ensure it aligns with your goals and requirements.": "Şirket seçenekleri, destek hizmetleri ve staj yeri tercihleri gibi faktörlere dayanarak doğru paketi seçebilir ve hedefleriniz ve gereksinimlerinizle uyumlu olduğundan emin olabilirsiniz.",
      "Can I customize my internship preferences with InternIF Premium Packages?": "InternIF Premium Paketleri ile staj tercihlerimi özelleştirebilir miyim?",
      "Yes, you can specify your preferred internship location and sector when using any of the InternIF Premium Packages. However, the level of customization may vary depending on the package chosen.": "Evet, InternIF Premium Paketlerinden herhangi birini kullanırken tercih ettiğiniz staj yeri ve sektörü belirtebilirsiniz. Ancak, seçilen pakete bağlı olarak özelleştirme düzeyi değişebilir.",
      "Can I customize my InternIF Premium package?": "InternIF Premium paketimi özelleştirebilir miyim?",
      "While InternIF Premium packages come with predefined features, you can discuss customization options with InternIF support to tailor the package to your specific needs and preferences.": "InternIF Premium paketlerinde önceden tanımlanmış özellikler bulunsa da, paketi belirli ihtiyaçlarınıza ve tercihlerinize göre özelleştirmek için InternIF destek ile özelleştirme seçeneklerini görüşebilirsiniz.",
      "What is included in the Standard Package?": "Standart Paket'te neler bulunuyor?",
      "The Standard Package includes essential features such as internship placement in micro-scale enterprises, general support, orientation, mentoring, and certificates upon successful completion.": "Standart Paket, mikro ölçekli işletmelerde staj yerleştirme, genel destek, oryantasyon, mentörlük ve başarılı bir şekilde tamamlanması durumunda sertifikalar gibi temel özellikleri içerir.",
      "What additional benefits does the Pro Package offer?": "Pro Paket, hangi ek avantajları sunuyor?",
      "The Pro Package provides the option to choose from three pre-selected companies, dedicated support from an InternIF member, and placement in small-scale enterprises, in addition to the features included in the Standard Package.": "Pro Paket, Standart Paket'te bulunan özelliklere ek olarak, üç önceden seçilmiş şirket arasından seçim yapma, InternIF üyesinden özel destek ve küçük ölçekli işletmelerde yerleştirme seçeneği sunar.",
      "What makes the Special Package unique?": "Special Paket'i özel kılan nedir?",
      "The Special Package offers internship placement in medium-scale enterprises and personalized support from a dedicated InternIF member, along with the features included in the Standard Package.": "Special Paket, Standart Paket'te bulunan özelliklere ek olarak, orta ölçekli işletmelerde staj yerleştirme ve özel bir InternIF üyesinden kişiselleştirilmiş destek sunar.",
      "Can you detail the Remote Internship Process with InternIF Premium?": "InternIF Premium ile Uzaktan Staj Sürecini detaylandırabilir misiniz?",
      "The Remote Internship Process with InternIF Premium is designed to provide students with a seamless and enriching international internship experience. Here's a breakdown of the process:": "InternIF Premium ile Uzaktan Staj Süreci, öğrencilere sorunsuz ve zenginleştirici bir uluslararası staj deneyimi sunmak üzere tasarlanmıştır. İşte sürecin ayrıntıları:",
      "Remote Internship Process": "Uzaktan Staj Süreci",
      "Initiation": "Başlatma",
      "Upon purchasing an InternIF Premium package, students initiate the internship process by clicking on the 'Use Package' button in their dashboard.": "InternIF Premium paketi satın alındıktan sonra öğrenciler, panelindeki 'Paketi Kullan' düğmesine tıklayarak staj sürecini başlatırlar.",
      "Internship Configuration": "Staj Yapılandırma",
      "Students specify their desired internship parameters, including the preferred time, country, sector, and weekly working hours (20 to 30 hours). They may also provide additional information relevant to their internship preferences.": "Öğrenciler, tercih ettikleri zamanı, ülkeyi, sektörü ve haftalık çalışma saatlerini (20 ila 30 saat) içeren istediği staj parametrelerini belirtirler. Ayrıca staj tercihleriyle ilgili ek bilgiler de sağlayabilirler.",
      "InternIF Review": "InternIF İnceleme",
      "The InternIF team reviews the submitted internship configuration and identifies suitable internship opportunities based on the student's preferences and qualifications.": "InternIF ekibi, gönderilen staj yapılandırmasını gözden geçirir ve öğrencinin tercihleri ve niteliklerine göre uygun staj fırsatlarını belirler.",
      "Company Matching": "Şirket Eşleştirme",
      "InternIF matches students with internship opportunities at partner companies, taking into account factors such as the student's profile, company requirements, and available positions.": "InternIF, öğrencileri staj fırsatlarıyla eşleştirirken öğrencinin profilini, şirket gereksinimlerini ve mevcut pozisyonları dikkate alır.",
      "Internship Commencement": "Staj Başlangıcı",
      "Once the internship details are finalized, students receive confirmation of their internship placement and begin their remote internship at the designated company.": "Staj ayrıntıları netleştiğinde öğrenciler, staj yerleştirmelerinin onayını alır ve belirlenen şirkette uzaktan stajlarına başlarlar.",
      "Task Assignment": "Görev Atama",
      "Interns receive their first task from the company between 09:00-12:00, depending on the time zone of the internship location. Tasks may vary in complexity and duration, spanning implementation, design, review, evaluation, editing, reporting, and research.": "Stajyerler, staj yerinin saat dilimine bağlı olarak şirketten ilk görevlerini 09:00-12:00 arasında alırlar. Görevler karmaşıklık ve süre açısından değişebilir, uygulama, tasarım, inceleme, değerlendirme, düzenleme, raporlama ve araştırma gibi alanları kapsayabilir.",
      "Task Completion": "Görev Tamamlama",
      "Interns complete assigned tasks within the specified timeframe, typically over a period of 20 working days. Tasks may be assigned on a daily, two-day, or three-day basis, depending on the nature of the internship project.": "Stajyerler, genellikle 20 iş günü boyunca belirlenen süre içinde atanan görevleri tamamlarlar. Görevler, staj projesinin doğasına bağlı olarak günlük, iki günlük veya üç günlük olarak atanabilir.",
      "Evaluation and Feedback": "Değerlendirme ve Geri Bildirim",
      "Upon task completion, interns may receive feedback and evaluation from the company based on their performance and deliverables. Task evaluations play a crucial role in assessing the intern's overall success and performance during the internship.": "Görev tamamlandıktan sonra stajyerler, performansları ve teslim edilebilirleri temel alarak şirketten geri bildirim ve değerlendirme alabilirler. Görev değerlendirmeleri, stajyerin staj süresince genel başarısını ve performansını değerlendirmede önemli bir rol oynar.",
      "Internship Completion": "Staj Tamamlama",
      "Upon successful completion of the internship, interns may receive certificates of completion, practical training certificates (for Turkish citizens), and reference letters (for high-performing interns).": "Stajın başarılı bir şekilde tamamlanmasının ardından stajyerler, tamamlama sertifikaları, pratik eğitim sertifikaları (Türk vatandaşları için) ve referans mektupları (yüksek performans gösteren stajyerler için) alabilirler.",
      "Ongoing Support": "Sürekli Destek",
      "Throughout the internship process, InternIF provides dedicated support to interns, addressing any queries, concerns, or challenges they may encounter during their internship journey.": "Staj süreci boyunca InternIF, stajyerlere herhangi bir soru, endişe veya karşılaşabilecekleri zorluklarla ilgili destek sağlar.",
      "The Remote Internship Process with InternIF Premium offers students a structured and supportive framework to gain valuable international work experience and advance their career goals.": "Uzaktan Staj Süreci, öğrencilere değerli uluslararası iş deneyimi kazanmaları ve kariyer hedeflerini ilerletmeleri için yapılandırılmış ve destekleyici bir çerçeve sunar.",
      "Internship Placement": "Staj Yerleştirme",
      "How are internship placements determined in InternIF Premium?": "InternIF Premium'da staj yerleştirmeleri nasıl belirlenir?",
      "Internship placements in InternIF Premium are determined based on factors such as student preferences, qualifications, and the availability of internship opportunities from partner companies.": "InternIF Premium'da staj yerleştirmeleri, öğrenci tercihleri, nitelikleri ve ortak şirketlerden gelen staj fırsatlarının uygunluğu gibi faktörlere dayanarak belirlenir.",
      "Can I choose the location and sector of my internship?": "Staj yerimi ve sektörümü seçebilir miyim?",
      "Yes, InternIF Premium allows you to specify your preferred internship location and sector, ensuring that the placement aligns with your interests and career goals.": "Evet, InternIF Premium, tercih ettiğiniz staj yeri ve sektörünü belirtmenize olanak tanır, böylece yerleştirme ilgi alanlarınıza ve kariyer hedeflerinize uygun olur.",
      "What types of companies offer internships through InternIF Premium?": "Hangi tür şirketler InternIF Premium aracılığıyla stajlar sunar?",
      "InternIF Premium partners with a diverse range of companies, including micro, small, and medium-scale enterprises, offering internship opportunities across various industries and sectors.": "InternIF Premium, mikro, küçük ve orta ölçekli işletmeler de dahil olmak üzere çeşitli sektörlerde staj fırsatları sunan çeşitli şirketlerle iş birliği yapar.",
      "During Internship": "Staj Süresince",
      "What support services are available during the internship with InternIF Premium?": "InternIF Premium ile staj süresince hangi destek hizmetleri sunulmaktadır?",
      "Interns in InternIF Premium receive comprehensive support services, including orientation sessions, dedicated support from an InternIF member, and mentoring throughout the internship duration.": "InternIF Premium'daki stajyerler, staj süresince yönlendirme oturumları, InternIF üyesinden özel destek ve mentorluk da dahil olmak üzere kapsamlı destek hizmetleri alırlar.",
      "What are the expectations during the internship with InternIF Premium?": "InternIF Premium ile staj süresince beklentiler nelerdir?",
      "Interns are expected to complete assigned tasks diligently, maintain regular communication with their supervisors, and adhere to the company's policies and guidelines throughout the internship period.": "Stajyerlerin atanan görevleri özenle tamamlamaları, süpervizörleriyle düzenli iletişim kurmaları ve staj süresince şirketin politika ve kurallarına uymaları beklenir.",
      "Can I request additional assistance or support during my internship with InternIF Premium?": "InternIF Premium ile staj süresince ek yardım veya destek talep edebilir miyim?",
      "Yes, interns enrolled in InternIF Premium can request additional assistance or support from InternIF, as needed. The support team is available to address any internship-related issues or concerns promptly.": "Evet, InternIF Premium'a kayıtlı stajyerler, gerektiğinde InternIF'ten ek yardım veya destek talep edebilirler. Destek ekibi, stajla ilgili herhangi bir sorunu veya endişeyi hızlı bir şekilde ele almak için hazırdır.",
      "Can you detail the evaluation of internship with InternIF Premium?": "InternIF Premium ile stajın değerlendirmesini detaylandırabilir misiniz?",
      "The evaluation process for internships with InternIF Premium is designed to assess interns' performance and provide recognition for their achievements. Here's a breakdown of the evaluation process:": "InternIF Premium ile stajlar için değerlendirme süreci, stajyerlerin performansını değerlendirmeyi ve başarılarını tanımak için tasarlanmıştır. İşte değerlendirme sürecinin ayrıntıları:",
      "Success Criteria": "Başarı Kriterleri",
      "InternIF establishes success criteria for interns, typically requiring a minimum success rate of 70% to consider the internship valid.": "InternIF, stajyerler için genellikle stajın geçerli olması için en az %70 başarı oranını gerektiren başarı kriterleri belirler.",
      "Company Evaluation": "Şirket Değerlendirmesi",
      "Interns' performance during the internship is evaluated by the company they intern with. The company assesses interns based on their task completion, quality of work, professionalism, and adherence to deadlines.": "Stajyerlerin staj süresince performansı, staj yaptıkları şirket tarafından değerlendirilir. Şirket, stajyerleri görev tamamlama, iş kalitesi, profesyonellik ve teslim tarihlerine uyumlarına göre değerlendirir.",
      "Certificate of Completion": "Tamamlama Sertifikası",
      "Interns who achieve the minimum success rate of 70% receive a certificate of completion from the company they intern with. This certificate serves as formal recognition of their successful participation in the internship program.": "En az %70 başarı oranını elde eden stajyerler, staj yaptıkları şirketten tamamlama sertifikası alırlar. Bu sertifika, staj programına başarılı katılımlarının resmi olarak tanınmasını sağlar.",
      "Practical Training Certificate": "Pratik Eğitim Sertifikası",
      "For Turkish citizens who achieve a success rate of over 80%, InternIF provides a practical training certificate that can be viewed on their e-government platform. This certificate acknowledges their practical training experience and enhances their professional credentials.": "Türk vatandaşları için %80'in üzerinde başarı oranı elde edenler için InternIF, e-devlet platformlarında görülebilen bir pratik eğitim sertifikası sunar. Bu sertifika, pratik eğitim deneyimlerini onaylar ve profesyonel kimliklerini güçlendirir.",
      "Exceptional interns who attain a success rate of over 95% are eligible to receive a reference letter from the company where they interned. This personalized letter highlights the intern's outstanding performance and serves as a valuable endorsement for future career opportunities.": "Üstün stajyerler, %95'in üzerinde başarı oranı elde edenler, staj yaptıkları şirketten referans mektubu almayı hak ederler. Bu kişiselleştirilmiş mektup, stajyerin olağanüstü performansını vurgular ve gelecekteki kariyer fırsatları için değerli bir referans oluşturur.",
      "InternIF Evaluation and Support": "InternIF Değerlendirme ve Destek",
      "InternIF also evaluates interns' performance and provides ongoing support throughout the internship process. Interns can reach out to InternIF for assistance with any challenges they encounter during their internship.": "InternIF, stajyerlerin performansını değerlendirir ve staj süreci boyunca sürekli destek sağlar. Stajyerler, staj süresince karşılaştıkları herhangi bir zorlukta yardım almak için InternIF'e başvurabilirler.",
      "The evaluation process with InternIF Premium ensures that interns receive recognition for their efforts and achievements, enhancing their professional development and career prospects.": "InternIF Premium ile değerlendirme süreci, stajyerlerin çabaları ve başarıları için tanınmalarını sağlar, profesyonel gelişimlerini ve kariyer fırsatlarını artırır.",
      "How is internship success evaluated in InternIF Premium?": "InternIF Premium'da staj başarısı nasıl değerlendirilir?",
      "Internship success in InternIF Premium is evaluated based on various factors, including task completion, performance feedback from the company, and overall professionalism demonstrated during the internship.": "InternIF Premium'da staj başarısı, görev tamamlama, şirketten performans geri bildirimi ve staj süresince sergilenen genel profesyonellik gibi çeşitli faktörlere dayanarak değerlendirilir.",
      "What certificates or documents will I receive upon completing my internship with InternIF Premium?": "InternIF Premium ile stajı tamamladıktan sonra hangi sertifikalar veya belgeleri alacağım?",
      "Upon successful completion of the internship, interns may receive certificates of completion, practical training certificates, and reference letters, depending on their performance and achievements during the internship period.": "Stajın başarılı bir şekilde tamamlanmasının ardından stajyerler, staj süresince gösterdikleri performans ve başarılarına bağlı olarak tamamlama sertifikaları, pratik eğitim sertifikaları ve referans mektupları alabilirler.",
      "Will InternIF Premium assist me with future career opportunities after completing my internship?": "InternIF Premium, stajı tamamladıktan sonra gelecekteki kariyer fırsatları konusunda bana yardımcı olacak mı?",
      "Yes, InternIF Premium provides ongoing support to successful interns, offering guidance and assistance with future career opportunities, including job placement services, resume building, and networking opportunities within the industry.": "Evet, InternIF Premium, başarılı stajyerlere gelecekteki kariyer fırsatları konusunda rehberlik ve yardım sağlar, iş yerleştirme hizmetleri, özgeçmiş oluşturma ve endüstrideki ağ oluşturma fırsatları dahil.",
      "Are you tired of endlessly searching for internship opportunities with no success? Say goodbye to uncertainty and hello to guaranteed international internship placement with InternIF Premium!": "Başarısızlıkla sonuçlanan staj fırsatları arayışlarından bıktınız mı? Belirsizliğe veda edin ve InternIF Premium ile garantili uluslararası staj yerleştirmesine merhaba deyin!",
      "To buy a package, you need to complete your billing information. Fill in the information in the Invoice section completely.": "Paket satın almak için fatura bilgilerinizi tamamlamanız gerekmektedir. Fatura bölümündeki bilgileri eksiksiz doldurunuz.",
      "Here's how our guaranteed internship placement works:": "Garantili staj yerleştirmemiz nasıl çalışır:",
      "Personalized Matching:": "Kişiselleştirilmiş Eşleştirme:",
      "We use cutting-edge technology to match you with internship opportunities that align with your skills, interests, and aspirations.": "Yetenekleriniz, ilgi alanlarınız ve hedeflerinizle uyumlu staj fırsatlarıyla sizi eşleştirmek için son teknolojiyi kullanıyoruz.",
      "Exclusive Access:": "Özel Erişim:",
      "InternIF Premium members get exclusive access to a curated selection of internship opportunities not available to the public. You'll have the first pick of the best internships before they're even advertised elsewhere.": "InternIF Premium üyeleri, genel erişime açılmayan özenle seçilmiş staj fırsatlarına özel erişim elde eder. Diğer yerlerde reklamı yapılmadan önce en iyi staj fırsatlarını ilk siz seçebilirsiniz.",
      "Dedicated Support:": "Özel Destek:",
      "Our team of internship placement specialists is here to support you every step of the way. We'll provide personalized guidance and support to ensure your success.": "Staj yerleştirme uzmanlarından oluşan ekibimiz, her adımda size destek olmak için burada. Başarınızı sağlamak için kişiselleştirilmiş rehberlik ve destek sağlayacağız.",
      "Peace of Mind:": "Huzur:",
      "With our guaranteed internship placement feature, you can rest assured knowing that you'll secure a valuable internship experience to boost your career prospects.": "Garantili staj yerleştirme özelliğimizle, kariyer fırsatlarınızı artırmak için değerli bir staj deneyimi elde edeceğinizi bilerek rahatça uyuyabilirsiniz.",
      "Don't leave your internship placement to chance. Upgrade to InternIF Premium today and unlock guaranteed internship placement for a brighter future!": "Staj yerleştirmenizi şansa bırakmayın. Bugün InternIF Premium'a yükseltin ve daha parlak bir gelecek için garantili staj yerleştirmesini açın!",
      "My Applications": "Başvurularım",
      "My Premium Packages": "Premium Paketlerim",
      "Packages in Use": "Kullanılan Paketler",
      "Used Date": "Kullanım Tarihi",
      "Action": "Aksiyon",
      "Premium Package History": "Premium Paket Geçmişi",
      "Use": "Kullan",
      "Practical Training Certification": "Pratik Eğitim Sertifikası",
      "Selection from 1 Country": "1 Ülkeden Seçim",
      "Selection from 3 Companies": "3 Şirketten Seçim",
      "Can you give us some information about how you will use the premium package?": "Premium paketi nasıl kullanacağınıza dair bize bilgi verebilir misiniz?",
      "Weekly Work Hours": "Haftalık Çalışma Saati",
      "Additional Information": "Ek Bilgiler",
      "Use Package": "Paketi Kullan",
      "What are the differences in terms of international remote internship experience whether companies are micro-size, middle-size or small-size?": "Şirketler mikro, orta veya küçük boyutta olduğunda uluslararası uzaktan staj deneyimi açısından farklar nelerdir?",
      "The experience of an international remote internship can vary based on the size of the company. Here are the differences in terms of international remote internship experience between micro-sized, small-sized, and medium-sized companies:": "Uluslararası uzaktan staj deneyimi, şirketin boyutuna bağlı olarak değişebilir. İşte mikro, küçük ve orta ölçekli şirketler arasındaki uluslararası uzaktan staj deneyimi açısından farklar:",
      "Scope of Responsibilities:": "Sorumlulukların Kapsamı:",
      "In micro-sized companies, interns may have the opportunity to take on a wide range of responsibilities and tasks. Due to the smaller team size, interns may be involved in various aspects of the business operations, including project management, client communication, and research.": "Mikro ölçekli şirketlerde stajyerler, geniş bir sorumluluk ve görev yelpazesi üstlenebilirler. Daha küçük bir ekip boyutuna sahip olmaları nedeniyle stajyerler, proje yönetimi, müşteri iletişimi ve araştırma gibi işletme faaliyetlerinin çeşitli yönlerinde yer alabilirler.",
      "Small-sized companies also offer interns diverse responsibilities but may provide more focused tasks within specific departments or projects. Interns may work closely with team members and supervisors to contribute to ongoing initiatives and gain hands-on experience in their chosen field.": "Küçük ölçekli şirketler de stajyerlere çeşitli sorumluluklar sunar ancak belirli departmanlarda veya projelerde daha odaklı görevler sağlayabilirler. Stajyerler, devam eden girişimlere katkıda bulunmak ve seçtikleri alanlarda uygulamalı deneyim kazanmak için ekip üyeleri ve süpervizörlerle yakın çalışabilirler.",
      "Medium-sized companies typically provide interns with structured roles and responsibilities within designated departments or teams. Interns may work on larger-scale projects or initiatives and collaborate with colleagues from different geographic locations.": "Orta ölçekli şirketler genellikle stajyerlere belirlenmiş departmanlarda veya ekiplerde yapılandırılmış roller ve sorumluluklar sunar. Stajyerler, daha büyük ölçekli projeler veya girişimler üzerinde çalışabilir ve farklı coğrafi konumlardaki meslektaşlarıyla işbirliği yapabilirler.",
      "Supervision and Mentorship:": "Denetim ve Mentorluk:",
      "In micro-sized companies, interns may receive direct supervision and mentorship from senior team members or company founders. This close mentorship can provide valuable guidance and support as interns navigate their responsibilities and learn new skills.": "Mikro ölçekli şirketlerde stajyerler, kıdemli ekip üyelerinden veya şirket kurucularından doğrudan denetim ve mentorluk alabilirler. Bu yakın mentorluk, stajyerlerin sorumluluklarını yönlendirmelerine ve yeni beceriler öğrenmelerine yardımcı olabilir.",
      "Small-sized companies may offer interns mentorship opportunities with experienced professionals within the organization. Interns may have regular check-ins with supervisors or mentors to discuss progress, receive feedback, and set goals for their internship experience.": "Küçük ölçekli şirketler, stajyerlere organizasyon içindeki deneyimli profesyonellerle mentorluk fırsatları sunabilir. Stajyerler, ilerlemeyi tartışmak, geri bildirim almak ve staj deneyimleri için hedefler belirlemek için süpervizörler veya mentorlarla düzenli olarak kontrol edebilirler.",
      "Medium-sized companies often provide interns with access to a broader network of mentors and advisors. Interns may have the opportunity to connect with professionals from different departments or locations for mentorship and guidance on their projects and career development.": "Orta ölçekli şirketler genellikle stajyerlere daha geniş bir mentor ve danışman ağına erişim sağlar. Stajyerler, projeleri ve kariyer gelişimleri üzerine mentorluk ve rehberlik için farklı departmanlardan veya konumlardan profesyonellerle bağlantı kurma fırsatına sahip olabilirler.",
      "Collaboration and Communication:": "İşbirliği ve İletişim:",
      "In micro-sized companies, interns may have the opportunity to collaborate closely with team members on projects and initiatives. Due to the smaller team size, interns may play a significant role in decision-making processes and have more direct communication with colleagues.": "Mikro ölçekli şirketlerde stajyerler, projeler ve girişimler üzerinde ekip üyeleriyle yakın işbirliği yapma fırsatına sahip olabilirler. Daha küçük ekip boyutu nedeniyle stajyerler, karar alma süreçlerinde önemli bir rol oynayabilir ve meslektaşlarıyla daha doğrudan iletişim kurabilirler.",
      "Small-sized companies also emphasize collaboration but may have more structured communication channels and project management systems in place. Interns may use digital collaboration tools and platforms to work with team members and share progress updates on their work.": "Küçük ölçekli şirketler de işbirliğine önem verir ancak daha yapılandırılmış iletişim kanalları ve proje yönetim sistemleri olabilir. Stajyerler, dijital işbirliği araçları ve platformları kullanarak ekip üyeleriyle çalışabilir ve işlerindeki ilerlemeleri paylaşabilirler.",
      "Medium-sized companies typically have established communication protocols and project management processes to facilitate collaboration among remote team members. Interns may participate in team meetings, virtual brainstorming sessions, and collaborative projects with colleagues from different locations.": "Orta ölçekli şirketler genellikle uzaktan ekip üyeleri arasında işbirliğini kolaylaştırmak için kurulmuş iletişim protokolleri ve proje yönetim süreçlerine sahiptir. Stajyerler, farklı konumlardaki meslektaşlarıyla takım toplantılarına, sanal beyin fırtınası oturumlarına ve işbirlikçi projelere katılabilirler.",
      "Professional Development Opportunities:": "Profesyonel Gelişim Fırsatları:",
      "In micro-sized companies, interns may have the opportunity to gain hands-on experience and develop a diverse skill set through their involvement in various aspects of the business. Interns may also have the chance to take on leadership roles and contribute to strategic decision-making processes.": "Mikro ölçekli şirketlerde stajyerler, işletmenin çeşitli yönlerinde yer alarak uygulamalı deneyim kazanma ve çeşitli beceri seti geliştirme fırsatına sahip olabilirler. Stajyerler, liderlik rollerini üstlenme ve stratejik karar alma süreçlerine katkıda bulunma şansına da sahip olabilirler.",
      "Small-sized companies offer interns opportunities for skill development and professional growth within a supportive and collaborative environment. Interns may receive training and mentorship to enhance their capabilities and prepare for future career opportunities.": "Küçük ölçekli şirketler, destekleyici ve işbirlikçi bir ortamda beceri geliştirme ve profesyonel büyüme fırsatları sunar. Stajyerler, yeteneklerini geliştirmek ve gelecekteki kariyer fırsatlarına hazırlanmak için eğitim ve mentorluk alabilirler.",
      "Medium-sized companies provide interns with access to structured training programs, professional development resources, and networking opportunities. Interns may participate in workshops, webinars, and industry events to expand their knowledge and build their professional network.": "Orta ölçekli şirketler, stajyerlere yapılandırılmış eğitim programları, profesyonel gelişim kaynakları ve ağ oluşturma fırsatları sunar. Stajyerler, bilgilerini genişletmek ve profesyonel ağlarını oluşturmak için atölye çalışmalarına, web seminerlerine ve endüstri etkinliklerine katılabilirler.",
      "In which countries can I do a remote internship with InternIF Premium?": "InternIF Premium ile hangi ülkelerde uzaktan staj yapabilirim?",
      "You can do a remote internship in Austria, Azerbaijan, Belgium, Canada, Croatia, Czechia, Denmark, England, Estonia, Finland, France, Germany, Greece, Holland, Ireland, Italy, Latvia, Luxembourg, Norway, Poland, Portugal, Spain, Sweden, Switzerland, and the USA.": "Avusturya, Azerbaycan, Belçika, Kanada, Hırvatistan, Çekya, Danimarka, İngiltere, Estonya, Finlandiya, Fransa, Almanya, Yunanistan, Hollanda, İrlanda, İtalya, Letonya, Lüksemburg, Norveç, Polonya, Portekiz, İspanya, İsveç, İsviçre ve ABD'de uzaktan staj yapabilirsiniz.",
      "In which internship areas can I do a remote internship with InternIF Premium?": "InternIF Premium ile hangi staj alanlarında uzaktan staj yapabilirim?",
      "You can do a remote internship in the field of Software Development, Marketing, Content Writing, Social Media Management, Graphic Design, Web Development, Data Entry, Market Research, Digital Marketing, Copywriting, SEO (Search Engine Optimization), Email Marketing, Sales, Business Development, Virtual Assistance, Video Editing, Project Management, Customer Support, UI/UX Design, Public Relations, Content Creation, Blogging, Research Analysis, Financial Analysis, Human Resources, E-commerce Management, Quality Assurance, Translation and Localization, Mobile App Development, IT Support, Data Analysis, Market Analysis, Financial Analysis, Human Resources, Business Development, Customer Service, Project Management, Quality Assurance, Technical Support, Legal Research, Data Entry, Graphic Design, Video Editing, Audio Production, UX/UI Design, Content Creation, Virtual Reality Development, Augmented Reality Development, Game Development, and Ethical Hacking.": "Yazılım Geliştirme, Pazarlama, İçerik Yazma, Sosyal Medya Yönetimi, Grafik Tasarım, Web Geliştirme, Veri Girişi, Pazar Araştırması, Dijital Pazarlama, Kopya Yazma, SEO (Arama Motoru Optimizasyonu), E-posta Pazarlama, Satış, İş Geliştirme, Sanal Asistanlık, Video Düzenleme, Proje Yönetimi, Müşteri Desteği, UI/UX Tasarım, Halkla İlişkiler, İçerik Oluşturma, Blog Yazma, Araştırma Analizi, Finansal Analiz, İnsan Kaynakları, E-ticaret Yönetimi, Kalite Güvencesi, Çeviri ve Yerelleştirme, Mobil Uygulama Geliştirme, BT Destek, Veri Analizi, Pazar Analizi, Finansal Analiz, İnsan Kaynakları, İş Geliştirme, Müşteri Hizmetleri, Proje Yönetimi, Kalite Güvencesi, Teknik Destek, Hukuki Araştırma, Veri Girişi, Grafik Tasarım, Video Düzenleme, Ses Prodüksiyonu, UX/UI Tasarım, İçerik Oluşturma, Sanal Gerçeklik Geliştirme, Artırılmış Gerçeklik Geliştirme, Oyun Geliştirme ve Etik Hacking alanlarında uzaktan staj yapabilirsiniz.",
      "Salary": "Maaş",
      "Quota": "Kota",
      "Description": "Açıklama",
      "Preferred Skills": "Tercih Edilen Beceriler",
      "Preferred Languages": "Tercih Edilen Diller",
      "View Details": "Detayları Gör",
      "Unsave": "Kaydetmeyi Kaldır",
      "Apply": "Başvur",
      "Apply to Company": "Şirkete Başvur",
      "Resume": "Özgeçmiş",
      "Go to Edit Resume": "Özgeçmişi Düzenle",
      "Share": "Paylaş",
      "Saved": "Kaydedildi",
      "Preferences": "Tercihler",
      "College Year": "Üniversite Yılı",
      "About Company": "Şirket Hakkında",
      "View More": "Daha Fazla Gör",
      "Parental Permission": "Veli İzni",
      "I join the system with my parent's permission.": "Sisteme velimin izniyle katılıyorum.",
      "Payment cannot be received for a short time. Please try again in the coming hours.": "Kısa bir süre için ödeme alınamıyor. Lütfen önümüzdeki saatlerde tekrar deneyin.",
      "For support:": "Destek için:",
      "High School Name": "Lise Adı",
      "Level": "Seviye",
      "Add to Cart": "Sepete Ekle",
      "Shopping Cart": "Alışveriş Sepeti",
      "Total Price": "Toplam Fiyat",
      "I have read and accept the": "Okudum ve kabul ediyorum",
      "Cart": "Sepet",
      "Preliminary Information Form": "Ön Bilgilendirme Formu",
      "Sales Agreement": "Mesafeli Satış Sözleşmesi",
      "Warning": "Uyarı",
      "You have been successfully registered on the waiting list.": "Başarıyla bekleme listesine kaydoldunuz.",
      "You are already on the waiting list.": "Daha önce bekleme listesine kaydoldunuz.",
      "An error occurred while registering on the waiting list.": "Bekleme listesine kayıt olurken bir hata oluştu.",
      "Join the Waitlist": "Bekleme Listesine Katıl",
      "VAT (20%)": "KDV (%20)",
      "Apply2": "Uygula",
      "Promotion Code": "İndirim Kodu",
      "Promotion Code is Applied": "İndirim Kodu Uygulandı",
      "Invalid Promotion Code": "Geçersiz İndirim Kodu",
      "Package": "Paket",
      "piece": "adet",
      "Payment Result": "Ödeme Sonucu",
      "Payment is completed successfully.": "Ödeme başarıyla tamamlandı.",
      "Your payment has been completed successfully. However, it may take up to 5 minutes for your package to be reflected in your account.": "Ödemeniz başarıyla tamamlandı. Ancak, paketinizin hesabınıza yansıması 5 dakika kadar sürebilmektedir.",
      "Payment is failed.": "Ödeme başarısız oldu.",
      "Re Order": "Yeniden Sipariş Ver",
      "Order Date": "Sipariş Tarihi",
      "Currency": "Para Birimi",
      "Success": "Başarılı",
      "Fail": "Başarısız",
      "My Orders": "Siparişlerim",
      "No content added yet.": "Henüz içerik eklenmedi.",
      "No certificate added yet." : "Henüz sertifika eklenmedi.",
      "No university added yet." : "Henüz üniversite eklenmedi.",
      "No experience added yet." : "Henüz deneyim eklenmedi.",
      "No languages added yet." : "Henüz dil eklenmedi.",
      "No skill added yet." : "Henüz beceri eklenmedi.",
      "Go to Premium": "Premium'a Git",
    },
  },
};

export default resources;
