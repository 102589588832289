import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";


const initialState = {
  intern_assignedTasksData: null,
  intern_assignedTasksCount: 0,
  intern_assignedTasksLoading: false,
  intern_assignedTasksError: null,
};

export const intern_assignedTasksFunc = createAsyncThunk("intern_assignedTasks/intern_assignedTasksFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `query Intern_assignedTasks($input: AssignedTasksInput) {
        intern_assignedTasks(input: $input) {
          count
          data {
            _id
            isEvaluated
            assigner {
              _id
            }
            attachments {
              _id
               caption
              thumbnailUrl
              url
              createdAt
              fileName
              isPrivate
            #  name
              updatedAt
            }
            
      comments {
        _id
        attachments {
          _id
          caption
          createdAt
          fileName
          isPrivate
          name
          thumbnailUrl
          updatedAt
          url
        }
        content
        createdAt
        createdBy {
          _id
        }
        parentModel {
          _id
        }
        replies {
          _id
          content
          createdAt
          tags
          updatedAt
        }
        tags
        type
        updatedAt
        updatedBy {
          _id
        }
      }
            createdAt
            createdBy {
              _id
            }
            intern {
              _id
            }
            internship {
              _id
            }
            status
            statusHistory {
              _id
              createdAt
              date
              prevStatus
              reason
              updatedAt
              updatedStatus
            }
            task {
              _id
              allowableFileFormats
              createdAt
              description
              difficulty
              dueDate
              duration
              name
              priority
              reminder
              startDate
              taskStatus
              taskType
              updatedAt
              attachments {
                _id
                caption
                createdAt
                createdBy {
                  _id
                }
                fileName
                isPrivate
               # name
                thumbnailUrl
                updatedAt
                updatedBy {
                  _id
                }
                url
              }
            }
            updatedAt
            updatedBy {
              _id
            }
          }
        }
      }`,
    variables: {
      "input": {
        "filter": {
          "isEvaluated": values.isEvaluated ?? null,
          "status": values.status ?? null
        },
        "pagination": {
          "page": values.page ?? 1,
          "pageSize": values.pageSize ?? 10,
          "sort": values.sort ?? null
        }
      }
    }

  });

  const config = initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const intern_assignedTasks = createSlice({
  name: "intern_assignedTasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(intern_assignedTasksFunc.pending, (state) => {
      state.intern_assignedTasksLoading = true;
    });
    builder.addCase(intern_assignedTasksFunc.fulfilled, (state, action) => {
      state.intern_assignedTasksLoading = false;
      state.intern_assignedTasksData = action.payload?.intern_assignedTasks?.data;
      state.intern_assignedTasksCount = action.payload?.intern_assignedTasks?.count;
      state.intern_assignedTasksError = action?.payload?.message;
    });
    builder.addCase(intern_assignedTasksFunc.rejected, (state, action) => {
      state.intern_assignedTasksLoading = false;
      state.intern_assignedTasksError = action.error.message;
    });
  }
});


export default intern_assignedTasks.reducer;