import axios from "axios";
import initialConfig from "context/initialConfig";

export const self_updateUserSettingsFunc = async ({ defaultLanguage, accessToken }) => {

    const data = JSON.stringify({
        query: `mutation Self_updateUserSettings($updateInput: UpdateUserSettingsInput!) {
            self_updateUserSettings(updateInput: $updateInput) {
              _id
            }
          }`,
        variables: {
            "updateInput": {
                "defaultLanguage": defaultLanguage,
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + "/user/graphql", data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors?.[0]?.message,
            };
        }
        return res?.data?.data?.self_updateUserSettings;
    } catch (err) {
        return err;
    }
}