import { StepLabel, Typography } from '@mui/material'

export const StepsLabel = ({ step, index}) => {
    return <StepLabel
        optional={
            index === 4 ? (
                <Typography variant="caption">{'Last step'}</Typography>
            ) : null
        }
    >
        {step.label}
    </StepLabel>
}
