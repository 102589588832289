import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_addAttachmentToAssignTask = async ({ accessToken, taskId, attachment }) => {

    const data = JSON.stringify({
        query: `mutation Intern_addAttachmentToAssignTask($filter: AddAttachmentToAssignedTaskFilterInput!, $input: AddAttachmentToAssignedTaskInput!) {
        intern_addAttachmentToAssignTask(filter: $filter, input: $input) {
            _id
        }
        }`,
        variables: {
            "filter": {
                "id": taskId
            },
            "input": {
                "url": attachment.url,
                "name": attachment.name,
                "fileName":attachment.fileName,
                "contentType": attachment.contentType,
                "caption": attachment.caption,
                "thumbnailUrl": attachment.thumbnailUrl,
                "isPrivate": attachment.isPrivate
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.intern_addAttachmentToAssignTask
    } catch (err) {
        return err
    }
}
