import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_updateCoverLetterFunc = async ({ values, id, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_updateCoverLetter($coverLetterId: ID!, $updateCoverLetterInput: UpdateCoverLetterInput!) {
            intern_updateCoverLetter(coverLetterId: $coverLetterId, updateCoverLetterInput: $updateCoverLetterInput) {
              _id
            }
          }
        `,
        variables: {
            "coverLetterId": id,
            "updateCoverLetterInput": {
                "content": values.content,
                // "isArchived": null,
                "name": values.name,
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }
        return res?.data?.data?.intern_updateCoverLetter;
    } catch (err) {
        return err
    }
}
