import { Grid } from '@mui/material'
import { t } from 'i18next'
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import { SideCalendarInfoSection, TasksListSection, TasksWidgetSection } from 'page-sections'
import { demoTaskData, demoRecentActivitiesData } from 'dummy-data'


const info = [
    "Faz 0 bitti",
    "Faz 1 başlamadı."
]

const Tasks = () => {


    const handleFilterToDate = (date) => {
        console.log({ date })
    }

    return (
        <DashboardLayout info={info}>
            <Grid container spacing={{xs:0, sm:3}} sx={{ p: {xs:0, sm:5}, width: '100%', }}>

                <Grid item xs={12} >
                    <AnimatedTitle title={t("Tasks")} />
                </Grid>


                <Grid item xs={12} xl={9}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} >
                            <TasksWidgetSection />
                        </Grid>

                        <Grid item xs={12} >
                            <TasksListSection tasks={demoTaskData} />
                        </Grid>
                    </Grid>
                </Grid>



                <Grid item xs={12} xl={3}>
                    <SideCalendarInfoSection
                        tasks={demoTaskData}
                        recentActivitiesData={demoRecentActivitiesData}
                        filterToDate={handleFilterToDate}
                    />
                </Grid>

            </Grid>
        </DashboardLayout>
    )
}

export default Tasks