import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";

const initialState = {
    companyAdmin_internsJoinedToInternshipData: null,
    companyAdmin_internsJoinedToInternshipLoading: false,
    companyAdmin_internsJoinedToInternshipError: null,
};

export const companyAdmin_internsJoinedToInternshipFunc = createAsyncThunk("companyAdmin_internsJoinedToInternship/companyAdmin_internsJoinedToInternshipFunc", async (values) => {

    const data1 = JSON.stringify({
        query: `query CompanyAdmin_internsJoinedToInternship($internshipId: ID!) {
            companyAdmin_internsJoinedToInternship(internshipId: $internshipId) {
                _id
                role
                displayName
                displayImageUrl
            }
            }`,
        variables: {
            "internshipId": values?.internshipId
        }
    });

    const config = initialConfig(process.env.REACT_APP_BASE_URL + "/internship/graphql", data1, values?.accessToken);

    const response = axios(config)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => {
            console.log("err:", err);
            return err;
        });

    const data = await response;
    return data;
}
);

const companyAdmin_internsJoinedToInternship = createSlice({
    name: "companyAdmin_internsJoinedToInternship",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(companyAdmin_internsJoinedToInternshipFunc.pending, (state) => {
            state.companyAdmin_internsJoinedToInternshipLoading = true;
        });
        builder.addCase(companyAdmin_internsJoinedToInternshipFunc.fulfilled, (state, action) => {
            state.companyAdmin_internsJoinedToInternshipLoading = false;
            state.companyAdmin_internsJoinedToInternshipData = action.payload?.companyAdmin_internsJoinedToInternship;
            state.companyAdmin_internsJoinedToInternshipError = action?.payload?.message;
        });
        builder.addCase(companyAdmin_internsJoinedToInternshipFunc.rejected, (state, action) => {
            state.companyAdmin_internsJoinedToInternshipLoading = false;
            state.companyAdmin_internsJoinedToInternshipError = action.error.message;
        });
    },
});

export default companyAdmin_internsJoinedToInternship.reducer;
