import { Route } from 'react-router-dom'
import {
  AboutCompany, Applications, Dashboard, CoverLetters, Documents, InternshipDetail, Premium,
  IFAI, Internships, Messages, MyAccount, MyResume, Notifications, Tasks, Settings, MyInternship, FormExample,
  MyInternships,
  Payment,
  PaymentSuccessIntern,
  PaymentFail,
  MyOrders
} from 'pages';
import Onboarding from 'pages/onboarding/intern/Onboarding';

const InternDashboardRouter = () => {
  return <>
    {/* <Route path="/" element={<Dashboard />} /> */}
    <Route path="/" element={<MyInternships />} />
    <Route path="/internship" element={<Internships />} />
    <Route path="/internship/:id" element={<InternshipDetail />} />
    <Route path="/company/:id" element={<AboutCompany />} />
    <Route path="/applications" element={<Applications />} />
    <Route path="/notifications" element={<Notifications />} />

    <Route path="/my-internship" element={<MyInternship />} />
    <Route path="/my-internship/tasks" element={<Tasks />} />
    <Route path="/my-internship/messages" element={<Messages />} />
    <Route path="/my-internship/documents" element={<Documents />} />

    <Route path="/my-cover-letters" element={<CoverLetters />} />
    <Route path="settings" element={<Settings />} />
    <Route path="/if-ai" element={<IFAI />} />
    <Route path="/premium" element={<Premium />} />
    <Route path="/my-account" element={<MyAccount />} />
    <Route path="/my-resume" element={<MyResume />} />
    <Route path="/form" element={<FormExample />} />
    <Route path="/my-internships" element={<MyInternships />} />

    <Route path="/payment" element={<Payment />} />
    <Route path="/payment/success" element={<PaymentSuccessIntern />} />
    <Route path="/payment/fail" element={<PaymentFail />} />
    <Route path='/my-orders' element={<MyOrders />} />
    <Route path="*" element={<MyInternships />} />
  </>
}
const InternOnboardingRouter = () => {
  return (
    <>
      <Route path="/" element={<Onboarding />} />
      <Route path="*" element={<Onboarding />} />
    </>
  );
};

export { InternDashboardRouter, InternOnboardingRouter }