import { palette as themePalette } from 'minimal/theme/palette';

// ----------------------------------------------------------------------

const palette = themePalette('light');

export const CALENDAR_COLOR_OPTIONS = [
  palette.primary.main,
  palette.secondary.main,
  palette.info.main,
  palette.info.darker,
  palette.success.main,
  palette.warning.main,
  palette.error.main,
  palette.error.darker,
  palette.grey[500],
  palette.alternative1.main,
  palette.alternative2.main
];
