import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, Tab, Tabs, Typography, useMediaQuery } from '@mui/material'

// import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CardContainer, NoItemsPlaceHolder } from 'components';


export const DocumentsListSection = ({ documentsList }) => {

    const isMobile = useMediaQuery('(max-width:600px)')

    const tabMenuList = [
        {
            id: '1',
            label: `${isMobile ? "All" : "All Documents"}`,
            index: 0
        },
        {
            id: '2',
            label: `${isMobile ? "Archived" : "Archived Documents"}`,
            index: 1
        },
    ]


    // ************** tab menu ******************
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (_, newValue) => {
        setTabValue(newValue);
    };

    // ************** filter tasks when tab changed ******************
    const [filteredDocumentsList, setFilteredDocumentsList] = useState(documentsList)
    useEffect(() => {
        setFilteredDocumentsList(tabValue === 0 ? documentsList : documentsList?.filter(task => task.archive))
    }, [documentsList, tabValue])





    return (
        <Box>

            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{ mb: '30px' }}
                aria-label="basic tabs example"
                style={{ marginBottom: "-7px" }}
                TabIndicatorProps={{
                    style: {
                        bottom: 7,
                        backgroundColor: "#FF9737",
                        height: "4px",
                        borderRadius: "10px",
                    },
                }}
            >
                {
                    tabMenuList?.map((item, index) => {
                        return (
                            <Tab
                                key={item?.id}
                                label={item.label}
                                index={item.index}
                            />
                        )
                    })
                }
            </Tabs>

            <Grid container spacing={1}>
                {
                    filteredDocumentsList?.length > 0 ?
                        (
                            filteredDocumentsList.map((item) => {

                                return (
                                    <Grid item xs={12} sm={4} lg={3} key={item.id}>
                                        <DocumentItem item={item} />
                                    </Grid>
                                )
                            })
                        ) : (
                            <NoItemsPlaceHolder
                                text={`There is no ${tabValue === 0 ? "" : "archived"} documents!`}
                            />
                        )
                }
            </Grid>

        </Box>
    )
}

const DocumentItem = ({ item }) => {

    return (
        <CardContainer>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    p: { xs: '16px', sm: '12px' },
                    border: "1px solid #E5EAED",
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: { xs: '8px', sm: '16px' },
                    }}
                >

                    <IconButton
                        sx={{
                            backgroundColor: 'rgba(61, 124, 248, 0.1)',
                            borderRadius: '8px',
                            color: '#3D7CF8',
                        }}
                    >
                        <AssignmentOutlinedIcon sx={{ width: '18px' }} />
                    </IconButton>

                    <Box>
                        <IconButton>
                            <UpdateOutlinedIcon />
                        </IconButton>

                        <IconButton>
                            <FileDownloadOutlinedIcon />
                        </IconButton>
                    </Box>

                </Box>

                <Typography
                    sx={{
                        // mb: { xs: '40px' },
                        fontFamily: { xs: 'Causten-Semibold', sm: 'Causten-Regular' },
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#0E1436'
                    }}
                >
                    {item.title}
                </Typography>

                <Typography>
                    {item.user}
                </Typography>

            </Box>
        </CardContainer>
    )
}
