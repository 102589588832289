import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_addForeignLanguageFunc = async ({ selectedLanguage, accessToken, values }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_addForeignLanguage($foreignLanguageInput: ForeignLanguageInput!) {
            intern_addForeignLanguage(foreignLanguageInput: $foreignLanguageInput) {
              _id
            }
          }
        `,
        variables: {
            "foreignLanguageInput": {
                "language": {
                    "_id": values ? values.language : selectedLanguage ? selectedLanguage?.language?._id : null,
                },
                "level": {
                    "_id": values ? values.languageLevel : selectedLanguage ? selectedLanguage.level?._id : null,
                }
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
        if (res.data?.errors?.length > 0) {
            return {
                errorMessage: res.data?.errors[0].message,
            };
        }

        return res?.data?.data?.intern_addForeignLanguage;

    } catch (err) {
        return err
    }
}
