
export const intern_internshipTips_title = `<b>Unlock Your Global Career<br/> with InternIF</b>`

export const intern_internshipTips = `
<p>
🚀 <b>Diverse Opportunities: </b>
<br/>
Explore a world of opportunities with internships spanning various industries and countries. Choose from a diverse array of experiences tailored to your career goals.
<br/>
<br/>
🤝 <b>Global Networking:</b>
<br/>
Connect with professionals and fellow interns from around the globe. Build a powerful global network that lasts beyond your internship.
<br/>
<br/>
🌍 <b>Cross-Cultural Exposure:</b>
<br/>
Immerse yourself in a cross-cultural work environment. Gain exposure to different work styles, perspectives, and industries, enriching your professional journey.
<br/>
<br/>
🌟 <b>End-to-End Support:</b>
<br/>
Experience a seamless internship journey with InternIF's E2E platform. From initial selection to international employment, we've got your entire career path covered.
<br/>
<br/>
🔍 <b>Tailored Internships:</b>
<br/>
Discover internships matched to your skills and aspirations. InternIF ensures a personalized selection process, connecting you with the right opportunities.
<br/>
<br/>
💼 <b> Ratings and Reviews:</b>
<br/>
Make informed decisions with company ratings and reviews. Know what it's like to work with a company before you apply, ensuring a great fit.
<br/>
<br/>
📈 <b>Performance Tracking:</b>
<br/>
Track your internship performance with insightful analytics. Showcase your achievements and enhance your professional profile.
<br/>
<br/>
💬 <b>In-App Communication:</b>
<br/>
Stay connected with companies through our In-App Chat and Video Meeting features. Enhance collaboration and make the most of your internship experience.
<br/>
<br/>
🏆 <b>Certification of Excellence:</b>
<br/>
Earn recognition for your hard work with a Certificate of Excellence. Showcase your InternIF internship on your resume and LinkedIn profile.
<br/>
<br/>
🔄 <b>Future Employment Opportunities:</b>
<br/>
Stand out for potential future employers. InternIF's EmployIF Phase could lead to exciting international job opportunities post-internship.
<br/>
</p>
`

export const intern_internshipTips_title_tr = `<b>InternIF ile Küresel Kariyerinizi<br/> Açığa Çıkarın</b>`
export const intern_internshipTips_tr = `
<p>
🚀 <b>Çeşitli Fırsatlar: </b>
<br/>
Kariyer hedeflerinize uygun stajyerlikler arasında sektörler ve ülkeler arasında gezin. Kariyer hedeflerinize uygun çeşitli deneyimler arasından seçim yapın.
<br/>
<br/>
🤝 <b>Küresel Ağ:</b>
<br/>
Dünyanın dört bir yanından profesyoneller ve diğer stajyerlerle bağlantı kurun. Stajyerliğinizden sonrasına kadar sürecek güçlü bir küresel ağ oluşturun.
<br/>
<br/>
🌍 <b>Çok Kültürlü Maruziyet:</b>
<br/>
Çok kültürlü bir iş ortamında kendinizi bulun. Farklı iş stilleri, bakış açıları ve sektörlerle tanışarak profesyonel yolculuğunuzu zenginleştirin.
<br/>
<br/>
🌟 <b>Uçtan Uca Destek:</b>
<br/>
InternIF'in E2E platformu ile sorunsuz bir stajyerlik deneyimi yaşayın. Başlangıçtan uluslararası istihdama kadar kariyer yolculuğunuzun tamamını kapsıyoruz.
<br/>
<br/>
🔍 <b>Özel Stajyerlikler:</b>
<br/>
Yeteneklerinize ve hedeflerinize uygun stajyerlikler keşfedin. InternIF, kişiselleştirilmiş bir seçim süreci sağlar ve sizi doğru fırsatlarla buluşturur.
<br/>
<br/>
💼 <b> Derecelendirme ve Yorumlar:</b>
<br/>
Şirket derecelendirmeleri ve yorumları ile bilinçli kararlar alın. Başvurmadan önce bir şirkette çalışmanın nasıl olduğunu öğrenin ve mükemmel bir uyum sağlayın.
<br/>
<br/>
📈 <b>Performans Takibi:</b>
<br/>
Derinlemesine analizlerle stajyerlik performansınızı takip edin. Başarılarınızı sergileyin ve profesyonel profilinizi geliştirin.
<br/>
<br/>
💬 <b>Uygulama İçi İletişim:</b>
<br/>
Şirketlerle uygulama içi sohbet ve video toplantı özelliklerimizle bağlantıda kalın. İşbirliğini artırın ve stajyerlik deneyiminizi en iyi şekilde değerlendirin.
<br/>
<br/>
🏆 <b>Mükemmellik Sertifikası:</b>
<br/>
Çalışmalarınızın karşılığını InternIF Mükemmellik Sertifikası ile alın. Stajyerliğinizi özgeçmişinizde ve LinkedIn profilinizde sergileyin.
<br/>
<br/>
🔄 <b>Gelecekteki İstihdam Fırsatları:</b>
<br/>
Potansiyel gelecekteki işverenler için öne çıkın. InternIF'in EmployIF Aşaması stajyerlik sonrası heyecan verici uluslararası iş fırsatlarına yol açabilir.
<br/>
</p>
`
