import { Box, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import userImage from 'assets/images/common/user.png';



export const ResumeProfileImage = ({ image }) => {
    return <Box sx={{
        width: { xs: 120, lg: 150 }, height: { xs: 120, lg: 150 }, padding: 1, zIndex: 1, backgroundColor: 'white', borderRadius: "80px",
        display: "flex", alignItems: 'center', justifyContent: 'center', position: 'relative', marginTop: { xs: '-65px', lg: '-95px' }, marginLeft: "20px",
        boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)"
    }}>
        <img src={image ?? userImage}
            alt="my account img"
            style={{ border: '1px solid #ccc', width: '100%', height: '100%', objectFit: "cover", borderRadius: "80px" }} />
    </Box>
}

