import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_addCommentToAssignTask = async ({ accessToken, assignTaskId, content }) => {

    const data = JSON.stringify({
        query: `mutation CompanyAdmin_addCommentToAssignTask($filter: AddCommentToAssignedTaskByAssignerFilterInput!, $input: AddCommentToAssignedTaskByAssignerInput!) {
            companyAdmin_addCommentToAssignTask(filter: $filter, input: $input) {
                _id
            }
            }`,
        variables: {
            "filter": {
                "id": assignTaskId
            },
            "input": {
                "type": "comment",
                "content": content,
                "parentModel": {
                    "_id": assignTaskId
                },
                "tags": []
            }
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.companyAdmin_addCommentToAssignTask
    } catch (err) {
        return err
    }
}
