import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Card, Grid, Typography, TextField, Button, Box, useMediaQuery, Stack,
    Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Rating
} from "@mui/material";
import { DashboardLayout } from "layouts";
import { AnimatedTitle, TaskCardForCompany } from "components";
import { GroupOutlined, AddRounded, WorkHistoryOutlined } from "@mui/icons-material";
import { companyAdmin_completeTask, companyAdmin_tasksFunc } from "context";
import { useTranslation } from "react-i18next";
import Iconify from "minimal/components/iconify";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import moment from 'moment'
import { TaskDetailDrawerForCompany } from "page-sections";
import {
    companyAdmin_addCommentToAssignTask, companyAdmin_completeAssignedTask, companyAdmin_reassignAssignedTask,
    companyAdmin_failAssignedTask, companyAdmin_startTask
} from "context";
import { enqueueSnackbar } from "notistack";

export const CalendarComponent = ({ events }) => {
    // const backgroundEvents = [
    //     {
    //         start: '2023-06-16', // Başlangıç tarihi
    //         end: '2023-06-18', // Bitiş tarihi
    //         display: 'background', // Arka plan olarak göster
    //         backgroundColor: 'red', // Arka plan rengi
    //     },
    //     {
    //         start: '2023-06-19',
    //         end: '2023-06-20',
    //         display: 'background',
    //         backgroundColor: 'blue',
    //     }
    // ];

    return (
        <FullCalendar
            height={'auto'}
            headerToolbar={{
                right: 'prev,next',
                left: 'title',
            }}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
            events={events}
        />
    );
};


const Task = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.user);
    const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const upLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const [selectedTabSectionValue, setSelectedTabSectionValue] = useState(0);
    const [inProgressTasks, setInProgressTasks] = useState([]);
    const [toDoTasks, setToDoTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [inProgressCount, setInProgressCount] = useState(0);
    const [todoCount, setTodoCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [selectedItemForDetail, setSelectedItemForDetail] = useState(null);
    const [selectedItemForStart, setSelectedItemForStart] = useState(null);
    const [events, setEvents] = useState([])
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [selectedItemForEvaluate, setSelectedItemForEvaluate] = useState(null);
    const [ratingValue, setRatingValue] = useState(0);

    const getTasks = useCallback(() => {
        dispatch(companyAdmin_tasksFunc({ accessToken, taskStatus: "toDo" })).then((res) => {
            setToDoTasks(res?.payload?.companyAdmin_tasks?.data);
            setTodoCount(res?.payload?.companyAdmin_tasks?.count);
        }).then(() => {
            dispatch(companyAdmin_tasksFunc({ accessToken, taskStatus: "inProgress" })).then((res) => {
                setInProgressTasks(res?.payload?.companyAdmin_tasks?.data);
                setInProgressCount(res?.payload?.companyAdmin_tasks?.count);
            }).then(() => {
                dispatch(companyAdmin_tasksFunc({ accessToken, taskStatus: "completed" })).then((res) => {
                    setCompletedTasks(res?.payload?.companyAdmin_tasks?.data);
                    setCompletedCount(res?.payload?.companyAdmin_tasks?.count);
                })
            })
        })
    }, []);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ py: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const handleSetSelectedTabSectionValue = (event, newValue) => {
        setSelectedTabSectionValue(newValue);
    };

    const handleDetailTask = (item) => {
        setSelectedItemForDetail(item);
    }

    const handleStartTask = (item) => {
        console.log(item, "item");
        setSelectedItemForStart(item);
    }

    const handleStartTaskAccept = () => {
        console.log("start task accept");
        companyAdmin_startTask({ accessToken, assignTaskId: selectedItemForStart?._id }).then((res) => {
            enqueueSnackbar(t("Task started successfully!"), { variant: 'success' });
            getTasks();
            setSelectedItemForDetail(null);
        })
    }

    const handleAddComment = async (commentContent, setCommentContent) => {
        try {
            const addComment = companyAdmin_addCommentToAssignTask({ accessToken, assignTaskId: selectedItemForDetail?.assignedTasks?.[0]?._id, content: commentContent })

            if (addComment) {
                enqueueSnackbar(t("Comment added successfully!"), { variant: 'success' });
                getTasks();
                setSelectedItemForDetail(null);
                setCommentContent('');
            }
        } catch (error) {
            console.log(error, "error");
        }
    }


    const handleSendAttachment = async (attachmentFile) => {
        console.log(attachmentFile, "attachmentFile");
    }

    const handleCompleteTask = async () => {
        console.log(selectedItemForEvaluate, "selectedItemForEvaluate");
        companyAdmin_completeAssignedTask({ accessToken, assignTaskId: selectedItemForEvaluate?.task?.assignedTasks?.[0]?._id, rate: ratingValue }).then((res) => {

            if (res) {
                companyAdmin_completeTask({ accessToken, taskId: selectedItemForEvaluate?.task?._id }).then((res) => {
                    enqueueSnackbar(t("Task completed successfully!"), { variant: 'success' });
                    getTasks();
                    setRatingValue(0)
                    setSelectedItemForEvaluate(null)
                    setSelectedItemForDetail(null)
                })
            }

        })
    }

    const handleReassignTask = async () => {
        console.log(selectedItemForEvaluate, "selectedItemForEvaluate");
        companyAdmin_reassignAssignedTask({ accessToken, assignTaskId: selectedItemForEvaluate?.task?.assignedTasks?.[0]?._id }).then((res) => {
            enqueueSnackbar(t("The task has been continued."), { variant: 'success' }); // todo: enes uyardı
            getTasks();
            setRatingValue(0)
            setSelectedItemForEvaluate(null)
            setSelectedItemForDetail(null)
        })
    }

    const handleFailTask = async () => {
        console.log(selectedItemForEvaluate, "selectedItemForEvaluate");
        companyAdmin_failAssignedTask({ accessToken, assignTaskId: selectedItemForEvaluate?.task?.assignedTasks?.[0]?._id, rate: ratingValue }).then((res) => {
            enqueueSnackbar(t("Task failed successfully!"), { variant: 'success' });
            getTasks();
            setRatingValue(0)
            setSelectedItemForEvaluate(null)
            setSelectedItemForDetail(null)
        })
    }

    useEffect(() => {
        if (accessToken) {
            getTasks();
        }
    }, [accessToken, getTasks]);

    return (
        <DashboardLayout>
            <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
                <Grid item xs={12} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <AnimatedTitle title="Tasks" />
                    <Button onClick={() => navigate('/create-task')}
                        variant="contained" color="primary" size={"large"} sx={{ mt: { xs: 5, lg: 0 } }}>
                        <AddRounded sx={{ mr: 1 }} />
                        Add New Task
                    </Button >
                </Grid>

                <Grid item xs={12} lg={8} xl={9} mt={2} >
                    <Grid container spacing={2} mb={5} >
                        <Grid item xs={4} sx={{ cursor: 'pointer' }} >
                            <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                                <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                    <Iconify icon="pajamas:work" width={150} />
                                </Box>
                                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                    {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <Iconify icon="pajamas:work" fontSize="30px" />
                                    </Box>}
                                    <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"}>
                                        {t("To Do Tasks")}
                                    </Typography>
                                </Stack>
                                <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} textAlign={{ xs: 'center', sm: 'left' }}>
                                    {todoCount ?? 0}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} onClick={() => navigate('/applications')} sx={{ cursor: 'pointer' }}>
                            <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                                <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                    <Iconify icon="jam:task-list" width={150} />
                                </Box>
                                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                    {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <Iconify icon="jam:task-list" fontSize="30px" />
                                    </Box>}
                                    <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"} >
                                        {t("In Progress Tasks")}
                                    </Typography>
                                </Stack>
                                <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} textAlign={{ xs: 'center', sm: 'left' }}>
                                    {inProgressCount ?? 0}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} onClick={() => navigate('/premium')} sx={{ cursor: 'pointer' }}>
                            <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                                <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                    <Iconify icon="solar:crown-linear" width={150} />
                                </Box>
                                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                                    {!downSm && <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <Iconify icon="solar:crown-linear" fontSize="30px" />
                                    </Box>}
                                    <Typography fontSize={"18px"} height={"50px"} fontWeight={"bold"} >
                                        {t("Completed Tasks")}
                                    </Typography>
                                </Stack>
                                <Typography fontSize={"30px"} fontWeight={"bold"} textAlign={{ xs: 'center', sm: 'left' }}>
                                    {completedCount ?? 0}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            borderBottom: 1, borderColor: 'divider', backgroundColor: '#fff',
                            position: 'sticky', top: { xs: 60, sm: 70, lg: 10 }, zIndex: 3, width: '100%',
                        }}
                    >
                        <Tabs value={selectedTabSectionValue} onChange={handleSetSelectedTabSectionValue}
                            style={{ marginBottom: "-7px" }}
                            aria-label="basic tabs example"
                            TabIndicatorProps={{
                                style: {
                                    bottom: 7,
                                    backgroundColor: "#FF9737",
                                    height: "4px",
                                    borderRadius: "10px",
                                }
                            }}>
                            <Tab label={t("To Do Tasks")} {...a11yProps(0)} />
                            <Tab label={t("In Progress Tasks")} {...a11yProps(1)} />
                            <Tab label={t("Completed Tasks")} {...a11yProps(2)} />
                        </Tabs>
                    </Box>

                    {/* ***********************  in progress tasks ****************** */}
                    <CustomTabPanel value={selectedTabSectionValue} index={0} p={0}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0, m: 0 }}  >
                            {todoCount > 0 && toDoTasks?.map((item, index) => (
                                <TaskCardForCompany
                                    item={item}
                                    key={index}
                                    handleDetailTask={handleDetailTask}
                                    handleStartTask={handleStartTask}
                                />
                            ))}
                            {toDoTasks?.length === 0 &&
                                <Typography variant="h6" color={"gray"} textAlign={"center"} mb={1} >No task found!</Typography>}
                        </Box>
                    </CustomTabPanel>

                    {/* ***********************  inprogress tasks ****************** */}
                    <CustomTabPanel value={selectedTabSectionValue} index={1}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0, m: 0 }}  >
                            {inProgressCount > 0 && inProgressTasks?.map((item, index) => (
                                <TaskCardForCompany
                                    item={item}
                                    key={index}
                                    handleDetailTask={handleDetailTask}
                                    handleStartTask={handleStartTask}
                                />
                            ))}
                            {inProgressTasks?.length === 0 &&
                                <Typography variant="h6" color={"gray"} textAlign={"center"} mb={1} >No task found!</Typography>}
                        </Box>


                    </CustomTabPanel>

                    {/* ***********************  completed tasks ****************** */}
                    <CustomTabPanel value={selectedTabSectionValue} index={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0, m: 0 }}  >
                            {completedCount > 0 && completedTasks?.map((item, index) => (
                                <TaskCardForCompany
                                    item={item}
                                    key={index}
                                    handleDetailTask={handleDetailTask}
                                    handleStartTask={handleStartTask}
                                />
                            ))}
                            {completedTasks?.length === 0 &&
                                <Typography variant="h6" color={"gray"} textAlign={"center"} mb={1} >No task found!</Typography>}
                        </Box>


                    </CustomTabPanel>

                </Grid>

                {upLg && <Grid item xs={12} lg={4} xl={3} mt={2}>
                    <Card sx={{
                        backgroundColor: "#F8F8F8", border: '1px solid #E0E0E0',
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                        minWidth: 320,
                        p: 1,
                        height: '100%'
                    }}>
                        <CalendarComponent events={events} />
                    </Card>
                </Grid>}
            </Grid>

            <TaskDetailDrawerForCompany
                task={selectedItemForDetail}
                handleCloseDetail={() => setSelectedItemForDetail(null)}
                handleAddComment={handleAddComment}
                handleSendAttachment={handleSendAttachment}
                loadingUpload={loadingUpload}
                setSelectedItemForEvaluate={setSelectedItemForEvaluate}
            />

            <Dialog open={selectedItemForStart !== null} onClose={() => setSelectedItemForStart(null)} fullWidth>
                <DialogTitle>
                    {t("Start Task")}: {selectedItemForStart?.name}
                </DialogTitle>
                <DialogContent>
                    {t("Are you sure you want to start this task?")}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSelectedItemForStart(null)} color="primary" variant='outlined'>
                        {t("Cancel")}
                    </Button>
                    <Button onClick={(event) => {
                        event.stopPropagation();
                        setSelectedItemForStart(null)
                        handleStartTaskAccept()
                    }} color="secondary" variant='contained'>
                        {t("Start")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={selectedItemForEvaluate !== null}
                onClose={() => {
                    setRatingValue(0)
                    setSelectedItemForEvaluate(null)
                }}
                fullWidth>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    {selectedItemForEvaluate?.type === "complete" && t("Complete Task")}
                    {selectedItemForEvaluate?.type === "reassign" && t("Continue Task")}
                    {selectedItemForEvaluate?.type === "fail" && t("Fail Task")}
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" sx={{ textAlign: "center" }} mb={2}>
                        {selectedItemForEvaluate?.type === "complete" && t("You are about to complete your task process.")}
                        {selectedItemForEvaluate?.type === "reassign" && t("You are about to continue your task process.")}
                        {selectedItemForEvaluate?.type === "fail" && t("You are about to fail your task.")}<br />
                        {selectedItemForEvaluate?.type !== "reassign" && t("If you want to rate this task out of 5, how many points would you give?")}
                        {selectedItemForEvaluate?.type !== "reassign" && <Box height={20} />}
                        {(selectedItemForEvaluate?.type === "complete" || selectedItemForEvaluate?.type === "fail") && <Rating
                            name="simple-controlled"
                            size='large'
                            value={ratingValue}
                            onChange={(event, newValue) => {
                                setRatingValue(newValue);
                            }}
                        />}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setRatingValue(0)
                        setSelectedItemForEvaluate(null)
                    }} color="primary" variant='outlined'>
                        {t("Cancel")}
                    </Button>
                    <Button
                        disabled={
                            selectedItemForEvaluate?.type !== "reassign" && ratingValue === 0}
                        onClick={() => {
                            selectedItemForEvaluate?.type === "complete" && handleCompleteTask()
                            selectedItemForEvaluate?.type === "reassign" && handleReassignTask()
                            selectedItemForEvaluate?.type === "fail" && handleFailTask()
                        }}
                        color={selectedItemForEvaluate?.type === "complete" ? "secondary" : selectedItemForEvaluate?.type === "reassign" ? "info" : "error"}
                        variant='contained'>
                        {selectedItemForEvaluate?.type === "complete" && t("Complete Task")}
                        {selectedItemForEvaluate?.type === "reassign" && t("Continue Task")}
                        {selectedItemForEvaluate?.type === "fail" && t("Fail Task")}
                    </Button>
                </DialogActions>
            </Dialog>

        </DashboardLayout >
    );
};

export default Task;
