import React, { useEffect } from 'react'
import { Grid, Typography, Box, Card, Button } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import animateScrollTo from 'animated-scroll-to';
import Lottie from 'lottie-react';
import PaymentSuccess from "assets/animations/payment_success.json";
import { AnimatedTitle } from 'components';
import { t } from 'i18next'





const PaymentSuccessIntern = () => {

    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId');
    const navigate = useNavigate();

    useEffect(() => {
        animateScrollTo(0, { maxDuration: 1000 })
    }, [])

    return <DashboardLayout>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', gap: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                <AnimatedTitle title={`${t("Payment Result")}`} />
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"} >
                <Card sx={{ p: 2, mb: 2, width: '100%', alignItems: "center", display: "flex", flexDirection: "column", }}>
                    <Box width="100%" >
                        <Typography variant="h6" textAlign={"center"} mb={5}>{t("Payment is completed successfully.")}</Typography>
                    </Box>
                    <Lottie
                        animationData={PaymentSuccess}
                        style={{ width: '100%', height: '280px' }}
                        autoplay={true}
                        loop={false}
                    />

                    <Typography variant="body1" textAlign={"center"} mt={5}>{t("Your payment has been completed successfully. However, it may take up to 5 minutes for your package to be reflected in your account.")}</Typography>

                    <Button variant="contained" color="primary" size='large'
                        onClick={() => navigate('/premium')}
                        sx={{ mt: 5 }}>{t("Go to Premium")}</Button>
                </Card>

            </Grid>
        </Grid>
    </DashboardLayout>
}

export default PaymentSuccessIntern