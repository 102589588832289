import React from 'react'
import { Box } from '@mui/material'

import { MyInternshipWidget } from 'components'
import { WorkedHoursIcon, CompletedTasksIcon, InternshipProgressIcon } from 'assets'


const selectedApplication = null
const tasks = null

export const MyInternshipWidgetSection = () => {


    const estimatedStartDate = selectedApplication?.internship?.estimatedStartDate
    const timePeriod = selectedApplication?.internship?.timePeriod ? Number.parseInt(selectedApplication?.internship?.timePeriod.slice(0, 1)) * 30 : 0
    const finishedDay = new Date().getTime() - new Date(estimatedStartDate).getTime()

    const totalTaskLength = tasks?.length ?? 0
    const finishedTaskLength = tasks?.filter(task => task.status !== 'inprogress')?.length ?? 0

    return (
        <Box
            sx={{

                display: 'flex',
                flexDirection: { xs: 'row', md: 'row', lg: 'row', xl: 'row' },
                justifyContent: { md: 'space-between' },
                gap: '16px',
                overflow: 'auto',
                pb: { xs: '15px' },

                '&::-webkit-scrollbar': {
                    width: '3px',
                    height: '5px',
                },
                '&::-webkit-scrollbar-track': {
                    // boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    // webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    // outline: '1px solid slategrey'
                }
            }}
        >


            <MyInternshipWidget
                icon={CompletedTasksIcon}
                title="Completed Tasks"
                desc="Shows ongoing and completed tasks."
                text1={`${finishedTaskLength}`}
                text2={`/${totalTaskLength} Tasks`}
                bottomText="10% has increased since last week"
            />

            <MyInternshipWidget
                icon={WorkedHoursIcon}
                title="Worked Hours"
                desc="Shows total worked hours this week"
                text1={`${0} `}
                text2="&nbsp;Hours"
                bottomText="10% has increased since last week"
            />

            <MyInternshipWidget
                upper={false}
                icon={InternshipProgressIcon}
                title="Internship Progress"
                desc="Shows total worked hours this week"
                text1={`${finishedDay > 0 ? Math.ceil(finishedDay / (1000 * 60 * 60 * 24)) : (0)}`}
                text2={`/${timePeriod}`}
                bottomText="16 days left to finish the internship"
            />

        </Box>
    )
}

