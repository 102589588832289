import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_removeApplication = async ({ applicationId, accessToken }) => {

    const data = JSON.stringify({
        query: `
        mutation Intern_removeApplication($applicationId: ID!) {
            intern_removeApplication(applicationId: $applicationId) {
              success
            }
          }
        `,
        variables: {
            "applicationId": applicationId
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data, accessToken));
        return res?.data?.data?.intern_removeApplication
    } catch (err) {
        return err
    }
}
