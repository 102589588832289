import { Provider } from "react-redux";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Router from "./Router"
import store from "context/store";
import ThemeProvider from 'minimal/theme';
import SnackbarProvider from 'minimal/components/snackbar/snackbar-provider';

const SnackbarContainer = ({ children }) => {
    return (
        <SnackbarProvider maxSnack={5}>
            {children}
        </SnackbarProvider>
    );
};


const Wrapper = () => {



    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} >

            <Provider store={store}>
                <ThemeProvider>

                    <SnackbarContainer>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                            <Router />

                        </LocalizationProvider>
                    </SnackbarContainer>
                </ThemeProvider>
            </Provider>

        </GoogleOAuthProvider>

    )
}

export default Wrapper;