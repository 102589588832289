import { Box, Card, Grid, Stack, Typography, Checkbox, Button } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getNameOfApplicationStatus } from 'utils'
import Label from 'minimal/components/label'
import { styled } from '@mui/material/styles';
import { CalendarMonth, Check, CheckCircle, Flag } from '@mui/icons-material'

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        borderRadius: '50%',
    },
}));

export const TaskCardForCompany = ({ item, handleDetailTask, handleStartTask }) => {

    console.log(item)

    return (
        <Card
            sx={{
                p: 2, borderRadius: 2, backgroundColor: 'rgba(255,255,255, 0.5)', backdropFilter: 'blur(6px)', cursor: 'pointer',
                '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)" }
            }}
            onClick={() => handleDetailTask(item)}
        >
            <Grid container spacing={2} alignItems='center' justifyContent={'center'}>
                <Grid item xs={12}>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} gap={1}>
                        <CustomCheckbox />
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"} sx={{ width: '100%' }} ml={1}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' }, gap: 1 }}>
                                <Typography fontWeight={"bold"} fontSize={20} color={'#444'} mr={1}>
                                    {item?.name?.toString()}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                                <Typography variant='body1' mr={1} color={'#888'}>
                                    {item?.description?.slice(0, 200)}...
                                </Typography>
                            </Box>
                            <Stack direction="row" alignItems={'center'} gap={1} justifyContent={"space-between"} sx={{ width: '100%' }} >
                                <Box display={'flex'} mt={1} alignItems={'center'} gap={2} justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                                    {/* <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>
                                    Start: {moment(item?.task?.startDate).format('DD.MM.YYYY')}
                                </Typography> */}
                                    <Stack direction="row" alignItems={'center'} gap={0.5}>
                                        <CalendarMonth fontSize='small' sx={{ color: '#999' }} />
                                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>
                                            {moment(item?.dueDate).format('DD.MM.YYYY')}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems={'center'}>
                                        <Flag fontSize='small' sx={{ color: item?.priority === "low" ? 'green' : item?.priority === "normal" ? 'orange' : 'darkred' }} />
                                        <Typography variant='body2' color={item?.priorty === "low" ? 'success' : item?.priority === "normal" ? 'warning' : 'error'} textAlign={{ xs: 'center', sm: 'left' }} >
                                            {item?.priority?.slice(0, 1).toUpperCase() + item?.priority?.slice(1)}
                                        </Typography>
                                    </Stack>
                                    <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>
                                        Task Type: <b>{item?.taskType?.slice(0, 1).toUpperCase() + item?.taskType?.slice(1)}</b>
                                    </Typography>
                                </Box>
                                {(item?.taskStatus === "toDo") && <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleStartTask(item)}
                                >
                                    <CheckCircle sx={{ mr: 1 }} />
                                    Start Task
                                </Button>}

                            </Stack>
                        </Box>
                    </Box>
                </Grid>

                <Grid item right={10} top={0} position={'absolute'}>
                    <Label
                        color={'primary'}
                        sx={{ borderRadius: 200, minWidth: 90 }}
                    >
                        {item.assignedTasks?.[0]?.status?.slice(0, 1).toUpperCase() + item.assignedTasks?.[0]?.status?.slice(1)}
                    </Label>
                </Grid>
            </Grid>
        </Card>
    );
}
