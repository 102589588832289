import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_createTask = async ({ accessToken, values, attachment }) => {

  const data = JSON.stringify({
    query: `mutation CompanyAdmin_createTask($input: CreateTaskInput!) {
      companyAdmin_createTask(input: $input) {
        _id
      }
    }`,
    variables: {
      "input": {
        "allowableFileFormats": values.allowableFileFormats ?? null,
        "attachments": attachment ? [attachment] : null,
        "description": values.description ?? null,
        "difficulty": values.difficulty ?? null,
        "dueDate": values.dueDate ?? null,
        "duration": values.duration ?? null,
        "interns": [
          {
            "_id": values.internId
          }
        ],
        "internship": {
          "_id": values.internshipId
        },
        "name": values.name ?? null,
        "priority": values.priority ?? null,
        "reminder": values.reminder ?? null,
        "startDate": values.startDate ?? null,
        "taskType": values.taskType ?? null
      }
    }
  })


  try {
    const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
    return res?.data?.data?.companyAdmin_createTask
  } catch (err) {
    return err
  }
}
