import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

// COMMON
import user from "./common/user";
import theme from "./common/theme";
import notes from "./common/notes";

// AUTH
import signin from "./auth/signin";
import signup from "./auth/signup";
import requestResetPassword from "./auth/requestResetPassword";
import confirmationEmail from "./auth/confirmationEmail";
import resendRegistrationConfirmation from "./auth/resendRegistrationConfirmation";
import resetPassword from "./auth/resetPassword";
import resetPasswordUsingOld from "./auth/resetPasswordUsingOld";
import update2fa from "./auth/update2fa";
import confirm2fa from "./auth/confirm2fa";
import get2fa from "./auth/get2fa";
import googleRegister from "./auth/googleRegister";
import googleLogin from "./auth/googleLogin";
import confirmRegistrationByUser from "./auth/confirmRegistrationByUser";

// USER
import self_intern from "./user/query/self_intern";
import parameters from "./user/query/parameters";
import self_companyUser from "./user/query/self_companyUser";
import user_company from "./user/query/user_company";
import user_intern from "./user/query/user_intern";
import user_internsByIdsForApplication from "./user/query/user_internsByIdsForApplication";
import companyAdmin_companyUsers from "./user/query/companyAdmin_companyUsers";
import self_user from "./user/query/self_user";
import anonymous_policies from "./user/query/anonymous_policies";


// INTERNSHIP
import intern_application from "./internship/query/intern/_intern_application";
import intern_applications from "./internship/query/intern/_intern_applications";
import intern_internship from "./internship/query/intern/_intern_internship";
import intern_internships from "./internship/query/intern/_intern_internships";
import intern_savedInternships from "./internship/query/intern/_intern_savedInternships";
import intern_packages from "./internship/query/intern/intern_packages";
import intern_joinedInternships from "./internship/query/intern/intern_joinedInternships";

import user_internshipPrice from "./internship/query/company//user_internshipPrice";
import companyUser_internships from "./internship/query/company/companyUser_internships";
import companyUser_internship from "./internship/query/company/companyUser_internship";
import companyUser_applications from "./internship/query/company/companyUser_applications";
import companyAdmin_internsJoinedToInternship from "./internship/query/company/companyAdmin_internsJoinedToInternship";

//PAYMENT
import self_user_billingInfo from "./payment/query/self_user_billingInfo";
import user_products from "./payment/query/user_products";
import self_organization_billingInfo from "./payment/query/self_organization_billingInfo";
import order from "./payment/query/order";
import hIntern_products from "./payment/query/hIntern_products";
import intern_products from "./payment/query/intern_products";
import user_productCode from "./payment/query/user_productCode";
import self_orders from "./payment/query/self_orders";

//NOTIFICATION
import user_userNotifications from "./notification/query/user_userNotifications";

//TASK
import intern_assignedTasks from "./task/query/intern/intern_assignedTasks";
import companyAdmin_tasks from "./task/query/company/companyAdmin_tasks";
import companyUser_menuInternships from "./task/query/company/companyUser_menuInternships";

const store = configureStore({

  middleware: [thunk],
  devTools: true,

  reducer: {
    user,
    theme,
    notes,
    signin,
    self_intern,
    self_companyUser,
    parameters,
    signup,
    requestResetPassword,
    confirmationEmail,
    resendRegistrationConfirmation,
    resetPassword,
    resetPasswordUsingOld,
    update2fa,
    confirm2fa,
    get2fa,
    googleRegister,
    googleLogin,
    confirmRegistrationByUser,

    intern_internships,
    intern_internship,
    intern_application,
    intern_applications,
    intern_savedInternships,
    user_internshipPrice,
    user_company,
    companyUser_internships,
    companyUser_internship,
    companyUser_applications,
    user_internsByIdsForApplication,
    user_intern,
    companyAdmin_companyUsers,
    self_user_billingInfo,
    user_products,
    self_organization_billingInfo,
    self_user,
    order,
    anonymous_policies,
    user_userNotifications,
    hIntern_products,
    intern_products,
    intern_packages,
    user_productCode,
    self_orders,
    intern_assignedTasks,
    intern_joinedInternships,
    companyAdmin_tasks,
    companyUser_menuInternships,
    companyAdmin_internsJoinedToInternship
  },
  middleware: [thunk],
  devTools: true
});

export default store;
