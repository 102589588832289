import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { DashboardLayout } from 'layouts'
import parse from 'html-react-parser'
import { useSearchParams } from 'react-router-dom'
import animateScrollTo from 'animated-scroll-to';
import PaymentSuccess from "assets/animations/payment_success.json";
import PaymentFailed from "assets/animations/payment_failed.json";



const Payment = () => {

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        animateScrollTo(0, { maxDuration: 1000 })
    }, [])

    const htmlCode = `<html><body><script src=\"https://www.paytr.com/js/iframeResizer.min.js\"></script><iframe  src=\"https://www.paytr.com/odeme/guvenli/${token}\" id=\"paytriframe\" frameborder=\"0\" scrolling=\"yes\" style=\"width: 100%; height:800px\"></iframe> <script>iFrameResize({},'#paytriframe');</script></body></html>`


    return <DashboardLayout>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', gap: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >

                {parse(htmlCode)}

            </Grid>
        </Grid>
    </DashboardLayout>
}

export default Payment