import axios from "axios";
import initialConfig from "context/initialConfig";

export const companyAdmin_completeTask = async ({ accessToken, taskId }) => {

    const data = JSON.stringify({
        query: `mutation CompanyAdmin_completeTask($filter: CompleteTaskFilterInput!) {
          companyAdmin_completeTask(filter: $filter) {
            _id
          }
        }`,
        variables: {
            "filter": {
              "id": taskId
            },
          }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_LOCAL_URL + '/graphql', data, accessToken))
        return res?.data?.data?.companyAdmin_completeTask
    } catch (err) {
        return err
    }
}
