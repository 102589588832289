import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    signupData: null,
    signupLoading: false,
    signupError: null,
};


export const signupFunc = createAsyncThunk("signup/signupFunc", async (values) => {


    let data1 = JSON.stringify({
        "email": values.email,
        "password": values.password,
        "role": values.userRole
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL + '/auth/auth/register',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer null'
        },
        data: data1
    };

    const response = axios.request(config)
        .then((res) => {

            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const signup = createSlice({
    name: "signup",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // signup
        builder.addCase(signupFunc.pending, (state) => {
            state.signupLoading = true;
        });
        builder.addCase(signupFunc.fulfilled, (state, action) => {
            state.signupLoading = false;
            state.signupData = action.payload;
            state.signupError = action.payload.message;
        });
        builder.addCase(signupFunc.rejected, (state, action) => {
            state.signupLoading = false;
            state.signupError = action.error.message;
        });
    }
});


export default signup.reducer;