import React from 'react';
import { Box, Dialog, DialogActions, Button, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export const DialogFormContainerResumeSection = ({ open, setOpen, title, children, handleSave }) => {

    const smDown = useMediaQuery(useTheme().breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
    };

    return <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <Box
            sx={{
                backgroundColor: '#fff',
                p: smDown ? 1 : 3,
            }}
        >
            {children}
        </Box>
        <DialogActions sx={{ pr: smDown ? 1 : 3 }}>
            <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
            </Button>
            <Button onClick={e => handleSave(e.target.value)} color="primary" variant="contained" autoFocus>
                Save
            </Button>
        </DialogActions>
    </Dialog >

}