import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "../initialConfig";

const initialState = {
    resetPasswordData: null,
    resetPasswordLoading: false,
    resetPasswordError: null,
};


export const resetPasswordFunc = createAsyncThunk("requestResetPassword/resetPasswordFunc", async (values) => {
    let data1 = JSON.stringify({
        "token": values.passwordToken,
        "password": values.password
    });

    var config = initialConfig(process.env.REACT_APP_BASE_URL + '/auth/auth/resetpassword', data1);

    const response = axios.request(config)
        .then((res) => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            return error;
        });

    const data = await response;
    return data;
});


const requestResetPassword = createSlice({
    name: "requestResetPassword",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // resetPassword
        builder.addCase(resetPasswordFunc.pending, (state) => {
            state.resetPasswordLoading = true;
        }
        );
        builder.addCase(resetPasswordFunc.fulfilled, (state, action) => {
            state.resetPasswordLoading = false;
            state.resetPasswordData = action.payload;
            state.resetPasswordError = action.payload.message;
        }
        );
        builder.addCase(resetPasswordFunc.rejected, (state, action) => {
            state.resetPasswordLoading = false;
            state.resetPasswordError = action.error.message;
        }
        );
    }
});


export default requestResetPassword.reducer;