import axios from "axios";
import initialConfig from "context/initialConfig";


export const user_createOrderForUserWithPaytrCheckoutFormPayment = async (values) => {

  const data = JSON.stringify({
    query: `mutation User_createOrderForUserWithPaytrCheckoutFormPayment($orderInput: CreateOrderWithCheckoutFormInput!) {
      user_createOrderForUserWithPaytrCheckoutFormPayment(orderInput: $orderInput) {
        order {
          _id
        }
        paymentResult {
          reason
          status
          token
        }
      }
    }`,
    variables: {
      "orderInput": {
        "currency": "TRY",
        "product": {
          "_id": values?.productId
        },
        "productCodeId": values?.productCodeId,
      }
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data, values.accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    if (res.errors?.length > 0) {
      return {
        errorMessage: res?.errors[0].message,
      };
    }
    return res?.data?.data?.user_createOrderForUserWithPaytrCheckoutFormPayment;
  } catch (err) {
    return err
  }
}