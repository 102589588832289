import axios from "axios";
import initialConfig from "context/initialConfig";


const url = process.env.REACT_APP_BASE_URL + "/internship/graphql"

// ###########################  companyAdmin_viewApplication ######################################
export const companyAdmin_viewApplication = async ({ applicationId, accessToken }) => {
    const data = JSON.stringify({
        query: `
    mutation CompanyAdmin_viewApplication($applicationId: ID!) {
        companyAdmin_viewApplication(applicationId: $applicationId) {
          success
        }
      }
    `,
        variables: {
            "applicationId": applicationId
        },
    });

    try {
        const res = await axios(initialConfig(url, data, accessToken))
        if (res.data?.errors && res.data?.errors.length > 0) {
            return { errorMessage: res.data?.errors[0].message };
        }
        return res?.data?.data?.companyAdmin_viewApplication
    } catch (err) {
        return err;
    }

};



// ###########################  companyAdmin_acceptApplication ######################################
export const companyAdmin_acceptApplication = async ({ applicationId, accessToken }) => {
    const data = JSON.stringify({
        query: `
        mutation CompanyAdmin_acceptApplication($applicationId: ID!) {
            companyAdmin_acceptApplication(applicationId: $applicationId) {
              success
            }
          }
    `,
        variables: {
            "applicationId": applicationId
        },
    });

    try {
        const res = await axios(initialConfig(url, data, accessToken))
        if (res.data?.errors && res.data?.errors.length > 0) {
            return { errorMessage: res.data?.errors[0].message };
        }
        return res?.data?.data?.companyAdmin_acceptApplication
    } catch (err) {
        return err;
    }

};

// ###########################  companyAdmin_evaluatingApplication ######################################
export const companyAdmin_evaluatingApplication = async ({ applicationId, accessToken }) => {
    const data = JSON.stringify({
        query: `mutation CompanyAdmin_evaluatingApplication($applicationId: ID!) {
            companyAdmin_evaluatingApplication(applicationId: $applicationId) {
              success
            }
          } `,
        variables: {
            "applicationId": applicationId
        },
    });

    try {
        const res = await axios(initialConfig(url, data, accessToken))
        if (res.data?.errors && res.data?.errors.length > 0) {
            return { errorMessage: res.data?.errors[0].message };
        }
        return res?.data?.data?.companyAdmin_evaluatingApplication
    } catch (err) {
        return err;
    }

};



// ###########################  companyAdmin_rejectedApplication ######################################
export const companyAdmin_rejectedApplication = async ({ applicationId, accessToken }) => {
    const data = JSON.stringify({
        query: `
        mutation CompanyAdmin_rejectedApplication($applicationId: ID!) {
            companyAdmin_rejectedApplication(applicationId: $applicationId) {
              success
            }
          }
    `,
        variables: {
            "applicationId": applicationId
        },
    });

    try {
        const res = await axios(initialConfig(url, data, accessToken))
        if (res.data?.errors && res.data?.errors.length > 0) {
            return { errorMessage: res.data?.errors[0].message };
        }
        return res?.data?.data?.companyAdmin_rejectedApplication
    } catch (err) {
        return err;
    }

};



// ###########################  companyAdmin_makeApplicationSuitable ######################################
export const companyAdmin_makeApplicationSuitable = async ({ applicationId, accessToken }) => {
    const data = JSON.stringify({
        query: `
        mutation CompanyAdmin_makeApplicationSuitable($applicationId: ID!) {
            companyAdmin_makeApplicationSuitable(applicationId: $applicationId) {
              success
            }
          }
    `,
        variables: {
            "applicationId": applicationId
        },
    });

    try {
        const res = await axios(initialConfig(url, data, accessToken))
        if (res.data?.errors && res.data?.errors.length > 0) {
            return { errorMessage: res.data?.errors[0].message };
        }
        return res?.data?.data?.companyAdmin_makeApplicationSuitable
    } catch (err) {
        return err;
    }

};


// ###########################  companyAdmin_makeApplicationUnsuitable ######################################
export const companyAdmin_makeApplicationUnsuitable = async ({ applicationId, accessToken }) => {
    const data = JSON.stringify({
        query: `
        mutation CompanyAdmin_makeApplicationUnsuitable($applicationId: ID!) {
            companyAdmin_makeApplicationUnsuitable(applicationId: $applicationId) {
              success
            }
          }
    `,
        variables: {
            "applicationId": applicationId
        },
    });

    try {
        const res = await axios(initialConfig(url, data, accessToken))
        if (res.data?.errors && res.data?.errors.length > 0) {
            return { errorMessage: res.data?.errors[0].message };
        }
        return res?.data?.data?.companyAdmin_makeApplicationSuitable
    } catch (err) {
        return err;
    }

};



// ###########################  companyAdmin_archiveInternship ######################################
export const companyAdmin_archiveInternship = async ({ applicationId, accessToken }) => {
    const data = JSON.stringify({
        query: `
        mutation CompanyAdmin_archiveInternship($internshipId: ID!) {
            companyAdmin_archiveInternship(internshipId: $internshipId) {
              success
            }
          }
    `,
        variables: {
            "applicationId": applicationId
        },
    });

    try {
        const res = await axios(initialConfig(url, data, accessToken))
        if (res.data?.errors && res.data?.errors.length > 0) {
            return { errorMessage: res.data?.errors[0].message };
        }
        return res?.data?.data?.companyAdmin_archiveInternship
    } catch (err) {
        return err;
    }

};