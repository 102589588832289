import {useState} from 'react';
import { Box, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import userImage from 'assets/images/common/user.png';



export const ProfileImage = ({ values, handleProfileImageChange, compressedProfileImage }) => {


 


    return <Box sx={{
        width: { xs: 120, lg: 150 }, height: { xs: 120, lg: 150 }, padding: 1, zIndex: 1, backgroundColor: 'white', borderRadius: "80px",
        display: "flex", alignItems: 'center', justifyContent: 'center', position: 'relative', marginTop: { xs: '-65px', lg: '-95px' }, marginLeft: "20px",
        boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)"
    }}>
        <IconButton onClick={() => document.getElementById('profile-image-input').click()} sx={{
            position: 'absolute', bottom: 0, right: 0, zIndex: 2, backgroundColor: 'white', borderRadius: "50px",
            boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)"
        }}>
            <EditIcon />
        </IconButton>
        <input
            id="profile-image-input"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleProfileImageChange}
        />
        <img src={
            compressedProfileImage?.url ? compressedProfileImage?.url :
                values?.profileImageUrl ? values?.profileImageUrl :
                    userImage}
            alt="my account img"
            style={{ border: '1px solid #ccc', width: '100%', height: '100%', objectFit: "cover", borderRadius: "80px" }} />
    </Box>
}

