const ValidationErrors_companyAdmin_createInternship_Errors = {
  invalidCompanyUser: {
    errorMessage: "InvalidCompanyUser",
    message: "Invalid company user",
  },
  noCompanyOfCompanyUser: {
    errorMessage: "NoCompanyOfCompanyUser",
    message: "No company of company User",
  },
  maxQuotaIsExceeded: {
    errorMessage: "MaxQuotaIsExceeded",
    message: "Max quota is exceeded",
  },
  companyCreditIsNotSufficient: {
    errorMessage: "CompanyCreditIsNotSufficient",
    message: "Company credit is not sufficient",
  },
};

export function getMessageOfValidationError(errorMessage) {
  if (!errorMessage) {
    return "";
  }
  const messageKey = Object.keys(
    ValidationErrors_companyAdmin_createInternship_Errors
  ).filter(
    (key) =>
      errorMessage ===
      ValidationErrors_companyAdmin_createInternship_Errors[key].errorMessage
  )[0];
  return (
    ValidationErrors_companyAdmin_createInternship_Errors[messageKey].message ??
    ""
  );
}
