import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accessToken: null,
    userRole: null,
    userId: null,
    email: null,
    avatar: null,
    fullName: null,
    companyId: null,
    remember: false
};


const user = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAccessToken(state, action) {
            state.accessToken = action.payload;
        },
        setUserRole(state, action) {
            state.userRole = action.payload;
        },
        setUserId(state, action) {
            state.userId = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setAvatar(state, action) {
            state.avatar = action.payload;
        },
        setFullName(state, action) {
            state.fullName = action.payload;
        },
        setCompanyId(state, action) {
            state.companyId = action.payload;
        },
        setRemember(state, action) {
            state.remember = action.payload;
        },
        removeUser: (state, action) => {
            state.fullName = null;
            state.autoInfo = false;
            state.accessToken = null;
            const email = state.email;
            state.email = null;
            state.userRole = null;
            state.userId = null;
            state.avatar = null;
            localStorage.clear();
            localStorage.setItem('remember', action.payload)
            action.payload && localStorage.setItem('email', email)
        }
    }
});


export const { setAccessToken, setUserRole, setUserId, setEmail, setAvatar, setFullName, removeUser, setCompanyId, setRemember } = user.actions;


export default user.reducer;