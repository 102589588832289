import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import { InternshipGoalForInternList, fillRateCalculator } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { intern_createApplication, intern_setInternshipNull } from 'context';
import { enqueueSnackbar } from 'notistack';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { t } from 'i18next';


export const ApplyToInternship = ({ internship }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { self_internData: user } = useSelector(state => state.self_intern);
    const { accessToken } = useSelector((state) => state.user);
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = useState({
        internshipGoal: null,
        coverLetter: null,
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApplyToInternship = async () => {

        console.log(values)
        try {
            const response = await intern_createApplication({
                coverLetter: values.coverLetter,
                internshipId: internship?._id,
                internshipGoal: values?.internshipGoal?.value,
                accessToken
            })

            console.log({ response })

            if (response?._id) {
                setOpen(false);
                dispatch(intern_setInternshipNull())
                enqueueSnackbar("The application has been applied!", { variant: "success" })
            } else {
                response?.forEach(item => {
                    if (item?.message === "AlreadyApplied") {
                        enqueueSnackbar("The application has already been applied!", { variant: "error" })
                    } else {
                        enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" })
                    }
                    setOpen(false);
                });
            }
        } catch (error) {
            if (error?.data?.errors?.includes(item => item?.message === "AlreadyApplied")) {
                enqueueSnackbar("The application has already been applied!", { variant: "error" })
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" })
            }
        }
    };


    return <>
        <Button variant='contained' sx={{ px: 5, width: '45%' }} color='primary' size='large' onClick={handleClickOpen}>
            {internship?.isSaved ? <CheckCircle /> : <CheckCircleOutline />}
            <Box width={5} />
            {true ? t('Apply') : ''}
        </Button>

        <Dialog onClose={handleClose} open={open} >
            <DialogTitle>{t("Apply to Company")}</DialogTitle>
            <DialogContent sx={{ width: { sx: '100%', md: 500 } }}>
                <Grid container spacing={3}  >
                    <Grid item xs={12}>
                        <Typography variant='body2'>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                        <TextField fullWidth placeholder={`${t("Resume")} ${fillRateCalculator(user)}%`} disabled />
                        <Typography fontSize={'small'} onClick={() => navigate('/my-resume')}
                            sx={{ position: 'absolute', right: 5, top: 32, mt: 1, mr: 1, color: 'secondary.main', cursor: 'pointer' }}>
                            {t("Go to Edit Resume")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            options={InternshipGoalForInternList ?? []}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            value={values?.internshipGoal}
                            onChange={(event, newValue) => {
                                console.log({ newValue })
                                setValues(prevState => ({ ...prevState, internshipGoal: newValue }));
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Internship Goal"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            options={user?.cv?.coverLetters ?? []}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            noOptionsText={(<Box display={'flex'} justifyContent={'space-between'} >
                                <Typography variant='body2'>No cover letters available</Typography>
                                <Typography fontSize={'small'} color='secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/my-cover-letters')}
                                >Go to Add Cover Letter</Typography>
                            </Box>)}
                            isOptionEqualToValue={(option, value) => option?._id === value?._id}
                            value={values?.coverLetter}
                            onChange={(event, newValue) => {
                                console.log({ newValue })
                                setValues(prevState => ({ ...prevState, coverLetter: newValue }));
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name} afa
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Cover letter"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="contained"
                    disabled={fillRateCalculator(user) < 50 || !values?.internshipGoal || !values?.coverLetter}
                    onClick={handleApplyToInternship}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>

    </>
}
