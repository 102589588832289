import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    autoInfo: false,
    isNavigationMini: false
};


const theme = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setAutoInfo(state, action) {
            state.autoInfo = action.payload;
        },
        setNavigationMini (state, action) {
            state.isNavigationMini = action.payload;
        }
    }
});


export const { setNavigationMini, setAutoInfo } = theme.actions;


export default theme.reducer;