import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InternshipCardForCompany } from "components";
import { useDispatch, useSelector } from "react-redux";
import { companyUser_internshipsFunc, self_organization_billingInfoFunc } from "context";
import { internshipStatusTabsList } from "utils/enums";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InternshipsSubSection = (internships = [], index, handleClick) => {
  return (
    <Grid
      container
      spacing={{ xs: 0, sm: 3 }}
      sx={{
        py: 3,
        width: "100%",
        gap: { xs: 2, sm: 0 }
      }}
    >
      {internships.map((internship) => (
        <Grid item xs={12}>
          <InternshipCardForCompany
            item={internship}
            loading={false}
          />
        </Grid>
      ))}
      {internships.length === 0 && (
        <Grid item xs={12}>
          {index === 0 && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
              variant="h6"
              color={"gray"}
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              You do not have any active internship posting yet.
            </Typography>
            <Button color="secondary" variant="contained" sx={{ mt: 2 }} onClick={handleClick}>
              Let’s Start
            </Button>
          </Box>}
          {index === 1 && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
              variant="h6"
              color={"gray"}
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              You do not have any completed internship.
            </Typography>
          </Box>}
          {index === 2 && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
              variant="h6"
              color={"gray"}
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              You do not have any failed internship.
            </Typography>
          </Box>}
          {index === 3 && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
              variant="h6"
              color={"gray"}
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              You do not have any archived internship.
            </Typography>
          </Box>}


        </Grid>
      )

      }
    </Grid>
  );
};

const InternshipsSection = ({ internshipStatuses, pageSize, internshipStatusTabs }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const { accessToken } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const { companyUser_internshipsData: internshipsData } = useSelector((state) => state.companyUser_internships);

  const pageCount = internshipsData?.count
    ? Math.ceil(internshipsData.count / pageSize)
    : 1;


  useEffect(() => {

    accessToken && dispatch(companyUser_internshipsFunc({
      variables: {
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        filter: {
          statuses: internshipStatusTabsList[value]?.value === "active" ? ["activeWithListing", "activeWithoutListing", "started", "decision"] :
            internshipStatusTabsList[value]?.value === "completed" ? ["completed"] :
              internshipStatusTabsList[value]?.value === "failed" ? ["failed", "expired"] :
                internshipStatusTabsList[value]?.value === "archived" ? ["archived"] : null,
        },
      },
      accessToken,
    }));
  }, [dispatch, accessToken, value, page, pageSize]);

  useEffect(() => {
    accessToken && dispatch(self_organization_billingInfoFunc({ accessToken }));
  }, [dispatch, accessToken]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePageChange = (event, newValue) => {
    setPage(newValue);
  };

  const handleClick = () => {
    navigate("/internship/createInternship");
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          style={{ marginBottom: "-7px" }}
          TabIndicatorProps={{
            style: {
              bottom: 7,
              backgroundColor: "#FF9737",
              height: "4px",
              borderRadius: "10px",
            },
          }}
        >
          {internshipStatusTabs.map((status) => (
            <Tab label={status} {...a11yProps(0)} />
          ))}
        </Tabs>
      </Box>
      {internshipStatusTabs.map((_, index) => (
        <CustomTabPanel value={value} index={index}>
          {InternshipsSubSection(internshipsData?.data ?? [], index, handleClick)}
        </CustomTabPanel>
      ))}
      {
        internshipsData?.count > pageSize &&
        <Pagination count={pageCount} page={page} onChange={handlePageChange} />
      }
    </Box>
  );
};

export default InternshipsSection;
