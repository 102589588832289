import axios from "axios";
import initialConfig from "context/initialConfig";

export const anonymous_view = async ({ viewedId, viewedType, accessToken }) => {

    const data = JSON.stringify({
        query: `mutation Anonymous_view($viewedId: String!, $viewedType: ViewedType!) {
            anonymous_view(viewedId: $viewedId, viewedType: $viewedType) {
              success
            }
          }`,
        variables: {
            "viewedId": viewedId ?? "",
            "viewedType": viewedType ?? "Internship"
        }
    })

    try {
        const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data, accessToken));
        return res?.data?.data?.view
    } catch (err) {
        return err
    }
}
