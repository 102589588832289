import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// locales
import { useLocales } from 'minimal/locales';
// components
import { SpaceDashboard as SpaceDashboardIcon, Groups2 as Groups2Icon, School as SchoolIcon, AccountBalanceWallet as AccountBalanceWalletIcon, ConfirmationNumber as ConfirmationNumberIcon, TextSnippet as TextSnippetIcon, Style as StyleIcon, BrandingWatermark, Stars, Psychology, CloudSync, Loop } from '@mui/icons-material';
import Label from "minimal/components/label/label";
import { NotificationImportant } from "@mui/icons-material";
import Iconify from "minimal/components/iconify/iconify";
import { Badge } from '@mui/material';

// ----------------------------------------------------------------------

export function useNavData() {

  const { t } = useLocales();
  const { user_userNotificationsCount: notificationCount } = useSelector(state => state.user_userNotifications)

  const pathname = window.location.pathname;

  const { userRole } = useSelector((state) => state.user);

  const data = useMemo(
    () => [
      {
        subheader: t('Dashboard'),
        items:
          userRole === "companyAdmin" ? [
            // { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
            { title: t('Internship'), path: "/", icon: <Groups2Icon />, },
            // { title: t('Team'), path: "/team", icon: <SchoolIcon /> },
            { title: t('Task'), path: "/task", icon: <AccountBalanceWalletIcon /> },
            {
              title: t('Notifications'), path: "/notifications", icon: <NotificationImportant />,
              info: <Badge badgeContent={notificationCount} sx={{ mr: 2 }} color={pathname === '/notifications' ? 'primary' : 'secondary'} />
            },
            { title: t('IF Credit'), path: "/if-credit", icon: <Stars /> },
            { title: t('Messages'), path: "/messages", icon: <ConfirmationNumberIcon /> }, {
              title: t('IF-AI'), path: "/if-ai", icon: <Psychology />,
              info: <Label color="alternative2" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
                {t('BETA')}
              </Label>
            },
            { title: t('Remote Transform'), path: "/remote-transform", icon: <Loop /> },
          ]
            : userRole === "intern" ? [
              // { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
              { title: t('My Internships'), path: "/", icon: <SpaceDashboardIcon />, },
              { title: t('Internships'), path: "/internship", icon: <Groups2Icon /> },
              { title: t('Applications'), path: "/applications", icon: <SchoolIcon />, },
              {
                title: t('Notifications'), path: "/notifications", icon: <NotificationImportant />,
                info: <Badge badgeContent={notificationCount} sx={{ mr: 2 }} color={pathname === '/notifications' ? 'primary' : 'secondary'} />
              },
              { title: t('Premium'), path: "/premium", icon: <Stars /> },
              {
                title: t('IF-AI'), path: "/if-ai", icon: <Psychology />,
                info: <Label color="alternative2" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
                  {t('BETA')}
                </Label>
              },
              // {
              //   title: t('IF Credit'),
              //   path: "#",
              //   icon: <Groups2Icon />,
              //   disabled: true,
              //   caption: 'Coming soon'
              // },
            ] :
              userRole === "highSchoolIntern" ? [
                { title: t('My Internships'), path: "/", icon: <SpaceDashboardIcon />, },
                {
                  title: t('Notifications'), path: "/notifications", icon: <NotificationImportant />,
                  info: <Badge badgeContent={notificationCount} sx={{ mr: 2 }} color={pathname === '/notifications' ? 'primary' : 'secondary'} />
                },
                { title: t('Premium'), path: "/premium", icon: <Stars /> },
                {
                  title: t('IF-AI'), path: "/if-ai", icon: <Psychology />,
                  info: <Label color="alternative2" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
                    {t('BETA')}
                  </Label>
                },
                // {
                //   title: t('IF Credit'),
                //   path: "#",
                //   icon: <Groups2Icon />,
                //   disabled: true,
                //   caption: 'Coming soon'
                // },
              ] :
                [
                  { title: t('Dashboard'), path: "/", icon: <SpaceDashboardIcon /> },
                ]
      },

      // userRole === "intern" && {
      //   subheader: t(t('My Internships')),
      //   items: [
      //     // {
      //     //   title: t('item_by_roles'),
      //     //   path: "/",
      //     //   icon: <SpaceDashboardIcon />,
      //     //   // roles: ['admin', 'manager'],
      //     //   caption: t('only_admin_can_see_this_item'),
      //     // },
      //     {
      //       title: t('Netflix (Demo)'),
      //       path: "/my-internship",
      //       icon: <SpaceDashboardIcon />,
      //       children: [
      //         {
      //           title: t('Tasks'),
      //           path: "/my-internship/tasks",
      //         },
      //         {
      //           title: t('Messages'),
      //           path: "/my-internship/messages",
      //         },
      //         {
      //           title: t('Documents'),
      //           path: "/my-internship/documents/",
      //         },
      //       ],
      //     }
      //   ],
      // },
    ],
    [t]
  );




  return data;
}
