import axios from "axios";
import initialConfig from "context/initialConfig";

export const self_updateOrganizationInvoiceInfo = async ({values, accessToken, type}) => {

  const data = JSON.stringify({
    query: `mutation Self_updateOrganizationInvoiceInfo($input: InvoiceInfoInput!) {
      self_updateOrganizationInvoiceInfo(input: $input) {
        success
      }
    }`,
    variables: {
      "input": {
        "business": {
          "address": values.address,
          "city": values.city,
          "corporateName": values.corporateName,
          "country": values.country,
          "email": values.email,
          "name": values.name,
          "phoneNumber": values.phone,
          "surname": values.surname,
          "taxNumber": values.taxNumber,
          "zip": values.zip,
          "taxOffice": values.taxOffice
        },
        "personal": {
          "address": values.address,
          "city": values.city,
          "country": values.country,
          "email": values.email,
          "id": values.id,
          "surname": values.surname,
          "zip": values.zip,
          "phoneNumber": values.phone,
          "name": values.name
        },
        "type": type
      }
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    if (res.errors?.length > 0) {
      return {
        errorMessage: res?.errors[0].message,
      };
    }
    return res?.data?.data?.self_updateOrganizationInvoiceInfo
  } catch (err) {
    return err
  }
}