import axios from "axios";
import initialConfig from "context/initialConfig";

export const intern_updateInternOnboardingStatusFunc = async ({ values, accessToken }) => {

  const data = JSON.stringify({
    query: `mutation Intern_updateInternOnboardingStatus($status: InternOnboardingStatus!) {
      intern_updateInternOnboardingStatus(status: $status) {
        success
      }
    }`,
    variables: {
      "status": "completed"
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/user/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    return res?.data?.data?.intern_updateInternOnboardingStatus
  } catch (err) {
    return err
  }
}